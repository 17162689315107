//All Actions Starting is the request type like GET,POST,PUT etc..

export * from "./auth/loginAction";
export * from "./auth/TwoFactorAction";
export * from "./users/client2FADetails";
export * from "./users/TwoFactorToggleSettingsAction";
export * from "./users/getLayersAction";
export * from "./currency/addCurrencyAction";
export * from "./currency/getCurrencyAction";
export * from "./users/addLayerAction";
export * from "./sports/getSportsAction";
export * from "./leagues/getLeaguesAction";
export * from "./events/getEventsAction";
export * from "./market/getMarketAction";
export * from "./restorePanel/panelAction";
export * from "./eventManagement/getManageEventsAction";
export * from "./sports/getDbSportsAction";
export * from "./eventManagement/postManageEventsAction";
export * from "./runners/getRunnersAction";
export * from "./leagues/getDbLeaguesAction";
export * from "./runners/getDbRunnersAction";
export * from "./result/resultAction";
export * from "./result/getResultsAction";
export * from "./settings/getSportSettingsAction";
export * from "./settings/postSportSettingsAction";
export * from "./bets/getAllBets";
export * from "./bets/deleteUserBet";
export * from "./market/globalMarketCodes";
export * from "./market/getEventMarkets";
export * from "./deposit/addDepositAction";
export * from "./withdrawal/addWithdrawalAction";
export * from "./withdrawal/getWithdrawalAction";
export * from "./users/getUserBalanceAction";
export * from "./users/addPermissionAction";
export * from "./users/addCreditReferenceAction";
export * from "./users/resetPassword";
export * from "./users/changePassword";
export * from "./reports/getResultTransactionAction";
export * from "./reports/getResultTransactionDetails";
export * from "./bets/getUserBetsByEvent";
export * from "./reports/getReportsTransactionAction";
export * from "./users/getUserExposureAction";
export * from "./market/getFancyMarkets";
export * from "./settlement/settlementAction";
export * from "./dashboard/getDashboardDataAction";
export * from "./dashboard/getBetFairBalanceAction"
export * from "./banking/gatBankingDataAction";
export * from "./settlement/getSettlementAction";
export * from "./currency/globalCurrencyValueAction";
export * from "./users/getInactiveLayersAction";
export * from "./analysis/getAnalysisDataAction";
export * from "./bets/getBetsAndBooks";
export * from "./bets/getBetStatus";
export * from "./auth/logoutAction";
export * from "./auth/loginHistoryAction";
export * from "./settings/getLeagueSettingAction";
export * from "./settings/getMatchSettingAction";
export * from "./settings/postLeagueSettingAction";
export * from "./settings/postMatchSettingAction";
export * from "./events/getDbEventAction";
export * from "./bets/getBetsByMarketType";
export * from "./auth/getAuthDetailAction";
export * from "./reports/getCasinoBetsByDateAction";
export * from "./reports/getCasinoBetsReportAction";
export * from "./reports/getCommissionReportsAction"
export * from "./eventManagement/addOddLimitAction";
export * from "./result/eventDeactivatedAction";
export * from "./result/rollBackAction";
export * from "./eventManagement/deleteMultipleEventsAction";
export * from "./bets/getDeletedBets";
export * from "./bets/getBetFilters";
export * from "./homeSliders/addBannerAction";
export * from "./homeSliders/getBannerAction";
export * from "./homeSliders/updateStatusAction";
export * from "./homeSliders/deleteSliderAction";
export * from "./managers/addManagerAction";
export * from "./managers/getManagerAction";
export * from "./managers/getManagerReports";
export * from "./whiteLabel/addWhiteLabelAction";
export * from "./whiteLabel/getWhiteLabelAction";
export * from "./settings/updateSettingAction";
export * from "./whiteLabel/fetchWhiteLabelData";
export * from "./currency/changeBetairCurrencyAction";
export * from "./users/updateBetfairShares";
export * from "./users/updateCommissionPercentage";
export * from "./countries/getCountries";
export * from "./venues/getVenues";
export * from "./timeRanges/getBetfairTimeRanges";
export * from "./live/getTvUrlAction";
export * from "./eventManagement/updateRadarIDAction";
export * from "./events/addRaceEvents";
export * from "./ssoId/addSsoIDAction";
export * from "./ssoId/betxExchangeSsoidAction"
export * from "./market/setPriorityLineMarketAction";
export * from "./market/getPriorityEventMarketAction";
export * from "./commission/setCommissionValue";
export * from "./commission/currentCommissionStatus";
export * from "./dashboard/getLiveExposureAction"

export * from "./TwoFA/TwoFactorStatusAction"
export * from "./TwoFA/VerifyTwoFactorCodeAction"
export * from "./TwoFA/googleloginValidation"
export * from "./settings/getFancyStakeSettingAction"
export * from "./settings/postFancyStakeSettinAction"

export * from "./TwoFA/googleAuthenticatorOTPValidation"
export * from "./TwoFA/generateQRcode"

export * from "./dashboard/getLiveExposureAction";
export * from "./TwoFA/TwoFactorStatusAction";
export * from "./TwoFA/VerifyTwoFactorCodeAction";
export * from "./TwoFA/googleloginValidation";
export * from "./eventManagement/getLineMarketsAction";
export * from "./eventManagement/addNewMarketAction";
export * from "./apk/upload";
export * from "./homeSliders/manageSportsAccordingly";
export * from "./homeSliders/setSportsPriority";

export * from "./homeSliders/manageLeagueAccordingly";
export * from "./homeSliders/setLeaguePriority";
export * from "./notification/postNotification";
export * from "./notification/getAllusers";
export * from "./notification/deleteNotification";
export * from "./notification/editNotification";
export * from "./bets/getErrorBets";
export * from "./bets/ChangeStausErrorBet";
export * from "./restrictClientBet/restrictClientBets";

export * from "./betfairEngine/betFairEngineAction";
export * from "./eventManagement/getLeagueEventData";
export * from "./eventManagement/deleteEventsAction";
export * from "./eventManagement/SearchEventData";

export * from "./currency/layerCurrencyAction";
export * from "./marketAnalysis/getSportsByMarketId";
export * from "./marketAnalysis/getBetsByMarketId";
export * from "./marketAnalysis/getMarketAnalysisData";
export * from "./marketAnalysis/searchEvents";
export * from "./eventManagement/updateChannelCodeAction";
export * from "./dashboard/getDashBoardDetailsAction";
export * from "./horseRace/addRaceEventsAll";
export * from "./users/updateClientSharesAction";
export * from "./bets/getBetsOfRunningMarkerAction";
export * from "./bets/getViewBetsOfRunningMarkerAction";

//STX configuration
export * from "./STX/getSTXDataAction";
export * from "./STX/getSTXMarketAssigningAction";
export * from "./STX/getAccountDetails";