import React, { useState } from "react";
import { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useEffect } from "react";
import MarketsModal from "../modal/MarketsModal";
import { groupArrayasObject } from "../../../../utils/helper";
import { eventMarketPriority } from "../../../../Constant";
import MatchOddsCard from "./odds/MatchOddsCard";
import BookMakerCard from "./odds/BookMakerCard";

import BookView from "./BookView";
import { useTranslation } from "react-i18next";
import {
  findEventFancyOdds,
  findFancyRunnerBooks,
  groupArrayasObjectLineByMarketId,
  findLineMarketBooks,
  findRunnerBooks,
  // groupArrayasObject,
} from "../../../../utils/helper";
import FancyOddsCard from "./odds/FancyOddsCard";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../../utils/oddApi";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";
import STX_DATA_API from "../../../../utils/STXDataApi";
import STXOddsCard from "./odds/STXOddsCard";
import { STX_SLUG_NAME } from "../../../../utils/constants";

const RunnersCard = ({
  eventName,
  eventMarkets,
  marketCodes,
  stxMarketCodes,
  allBets,
  eventCode,
  sportCode,
  eventId,
  currencyId,
  setBetFairBooks,
  betFairBooks,
  sharedBooks,
  setSharedBooks,
  setBetIds,
  betIds,
  currency,
  matchType,
  viewDeleted,
  socketData,
  setTotalPLByColumnResult,
  totalPLByColumnResult,
  isColumnTotalNewBet,
  setIsColumnTotalNewBet,
  isColumnTotalNewBetCurrency,
  setIsColumnTotalNewBetCurrency
}) => {

  let fancyData = useSelector(
    (state) => state?.getFancyMarkets?.fancyData?.data
  );
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [marketBets, setMarketBets] = useState([]);
  const [groupedBets, setGroupedBets] = useState([]);
  const [modalTitle, setModalTitle] = useState([]);
  const [marketId, setMarketId] = useState("");
  const [fancyBets, setFancyBets] = useState([]);
  const [lineMarketBets, setLineMarketBets] = useState([]);
  const [fancyMarkets, setFancyMarkets] = useState([]);
  const [marketOdds, setMarketOdds] = useState([]);
  const [stxMarketOdds, setStxMarketOdds] = useState([]);
  const [fancyOdds, setFancyOdds] = useState([]);
  const [marketType, setMarketType] = useState("");
  // console.log("matchType",matchType);
  // const [totalPLByColumnResult, setTotalPLByColumnResult] = useState([]);
  const [totalPLCalculation, setTotalPLCalculation] = useState([]);
  const [matchOddsCaculations, setMatchOddsCaculations] = useState([]);

  useEffect(() => {
    if (allBets !== undefined && allBets.length !== 0) {
      const grouped = groupArrayasObject(allBets?.bets, "marketId", "_id");
      setGroupedBets(grouped);
    }
  }, [allBets]);

  useEffect(() => {
    if (allBets !== undefined && allBets.length !== 0) {
      const grouped = groupArrayasObject(allBets?.bets, "marketId", "_id");
      setGroupedBets(grouped);
      const fancyBets = allBets?.bets?.filter(
        (bet) => bet?.marketType === "fancy"
      );
      const lineBets = allBets?.bets?.filter(
        (bet) => bet?.bettingType === "LINE"
      );

      const fancyGrouped = groupArrayasObject(fancyBets, "selectionId");

      const lineGrouped = groupArrayasObjectLineByMarketId(lineBets);
      let line_grouped = lineGrouped === undefined ? [] : lineGrouped;
      let fancy_grouped = fancyGrouped === undefined ? [] : fancyGrouped;
      setLineMarketBets(line_grouped);
      setFancyBets(fancy_grouped);
    }
  }, [allBets]);
  // console.log("marketType", marketType);
  useEffect(() => {
    let interval;
    if (marketCodes?.length > 0) {
      if (matchType === "/running-markets/exchange") {
        interval = setInterval(() => {
          api
            .post("/odds/event-exch", marketCodes)
            .then((response) => {
              if (response?.data?.data?.length > 0) {
                setMarketOdds(response?.data?.data);
              }
            })
            ?.catch((error) => {
              console.log("error", error);
            });
        }, 900);
      } else {
        interval = setInterval(() => {
          api
            .get(`/odds/event-fancy?id=${eventCode}`)
            .then((response) => {
              if (response?.data?.data && response?.data?.data !== null) {
                setFancyOdds(response?.data?.data);
              }
            })
            ?.catch((error) => {
              console.log("error", error);
            });
        }, 1500);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [marketCodes]);

    // STX ODDS
    useEffect(() => {
      console.log("stxMarketCodes", stxMarketCodes);
      if (stxMarketCodes.length !== 0) {
        console.log("stsmarketCodes function is calling");
        const fetchSTXOdds = async () => {
          let marketIds = { marketIds: stxMarketCodes };
          STX_DATA_API
            .post("/v1/get-market-infos", marketIds)
            .then((response) => {
              if (response?.data?.data?.length > 0) {
                setStxMarketOdds((prevState) => {
                  // setPrevOddData(prevState);
                  return response?.data?.data;
                });
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        };
  
        if (stxMarketCodes?.length > 0) {
          fetchSTXOdds();
        }
      }
    }, [stxMarketCodes]);

  useEffect(() => {
    if (fancyData?.data != null) {
      // Update the value of arrayOfObjects inside the useEffect
      const updatedArrayOfObjects = Object?.entries(fancyData?.data)?.map(
        ([key, value]) => {
          const parsedValue = JSON.parse(value);
          return parsedValue;
        }
      );
      setFancyMarkets(updatedArrayOfObjects);
    }
  }, [fancyData]);

  const handleShowModal = (marketId, marketName, type) => {
    if (groupedBets !== undefined) {
      setMarketBets(groupedBets[marketId]);
    }
    setOpenModal(!openModal);
    setMarketId(marketId);
    setModalTitle(marketName);
    setMarketType(type);
  };

  const handleShowFancyModal = (marketId, marketName) => {
    const bets = allBets?.bets?.filter((bet) => bet?.selectionId === marketId);
    setMarketBets(bets);
    setOpenModal(!openModal);
    setModalTitle(marketName);
  };

  return (
    <Fragment>
      {eventMarkets
        .sort((a, b) => {
          const priorityA = eventMarketPriority[a.marketName] || 9999;
          const priorityB = eventMarketPriority[b.marketName] || 9999;

          return (
            priorityA - priorityB || a.marketName.localeCompare(b.marketName)
          );
        })
        ?.map((market, index) => {
          let odds;
          if (market?.type === "exchange") {
            odds = marketOdds?.find(
              (odds) => odds?.marketId === market?.marketId
            );
            if(odds === undefined){
              odds = stxMarketOdds?.find((odd) => odd?.marketId === market?.marketId);
            }
          } else if (market?.type === "bookmaker") {
            odds = fancyOdds?.bkmr;
          } else {
            odds = fancyOdds?.fancy;
          }
          if (odds && market?.runners?.length !== 0) {
            // let runnerBook;
            // if (groupedBets !== undefined) {
            //   runnerBook = findRunnerBooks(
            //     groupedBets[market?._id],
            //     market?.runners,
            //     market?._id
            //   );
            // }
            if (
              (matchType === "/running-markets/exchange" &&
                market?.type === "exchange") ||
              (matchType === "/running-markets/bookmaker-fancy" &&
                market?.type === "bookmaker")
            ) {
              let lineMarketBooks = [];
              if (lineMarketBets !== undefined) {
                // lineMarketBooks = findLineMarketBooks(
                //   lineMarketBets[market?._id],
                //   false,
                //   totalPLByColumnResult,
                //   book,
                //   sharedBooks,
                //   betFairBooks
                // );
              }
        
              return (
                <Card className="mt-3" key={index}>
                  {matchType === "/running-markets/exchange" &&
                    market?.type === "exchange" ? (
                    <>
                      <CardHeader className="p-0">
                        <div className="d-flex align-items-center px-3 py-2">
                          <Col className=" market-type">
                            {market?.marketName}
                          </Col>
                          <Col className="d-flex justify-content-end">
                            <button
                              className="btn d-flex gap-1 align-items-center text-center bet-button"
                              onClick={() =>
                                handleShowModal(market?._id, market?.marketName, market?.bettingType)
                              }
                            >
                              <span className="d-flex gap-1 align-items-center m-auto">
                                <span
                                  className="fs-6 text-light d-flex"
                                  style={{ fontSize: "12px" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-file-text"
                                    style={{ height: "18px" }}
                                  >
                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                    <polyline points="14 2 14 8 20 8"></polyline>
                                    <line x1="16" y1="13" x2="8" y2="13"></line>
                                    <line x1="16" y1="17" x2="8" y2="17"></line>
                                    <polyline points="10 9 9 9 8 9"></polyline>
                                  </svg>
                                </span>
                                <span className="text-light">{t("BETS")}</span>
                              </span>
                            </button>
                          </Col>
                        </div>
                        {groupedBets !== undefined && (
                          <BookView
                            eventName={eventName}
                            runners={market?.runners}
                            market={market}
                            // allBets={allBets}
                            // allBets={groupedBets[market?.marketId]}
                            setBetFairBooks={setBetFairBooks}
                            betFairBooks={betFairBooks}
                            marketId={market?._id}
                            runnerBook={allBets?.books}
                            sharedBooks={sharedBooks}
                            setSharedBooks={setSharedBooks}
                            currency={currency}
                            setTotalPLByColumnResult={setTotalPLByColumnResult}
                            setTotalPLCalculation={setTotalPLCalculation}
                            setMatchOddsCaculations={setMatchOddsCaculations}
                            stx={market?.slugName === STX_SLUG_NAME}
                          />
                        )}
                      </CardHeader>
                  
                  
                      {market.slugName === STX_SLUG_NAME ? (
                      <CardBody className="p-0 pt-2 pb-4 px-2" key={index}>
                      <STXOddsCard
                          eventName={eventName}
                          matchOddsCaculations={matchOddsCaculations}
                          totalPLCalculation={totalPLCalculation}
                          marketName={market?.marketName}
                          eventId={eventId}
                          sportCode={sportCode}
                          currencyId={currencyId}
                          runners={
                            market?.type === "fancy"
                              ? fancyMarkets
                              : market?.runners
                          }
                          market={market}
                          marketId={market?._id}
                          runnerBook={
                            allBets?.books !== undefined &&
                            allBets?.books[market?._id]
                          }
                          runnerBookRefresh={allBets?.books}
                          sharedBooks={sharedBooks}
                          odds={odds}
                          currency={currency}
                          betFairBooks={betFairBooks}
                          line={market?.bettingType === "LINE" ? "LINE" : null}
                          marketBooks={
                            lineMarketBooks !== undefined ? lineMarketBooks : []
                          }
                          lineMarketBets={lineMarketBets}
                          totalPLByColumnResult={totalPLByColumnResult}
                          isColumnTotalNewBet={isColumnTotalNewBet}
                          setIsColumnTotalNewBet={setIsColumnTotalNewBet}
                          isColumnTotalNewBetCurrency={isColumnTotalNewBetCurrency}
                          setIsColumnTotalNewBetCurrency={setIsColumnTotalNewBetCurrency}
                        />
                        <Row className="">
                          <p className="stake-amt text-black">
                            Min: <span>100 </span> | Max: <span>100K</span>
                          </p>
                        </Row>
                      </CardBody>
                      ):(
                      <CardBody className="p-0 pt-2 pb-4 px-2" key={index}>
                      <MatchOddsCard
                          eventName={eventName}
                          matchOddsCaculations={matchOddsCaculations}
                          totalPLCalculation={totalPLCalculation}
                          marketName={market?.marketName}
                          eventId={eventId}
                          sportCode={sportCode}
                          currencyId={currencyId}
                          runners={
                            market?.type === "fancy"
                              ? fancyMarkets
                              : market?.runners
                          }
                          market={market}
                          marketId={market?._id}
                          runnerBook={
                            allBets?.books !== undefined &&
                            allBets?.books[market?._id]
                          }
                          runnerBookRefresh={allBets?.books}
                          sharedBooks={sharedBooks}
                          odds={odds}
                          currency={currency}
                          betFairBooks={betFairBooks}
                          line={market?.bettingType === "LINE" ? "LINE" : null}
                          marketBooks={
                            lineMarketBooks !== undefined ? lineMarketBooks : []
                          }
                          lineMarketBets={lineMarketBets}
                          totalPLByColumnResult={totalPLByColumnResult}
                          isColumnTotalNewBet={isColumnTotalNewBet}
                          setIsColumnTotalNewBet={setIsColumnTotalNewBet}
                          isColumnTotalNewBetCurrency={isColumnTotalNewBetCurrency}
                          setIsColumnTotalNewBetCurrency={setIsColumnTotalNewBetCurrency}
                        />
                        <Row className="">
                          <p className="stake-amt text-black">
                            Min: <span>100 </span> | Max: <span>100K</span>
                          </p>
                        </Row>
                      </CardBody>
                      )}
                     
                     
                      
                    </>
                  ) : null}
                  {matchType === "/running-markets/bookmaker-fancy" &&
                    market?.type === "bookmaker" ? (
                    <>
                      <CardHeader className="p-0">
                        <div className="d-flex align-items-center px-3 py-2">
                          <Col className=" market-type">
                            {market?.marketName}
                          </Col>
                          <Col className="d-flex justify-content-end">
                            <button
                              className="btn d-flex gap-1 align-items-center text-center bet-button"
                              onClick={() =>
                                handleShowModal(market?._id, market?.marketName)
                              }
                            >
                              <span className="d-flex gap-1 align-items-center m-auto">
                                <span
                                  className="fs-6 text-light d-flex"
                                  style={{ fontSize: "12px" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-file-text"
                                    style={{ height: "18px" }}
                                  >
                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                    <polyline points="14 2 14 8 20 8"></polyline>
                                    <line x1="16" y1="13" x2="8" y2="13"></line>
                                    <line x1="16" y1="17" x2="8" y2="17"></line>
                                    <polyline points="10 9 9 9 8 9"></polyline>
                                  </svg>
                                </span>
                                <span className="text-light">{t("BETS")}</span>
                              </span>
                            </button>
                          </Col>
                        </div>
                        {/* {groupedBets !== undefined && (
                          <BookView
                            eventName={eventName}
                            matchOddsCaculations={matchOddsCaculations}
                            runners={market?.runners}
                            allBets={allBets}
                            // allBets={groupedBets[market?.marketId]}
                            setBetFairBooks={setBetFairBooks}
                            betFairBooks={betFairBooks}
                            marketId={market?._id}
                            runnerBook={allBets?.books}
                            sharedBooks={sharedBooks}
                            setSharedBooks={setSharedBooks}
                            currency={currency}
                            setMatchOddsCaculations={setMatchOddsCaculations}
                          />
                        )} */}
                      </CardHeader>
                      <CardBody className="p-0 pt-2 pb-4 px-2" key={index}>
                        <BookMakerCard
                          runners={market?.runners}
                          runnerBook={
                            allBets?.books !== undefined &&
                            allBets?.books[market?._id]
                          }
                          sharedBooks={sharedBooks}
                          odds={odds}
                        />

                        <Row className="">
                          <p className="stake-amt text-black">
                            Min: <span>100 </span> | Max: <span>100K</span>
                          </p>
                        </Row>
                      </CardBody>
                    </>
                  ) : null}
                </Card>
              );
            }
          }
        })}

      {fancyOdds?.fancy !== undefined && fancyMarkets?.length > 0 ? (
        <>
          {fancyMarkets?.map((runner, index) => {
            const eventFancy = fancyOdds?.fancy;
            let currentRunnerodd;
            let marketBooks;
            if (eventFancy && Object?.values(eventFancy)?.length !== 0) {
              currentRunnerodd = eventFancy[runner?.id];
              currentRunnerodd =
                currentRunnerodd && JSON.parse(currentRunnerodd);
              if (fancyBets?.hasOwnProperty(runner?.id)) {
                marketBooks = findFancyRunnerBooks(fancyBets[runner?.id]);
              }
              if (fancyOdds?.length !== 0) {
                return (
                  <Card key={index} className="pb-1 p-2">
                    <Row className="p-2">
                      <Col className=" market-type">{runner?.name}</Col>
                      <Col className="d-flex justify-content-end">
                        <button
                          className="btn px-3 d-flex gap-1 align-items-center text-center bet-button"
                          onClick={() =>
                            handleShowFancyModal(runner?.id, runner?.name)
                          }
                        >
                          <span className="d-flex gap-1 align-items-center m-auto">
                            <span
                              className="fs-6 text-light d-flex"
                              style={{ fontSize: "12px" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-file-text"
                                style={{ height: "18px" }}
                              >
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                <polyline points="14 2 14 8 20 8"></polyline>
                                <line x1="16" y1="13" x2="8" y2="13"></line>
                                <line x1="16" y1="17" x2="8" y2="17"></line>
                                <polyline points="10 9 9 9 8 9"></polyline>
                              </svg>
                            </span>
                            <span className="text-light">{t("BETS")}</span>
                          </span>
                        </button>
                      </Col>
                    </Row>
                    <hr
                      className="my-0"
                      style={{ borderTop: "1px solid #ccc" }}
                    />
                    <FancyOddsCard
                      currentRunnerodd={currentRunnerodd}
                      runner={runner}
                      marketBooks={marketBooks}
                    />
                  </Card>
                );
              }
            }
          })}
        </>
      ) : (
        <>
          {eventMarkets?.map((market, index) => {
            if (market.type === "fancy" && fancyBets !== undefined) {
              const marketBooks = findFancyRunnerBooks(
                fancyBets[market?.selectionId]
              );
              return (
                <Card key={index} className="pb-1 p-2">
                  <Row className="p-2">
                    <Col className=" market-type">{market?.marketName}</Col>
                    <Col className="d-flex justify-content-end">
                      <button
                        className="btn px-3 d-flex gap-1 align-items-center text-center bet-button"
                        onClick={() =>
                          handleShowFancyModal(
                            market?.selectionId,
                            market?.marketName
                          )
                        }
                      >
                        <span className="d-flex gap-1 align-items-center m-auto">
                          <span
                            className="fs-6 text-light d-flex"
                            style={{ fontSize: "12px" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-file-text"
                              style={{ height: "18px" }}
                            >
                              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                              <polyline points="14 2 14 8 20 8"></polyline>
                              <line x1="16" y1="13" x2="8" y2="13"></line>
                              <line x1="16" y1="17" x2="8" y2="17"></line>
                              <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                          </span>
                          <span className="text-light">Bets</span>
                        </span>
                      </button>
                    </Col>
                  </Row>
                  <hr
                    className="my-0"
                    style={{ borderTop: "1px solid #ccc" }}
                  />
                  <FancyOddsCard
                    // currentRunnerodd={}
                    // runner={market}
                    marketBooks={marketBooks}
                  />
                </Card>
              );
            }
          })}
        </>
      )}
      {openModal && <MarketsModal
        isOpen={openModal}
        title={modalTitle}
        toggler={handleShowModal}
        size="lg"
        // modalData={marketBets}
        betIds={betIds}
        setBetIds={setBetIds}
        pagination={true}
        isMultiple={false}
        marketId={marketId}
        viewDeleted={viewDeleted}
        socketData={socketData}
        eventId={eventId}
        currency={currency}
        matchType={matchType}
        marketType={marketType}
      />}

    </Fragment>
  );
};

export default RunnersCard;
