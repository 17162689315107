import PropTypes from "prop-types";
import { MARKETS_TYPES } from "../Constant";
import { toast } from "react-toastify";
import NotificationSound from "../assets/audio/notification-sound.mp3";
import DeleteSound from "../assets/audio/delete.mp3";
import { handleLineBookPlAsPerCs } from "./book";
import { awayRoles, drawRoles, homeRoles } from "./constants";
export const url = process.env.REACT_APP_API_ENDPOINT;
export const betxExchangeUrl = process.env.REACT_APP_BETX_EXCHANGE_API_ENDPOINT || ""
export const x_auth_key = process.env.REACT_APP_BETX_EXCHANGE_X_AUTH_KEY  || ""
export const betxexchangeid = process.env.REACT_APP_BETX_EXCHANGE_ID || ""
export const oddUrl = process.env.REACT_APP_ODD_API_ENDPOINT || "";

//STX DATA api
export const STX_api = process.env.REACT_APP_API_ENDPOINT_STX_DATA || "";

//horse race api
export const horseRace_API = process.env.REACT_APP_API_ENDPOINT_HORSE_RACE;

//GET LOCAL STORAGE ITEM
export const getLocalStorageItem = (key) => localStorage.getItem(key);

//SET VALUE TO LOCAL STORAGE
export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, value);

//REMOVE ITEM FROM LOCALSTORAGE
export const removeLocalStorageItem = (key) => localStorage.removeItem(key);

//GET DEFAULT VALUES USING LOCALSTORAGE

export const getDefaultState = (keyName) => {
  const storedValue = localStorage.getItem(keyName);

  if (storedValue !== null && storedValue !== undefined) {
    try {
      const value = JSON.parse(storedValue);

      return value;
    } catch (error) {
      console.error("Error parsing localStorage value:", error);
    }
  } else {
    return null;
  }
};

export const ErrorToast = ({ msg }) => (
  <div>
    <svg
      width="1.0625em"
      height="1em"
      viewBox="0 0 17 16"
      className="bi bi-exclamation-triangle mb-1 mr-1"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
      />
      <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
    </svg>
    &nbsp;&nbsp;
    {msg}
  </div>
);
ErrorToast.propTypes = {
  msg: PropTypes.string,
};

export const SuccessToast = ({ msg }) => (
  <div>
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      className="bi bi-check-circle mb-1 mr-1"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
      />
      <path
        fillRule="evenodd"
        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
      />
    </svg>
    &nbsp;&nbsp;
    {msg}
  </div>
);
SuccessToast.propTypes = {
  msg: PropTypes.string,
};

export const WarningToast = ({ msg }) => (
  <div>
    <svg
      width="1.0625em"
      height="1em"
      viewBox="0 0 17 16"
      className="bi bi-exclamation-triangle mb-1 mr-1"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
      />
      <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
    </svg>
    &nbsp;&nbsp;
    {msg}
  </div>
);
WarningToast.propTypes = {
  msg: PropTypes.string,
};

// Convert URL text to Camel cases.

export const convertToCamelCase = (text) => {
  const words = text.split("-");

  // const convertedWords = words?.map((word, index) => {
  //   if (index === 0) {
  //     return word;
  //   } else {
  //     return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  //   }
  // });

  const convertedText = words.join("");

  return convertedText;
};

//Create Select Options

export const createOption = (data, keep = "null") => {
  if (data && data?.length !== 0) {
    const result = data?.map((elem) => {
      const option = {
        label:
          elem?.name === undefined
            ? elem?.marketName === undefined
              ? elem?.runnerName
              : elem?.marketName
            : elem?.name,
        value: elem?._id,
      };
      if (keep !== "null") {
        option[keep] = elem?.[keep];
      }
      return option;
    });
    return result;
  }
};

//Function to format Date in the format 29 June 5:59PM

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Format day
  const day = date.getDate();
  const formattedDay = day < 10 ? `0${day}` : day;

  // Format month
  const month = date?.toLocaleString("default", { month: "long" });

  // Format time
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? `0${minutes}` : minutes
    }${hours < 12 ? "AM" : "PM"}`;

  // Construct the formatted date string
  const formattedDate = `${formattedDay} ${month} ${formattedTime}`;

  return formattedDate;
};

// Function to group Array in the format of object
// @ pass Array to group , along with the key which need to group based on.
export const groupArrayasObject = (array, groupBy, subGroup) => {
  if (array && array?.length !== 0) {
    // console.log({ array: array, groupBy: groupBy, subGroup: subGroup });
    const result = array?.reduce((result, obj) => {
      let eventId;
      if (obj !== null) {
        if (obj?.sportBets) {
          // console.log("groupBy 1",groupBy);
          // console.log("obj 1",obj);
          eventId = subGroup
            ? obj?.sportBets[groupBy][subGroup]
            : obj?.sportBets[groupBy];
        } else {
          // console.log("--------------");
          // console.log("groupBy",groupBy);
          // console.log("obj",obj);
          eventId = subGroup ? obj[groupBy][subGroup] : obj[groupBy];
        }
        if (!result[eventId]) {
          result[eventId] = [];
        }
        result[eventId]?.push(obj);

        return result;
      }
    }, {});
    // console.log({ result: result });
    return result;
  }
};

export const groupSTXArrayasObject = (array, groupBy, subGroup) => {
  if (array && array.length !== 0) {
    const result = array.reduce((result, obj) => {
      let eventId;
      const exclude = ["cancelled"]
      // Check if the object is not null and eventstatus is not "cancelled"
      if (obj !== null && !exclude.includes(obj?.eventStatus) ) {
        if (obj?.sportBets) {
          // Handle grouping by subGroup if provided
          eventId = subGroup 
            ? obj?.sportBets[groupBy][subGroup] 
            : obj?.sportBets[groupBy];
        } else {
          // Group based on top-level object if sportBets is not present
          eventId = subGroup 
            ? obj[groupBy][subGroup] 
            : obj[groupBy];
        }

        // Initialize an array for the group if it doesn't exist
        if (!result[eventId]) {
          result[eventId] = [];
        }

        // Add the current object to the corresponding group
        result[eventId].push(obj);
      }

      return result;
    }, {});

    return result;
  }
  return {}; // Return an empty object if input array is null/empty
};

export const groupArrayasObjectLineByMarketId = (array) => {
  if (array && array?.length !== 0) {
    const groupedData = array?.reduce((acc, obj) => {
      const marketId = obj.marketId._id;
      if (!acc[marketId]) {
        acc[marketId] = [];
      }
      acc[marketId].push(obj);
      return acc;
    }, {});
    return groupedData;
  }
};

export const groupArrayasObjects = (array, groupBy, subGroup) => {
  const result = array?.reduce((result, obj) => {
    let eventId;
    if (obj) {
      eventId = subGroup ? obj[groupBy][subGroup] : obj[groupBy];
    } else {
      eventId = obj[groupBy];
    }
    if (!result[eventId]) {
      result[eventId] = [];
    }
    result[eventId].push(obj);
    return result;
  }, {});
  return result;
};

//To Find odds for the runner in Market From the API data emit by Socket

export const findRunnerOdds = (odds, runnerCode) => {
  if (odds?.length !== 0) {
    var currentodd = odds?.runners?.find((odd) => {
      const data = parseFloat(odd?.selectionId) === parseFloat(runnerCode);
      return data;
    });
    return currentodd;
  }
};

//Find Direct Parent from Parents Array
export const finduserParent = (array) => {
  if (array?.length === 0) {

  } else {
    const result = array?.reduce((maxObj, obj) => {
      return obj?.role > maxObj?.role ? obj : maxObj;
    }, array[0]);
    return result;
  }
};

//Find Direct Parent from Parents Array
export const findAdminParent = (array, role) => {
  // Ensure the input array is not empty and contains objects with a 'role' property
  if (!Array.isArray(array) && array?.length === 0) {
    return null; // Return null or handle the empty array case as needed
  } else {
    if (array?.length === 1) {
      return array[0];
    } else {
      const filteredArray = array?.filter((obj) => obj?.role !== role);
      // Find the object with the 'role' closest to the given 'role' number
      if (Array.isArray(filteredArray) && filteredArray?.length !== 0) {
        const closestObject = filteredArray?.reduce((closest, current) => {
          const closestDiff = Math.abs(closest?.role - role);
          const currentDiff = Math.abs(current?.role - role);

          if (currentDiff < closestDiff) {
            return current;
          }

          return closest;
        });

        return closestObject;
      } else {
        return null;
      }
    }
  }
};

//Find odds for the runners for bookmaker market
export const findBookMakerOdds = (odds, runnerCode) => {
  if (odds !== null && odds !== undefined) {
    if (odds && odds !== undefined) {
      const runners = JSON.parse(odds?.data?.runners);
      return runners[runnerCode];
    }
  }
};

//make first letter capital
export const capitalizeFirstLetter = (string) => {
  if(string === undefined) return "";
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

//Find odds for current market from array of fancies for events
export const findEventFancyOdds = (allOdds, eventCode) => {
  try {
    for (let i = 0; i < allOdds?.length; i++) {
      const key = Object.keys(allOdds[i])[0];
      if (key === `fancy-${eventCode}`) {
        return allOdds[i][key];
      }
    }
    return null; // If the code is not found in the array
  } catch (error) {
    console.log(error);
    return null;
  }
};

const betOnBack = {
  lose: (odds, stake) => {
    return -1 * Math.round(parseFloat(odds - 1).toFixed(2) * stake);
  },
  profit: (stake) => {
    return stake;
  },
};

// Calculate for Bet on Lay
const betOnLay = {
  profit: (odds, stake) => {
    return Math.round(parseFloat(odds - 1).toFixed(2) * stake);
  },
  lose: (stake) => {
    return -1 * stake;
  },
};

const betOnLayBookmaker = {
  profit: (odds, stake) => {
    return Math.round(parseFloat(odds / 100).toFixed(2) * stake);
  },
  lose: (stake) => {
    return -1 * stake;
  },
};
// Calculate Book for Bet on Lay for BOOKMAKER ----------
const betOnBackBookmaker = {
  lose: (odds, stake) => {
    return -1 * Math.round(parseFloat(odds / 100).toFixed(2) * stake);
  },
  profit: (stake) => {
    return stake;
  },
};

export const findRunnerBooks = (allBets, runners, marketId, user) => {
  let runnerBook = [];
  runners?.forEach((runner) => {
    let data = {
      name: runner?.runnerName,
      pl_pr: 0,
      pl: 0,
    };
    if (user) {
      data = { ...data, admin: user };
    }
    runnerBook.push(data);
  });

  allBets?.forEach((bet) => {
    if (bet?.marketId?._id === marketId) {
      runnerBook?.forEach((runner) => {
        if (String(runner?.name) === String(bet?.selection)) {
          // if (bet?.userId?.parents?.length === 1) {
          //   runner.child = bet?.userId?.username;
          // } else {
          //   const result = bet?.userId?.parents?.reduce((maxObj, obj) => {
          //     return obj.role > maxObj.role ? obj : maxObj;
          //   }, bet?.userId?.parents[0]);
          //   runner.child = result.username;
          // }
          if (bet?.marketType === MARKETS_TYPES?.EXCH.toLowerCase()) {
            if (bet?.selectionType === "back") {
              runner.pl += betOnBack.lose(bet?.odds, bet?.stake);
            } else {
              runner.pl += betOnLay.profit(bet?.odds, bet?.stake);
            }
          } else {
            if (bet?.selectionType === "back") {
              runner.pl += betOnBackBookmaker.lose(bet?.odds, bet?.stake);
            } else {
              runner.pl += betOnLayBookmaker.profit(bet?.odds, bet?.stake);
            }
          }
        } else {
          if (bet?.marketType === MARKETS_TYPES?.EXCH.toLowerCase()) {
            if (bet?.selectionType === "back") {
              runner.pl += betOnBack.profit(bet?.stake);
            } else {
              runner.pl += betOnLay.lose(bet?.stake);
            }
          } else {
            if (bet?.selectionType === "back") {
              runner.pl += betOnBackBookmaker.profit(bet?.stake);
            } else {
              runner.pl += betOnLayBookmaker.lose(bet?.stake);
            }
          }
        }
      });
    }
  });
  return runnerBook;
};

export const findFancyRunnerBooks = (fancyBets) => {
  if (fancyBets !== undefined && fancyBets?.length !== 0) {
    let book = [];
    let real_books = [];
    if (fancyBets?.length > 0) {
      let min_odd = Math.min(...fancyBets?.map((bet) => bet?.odds));
      let max_odd = Math.max(...fancyBets?.map((bet) => bet?.odds));
      for (let i = min_odd - 5; i < max_odd + 5; i++) {
        if (i >= 0) {
          let filteredBets = fancyBets?.filter((bet) => bet?.odds === i);
          if (filteredBets?.length > 0) {
            filteredBets?.forEach((bet) => {
              let my_pr = 100; //my_pr means percentage share
              let result = 0;
              let pl =
                bet?.selectionType === "lay"
                  ? (bet?.stake * my_pr) / 100
                  : (bet?.stake * (parseInt(bet?.oddsSize) / 100) * my_pr) /
                  100;
              let liability =
                bet?.liability === 0
                  ? (bet?.stake * my_pr) / 100
                  : (bet?.liability * my_pr) / 100;
              if (bet?.selectionType === "lay") {
                result = pl;
              } else {
                result = -liability;
              }
              book.push({
                odds: i,
                liability: liability,
                pl: pl,
                type: bet?.selectionType,
                result: result,
              });
            });
          } else {
            book.push({
              odds: i,
              liability: 0,
              pl: 0,
              type: null,
              result: 0,
            });
          }
        }
      }
    }
    if (book?.length > 0) {
      for (let i = 0; i < book?.length; i++) {
        let value = book[i];
        let result = 0;
        let odds = value.odds;
        for (let j = 0; j < book?.length; j++) {
          let value2 = book[j];
          if (value2.type !== null) {
            if (value2.odds > odds) {
              if (value2.type === "lay") {
                result += value2.pl;
              } else if (value2.type === "back") {
                result -= value2.liability;
              }
            } else if (value2.odds === odds) {
              if (value2.type === "lay") {
                result -= value2.liability;
              } else if (value2.type === "back") {
                result += value2.pl;
              }
            } else if (value2.odds < odds) {
              if (value2.type === "back") {
                result += value2.pl;
              } else if (value2.type === "lay") {
                result -= value2.liability;
              }
            }
          }
        }
        let data = {
          odds: value.odds,
          result: result,
        };
        real_books.push(data);
      }
    }
    // Assuming real_books is an array of objects, similar to the PHP version.
    // The next two lines will make real_books unique based on the 'odds' property and extract only the 'result' property.
    // real_books = real_books.filter(
    //   (book, index, self) =>
    //     index === self.findIndex((b) => b.odds === book.odds)
    // );
    real_books = real_books?.reduce((result, book) => {
      const { odds, result: bookResult } = book;
      if (!result.hasOwnProperty(odds)) {
        result[odds] = bookResult;
      }
      return result;
    }, {});
    // real_books = real_books?.map((book) => book?.result);
    return real_books;
  }
};

// export const findLineMarketBooks = (bet, exp) => {
//   if (bet !== undefined && bet?.length !== 0) {
//     let fancyBets = undefined;
//     fancyBets = bet
//       ?.map((bet_data) => {
//         return {
//           selectionType: bet_data?.selectionType === "back" ? "lay" : "back",
//           odds: Math.round(bet_data?.odds),
//           stake: Math.floor(bet_data?.stake),
//           liability: bet_data?.liability,
//           status: bet_data?.status,
//         };
//       })
//       ?.filter((match) => match?.status === "1");

//     if (fancyBets !== undefined && fancyBets?.length !== 0) {
//       let lay = fancyBets?.filter(
//         (decrement) => decrement?.selectionType === "lay"
//       );
//       let back = fancyBets?.filter(
//         (increment) => increment?.selectionType === "back"
//       );
//       let min = Math.min(...fancyBets?.map((bet) => bet?.odds));
//       let max = Math.max(...fancyBets?.map((bet) => bet?.odds));

//       let min_odd_lay = Math.min(...lay?.map((bet) => bet?.odds));
//       let max_odd_lay = Math.max(...lay?.map((bet) => bet?.odds));

//       let min_odd_back = Math.min(...back?.map((bet) => bet?.odds));
//       let max_odd_back = Math.max(...back?.map((bet) => bet?.odds));

//       const layToBack = (data1, data2) => {
//         let book = [];
//         for (let i = data1 - 2; i < data2 + 4; i++) {
//           const backIsExist = fancyBets?.some(
//             (num) => num.odds === i && num.selectionType === "back"
//           );
//           if (i < min) {
//             let layTotalWinGreaterThenCurrent = fancyBets?.reduce(
//               (accumulator, currentValue) => {
//                 if (
//                   currentValue.selectionType === "lay" &&
//                   currentValue.odds > i
//                 ) {
//                   return accumulator + currentValue.stake;
//                 } else {
//                   return accumulator;
//                 }
//               },
//               0
//             );

//             let backTotalLossGreaterThenCurrent = fancyBets?.reduce(
//               (accumulator, currentValue) => {
//                 if (
//                   currentValue.selectionType === "back" &&
//                   currentValue.odds > i
//                 ) {
//                   return accumulator + currentValue.stake;
//                 } else {
//                   return accumulator;
//                 }
//               },
//               0
//             );

//             let backTotalCurrentWin = fancyBets?.reduce(
//               (accumulator, currentValue) => {
//                 if (
//                   currentValue.selectionType === "back" &&
//                   currentValue.odds <= i
//                 ) {
//                   return accumulator + currentValue.stake;
//                 } else {
//                   return accumulator;
//                 }
//               },
//               0
//             );

//             let layTotalLossLessThenCurrent = fancyBets?.reduce(
//               (accumulator, currentValue) => {
//                 if (
//                   currentValue.selectionType === "lay" &&
//                   currentValue.odds < i
//                 ) {
//                   return accumulator + currentValue.stake;
//                 } else {
//                   return accumulator;
//                 }
//               },
//               0
//             );

//             let layTotalCurrentloss = fancyBets?.reduce(
//               (accumulator, currentValue) => {
//                 if (
//                   currentValue.selectionType === "lay" &&
//                   currentValue.odds === i
//                 ) {
//                   return accumulator + currentValue.stake;
//                 } else {
//                   return accumulator;
//                 }
//               },
//               0
//             );

//             let totalLoss =
//               backTotalLossGreaterThenCurrent +
//               layTotalLossLessThenCurrent +
//               layTotalCurrentloss;
//             let totalProfit =
//               layTotalWinGreaterThenCurrent + backTotalCurrentWin;

//             let final = totalProfit - totalLoss;

//             let reverseForAdmin = 0;
//             if (final > 0) {
//               reverseForAdmin = -final;
//             } else {
//               reverseForAdmin = final;
//             }

//             book.push({
//               odds: i,
//               stake: reverseForAdmin,
//               type: "back",
//               result: reverseForAdmin,
//             });
//           } else {
//             if (backIsExist) {
//               let layTotalWinGreaterThenCurrent = fancyBets?.reduce(
//                 (accumulator, currentValue) => {
//                   if (
//                     currentValue.selectionType === "lay" &&
//                     currentValue.odds > i
//                   ) {
//                     return accumulator + currentValue.stake;
//                   } else {
//                     return accumulator;
//                   }
//                 },
//                 0
//               );

//               let backTotalLossGreaterThenCurrent = fancyBets?.reduce(
//                 (accumulator, currentValue) => {
//                   if (
//                     currentValue.selectionType === "back" &&
//                     currentValue.odds > i
//                   ) {
//                     return accumulator + currentValue.stake;
//                   } else {
//                     return accumulator;
//                   }
//                 },
//                 0
//               );

//               let backTotalCurrentWin = fancyBets?.reduce(
//                 (accumulator, currentValue) => {
//                   if (
//                     currentValue.selectionType === "back" &&
//                     currentValue.odds <= i
//                   ) {
//                     return accumulator + currentValue.stake;
//                   } else {
//                     return accumulator;
//                   }
//                 },
//                 0
//               );

//               let layTotalLossLessThenCurrent = fancyBets?.reduce(
//                 (accumulator, currentValue) => {
//                   if (
//                     currentValue.selectionType === "lay" &&
//                     currentValue.odds < i
//                   ) {
//                     return accumulator + currentValue.stake;
//                   } else {
//                     return accumulator;
//                   }
//                 },
//                 0
//               );

//               let layTotalCurrentloss = fancyBets?.reduce(
//                 (accumulator, currentValue) => {
//                   if (
//                     currentValue.selectionType === "lay" &&
//                     currentValue.odds === i
//                   ) {
//                     return accumulator + currentValue.stake;
//                   } else {
//                     return accumulator;
//                   }
//                 },
//                 0
//               );

//               let totalLoss =
//                 backTotalLossGreaterThenCurrent +
//                 layTotalLossLessThenCurrent +
//                 layTotalCurrentloss;
//               let totalProfit =
//                 layTotalWinGreaterThenCurrent + backTotalCurrentWin;

//               let final = totalProfit - totalLoss;

//               let reverseForAdmin = 0;
//               if (final > 0) {
//                 reverseForAdmin = -final;
//               } else {
//                 reverseForAdmin = final;
//               }

//               book.push({
//                 odds: i,
//                 stake: reverseForAdmin,
//                 type: "back",
//                 result: reverseForAdmin,
//               });
//             } else {
//               const layIsExist = fancyBets?.some(
//                 (num) => num.odds === i && num.selectionType === "lay"
//               );
//               if (layIsExist) {
//                 let layTotalLosslessThenCurrent = fancyBets?.reduce(
//                   (accumulator, currentValue) => {
//                     if (
//                       currentValue.selectionType === "lay" &&
//                       currentValue.odds <= i
//                     ) {
//                       return accumulator + currentValue.stake;
//                     } else {
//                       return accumulator;
//                     }
//                   },
//                   0
//                 );

//                 let layTotalWinGreaterThenCurrent = fancyBets?.reduce(
//                   (accumulator, currentValue) => {
//                     if (
//                       currentValue.selectionType === "lay" &&
//                       currentValue.odds > i
//                     ) {
//                       return accumulator + currentValue.stake;
//                     } else {
//                       return accumulator;
//                     }
//                   },
//                   0
//                 );

//                 let backTotalWin = fancyBets?.reduce(
//                   (accumulator, currentValue) => {
//                     if (
//                       currentValue.selectionType === "back" &&
//                       currentValue.odds < i
//                     ) {
//                       return accumulator + currentValue.stake;
//                     } else {
//                       return accumulator;
//                     }
//                   },
//                   0
//                 );

//                 let backTotalLossGreaterThenCurrent = fancyBets?.reduce(
//                   (accumulator, currentValue) => {
//                     if (
//                       currentValue.selectionType === "back" &&
//                       currentValue.odds > i
//                     ) {
//                       return accumulator + currentValue.stake;
//                     } else {
//                       return accumulator;
//                     }
//                   },
//                   0
//                 );

//                 let totalLoss =
//                   layTotalLosslessThenCurrent + backTotalLossGreaterThenCurrent;

//                 let totalProfit = layTotalWinGreaterThenCurrent + backTotalWin;
//                 let final = totalProfit - totalLoss;

//                 let reverseForAdmin = 0;
//                 if (final > 0) {
//                   reverseForAdmin = -final;
//                 } else {
//                   reverseForAdmin = final;
//                 }

//                 book.push({
//                   odds: i,
//                   stake: reverseForAdmin,
//                   type: "lay",
//                   result: reverseForAdmin,
//                 });
//               }
//             }
//           }
//         }
//         return book;
//       };

//       const minLayToMaxLay = (data1, data2) => {
//         let book = [];
//         for (let i = data1 - 2; i < data2 + 3; i++) {
//           if (i < min_odd_lay) {
//             let reverseForAdmin = 0;
//             let layTotal = fancyBets?.reduce((accumulator, currentValue) => {
//               if (currentValue.selectionType === "lay") {
//                 return accumulator + currentValue.stake;
//               } else {
//                 return accumulator;
//               }
//             }, 0);

//             if (layTotal > 0) {
//               reverseForAdmin = -layTotal;
//             } else {
//               reverseForAdmin = layTotal;
//             }
//             book.push({
//               odds: i,
//               stake: reverseForAdmin,
//               type: "lay",
//               result: reverseForAdmin,
//             });
//           } else {
//             let layTotalPositive = fancyBets?.reduce(
//               (accumulator, currentValue) => {
//                 if (
//                   currentValue.odds > i &&
//                   currentValue.selectionType === "lay"
//                 ) {
//                   return accumulator + currentValue.stake;
//                 } else {
//                   return accumulator;
//                 }
//               },
//               0
//             );

//             let layTotalNegative = fancyBets?.reduce(
//               (accumulator, currentValue) => {
//                 if (
//                   currentValue.odds <= i &&
//                   currentValue.selectionType === "lay"
//                 ) {
//                   return accumulator + currentValue.stake;
//                 } else {
//                   return accumulator;
//                 }
//               },
//               0
//             );
//             let reverseForAdmin = 0;
//             let final = layTotalPositive - layTotalNegative;
//             if (final > 0) {
//               reverseForAdmin = -final;
//             } else {
//               reverseForAdmin = final;
//             }
//             book.push({
//               odds: i,
//               stake: reverseForAdmin,
//               type: "lay",
//               result: reverseForAdmin,
//             });
//           }
//         }
//         return book;
//       };

//       const minBackToMaxBack = (data1, data2) => {
//         let book = [];
//         for (let i = data1 - 1; i < data2 + 2; i++) {
//           if (i < min_odd_back) {
//             let backTotal = fancyBets?.reduce((accumulator, currentValue) => {
//               if (currentValue.selectionType === "back") {
//                 return accumulator + currentValue.stake;
//               } else {
//                 return accumulator;
//               }
//             }, 0);
//             let reverseForAdmin = 0;
//             if (backTotal > 0) {
//               reverseForAdmin = backTotal;
//             } else {
//               reverseForAdmin = -backTotal;
//             }
//             book.push({
//               odds: i,
//               stake: reverseForAdmin,
//               type: "back",
//               result: reverseForAdmin,
//             });
//           } else {
//             let backTotalPositive = fancyBets?.reduce(
//               (accumulator, currentValue) => {
//                 if (
//                   currentValue.odds <= i &&
//                   currentValue.selectionType === "back"
//                 ) {
//                   return accumulator + currentValue.stake;
//                 } else {
//                   return accumulator;
//                 }
//               },
//               0
//             );

//             let backTotalNegative = fancyBets?.reduce(
//               (accumulator, currentValue) => {
//                 if (
//                   currentValue.odds > i &&
//                   currentValue.selectionType === "back"
//                 ) {
//                   return accumulator + currentValue.stake;
//                 } else {
//                   return accumulator;
//                 }
//               },
//               0
//             );

//             let final = -backTotalNegative + backTotalPositive;

//             let reverseForAdmin = 0;
//             if (final > 0) {
//               reverseForAdmin = -final;
//             } else {
//               reverseForAdmin = final;
//             }

//             book.push({
//               odds: i,
//               stake: reverseForAdmin,
//               type: "back",
//               result: reverseForAdmin,
//             });
//           }
//         }
//         return book;
//       };

//       //start
//       let data = null;
//       if (lay?.length > 0 && back?.length > 0) {
//         data = layToBack(min, max);
//       } else if (lay?.length > 0) {
//         data = minLayToMaxLay(min_odd_lay, max_odd_lay);
//         // return data;
//       } else if (back?.length > 0) {
//         data = minBackToMaxBack(min_odd_back, max_odd_back);
//         // return data;
//       }
//       //start

//       let modifiedBooks = data?.map((books) => books.stake);

//       let ex = Math.min(...modifiedBooks);
//       if (exp) {
//         return ex;
//       } else {
//         return data;
//       }
//     }
//   }
// };
export const findLineMarketBooks = (bet, exp, totalPL, book, sharedBooks,
  betFairBooks) => {
  if ((bet !== undefined && bet?.length !== 0) && book?.length > 0) {
    // console.log("bet",bet);
    let fancyBets = undefined;
    let clientShare = 0;
    let betFairShare = 0;
    let cs = 0;
    let bf = 0;
    let directParent = false;
    let clientShareVisibility = false;
    fancyBets = bet
      ?.map((bet_data) => {
        return {
          selectionType: bet_data?.selectionType === "back" ? "lay" : "back",
          odds: Math.round(bet_data?.odds),
          stake: Math.floor(bet_data?.stake),
          liability: bet_data?.liability,
          status: bet_data?.status,
        };
      })
      ?.filter((match) => !["6", "9"]?.includes(match?.status));

    let bookData = book?.find(item => item.name === bet[0]?.selection);
    if ((fancyBets !== undefined && fancyBets?.length !== 0) && bookData !== undefined) {
      if (betFairBooks) {
        betFairShare = Math.abs(totalPL?.find(item => item.name === bet[0]?.selection)?.betFairShare);
        clientShare = Math.abs(totalPL?.find(item => item.name === bet[0]?.selection)?.clientShare);
        bf = (totalPL?.find(item => item.name === bet[0]?.selection)?.betFairShare);
        cs = (totalPL?.find(item => item.name === bet[0]?.selection)?.clientShare);
      } else {
        clientShare = Math.abs(book?.find(item => item.name === bet[0]?.selection)?.clientShare);
        cs = (book?.find(item => item.name === bet[0]?.selection)?.clientShare);
      }

      directParent = bookData?.directParent;
      clientShareVisibility = bookData?.clientShareVisibility;

      let bfCal = 0;
      let csCal = 0;

      // if (bf < 0) {
      //   bfCal = -1 * bf;
      // } else {
      //   bfCal = -bf;
      // }

      // if (cs < 0) {
      //   csCal = -1 * cs;
      // } else {
      //   csCal = -cs;
      // }

      // console.log("totalPL", totalPL);
      // console.log("bookData", bookData?.name);
      // console.log("clientShare", clientShare);
      // console.log("betFairShare", betFairShare);
      // console.log("betFairBooks", betFairBooks);
      // console.log("sharedBooks", sharedBooks);
      let lay = fancyBets?.filter(
        (decrement) => decrement?.selectionType === "lay"
      );
      let back = fancyBets?.filter(
        (increment) => increment?.selectionType === "back"
      );
      let min = Math.min(...fancyBets?.map((bet) => bet?.odds));
      let max = Math.max(...fancyBets?.map((bet) => bet?.odds));

      let min_odd_lay = Math.min(...lay?.map((bet) => bet?.odds));
      let max_odd_lay = Math.max(...lay?.map((bet) => bet?.odds));

      let min_odd_back = Math.min(...back?.map((bet) => bet?.odds));
      let max_odd_back = Math.max(...back?.map((bet) => bet?.odds));

      const layToBack = (data1, data2) => {
        // console.log("layToBack");
        let book = [];
        for (let i = data1 - 2; i < data2 + 4; i++) {
          const backIsExist = fancyBets?.some(
            (num) => num.odds === i && num.selectionType === "back"
          );
          if (i < min) {
            let layTotalWinGreaterThenCurrent = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "lay" &&
                  currentValue.odds > i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let backTotalLossGreaterThenCurrent = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "back" &&
                  currentValue.odds > i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let backTotalCurrentWin = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "back" &&
                  currentValue.odds <= i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let layTotalLossLessThenCurrent = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "lay" &&
                  currentValue.odds < i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let layTotalCurrentloss = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.selectionType === "lay" &&
                  currentValue.odds === i
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let totalLoss =
              backTotalLossGreaterThenCurrent +
              layTotalLossLessThenCurrent +
              layTotalCurrentloss;
            let totalProfit =
              layTotalWinGreaterThenCurrent + backTotalCurrentWin;

            let final = totalProfit - totalLoss;

            let calPl = -1 * final;
            if (calPl < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }

            // console.log({ csCal: csCal, bfCal: bfCal });
            // betFairBooks && sharedBooks
            // let { bookValue, toggle } = handleLineBookPlAsPerCs(betFairBooks, sharedBooks, betFairShare, clientShare, directParent, final, bf, cs);
            let bookValue = 0;
            let toggle = false;
            if (betFairBooks && sharedBooks) {
              if (directParent) {
                bookValue = calPl - bfCal;
              } else {
                bookValue = calPl - bfCal - csCal;
              }
              toggle = true;
            } else if (betFairBooks) {
              bookValue = calPl - bfCal;
              toggle = true;
            } else if (sharedBooks) {
              if (directParent) {
                bookValue = final;
              } else {
                bookValue = calPl - csCal;
              }
              toggle = true;
            } else {
              bookValue = final;
            }

            if (toggle) {
              if (final < 0) {
                if (bookValue > 0) {
                  bookValue = -1 * bookValue;
                }
              } else {
                if (bookValue < 0) {
                  bookValue = -1 * bookValue;
                }
              }
            }

            if (bookValue < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }
            // console.log("layToBack bookValue 1",bookValue);
            // console.log("bookValue 1",bookValue);
            // console.log("final 1",final);
            // betFairBooks && sharedBooks
            book.push({
              odds: i,
              stake: bookValue,
              type: "back",
              pl: final,
              result: bookValue,
              betFairShare: bfCal,
              clientShare: csCal,
              directParent: directParent,
              clientShareVisibility: clientShareVisibility
            });
          } else {
            if (backIsExist) {
              let layTotalWinGreaterThenCurrent = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "lay" &&
                    currentValue.odds > i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let backTotalLossGreaterThenCurrent = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "back" &&
                    currentValue.odds > i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let backTotalCurrentWin = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "back" &&
                    currentValue.odds <= i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let layTotalLossLessThenCurrent = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "lay" &&
                    currentValue.odds < i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let layTotalCurrentloss = fancyBets?.reduce(
                (accumulator, currentValue) => {
                  if (
                    currentValue.selectionType === "lay" &&
                    currentValue.odds === i
                  ) {
                    return accumulator + currentValue.stake;
                  } else {
                    return accumulator;
                  }
                },
                0
              );

              let totalLoss =
                backTotalLossGreaterThenCurrent +
                layTotalLossLessThenCurrent +
                layTotalCurrentloss;
              let totalProfit =
                layTotalWinGreaterThenCurrent + backTotalCurrentWin;

              let final = totalProfit - totalLoss;

              let calPl = -1 * final;
              if (calPl < 0) {
                bfCal = -1 * Math.abs(bf);
                csCal = -1 * Math.abs(cs);
              } else {
                bfCal = Math.abs(bf);
                csCal = Math.abs(cs);
              }

              // betFairBooks && sharedBooks
              // let { bookValue, toggle } = handleLineBookPlAsPerCs(betFairBooks, sharedBooks, betFairShare, clientShare, directParent, final, bf, cs);

              let bookValue = 0;
              let toggle = false;
              if (betFairBooks && sharedBooks) {
                if (directParent) {
                  bookValue = calPl - bfCal;
                } else {
                  bookValue = calPl - bfCal - csCal;
                }
                toggle = true;
              } else if (betFairBooks) {
                bookValue = calPl - bfCal;
                toggle = true;
              } else if (sharedBooks) {
                if (directParent) {
                  bookValue = final;
                } else {
                  bookValue = calPl - csCal;
                }
                toggle = true;
              } else {
                bookValue = final;
              }

              if (toggle) {
                if (final < 0) {
                  if (bookValue > 0) {
                    bookValue = -1 * bookValue;
                  }
                } else {
                  if (bookValue < 0) {
                    bookValue = -1 * bookValue;
                  }
                }
              }

              if (bookValue < 0) {
                bfCal = -1 * Math.abs(bf);
                csCal = -1 * Math.abs(cs);
              } else {
                bfCal = Math.abs(bf);
                csCal = Math.abs(cs);
              }
              // console.log("layToBack bookValue 2",bookValue);
              // console.log("bookValue 2",bookValue);
              // console.log("final 2",final);
              // betFairBooks && sharedBooks
              book.push({
                odds: i,
                stake: bookValue,
                type: "back",
                result: bookValue,
                pl: final,
                betFairShare: bfCal,
                clientShare: csCal,
                directParent: directParent,
                clientShareVisibility: clientShareVisibility
              });
            } else {
              const layIsExist = fancyBets?.some(
                (num) => num.odds === i && num.selectionType === "lay"
              );
              if (layIsExist) {
                let layTotalLosslessThenCurrent = fancyBets?.reduce(
                  (accumulator, currentValue) => {
                    if (
                      currentValue.selectionType === "lay" &&
                      currentValue.odds <= i
                    ) {
                      return accumulator + currentValue.stake;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );

                let layTotalWinGreaterThenCurrent = fancyBets?.reduce(
                  (accumulator, currentValue) => {
                    if (
                      currentValue.selectionType === "lay" &&
                      currentValue.odds > i
                    ) {
                      return accumulator + currentValue.stake;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );

                let backTotalWin = fancyBets?.reduce(
                  (accumulator, currentValue) => {
                    if (
                      currentValue.selectionType === "back" &&
                      currentValue.odds < i
                    ) {
                      return accumulator + currentValue.stake;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );

                let backTotalLossGreaterThenCurrent = fancyBets?.reduce(
                  (accumulator, currentValue) => {
                    if (
                      currentValue.selectionType === "back" &&
                      currentValue.odds > i
                    ) {
                      return accumulator + currentValue.stake;
                    } else {
                      return accumulator;
                    }
                  },
                  0
                );

                let totalLoss =
                  layTotalLosslessThenCurrent + backTotalLossGreaterThenCurrent;

                let totalProfit = layTotalWinGreaterThenCurrent + backTotalWin;
                let final = totalProfit - totalLoss;

                let calPl = -1 * final;
                if (calPl < 0) {
                  bfCal = -1 * Math.abs(bf);
                  csCal = -1 * Math.abs(cs);
                } else {
                  bfCal = Math.abs(bf);
                  csCal = Math.abs(cs);
                }

                // betFairBooks && sharedBooks
                // let { bookValue, toggle } = handleLineBookPlAsPerCs(betFairBooks, sharedBooks, betFairShare, clientShare, directParent, final, bf, cs);

                let bookValue = 0;
                let toggle = false;
                if (betFairBooks && sharedBooks) {
                  if (directParent) {
                    bookValue = calPl - bfCal;
                  } else {
                    bookValue = calPl - bfCal - csCal;
                  }
                  toggle = true;
                } else if (betFairBooks) {
                  bookValue = calPl - bfCal;
                  toggle = true;
                } else if (sharedBooks) {
                  if (directParent) {
                    bookValue = final;
                  } else {
                    bookValue = calPl - csCal;
                  }
                  toggle = true;
                } else {
                  bookValue = final;
                }

                if (toggle) {
                  if (final < 0) {
                    if (bookValue > 0) {
                      bookValue = -1 * bookValue;
                    }
                  } else {
                    if (bookValue < 0) {
                      bookValue = -1 * bookValue;
                    }
                  }
                }

                if (bookValue < 0) {
                  bfCal = -1 * Math.abs(bf);
                  csCal = -1 * Math.abs(cs);
                } else {
                  bfCal = Math.abs(bf);
                  csCal = Math.abs(cs);
                }
                // console.log("layToBack bookValue 3",bookValue);
                // console.log("bookValue 3",bookValue);
                // console.log("final 3",final);
                // betFairBooks && sharedBooks
                book.push({
                  odds: i,
                  stake: bookValue,
                  type: "lay",
                  result: bookValue,
                  pl: final,
                  betFairShare: bfCal,
                  clientShare: csCal,
                  directParent: directParent,
                  clientShareVisibility: clientShareVisibility
                });
              }
            }
          }
        }
        return book;
      };

      const minLayToMaxLay = (data1, data2) => {
        // console.log("minLayToMaxLay");
        let book = [];
        for (let i = data1 - 2; i < data2 + 3; i++) {
          if (i < min_odd_lay) {
            let layTotal = fancyBets?.reduce((accumulator, currentValue) => {
              if (currentValue.selectionType === "lay") {
                return accumulator + currentValue.stake;
              } else {
                return accumulator;
              }
            }, 0);

            // betFairBooks && sharedBooks
            // let { bookValue, toggle } = handleLineBookPlAsPerCs(betFairBooks, sharedBooks, betFairShare, clientShare, directParent, layTotal, bf, cs);
            let calPl = -1 * layTotal;
            if (calPl < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }

            // console.log("minLayToMaxLay calPl 1", calPl);
            // console.log("minLayToMaxLay layTotal 1", calPl);
            let bookValue = 0;
            let toggle = false;
            if (betFairBooks && sharedBooks) {
              if (directParent) {
                bookValue = calPl - bfCal;
              } else {
                bookValue = calPl - bfCal - csCal;
              }
              toggle = true;
            } else if (betFairBooks) {
              bookValue = calPl - bfCal;
              toggle = true;
            } else if (sharedBooks) {
              if (directParent) {
                bookValue = layTotal;
              } else {
                bookValue = calPl - csCal;
              }
              toggle = true;
            } else {
              bookValue = layTotal;
            }

            if (toggle) {
              if (layTotal < 0) {
                if (bookValue > 0) {
                  bookValue = -1 * bookValue;
                }
              } else {
                if (bookValue < 0) {
                  bookValue = -1 * bookValue;
                }
              }
            }

            if (bookValue < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }
            // console.log("minLayToMaxLay toggle 1", toggle);
            // console.log("minLayToMaxLay bookValue 1", bookValue);
            // console.log("minLayToMaxLay layTotal 1", layTotal);
            // betFairBooks && sharedBooks
            book.push({
              odds: i,
              stake: bookValue,
              type: "lay",
              result: bookValue,
              pl: layTotal,
              betFairShare: bfCal,
              clientShare: csCal,
              directParent: directParent,
              clientShareVisibility: clientShareVisibility
            });
          } else {
            let layTotalPositive = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.odds > i &&
                  currentValue.selectionType === "lay"
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let layTotalNegative = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.odds <= i &&
                  currentValue.selectionType === "lay"
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );
            let final = layTotalPositive - layTotalNegative;

            let calPl = -1 * final;
            if (calPl < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }

            // betFairBooks && sharedBooks
            // let { bookValue, toggle } = handleLineBookPlAsPerCs(betFairBooks, sharedBooks, betFairShare, clientShare, directParent, final, bf, cs);

            let bookValue = 0;
            let toggle = false;
            if (betFairBooks && sharedBooks) {
              if (directParent) {
                bookValue = calPl - bfCal;
              } else {
                bookValue = calPl - bfCal - csCal;
              }
              toggle = true;
            } else if (betFairBooks) {
              bookValue = calPl - bfCal;
              toggle = true;
            } else if (sharedBooks) {
              if (directParent) {
                bookValue = final;
              } else {
                bookValue = calPl - csCal;
              }
              toggle = true;
            } else {
              bookValue = final;
            }

            if (toggle) {
              if (final < 0) {
                if (bookValue > 0) {
                  bookValue = -1 * bookValue;
                }
              } else {
                if (bookValue < 0) {
                  bookValue = -1 * bookValue;
                }
              }
            }

            if (bookValue < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }

            // betFairBooks && sharedBooks
            book.push({
              odds: i,
              stake: bookValue,
              type: "lay",
              result: bookValue,
              pl: final,
              betFairShare: bfCal,
              clientShare: csCal,
              directParent: directParent,
              clientShareVisibility: clientShareVisibility
            });
          }
        }
        return book;
      };

      const minBackToMaxBack = (data1, data2) => {
        // console.log("minBackToMaxBack");
        let book = [];
        for (let i = data1 - 1; i < data2 + 2; i++) {
          if (i < min_odd_back) {
            let backTotal = fancyBets?.reduce((accumulator, currentValue) => {
              if (currentValue.selectionType === "back") {
                return accumulator + currentValue.stake;
              } else {
                return accumulator;
              }
            }, 0);

            // betFairBooks && sharedBooks
            // let { bookValue, toggle } = handleLineBookPlAsPerCs(betFairBooks, sharedBooks, betFairShare, clientShare, directParent, backTotal, bf, cs);

            // backTotal = -backTotal;
            // let calPl = -1 * backTotal;

            backTotal = -backTotal;
            let calPl = backTotal;

            if (calPl < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }

            let bookValue = 0;
            if (betFairBooks && sharedBooks) {
              if (directParent) {
                bookValue = calPl - bfCal;
              } else {
                bookValue = calPl - bfCal - csCal;
              }
            } else if (betFairBooks) {
              bookValue = calPl - bfCal;
            } else if (sharedBooks) {
              if (directParent) {
                bookValue = backTotal;
              } else {
                bookValue = calPl - csCal;
              }
            } else {
              bookValue = backTotal;
            }

            if (bookValue < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }

            // console.log("minBackToMaxBack bookValue 1", bookValue);
            // betFairBooks && sharedBooks
            book.push({
              odds: i,
              stake: bookValue,
              type: "back",
              result: bookValue,
              pl: backTotal,
              betFairShare: bfCal,
              clientShare: csCal,
              directParent: directParent,
              clientShareVisibility: clientShareVisibility
            });
          } else {
            let backTotalPositive = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.odds <= i &&
                  currentValue.selectionType === "back"
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let backTotalNegative = fancyBets?.reduce(
              (accumulator, currentValue) => {
                if (
                  currentValue.odds > i &&
                  currentValue.selectionType === "back"
                ) {
                  return accumulator + currentValue.stake;
                } else {
                  return accumulator;
                }
              },
              0
            );

            let final = -backTotalNegative + backTotalPositive;

            let calPl = -1 * final;
            if (calPl < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }

            // betFairBooks && sharedBooks
            // let { bookValue, toggle } = handleLineBookPlAsPerCs(betFairBooks, sharedBooks, betFairShare, clientShare, directParent, final, bf, cs);

            let bookValue = 0;
            let toggle = false;
            if (betFairBooks && sharedBooks) {
              if (directParent) {
                bookValue = calPl - bfCal;
              } else {
                bookValue = calPl - bfCal - csCal;
              }
              toggle = true;
            } else if (betFairBooks) {
              bookValue = calPl - bfCal;
              toggle = true;
            } else if (sharedBooks) {
              if (directParent) {
                bookValue = final;
              } else {
                bookValue = calPl - csCal;
              }
              toggle = true;
            } else {
              bookValue = final;
            }

            if (toggle) {
              if (final < 0) {
                if (bookValue > 0) {
                  bookValue = -1 * bookValue;
                }
              } else {
                if (bookValue < 0) {
                  bookValue = -1 * bookValue;
                }
              }
            }

            if (bookValue < 0) {
              bfCal = -1 * Math.abs(bf);
              csCal = -1 * Math.abs(cs);
            } else {
              bfCal = Math.abs(bf);
              csCal = Math.abs(cs);
            }

            // console.log("minBackToMaxBack bookValue 2",bookValue);
            // betFairBooks && sharedBooks
            book.push({
              odds: i,
              stake: bookValue,
              type: "back",
              result: bookValue,
              pl: final,
              betFairShare: bfCal,
              clientShare: csCal,
              directParent: directParent,
              clientShareVisibility: clientShareVisibility
            });
          }
        }
        return book;
      };

      //start
      let data = null;
      if (lay?.length > 0 && back?.length > 0) {
        data = layToBack(min, max);
      } else if (lay?.length > 0) {
        data = minLayToMaxLay(min_odd_lay, max_odd_lay);
        // return data;
      } else if (back?.length > 0) {
        data = minBackToMaxBack(min_odd_back, max_odd_back);
        // return data;
      }
      //start

      let modifiedBooks = data?.map((books) => books.stake);

      let ex = Math.min(...modifiedBooks);
      if (exp) {
        return ex;
      } else {
        return data;
      }
    }
  }
};


export const handlePlAsPerBfAndCss = (actualPl, bfCal, csCal, bf, cs) => {
  let finalPl = 0;
  if (bf < 0) {
    bfCal = -1 * bf;
  } else {
    bfCal = -bf;
  }

  if (cs < 0) {
    csCal = -1 * cs;
  } else {
    csCal = -cs;
  }

  //pl calculation
  //bf and cs positive 
  if (bfCal >= 0 & csCal >= 0) {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    if (actualPl >= total_share) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else if (bfCal >= 0 & csCal <= 0) {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    if (actualPl >= bfCal) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else if (bfCal <= 0 & csCal >= 0) {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    if (actualPl >= csCal) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else if (bfCal >= 0 & csCal >= 0) {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    if (actualPl >= csCal) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    finalPl = Math.abs(actualPl) - total_share;
    if (actualPl < 0) {
      if (finalPl > 0) {
        finalPl = -1 * finalPl;
      }
    } else {
      if (finalPl < 0) {
        finalPl = -1 * finalPl;
      }
    }
  }
  return finalPl;
};

export const handlePlAsPerBff = (actualPl, bfCal, bf) => {
  let finalPl = 0;

  if (bf < 0) {
    bfCal = -1 * bf;
  } else {
    bfCal = -bf;
  }

  if (bfCal >= 0) {
    let total_share = (Math.abs(bf));
    if (actualPl >= total_share) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else {
    let total_share = Math.abs(bf);
    finalPl = Math.abs(actualPl) - total_share;
    if (actualPl < 0) {
      if (finalPl > 0) {
        finalPl = -1 * finalPl;
      }
    } else {
      if (finalPl < 0) {
        finalPl = -1 * finalPl;
      }
    }
  }
  return finalPl;
};

export const handlePlAsPerCss = (actualPl, csCal, cs) => {
  let finalPl = 0;

  if (cs < 0) {
    csCal = -1 * cs;
  } else {
    csCal = -cs;
  }

  if (csCal >= 0) {
    let total_share = (Math.abs(cs));
    if (actualPl >= total_share) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else {
    let total_share = Math.abs(cs);
    finalPl = Math.abs(actualPl) - total_share;
    if (actualPl < 0) {
      if (finalPl > 0) {
        finalPl = -1 * finalPl;
      }
    } else {
      if (finalPl < 0) {
        finalPl = -1 * finalPl;
      }
    }
  }
  return finalPl;
};

// Function to convert INR to another currency
export const convertINRToCurrency = (amountINR, conversionRate, number) => {
  if (amountINR !== undefined) {
    //***pass true in number if we want response in number format
    let rate = parseFloat(conversionRate);
    if (
      conversionRate === undefined ||
      conversionRate === null ||
      conversionRate === 0 ||
      isNaN(conversionRate)
    ) {
      rate = 1;
    }
    const amountConverted = amountINR * rate;
    if (number) {
      //returns number
      return parseFloat(amountConverted).toFixed(2);
    } else {
      return amountConverted.toLocaleString("en-us", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }
};

export const handlePlAsPerBfAndCs = (actualPl, bfCal, csCal, bf, cs) => {
  let finalPl = 0;
  //pl calculation
  //bf and cs positive 
  if (bfCal >= 0 & csCal >= 0) {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    if (actualPl >= total_share) {
      return finalPl = actualPl - total_share;
    } else {
      return finalPl = total_share - Math.abs(actualPl);
    }
  } else if (bfCal >= 0 & csCal <= 0) {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    if (actualPl >= bfCal) {
      return finalPl = actualPl - total_share;
    } else {
      return finalPl = total_share - Math.abs(actualPl);
    }
  } else if (bfCal <= 0 & csCal >= 0) {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    if (actualPl >= csCal) {
      return finalPl = actualPl - total_share;
    } else {
      return finalPl = total_share - Math.abs(actualPl);
    }
  } else if (bfCal >= 0 & csCal >= 0) {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    if (actualPl >= csCal) {
      return finalPl = actualPl - total_share;
    } else {
      return finalPl = total_share - Math.abs(actualPl);
    }
  } else {
    let total_share = (Math.abs(bf) + Math.abs(cs));
    finalPl = actualPl - total_share;
    if (actualPl < 0) {
      if (finalPl > 0) {
        return finalPl = -1 * actualPl;
      }
    } else {
      if (finalPl < 0) {
        return finalPl = -1 * actualPl;
      }
    }
  }

};

export const handlePlAsPerBf = (actualPl, bfCal, bf) => {
  let finalPl = 0;
  if (bfCal >= 0) {
    let total_share = (Math.abs(bf));
    if (actualPl >= total_share) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else {
    let total_share = Math.abs(bf);
    finalPl = actualPl - total_share;
    if (actualPl < 0) {
      if (finalPl > 0) {
        finalPl = -1 * actualPl;
      }
    } else {
      if (finalPl < 0) {
        finalPl = -1 * actualPl;
      }
    }
  }
};

export const handlePlAsPerCs = (actualPl, csCal, cs) => {
  let finalPl = 0;
  if (csCal >= 0) {
    let total_share = (Math.abs(cs));
    if (actualPl >= total_share) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else {
    let total_share = Math.abs(cs);
    finalPl = actualPl - total_share;
    if (actualPl < 0) {
      if (finalPl > 0) {
        finalPl = -1 * actualPl;
      }
    } else {
      if (finalPl < 0) {
        finalPl = -1 * actualPl;
      }
    }
  }
};

//Function to convert back to INR
export const convertToINR = (amount, conversionRate) => {
  if (amount !== undefined) {
    let rate = conversionRate;
    if (
      conversionRate === undefined ||
      conversionRate === null ||
      conversionRate === 0 ||
      isNaN(conversionRate)
    ) {
      rate = 1;
    }
    const amountConverted = amount / rate;
    return parseFloat(amountConverted).toFixed(2);
  }
};

export const currencyConversion = (selectedCurrency, clientCurrency, pts) => {
  let convertedPts;

  if (pts) {
    if (!selectedCurrency) {
      return null;
    } else if (selectedCurrency === clientCurrency) {
      return null;
    } else {
      const inr = pts / clientCurrency; // Convert to INR equivalent
      convertedPts = inr * selectedCurrency; // Convert to selectedCurrency
      convertedPts = convertedPts.toFixed(2);

      if (isNaN(convertedPts)) {
        return pts;
      } else {
        return convertedPts;
      }
    }
  } else {
    return null;
  }
};

export const findBookViewData = (allBets, runners, marketId, user) => {
  try {
    let runnerBook = [];

    allBets?.forEach((bet) => {
      if (bet?.marketId?._id === marketId) {
        runners?.forEach((runner) => {
          let child;
          let data = {};
          if (bet?.userId?.parents?.length === 1) {
            child = bet?.userId?.username;
          } else {
            const result = bet?.userId?.parents?.reduce((maxObj, obj) => {
              return obj?.role > maxObj.role ? obj : maxObj;
            }, bet?.userId?.parents[0]);
            child = result?.username;
          }
          // if (!Object.keys(runner).includes(child)) {
          const exist = runnerBook?.findIndex((book) => book?.child === child);
          if (exist === -1) {
            data[runner?.name] = 0;
            data.child = child;
          } else {
            const val = runnerBook[exist];
            data[runner?.name] =
              val[runner?.name] !== undefined ? val[runner?.name] : 0;
            data.child = val?.child;
          }
          if (runner?.name === bet?.selection) {
            // if (String(runner?.name) === String(bet?.selection)) {

            // }

            if (bet?.marketType === MARKETS_TYPES?.EXCH.toLowerCase()) {
              if (bet?.selectionType === "back") {
                data[runner.name] += betOnBack.lose(bet?.odds, bet?.stake);
              } else {
                data[runner.name] += betOnLay.profit(bet?.odds, bet?.stake);
              }
            } else {
              if (bet?.selectionType === "back") {
                data[runner.name] += betOnBackBookmaker.lose(
                  bet?.odds,
                  bet?.stake
                );
              } else {
                data[runner.name] += betOnLayBookmaker.profit(
                  bet?.odds,
                  bet?.stake
                );
              }
            }

            if (exist === -1) {
              runnerBook.push(data);
            } else {
              runnerBook[exist] = { ...runnerBook[exist], ...data };
            }
          } else {
            if (bet?.marketType === MARKETS_TYPES?.EXCH.toLowerCase()) {
              if (bet?.selectionType === "back") {
                data[runner.name] += betOnBack.profit(bet?.stake);
              } else {
                data[runner.name] += betOnLay.lose(bet?.stake);
              }
            } else {
              if (bet?.selectionType === "back") {
                data[runner.namerunner.name] += betOnBackBookmaker.profit(
                  bet?.stake
                );
              } else {
                data[runner.name] += betOnLayBookmaker.lose(bet?.stake);
              }
            }

            if (exist === -1) {
              runnerBook.push(data);
            } else {
              runnerBook[exist] = { ...runnerBook[exist], ...data };
            }
          }
          // }
        });
      }
    });

    return runnerBook;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const invalidTokenAction = () => {
  removeLocalStorageItem("userData");
  removeLocalStorageItem("token");
  window.location.reload();
};

export const notifyWarning = (() => {
  let hasToastBeenShown = false;
  const theme = localStorage?.getItem("theme");

  return (message) => {
    if (!hasToastBeenShown) {
      hasToastBeenShown = true;

      const toastId = toast.warn(message, {
        className: theme == "true" ? "custom-toast-dark" : "custom-toast-light",
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: theme == "true" ? "dark" : "light",
        onClose: () => {
          hasToastBeenShown = false; // Reset the flag when the toast is closed.
        },
      });

      return toastId;
    }
  };
})();

export const notifySuccess = (() => {
  let hasToastBeenShown = false;
  const theme = localStorage?.getItem("theme");

  return (message) => {
    if (!hasToastBeenShown) {
      hasToastBeenShown = true;

      const toastId = toast.success(message, {
        className: theme == "true" ? "custom-toast-dark" : "custom-toast-light",
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: theme == "true" ? "dark" : "light",
        onClose: () => {
          hasToastBeenShown = false; // Reset the flag when the toast is closed.
        },
      });

      return toastId;
    }
  };
})();

export const notifyDanger = (() => {
  let hasToastBeenShown = false;
  const theme = localStorage?.getItem("theme");

  return (message) => {
    if (!hasToastBeenShown) {
      hasToastBeenShown = true;

      const toastId = toast.error(message, {
        className: theme == "true" ? "custom-toast-dark" : "custom-toast-light",
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: theme == "true" ? "dark" : "light",
        onClose: () => {
          hasToastBeenShown = false; // Reset the flag when the toast is closed.
        },
      });

      return toastId;
    }
  };
})();

export const findBetPl = (marketType, selectionType, odds, stake, oddsSize) => {
  if (marketType === (MARKETS_TYPES?.EXCH).toLowerCase()) {
    if (selectionType === "back") {
      let lose = betOnBack.lose(odds, stake);
      let profit = betOnBack.profit(stake);
      return { profit, lose };
    } else {
      const lose = betOnLay.lose(stake);
      const profit = betOnLay.profit(odds, stake);
      return { lose, profit };
    }
  } else if (marketType === (MARKETS_TYPES?.BKMKR).toLowerCase()) {
    if (selectionType === "back") {
      const profit = betOnBackBookmaker.profit(stake);
      const lose = betOnBackBookmaker.lose(odds, stake);
      return { profit, lose };
    } else {
      const profit = betOnLayBookmaker.profit(odds, stake);
      const lose = betOnLayBookmaker.lose(stake);
      return { profit, lose };
    }
  } else {
    const pl = (stake * (oddsSize / 100)).toFixed(2);
    if (pl < 0) {
      return {
        profit: -1 * pl,
        lose: pl,
      };
    } else {
      return {
        profit: pl,
        lose: -1 * pl,
      };
    }
  }
};

export const convertHKDToINR = (amountInHKD) => {
  // Assuming exchange rate as of writing this code
  const exchangeRate = 10.59;

  // Convert HKD to INR
  const amountInINR = amountInHKD * exchangeRate;

  return amountInINR;
};

export const playNotificationSound = async (params) => {
  try {
    let sound;
    if (params === "placebet") {
      sound = NotificationSound;
    } else if (params === "delete") {
      sound = DeleteSound;
    }
    const audio = new Audio(sound);
    await audio.play();
  } catch (err) {
    console.log("error ", err?.message);
  }
};
export const stxAddDateAndReorderParticipants = (data) => {
  try {
    const excludedStatuses = ["cancelled", "resulted"];
    const homeRoles = ["home", "home_winner_regulation_3way", "home_winner_regulation"];
    const awayRoles = ["away", "away_winner_regulation_3way", "away_winner_regulation"];
    const drawRoles = ["draw", "draw_winner_regulation_3way", "draw_regulation_3way"];
    
    // Combine all valid rules
    const allRulesAccepted = [...homeRoles, ...awayRoles];

    const modifiedData = data?.map((element) => {
      if (
        !element?.participants ||
        !element?.eventStart ||
        excludedStatuses.includes(element?.status) ||
        !allRulesAccepted.includes(element?.rules)
      ) {
        return null;
      }

      const dateof = new Date(element.eventStart);
      const date = dateof.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const homeTeam = element?.participants?.find((team) => team.role === "home");
      const awayTeam = element?.participants?.find((team) => team.role === "away");

      if (!homeTeam || !awayTeam) return null;

      const match = `${homeTeam.name} v ${awayTeam.name}`;

      return { ...element, match, date };
    });

    return modifiedData.filter((el) => el !== null);
  } catch (err) {
    console.error("Error in stxAddDateAndReorderParticipants:", err);
    return [];
  }
};

export const createLink = async (data) => {
  const password = 'betxfair'
  const encoded = new TextEncoder().encode(password);
  const key = await crypto.subtle.importKey('raw', encoded, { name: 'PBKDF2' }, false, ['deriveKey']);
  const derivedKey = await crypto.subtle.deriveKey(
    { name: 'PBKDF2', salt: encoded, iterations: 100000, hash: 'SHA-256' },
    key,
    { name: 'AES-GCM', length: 256 },
    false,
    ['encrypt']
  );

  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encryptedData = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: iv },
    derivedKey,
    new TextEncoder().encode(JSON.stringify(data))
  );

  return `${btoa(String.fromCharCode(...iv))}.${btoa(String.fromCharCode(...new Uint8Array(encryptedData)))}`;
}
export const generateLink = async (link) => {
  const password = 'betxfair'
  const [iv, data] = link.split('.').map(part => new Uint8Array(atob(part).split('').map(char => char.charCodeAt(0))));
  const encoded = new TextEncoder().encode(password);
  const key = await crypto.subtle.importKey('raw', encoded, { name: 'PBKDF2' }, false, ['deriveKey']);
  const derivedKey = await crypto.subtle.deriveKey(
    { name: 'PBKDF2', salt: encoded, iterations: 100000, hash: 'SHA-256' },
    key,
    { name: 'AES-GCM', length: 256 },
    false,
    ['decrypt']
  );

  const decryptedData = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: iv },
    derivedKey,
    data
  );

  return JSON.parse(new TextDecoder().decode(decryptedData));
}
