import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";
import ThemeCustomize from "../Layout/ThemeCustomizer";
import Footer from "./Footer";
import CustomizerContext from "../_helper/Customizer";
import { useDispatch } from "react-redux";
import { fetchWhiteLabelData } from "../redux/action";

const AppLayout = ({ children, classNames, ...rest }) => {
  const { layout } = useContext(CustomizerContext);
  const { sidebarIconType } = useContext(CustomizerContext);

  const layout1 = localStorage.getItem("sidebar_layout") || layout;
  const sideBarIcon =
    localStorage.getItem("sidebar_icon_type") || sidebarIconType;
  const dispatch = useDispatch();

  const [whiteLabelData, setWhiteLabelData] = useState({});

  useEffect(() => {
    dispatch(
      fetchWhiteLabelData({
        domain: window.location.origin,
        callback: (data) => {
          setWhiteLabelData(data);
        },
      })
    );
  }, []);

  return (
    <>
      <div
        className={`page-wrapper ${layout1}`}
        sidebar-layout={sideBarIcon}
        id="pageWrapper"
      >
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeCustomize />
      <ToastContainer />
    </>
  );
};
export default AppLayout;
