import validator from "validator";

function validateSettlement(data) {
  const errors = {};
  if (data?.p_l == 0) {
    errors.p_l = "Value cannot be zero.";
  }
  if (validator.isEmpty(data?.p_l?.toString())) errors.p_l = "Please enter PL.";
  // if (validator.isEmpty(data?.desc?.toString()))
  //   errors.desc = "Please enter the description.";
  if (validator.isEmpty(data?.password?.toString()))
    errors.password = "Please enter the password.";
  return { errors, isValid: Object.keys(errors)?.length <= 0 };
}

export default validateSettlement;
