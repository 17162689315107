import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_LAYERS } from "../../action/types";
import { getLayersSuccess, getLayersFailure } from "../../action";
import API from "../../../utils/api";
import { invalidTokenAction } from "../../../utils/helper";

function* getLayersRequest(action) {
  try {
    const { page, perPage, buildTreeFrom, search, selectedCurrency,userType } = action.payload;
    
    const { data } = yield API.get(`/admin/getusers?buildTreeFrom=${buildTreeFrom}&page=${page}&perPage=${perPage}&selectedCurrency=${selectedCurrency}&userType=${userType}&search=${search}`);
    if (data.meta.code === 200) {
      yield put(getLayersSuccess(data));
      yield call(action.payload.callback, data);
    } else if (data.meta.code === 401) {
      yield put(getLayersFailure());
      invalidTokenAction();
    }else if (data.meta.code !== 200) {
      yield put(getLayersFailure());
      yield call(action.payload.callback, data.meta);
    }
  } catch (error) {
    yield put(getLayersFailure());
  }
}

export function* watchLayersAPI() {
  yield takeEvery(GET_LAYERS, getLayersRequest);
}

export default function* rootSaga() {
  yield all([watchLayersAPI()]);
}
