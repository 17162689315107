// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add appropriate styles for the skeleton loading */
.widget-round.skeleton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #ddd;
  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);
  /* Shimmer effect */
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-total {
  width: 150px;
  height: 20px;
  background-color: #ddd;
  margin-top: 10px;
  border-radius: 10px;
  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);
  /* Shimmer effect */
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.skeleton-title {
  width: 80px;
  height: 20px;
  background-color: #ddd;
  margin-top: 5px;
  border-radius: 10px;
  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);
  /* Shimmer effect */
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/components/_skeleton-loading.scss"],"names":[],"mappings":"AAAA,oDAAA;AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EAEA,sBAAA;EACA,yEAAA;EACA,mBAAA;EACA,0BAAA;EACA,gCAAA;AAAF;;AAKA;EACE,YAAA;EACA,YAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yEAAA;EACA,mBAAA;EACA,0BAAA;EACA,gCAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;EACA,mBAAA;EACA,yEAAA;EACA,mBAAA;EACA,0BAAA;EACA,gCAAA;AAFF;;AAKA;EACE;IACE,2BAAA;EAFF;EAKA;IACE,4BAAA;EAHF;AACF","sourcesContent":["/* Add appropriate styles for the skeleton loading */\n.widget-round.skeleton {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  border: none;\n\n  background-color: #ddd;\n  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);\n  /* Shimmer effect */\n  background-size: 200% 100%;\n  animation: shimmer 1.5s infinite;\n}\n\n\n\n.skeleton-total {\n  width: 150px;\n  height: 20px;\n  background-color: #ddd;\n  margin-top: 10px;\n  border-radius: 10px;\n  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);\n  /* Shimmer effect */\n  background-size: 200% 100%;\n  animation: shimmer 1.5s infinite;\n}\n\n.skeleton-title {\n  width: 80px;\n  height: 20px;\n  background-color: #ddd;\n  margin-top: 5px;\n  border-radius: 10px;\n  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);\n  /* Shimmer effect */\n  background-size: 200% 100%;\n  animation: shimmer 1.5s infinite;\n}\n\n@keyframes shimmer {\n  0% {\n    background-position: 200% 0;\n  }\n\n  100% {\n    background-position: -200% 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
