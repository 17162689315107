import {
  STX_ACCOUNT_DETAILS,
  STX_ACCOUNT_DETAILS__FAILURE,
  STX_ACCOUNT_DETAILS__SUCCESS,
  STX_ACCOUNT_DETAILS_SOCKET_DATA,
} from "../types";

export const getSTXAccountDetails = (payload) => ({
  type: STX_ACCOUNT_DETAILS,
  payload,
});

export const getSTXAccountDetailsSuccess = (payload) => ({
  type: STX_ACCOUNT_DETAILS__SUCCESS,
  payload,
});

export const getSTXAccountDetailsSocket = (data) => ({
  type: STX_ACCOUNT_DETAILS_SOCKET_DATA,
  payload: data,
});

export const getSTXAccountDetailsFailure = () => ({
  type: STX_ACCOUNT_DETAILS__FAILURE,
});
