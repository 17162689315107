import React, { useContext, useState } from "react";
import { Menu } from "react-feather";
import { Link } from "react-router-dom";
import { Image } from "../../AbstractElements";
import OfficialIconLight from "../../assets/images/logo/logo-light-white.png";
import OfficialIconDark from "../../assets/images/logo/logo-dark.png";
import CustomizerContext from "../../_helper/Customizer";
import adminLogo from "../../assets/images/adminLogo.png"

const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, layout, layoutURL } =
    useContext(CustomizerContext);

  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };

  const layout1 = localStorage.getItem("sidebar_layout") || layout;
  const defaultUrl ="https://myxxexchbucket.s3.ap-south-1.amazonaws.com/Logo/xxexch247.com/xxexch247.com-light.png"

  return (
    <div className="logo-wrapper top-left-box">
      {layout1 !== "compact-wrapper dark-sidebar" &&
      layout1 !== "compact-wrapper color-sidebar" &&
      mixLayout ? (
        <Link to={`/dashboard`}>
          <Image
            attrImage={{
              className: "img-fluid d-inline w-75",
              src: `${adminLogo}`,
              alt: "",
            }}
          />
        </Link>
      ) : (
        <Link to={`/dashboard`}>
          <Image
            attrImage={{
              className: "img-fluid d-inline w-75",
              src: `${adminLogo}`,
              alt: "",
            }}
          />
        </Link>
      )}
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
      <div className="toggle-sidebar mt-1" onClick={openCloseSidebar}>
        <Menu className="status_toggle middle sidebar-toggle" />
      </div>
    </div>
  );
};

export default SidebarLogo;
//change here
