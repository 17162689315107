import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ActualPlModal = (props) => {
  const { t } = useTranslation();

  const dashboardData = useSelector(
    (state) =>
      state?.getDashboardDataDetailsReducer?.dashboardDetails?.data
        ?.totalActualPlData?.bet_data
  );

  return (
    <Modal
      className=""
      show={props.isOpen}
      onHide={props.toggler}
      centered
      size={props.size}
    >
      <Modal.Header closeButton>
        <Modal.Title className="p-1 pb-0">{`${t("TOTAL PL")}`}</Modal.Title>
      </Modal.Header>

      <Modal.Body className={props.bodyClass} style={{ padding: "5px" }}>
        <div className="overflow-auto" style={{ maxHeight: "60vh" }}>
          <div className="table-responsive  ">
            <table className="table table-bordered table-hover mt-3">
              <thead className="table-light border">
                <tr>
                  <th scope="col" className="text-uppercase text-nowrap">
                    {t("SELECTION")}
                  </th>
                  <th scope="col" className="text-uppercase text-nowrap">
                    {t("USER PL")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {dashboardData?.map((data, index) => (
                  <tr
                    key={index}
                    // className={`${
                    //   data?.selectionType === "back"
                    //     ? "table-primary"
                    //     : "table-danger"
                    // }`}

                    className={`${
                      data?.bettingType === "LINE"
                        ? data?.selectionType === "back"
                          ? "table-danger"
                          : "table-primary"
                        : data?.selectionType === "back"
                        ? "table-primary"
                        : "table-danger"
                    }`}
                  >
                    <td className="fw-semibold text-dark">{data?.selection}</td>
                    <td className="fw-semibold text-dark">
                      {data?.pl > 0
                        ? Math.round(-data?.pl)?.toFixed(2)
                        : Math.round(-data?.pl)?.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ActualPlModal;
