import { GET_STX_DATAS, GET_STX_DATAS_SUCCESS, GET_STX_DATAS_FAILURE } from "../types";

export const getSTXData = (payload) => ({
  type: GET_STX_DATAS,
  payload,
});

export const getSTXDataSuccess = (payload) => ({
  type: GET_STX_DATAS_SUCCESS,
  payload,
});

export const getSTXDataFailure = () => ({
  type: GET_STX_DATAS_FAILURE,
})