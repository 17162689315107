import axios from "axios";
import { getLocalStorageItem, STX_api } from "./helper";

const STX_DATA_API = axios.create({
  baseURL: STX_api,
});

STX_DATA_API.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${getLocalStorageItem("token")}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

STX_DATA_API.interceptors.response.use(
  (response) => response,
  function (error) {
    console.log(error, "error");
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default STX_DATA_API;
