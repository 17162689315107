import { Fragment,useRef } from "react";
import usePageTitle from "../../../../Hooks/usePageTitle";
import { Breadcrumbs } from "../../../../AbstractElements";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import WidgetsWrapper from "./WidgetsWraper";
import RecentOrders from "./RecentOrders";
import GreetingCard from "./GreetingCard";


const Dashboard = () => {
  const title = usePageTitle();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={t("DASHBOARD")}
        title={title?.title}
        parent={title?.parent}
      />
      <Container fluid={true}>
        <GreetingCard />
        <Row className="widget-grid">
          <RecentOrders />
          <WidgetsWrapper />
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
