import React, { useState, memo } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";
import PaginationButtons from "../../../Common/Component/pagination/PaginationButtons";
import VoidInvalidModal from "./VoidInvalidModal";
import {
  convertINRToCurrency,
  findAdminParent,
} from "../../../../utils/helper";
import { useEffect } from "react";
import { BetStatus, PAGINATION } from "../../../../Constant";
import {
  getViewBetsOfRunningMarket
} from "../../../../redux/action";
import { socket } from "../../../../context/socketContext";

const MarketsModal = memo((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const adminData = useSelector((state) => state.Login.userData);
  const [voidInvalidModal, setvoidInvalidModal] = useState(false);
  const [modalData, setModaldata] = useState({});
  const [action, setAction] = useState("");
  const [filteredBets, setFilteredBets] = useState([]);
  const [currentPage, setCurrentPage] = useState(PAGINATION?.currentPage);
  const [limit, setLimit] = useState(PAGINATION?.perPage);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [totalPages, setTotalPages] = useState(PAGINATION?.totalPage);

  // useEffect(() => {
  //   init();
  // }, [props?.currency, props?.socketData, props.isOpen, currentPage]);

  useEffect(() => {
    let isMounted = true;
    if (props?.socketData !== undefined && props?.socketData?.length > 0) {
      if (props?.socketData?.data?.allBets[0]?.eventId === props?.eventId) {
        init();
      }
    } else {
      init();
    }
    return () => {
      isMounted = false; // Cancel the operation on unmount
    };
  }, [props?.currency, props?.socketData, props.isOpen, currentPage]);

  useEffect(() => {
    socket.on("betStatusChange", (data) => {
      if (data && data?.length > 0) {
        init();
      }
    });
  }, [socket]);

  const init = () => {
    const currencyId = props?.currency?.value?._id;
    let type = props?.marketType;

    dispatch(
      getViewBetsOfRunningMarket({
        eventId: props?.eventId,
        marketId: props?.marketId,
        isMultiple: props?.isMultiple,
        currencyId,
        deleted: props?.viewDeleted?.value === "ACTIVE" ? false : true,
        type,
        perPage: limit,
        currentPage: currentPage,
        callback: (data) => {
          if (data) {
            console.log("data,,,,,,,,,,,,,", data);
            setTotalDataCount(data?.meta?.count);
            let totalPage = Math.ceil(data?.meta?.count / limit);
            setTotalPages(totalPage);
            setCurrentPage(currentPage);
            // let currencyConversion = data?.data?.map((bet) => {
            //   return {
            //     ...bet,
            //     stake:
            //       props?.currency === undefined
            //         ? bet?.stake
            //         : parseInt(
            //           convertINRToCurrency(
            //             bet?.stake,
            //             parseFloat(props?.currency?.value?.value)
            //           )
            //         ),
            //     liability:
            //       props?.currency === undefined
            //         ? bet?.liability
            //         : parseInt(
            //           convertINRToCurrency(
            //             bet?.liability,
            //             parseFloat(props?.currency?.value?.value)
            //           )
            //         ),
            //   };
            // });
            setFilteredBets(data?.data);

          }
        },
      })
    );
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = (betId) => {
    setvoidInvalidModal(true);
    setModaldata(betId);
  };

  return (
    <>
      <Modal
        show={props.isOpen}
        onHide={props.toggler}
        centered
        size={props.size}
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontSize: "1.2rem" }}
          >{`${props.title} view bets`}</Modal.Title>
          {/* <input
          type="search"
          className="search-field"
          placeholder="search user"
        /> */}
        </Modal.Header>
        <Modal.Body className={props.bodyClass} style={{ padding: 0 + "px" }}>
          <div className="overflow-auto" style={{ height: "75vh" }}>
            <table className="table table-bordered">
              <thead className="table-light">
                <tr>
                  <th scope="col" className="text-uppercase">
                    {t("USER_PARENT")}
                  </th>
                  <th scope="col" className="text-uppercase">
                    {t("SELECTION")}
                  </th>
                  <th scope="col" className="text-uppercase">
                    {t("ODDS")}
                  </th>
                  <th scope="col" className="text-uppercase">
                    {t("STAKE")}
                  </th>
                  {adminData.role < 2 &&
                    props?.matchType === "/running-markets/exchange" && (
                      <>
                        {" "}
                        <th scope="col" className="text-uppercase text-nowrap">
                          {t("BETFAIR_STAKE")}
                        </th>
                        <th scope="col" className="text-uppercase text-nowrap">
                          {t("BETFAIR_SHARE") + "(%)"}
                        </th>
                      </>
                    )}
                  <th scope="col" className="text-uppercase">
                    {t("DATE")}
                  </th>
                  <th scope="col" className="text-uppercase">
                    {t("BROWSER_IP_ADDRESS")}
                  </th>
                  <th scope="col" className="text-uppercase">
                    {t("SYSTEM_IP_ADDRESS")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredBets?.map((data, index) => {
                  let parent = findAdminParent(
                    data?.userId?.parents,
                    adminData?.role
                  );
                  return (
                    <tr
                      style={{
                        ...(data?.status !== BetStatus.OPEN
                          ? {
                            opacity: 0.5,
                          }
                          : ""),
                      }}
                      // className={`text-nowrap ${
                      //   data?.selectionType === "back"
                      //     ? "table-primary"
                      //     : "table-danger"
                      // } `}

                      className={`text-nowrap ${data?.bettingType === "LINE"
                        ? data?.selectionType === "back"
                          ? "table-danger"
                          : data?.selectionType === "lay"
                            ? "table-primary"
                            : "table-danger"
                        : data?.selectionType === "back"
                          ? "table-primary"
                          : "table-danger"
                        }`}
                      key={index}
                    >
                      <td className="table-text-blue text-nowrap">
                        <div className="d-flex align-items-center">
                          {props?.role == 1 &&
                            data?.status === "6" &&
                            data?.marketType === "exchange" && (
                              <MdDelete
                                className="delete-icon fs-6"
                                onClick={() => handleDelete(data?._id)}
                              />
                            )}
                          <span>
                            {data?.userId?.username}
                            {`[${parent?.parent_id?.username}]`}
                          </span>
                        </div>
                      </td>
                      <td className="fw-bold">{data?.selection}</td>
                      <td className="table-text-blue">
                        {/line/i.test(data?.selection)
                          ? Math.round(data?.odds)
                          : data?.odds}
                      </td>
                      <td className="fw-bold">
                        {" "}
                        {props?.currency
                          ? Math.round(
                            convertINRToCurrency(
                              data?.stake,
                              parseFloat(data?.currency?.value)
                            ) * 100
                          ) / 100
                          : data?.stake?.toFixed(2)}
                      </td>
                      {adminData.role < 2 &&
                        props?.matchType === "/running-markets/exchange" && (
                          <>
                            <td className="table-text">
                              {props?.currency
                                ? Math.round(
                                  convertINRToCurrency(
                                    (data?.stake * data?.betFairPercentage) /
                                    100,
                                    parseFloat(data?.currency?.value)
                                  ) * 100
                                ) / 100
                                : (
                                  (data?.stake * data?.betFairPercentage) /
                                  100
                                )?.toFixed()}
                            </td>

                            <td className="table-text">
                              {data?.betFairPercentage}
                            </td>
                          </>
                        )}
                      <td className="fw-bold">
                        {new Date(data?.createdAt)?.toLocaleString()}
                      </td>
                      <td className="table-text-blue">
                        {data?.userId?.ip_address?.browser_ip
                          ? data?.userId?.ip_address?.browser_ip
                          : "-"}
                      </td>
                      <td className="table-txt-blue">
                        {data?.userId?.ip_address?.system_ip
                          ? data?.userId?.ip_address?.system_ip
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props?.pagination ? (
            <PaginationButtons
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          ) : (
            <>
              <Button variant="secondary" onClick={props.toggler}>
                Close
              </Button>
              <Button variant="primary" onClick={props.toggler}>
                Save Changes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {voidInvalidModal && (
        <VoidInvalidModal
          isOpen={voidInvalidModal}
          title={t("DELETE_BET_?")}
          toggler={setvoidInvalidModal}
          modalData={modalData}
          setBetIds={props.setBetIds}
          action={setAction}
          size="md"
          fieldTitle={t("NEW_PASSWORD")}
        />
      )}
    </>
  );
});

export default MarketsModal;
