import React, { Fragment, useEffect, useState } from "react";
import { ButtonGroup, CardHeader, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import Select from "react-select";
import { MARKETS_TYPES, ManagerRoles, PAGINATION } from "../../../../Constant";
import usePageTitle from "../../../../Hooks/usePageTitle";
import Loader from "../../../../Layout/Loader";
import {
  addResult,
  getDbSports,
  getManageEventData,
  eventDeActivate,
  getEventBasedBets,
  getBetsByMarketId,
  getLayerCurrency,
  getMarketAnalysisData,
  searchEventData,
} from "../../../../redux/action";
import {
  convertINRToCurrency,
  createLink,
  createOption,
  formatDate,
} from "../../../../utils/helper";
import PaginationButtons from "../../../Common/Component/pagination/PaginationButtons";
import { Navigate, useLocation, useNavigate } from "react-router";
import MarketAnalysisBetsModal from "../modal/MarketAnalysisBetsModal";
import View from "../modal/View";
import { dateFilter } from "../../../../utils/constants";

const ResultAnalysis = () => {
  const title = usePageTitle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  //Accessing States
  const sportData = useSelector((state) => state?.GetDbSports?.sportsData);
  const allEventDatas = useSelector((state) => state?.ManageEvents?.manageData);
  const adminData = useSelector((state) => state.Login.userData);
  const sportLoading = useSelector((state) => state?.GetDbSports?.loading);
  const eventLoading = useSelector((state) => state?.ManageEvents?.loading);
  //States of Component
  const [sportOption, setsportOption] = useState([]);
  const [leagueOption, setLeagueOption] = useState([]);
  const [eventOption, setEventOption] = useState([]);
  const [sport, setSport] = useState("");
  const [event, setEvent] = useState("");
  const [league, setLeague] = useState("");
  const [marketType, setMarketType] = useState("");
  const [market, setMarket] = useState("");
  const [loading, setLoading] = useState(false);
  // const [isSubmit, setIsSubmit] = useState(false);
  const [currentPage, setCurrentPage] = useState(PAGINATION?.currentPage);
  const [load, setLoad] = useState(false);
  const [filteredResultData, setFilteredResultData] = useState([]);
  const [prevFilteredResultData, setPrevFilteredResultData] = useState([]);
  const [isRoleSelectOpen, setIsRoleSelectOpen] = useState(false);
  const [isRoleSelectOpen2, setIsRoleSelectOpen2] = useState(false);
  const [isRoleSelectOpen3, setIsRoleSelectOpen3] = useState(false);
  const [currency, setCurreny] = useState();
  const [currentType, setCurrentType] = useState("ALL");
  const [lastDate, setLastDate] = useState(1);

  const [searchResult, setSearchResult] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  //Invoking function for getting sport Data
  useEffect(() => {
    dispatch(getDbSports());
  }, []);

  const params = new URLSearchParams(location.search);
  const currentPageFromUrl = params.get("currentPage");

  useEffect(() => {
    const selectedValue = currentPageFromUrl ? parseInt(currentPageFromUrl) : 1;
    setLastDate(selectedValue);
    const fetchData = async (selectedValue) => {
      try {
        const data = await getMarketData(selectedValue);
        const updateFilteredResultData = (data) => {
          setPrevFilteredResultData(filteredResultData);
          setFilteredResultData(data);
        };

        updateFilteredResultData(data);

        setCurrentType("ALL");
      } catch (error) {
        console.error(error);
      }
    };
    fetchData(selectedValue);
  }, [currentPageFromUrl]);

  /* ---------------- //For getting data for sports Select tag ---------------- */
  useEffect(() => {
    let data = createOption(sportData);

    let sortedCricket = data?.sort((a, b) => {
      if (a.label === "Cricket") {
        return -1; // "Cricket" comes first
      }
      if (b.label === "Cricket") {
        return 1; // "Cricket" comes first
      }
      return 0; // no change in order
    });

    setsportOption(sortedCricket);
  }, [sportData]);

  /* ------------------- //Creating option for leagues select ------------------- */
  useEffect(() => {
    if (allEventDatas !== null) {
      let data = createOption(allEventDatas?.data?.leagues, "events");
      setLeagueOption(data);
    }
  }, [allEventDatas]);

  useEffect(() => {
    dispatch(
      getLayerCurrency({
        layerId: location?.state?.id,
        callback: (data) => {
          setCurreny(data?.currencyId);
        },
      })
    );
  }, []);

  /* --------------------- Handles the selection of sport to call API -------------- */
  const handleSportSelect = async (option, field) => {
    if (option?.value !== "sport") {
      setSport(option);
      setLeague("");
      setEvent("");
      setMarketType("");
      setMarket("");
    }
    setLeagueOption([]);
    setEventOption([]);
    field.onChange(option?.value);
    const id = option?.value;
    const result = true;
    dispatch(
      getManageEventData({
        id,
        result,
      })
    );
  };

  /* --------------------- Handles the selection of League  ------------- */
  const handleLeagueSelect = async (option, field) => {
    setLeague(option);
    setEvent("");
    setMarketType("");
    setMarket("");
    field.onChange(option?.value);
    if (option?.events) {
      const data = createOption(option?.events, "markets");
      setEventOption(data);
    }
  };

  /* --------------------- IN THE SELECTION BOX SELECT EVENTID -------------------- */
  //---------------------- RETURN A URL AND NAVIGATE INTO THAT URL WITH EVENT ID
  const handleEventSelect = async (option, field) => {
    try {
      setEvent(option);
      setMarketType("");
      field.onChange(option?.value);

      if (option.value !== undefined && option.value !== null) {
        const eventIdLink = await createLink(option.value);
        navigate(`/reports/analysis/${encodeURIComponent(eventIdLink)}`);
      } else {
        console.log(
          "Error: option value is undefined or null in market analysis initial page"
        );
      }
    } catch (error) {
      console.error("Error in handleEventSelect:", error);
    }
  };

  // HERE WE WILL PASS THE EVENT IS
  // IN EVENTVIEW COMPONENT IT WILL CATCH
  // WE ARE ENCODING THE EVENT URL AND RECEIVED IN EVENTID
  // IN EVENT-VIEW COMPONENT AND FETCH DETAILS FROM BACKEND
  const handleEventClick = async (eventId) => {
    try {
      if (eventId !== undefined && eventId !== null) {
        const eventIdLink = await createLink(eventId);
        const currentPage = lastDate;
        navigate(
          `/reports/analysis/${encodeURIComponent(
            eventIdLink
          )}?currentPage=${encodeURIComponent(currentPage)}`
        );
      } else {
        console.log("Invalid eventId:", eventId);
      }
    } catch (error) {
      console.log("Error in handleEventClick:", error);
    }
  };

  //Clearing all fields on click on cancel button
  const handleCancel = () => {
    reset();
    setSport("");
    setLeague("");
    setEvent("");
    setMarketType("");
    setMarket("");
    setMarketType("");
  };

  //handle the submit button click
  const onSubmit = (data, e) => {
    if (marketType?.value === "bookmaker") {
      data = { ...data, market_type: marketType?.value };
    } else {
      data = { ...data, market_type: MARKETS_TYPES.EXCH.toLowerCase() };
    }
    // alert("click");
    setLoading(true);
    // setIsSubmit(true);

    dispatch(
      addResult({
        data,
        callback: () => {
          handleCancel();
          setLoad(!load);
          setLoading(false);
          if (market?.label === "Match Odds") {
            let data = {
              market: market?.label,
              event_id: event?.value,
            };
            dispatch(eventDeActivate(data));
          }
        },
      })
    );
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    if (query.length > 3) {
      dispatch(
        searchEventData({
          keyword: query,
          callback: (data) => {
            if (data) {
              setSearchResult([]);
            } else {
              setSearchResult([]);
            }
          },
        })
      );
    }
  };
  const handleSearchFunction = debounce(handleSearch, 1000);

  const handleDatePreference = async (event) => {
    const selectedValue = parseInt(event.target.value);
    setLastDate(selectedValue);
    const fetchData = async (selectedValue) => {
      try {
        const data = await getMarketData(selectedValue);
        const updateFilteredResultData = (data) => {
          setPrevFilteredResultData(filteredResultData);
          setFilteredResultData(data);
        };
        updateFilteredResultData(data);
        setCurrentType("ALL");
      } catch (error) {
        console.error(error);
      }
    };
    fetchData(selectedValue);
  };

  function formatNumbers(input) {
    const inputString = String(input);
    const firstPart = inputString.split(" ")[0];
    const formattedNumber = parseFloat(firstPart).toFixed(2);
    return formattedNumber;
  }

  const getMarketData = (lastDate) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(
          getMarketAnalysisData({
            lastDate,
            callback: (data) => {
              try {
                const transformData = (data) => {
                  return data.sort((a, b) => {
                    if (a.date > b.date) return -1;
                    if (a.date < b.date) return 1;
                    return 0;
                  });
                };
                const responseData = transformData(data);
                resolve(responseData);
              } catch (error) {
                reject("Error in callback transformation:", error);
              }
            },
          })
        );
      } catch (error) {
        reject("Error in getMarketData:", error);
      }
    });
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const resolvedData = await getMarketData(lastDate);
  //       const updateFilteredResultData = (newData) => {
  //         setPrevFilteredResultData(filteredResultData);
  //         setFilteredResultData(newData);
  //       };
  //       updateFilteredResultData(resolvedData);
  //       setCurrentType("ALL");
  //     } catch (error) {
  //       console.error("Error fetching market data:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={t("RESULTS")}
        title={title?.title}
        parent={title?.parent}
      />

      <Container fluid={true}>
        {adminData?.role !== ManagerRoles.MONITORING_MANAGER && (
          <Row>
            <Col sm="12" className="px-3">
              <Card className="px-2">
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate=""
                    id="result"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="d-flex flex-col flex-wrap">
                      <Col md="4 mb-3" className="">
                        <Label htmlFor="validationCustom01">
                          {t("SELECT_SPORTS")}
                        </Label>
                        <Controller
                          name="sportsId"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={sportOption}
                              placeholder={t("SELECT_DOT")}
                              className="mySelect"
                              isLoading={sportLoading}
                              value={sport}
                              onChange={(option) => {
                                handleSportSelect(option, field);
                                setIsRoleSelectOpen(false);
                              }}
                              menuIsOpen={isRoleSelectOpen}
                              onFocus={() => setIsRoleSelectOpen(true)}
                              onBlur={() => setIsRoleSelectOpen(false)}
                            />
                          )}
                        />
                        <span className="text-danger">
                          {errors.sportsId && t("FIELD_REQUIRED")}
                        </span>
                      </Col>

                      <Col md="4 mb-3" className="">
                        <Label htmlFor="validationCustom02">
                          {t("SELECT_LEAGUE")}
                        </Label>
                        <Controller
                          name="leagueId"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="mySelect"
                              options={sport ? leagueOption : []}
                              placeholder={t("SELECT_DOT")}
                              value={league}
                              isLoading={eventLoading}
                              onChange={(option) => {
                                handleLeagueSelect(option, field);
                                setIsRoleSelectOpen2(false);
                                setLeague(option);
                                field.onChange(option?.value);
                              }}
                              menuIsOpen={isRoleSelectOpen2}
                              onFocus={() => setIsRoleSelectOpen2(true)}
                              onBlur={() => setIsRoleSelectOpen2(false)}
                            />
                          )}
                        />
                        <span className="text-danger">
                          {errors.leagueId && t("FIELD_REQUIRED")}
                        </span>
                      </Col>

                      <Col md="4 mb-3" className="">
                        <Label htmlFor="validationCustom03">
                          {t("SELECT_EVENT")}
                        </Label>
                        <Controller
                          name="eventId"
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="mySelect"
                              placeholder={t("SELECT_DOT")}
                              options={eventOption}
                              value={event}
                              onChange={(option) => {
                                handleEventSelect(option, field);
                                setIsRoleSelectOpen3(false);
                              }}
                              menuIsOpen={isRoleSelectOpen3}
                              onFocus={() => setIsRoleSelectOpen3(true)}
                              onBlur={() => setIsRoleSelectOpen3(false)}
                            />
                          )}
                        />
                        <span className="text-danger">
                          {errors.eventId && t("FIELD_REQUIRED")}
                        </span>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        <Row className="px-3">
          <Card className="px-0">
            {currentType === "ALL" && (
              <CardHeader className="py-3 ">
                {/* <Col sm="4">
                  <Controller
                    name="search"
                    control={control}
                    render={({ field }) => (
                      <input
                      {...field} 
                      className="form-control p-2"
                      type="search"
                      onChange={(e) => {
                        field.onChange(e);
                        handleSearchFunction(e);
                      }}
                      placeholder="Search Event Name..."
                    />
                    )}
                  />
                </Col> */}

                <Row className="d-flex gap-3 justify-content-between">
                  <Col sm="4">
                    <form>
                      <div className="form-row align-items-center">
                        <div className="col-auto my-1">
                          <label
                            className="mr-sm-2"
                            htmlFor="inlineFormCustomSelect"
                          >
                            Preference
                          </label>
                          <select
                            className="custom-select m-2 rounded p-2"
                            id="inlineFormCustomSelect"
                            value={lastDate}
                            onChange={handleDatePreference}
                          >
                            {dateFilter.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </form>
                  </Col>
                </Row>
              </CardHeader>
            )}

            {/* INITIAL LOADING AND OTHER LOADING COMPONENT */}
            <CardBody className="p-0">
              {loading && (
                <div className="d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              )}

              {/* ALL VALUE MAPING FOR THE INITIAL RENDER IS HERE */}
              {/* SETCURRENTTYPE THERE IS A STATE FOR VIEW THE CURRENT TYPE */}
              {/* EXPECTED ALL MEANS INITAL VIEW */}
              {/* EVENT-VIEW MEANS OTHER VIEW OF EVENTS REUSEING THE SAME COMPONENT */}
              <Col md="12">
                <div
                  className="overflow-auto"
                  style={{ height: "auto", position: "relative", zIndex: 0 }}
                >
                  <table
                    responsive
                    className="table table-bordered table-hover "
                  >
                    <thead
                      className="table-light sticky-top"
                      style={{ zIndex: 1 }}
                    >
                      <tr className="text-left" style={{ border: "none " }}>
                        {currentType === "ALL" && (
                          <th style={{ minWidth: "100px", maxWidth: "110px" }}>
                            {t("DATE")}
                          </th>
                        )}

                        <th>
                          {t(currentType === "ALL" ? "EVENTS" : "MARKETS")}
                        </th>
                        {currentType === "EVENT-VIEW" && <th>{t("PATH")}</th>}
                        {currentType === "EVENT-VIEW" && (
                          <th>{t("VIEW BETS")}</th>
                        )}

                        <th>{t("TOTAL PL")}</th>
                        <th>{t("ACTUAL PL")}</th>
                        <th>{t("BETFAIR PL")}</th>
                        <th>{t("CLIENT SHARES")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentType === "ALL" &&
                        filteredResultData !== null &&
                        filteredResultData?.map((element, index) => {
                          if (!element.eventId || !element.date) {
                            return null;
                          }
                          return (
                            <tr className="text-left" key={index}>
                              <td style={{ maxWidth: "40px" }}>
                                {formatDate(element?.date)}
                              </td>
                              <td
                                className="text-primary"
                                style={{ cursor: "pointer" }}
                              >
                                <span style={{ cursor: "not-allowed" }}>
                                  {element?.sportsName}
                                </span>
                                <span style={{ color: "black" }}>{` > `}</span>
                                <span style={{ cursor: "not-allowed" }}>
                                  {element?.leagueName}
                                </span>
                                <span style={{ color: "black" }}>{` > `}</span>
                                <span
                                  onClick={() => {
                                    handleEventClick(element.eventId);
                                  }}
                                >
                                  {element?.eventName}
                                </span>
                              </td>
                              <td
                                className={`${-1 * element?.totalPl > 0
                                    ? "text-success"
                                    : "text-danger"
                                  }`}
                              >
                                {/* {formatNumbers(-1 * element?.totalPl)} */}

                                {adminData?.currencyId?.value
                                  ? convertINRToCurrency(
                                    -1 * element?.totalPl?.toFixed(2),
                                    parseFloat(adminData?.currencyId?.value)
                                  )
                                  : -1 * element?.totalPl?.toFixed(2)}
                              </td>

                              <td>
                                {/* {element?.actualPl
                                  ? (-1 * element?.actualPl).toFixed(2)
                                  : (0).toFixed(2)} */}

                                {adminData?.currencyId?.value
                                  ? convertINRToCurrency(
                                    -1 * element?.actualPl.toFixed(2),
                                    parseFloat(adminData?.currencyId?.value)
                                  )
                                  : -1 * element?.actualPl.toFixed(2)}
                              </td>
                              <td>
                                {adminData?.currencyId?.value
                                  ? convertINRToCurrency(
                                    -1 * element?.betfairPl.toFixed(2),
                                    parseFloat(adminData?.currencyId?.value)
                                  )
                                  : -1 * element?.betfairPl.toFixed(2)}
                              </td>
                              <td>
                                {element?.totalParentShare}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {loading === false &&
                    (filteredResultData?.length === 0 ||
                      filteredResultData === null) && (
                      <div className="d-flex justify-content-center">
                        <p className="p-3">{t("NO_DATA_AVAILABLE")}</p>
                      </div>
                    )}
                </div>
              </Col>

              {!loading && filteredResultData.length > 0 && (
                <Row className="px-3">
                  <Col md="12">
                    {/* <View
                            filteredResultData={filteredResultData}
                            label={filteredResultData[0]?.description}
                          /> */}
                  </Col>
                </Row>
              )}

              {/* <Col xl="12" className="py-3 px-4">
                <PaginationButtons
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </Col> */}
            </CardBody>
          </Card>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ResultAnalysis;
