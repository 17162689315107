// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
    font-size: 11px;
    padding: 1em 2.7em;
    font-weight: 800;
    background: #1f2937;
    color: white;
    border: none;
    position: relative;
    overflow: hidden;
    border-radius: 0.6em;
    cursor: pointer;
  }
  
  .custom-button .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0.6em;
    margin-top: -0.25em;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3)
    );
  }
  
  .custom-button .label {
    position: relative;
    top: -1px;
  }
  
  .custom-button .transition {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 500ms;
    background-color: rgba(16, 185, 129, 0.6);
    border-radius: 9999px;
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .custom-button:hover .transition {
    width: 14em;
    height: 14em;
  }
  
  .custom-button:active {
    transform: scale(0.97);
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Pages/PageLayout/RestorePanel/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IACpB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,OAAO;IACP,MAAM;IACN,oBAAoB;IACpB,mBAAmB;IACnB;;;;KAIC;EACH;;EAEA;IACE,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,sDAAsD;IACtD,0BAA0B;IAC1B,yCAAyC;IACzC,qBAAqB;IACrB,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;EAClC;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":[".custom-button {\n    font-size: 11px;\n    padding: 1em 2.7em;\n    font-weight: 800;\n    background: #1f2937;\n    color: white;\n    border: none;\n    position: relative;\n    overflow: hidden;\n    border-radius: 0.6em;\n    cursor: pointer;\n  }\n  \n  .custom-button .gradient {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 0;\n    top: 0;\n    border-radius: 0.6em;\n    margin-top: -0.25em;\n    background-image: linear-gradient(\n      rgba(0, 0, 0, 0),\n      rgba(0, 0, 0, 0),\n      rgba(0, 0, 0, 0.3)\n    );\n  }\n  \n  .custom-button .label {\n    position: relative;\n    top: -1px;\n  }\n  \n  .custom-button .transition {\n    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);\n    transition-duration: 500ms;\n    background-color: rgba(16, 185, 129, 0.6);\n    border-radius: 9999px;\n    width: 0;\n    height: 0;\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  }\n  \n  .custom-button:hover .transition {\n    width: 14em;\n    height: 14em;\n  }\n  \n  .custom-button:active {\n    transform: scale(0.97);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
