// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 12.5px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-custom {
  color: #000;
  background-color: #fff;
  padding: 5px;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
  padding: 5px;
}

.badge-orange {
  background-color: #EC3F00;
  padding: 5px;
  color: #fff;
}

.cursor-pointer-custom {
  cursor: pointer !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/pages/resultTransaction.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,qBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,wBAAA;EACA,sBAAA;EACA,qIAAA;AACJ;;AACA;EACI,WAAA;EACA,sBAAA;EACA,YAAA;AAEJ;;AACA;EACI,WAAA;EACA,yBAAA;EACA,YAAA;AAEJ;;AACA;EACI,yBAAA;EACA,YAAA;EACA,WAAA;AAEJ;;AACA;EACI,0BAAA;AAEJ","sourcesContent":[".badge {\n    display: inline-block;\n    padding: 0.25em 0.4em;\n    font-size: 12.5px;\n    font-weight: 700;\n    line-height: 1;\n    text-align: center;\n    white-space: nowrap;\n    vertical-align: baseline;\n    border-radius: 0.25rem;\n    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;\n}\n.badge-custom {\n    color: #000;\n    background-color: #fff;\n    padding: 5px;\n}\n\n.badge-info {\n    color: #fff;\n    background-color: #17a2b8;\n    padding: 5px;\n}\n\n.badge-orange {\n    background-color: #EC3F00;\n    padding: 5px;\n    color: #fff;\n}\n\n.cursor-pointer-custom {\n    cursor: pointer !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
