// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add styling for the chart skeleton with shimmer animation */
.skeleton-chart {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

/* Add styling for the order content skeleton with shimmer animation */
.skeleton-order {
  display: flex;
  align-items: center;
  padding: 10px 0;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.skeleton-order > div {
  flex-grow: 1;
}

.skeleton-order .f-light,
.skeleton-order h4 {
  width: 100px;
  height: 18px;
  border-radius: 10px;
  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);
  background-size: 200% 100%;
  margin: 0;
  animation: shimmer 1.5s infinite;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Component/CommonWidgets/DashboardLeftLoading.scss"],"names":[],"mappings":"AAAA,8DAAA;AACA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,yEAAA;EACA,0BAAA;EACA,gCAAA;AACF;;AAEA,sEAAA;AACA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gCAAA;AACF;;AAEA;EACE;IACE,2BAAA;EACF;EAEA;IACE,4BAAA;EAAF;AACF;AAGA;EACE,YAAA;AADF;;AAIA;;EAEE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,yEAAA;EACA,0BAAA;EACA,SAAA;EACA,gCAAA;AADF","sourcesContent":["/* Add styling for the chart skeleton with shimmer animation */\n.skeleton-chart {\n  width: 175px;\n  height: 175px;\n  border-radius: 50%;\n  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);\n  background-size: 200% 100%;\n  animation: shimmer 1.5s infinite;\n}\n\n/* Add styling for the order content skeleton with shimmer animation */\n.skeleton-order {\n  display: flex;\n  align-items: center;\n  padding: 10px 0;\n  animation: shimmer 1.5s infinite;\n}\n\n@keyframes shimmer {\n  0% {\n    background-position: 200% 0;\n  }\n\n  100% {\n    background-position: -200% 0;\n  }\n}\n\n.skeleton-order>div {\n  flex-grow: 1;\n}\n\n.skeleton-order .f-light,\n.skeleton-order h4 {\n  width: 100px;\n  height: 18px;\n  border-radius: 10px;\n  background: linear-gradient(to right, #f0f0f0 0%, #ddd 50%, #f0f0f0 100%);\n  background-size: 200% 100%;\n  margin: 0;\n  animation: shimmer 1.5s infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
