import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { STX_AccountDetails_exclude } from "../../../../utils/constants";

const STXAccountDetailsModal = (props) => {
  const { t } = useTranslation();
  const STXAccountData = useSelector((state) => state?.StxAccountDetails);
  const [stxAccountDetails, setStxAccountDetails] = useState({});

  useEffect(() => {
    if (STXAccountData?.data?.data?.data?.account !== undefined) {
      const globalData = STXAccountData?.data?.data?.data?.account;
      try {
        const socketData = globalData;
        const filteredData = Object.entries(socketData)
          .filter(([key]) => !STX_AccountDetails_exclude.includes(key))
          .sort(([, valueA], [, valueB]) => valueB - valueA)
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
        setStxAccountDetails(filteredData);
      } catch (error) {
        console.log('error in processing FilteredData', error);
      }
    }
  }, [props.show, props.data]);

  return (
    stxAccountDetails &&
    Object.keys(stxAccountDetails).length && (
      <Modal
        className=""
        show={props.isOpen}
        onHide={props.toggler}
        centered
        size={props.size}
      >
        <Modal.Header closeButton>
          <Modal.Title className="p-1 pb-0">{props?.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={props?.bodyClass} style={{ padding: "5px" }}>
          <div className="overflow-auto" style={{ maxHeight: "60vh" }}>
            <div className="table-responsive">
              <table className="table table-bordered table-hover mt-3">
                <thead className="table-light border">
                  <tr>
                    <th scope="col" className="text-uppercase text-nowrap">
                      {t("KEY")}
                    </th>
                    <th scope="col" className="text-uppercase text-nowrap">
                      {t("VALUE")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(stxAccountDetails)?.map(
                    ([key, value], index) => (
                      <tr key={index}>
                        <td className="fw-semibold text-dark">
                          {key
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </td>
                        <td className="fw-semibold text-dark">
                          {typeof value === "number"
                            ? value.toLocaleString()
                            : value
                          }
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  );
};

export default STXAccountDetailsModal;
