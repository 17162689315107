import { all, call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  notifyDanger,
  notifySuccess,
} from "../../../utils/helper";
import API from "../../../utils/oddApi";
import { POST_STX_MARKET_ASSIGNING } from "../../action/types";
import { stxMarketAssigningFailure, stxMarketAssigningSuccess } from "../../action";

function* stxMarketAssigning(action) {
  try {
    console.log("assigning action to",action.payload);
    const { data } = yield API.post(
      "api/v2/stx-market-assign-to-event",
      action?.payload?.data
    );
    if (data?.meta?.code === 200) {
      yield put(stxMarketAssigningSuccess(data));
      yield call(action.payload.callback, data);  
    }
    else if (data?.meta?.code !== 200) {
      yield put(stxMarketAssigningFailure());
      notifyDanger(data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  } catch (error) {
    yield put(stxMarketAssigningFailure());
    notifyDanger(error?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  }
}

export function* watchStxMarketAssigningAPI() {
  yield takeEvery(POST_STX_MARKET_ASSIGNING, stxMarketAssigning);
}

export default function* rootSaga() {
  yield all([watchStxMarketAssigningAPI()]);
}