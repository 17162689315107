import React from "react";
import "../../../../assets/scss/pages/_restorePanel.scss";
import { STX_AVAILABLE_SPORTS } from "../../../../utils/constants";

const EventsPanel = ({
  event,
  setSelectedEvents,
  selectedEvents,
  selectedIds,
  onChange,
  handleAssignSTXMarket,
  index,

  checked,
  assigned,
  stxSelectedMarket,
  clickedIndex,
  stxLeagueIndexes,

  matchingValue,
  matchingMultipleEvents,
  assignedMarketAndSTXMarket,
  currentSport,
}) => {
  const onChangeCheckedBox = (e, event, date) => {
    if (e.target.checked) {
      setSelectedEvents((prev) => [
        ...prev,
        {
          events_name: event?.name,
          events_slugName: event?.name,
          events_code: event?.id,
          events_eventDate: date,
        },
      ]);
    } else {
      let eventData = selectedEvents?.filter(
        (events) => events?.events_code !== event?.id
      );
      setSelectedEvents(eventData);
    }
  };

  //THIS WILL ONLY WORK IF THE SELECTED SPORTS IS CRICKET AND MATCHED EVENTS IS CORRECTLY IS PROVIDED
  let currentMarket = null;
  // let findAssignedMarket = false;
  if (
    STX_AVAILABLE_SPORTS.includes(currentSport) &&
    matchingMultipleEvents &&
    matchingMultipleEvents?.length !== 0
  ) {
    currentMarket = assignedMarketAndSTXMarket.find(
      (item) => item.index === index
    );
    // findAssignedMarket = assignedMarketAndSTXMarket.some((item) => {
    //   return item.index === index;
    // });
  }
  // console.log("currentMarket",currentMarket)
  // console.log("findAssignedMarket",findAssignedMarket)
  // console.log("matchingMultipleEvents",matchingMultipleEvents)

  return (
    <tr>
      <td style={{ width: "50px", textAlign: "center" }} className="relative">
        <label className="checkbox-container" style={{ margin: "0px" }}>
          <input
            // id="customCheckbox"
            type="checkbox"
            name="game1"
            className="checkbox-events"
            checked={selectedIds.includes(event.event.id)} // Use the checked prop to control the checkbox
            onChange={(e) => {
              onChangeCheckedBox(e, event.event, event.event?.id); // Call the internal function
              onChange(event.event?.id); // Call the external onChange prop if passed
            }}
          />
          <div className="checkmark"></div>
        </label>
      </td>
      <td className="h6">{event.event?.name}</td>
      <td className="text-success h6">
        {new Date(event.event?.openDate).toLocaleString()}

        {/* {new Date(event.event?.openDate)?.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone:'2-digit',
        })} */}

        {/* {currentMarket && (
          <p className="text-danger p-0 m-0">
            " {currentMarket?.stxSelectedMarket?.title} "
          </p>
        )} */}
      </td>

      {matchingMultipleEvents && matchingMultipleEvents?.length !== 0 && (
        <button
          onClick={() =>
            handleAssignSTXMarket(
              matchingValue,
              event,
              index,
              matchingMultipleEvents
            )
          }
          className={`mx-2 btn text-white ${
            currentMarket ? "btn-danger" : "btn-success"
          } `}
        >
          {currentMarket ? "Undo Assign" : "Assign STX"}
        </button>
      )}
    </tr>
  );
};

export default EventsPanel;
