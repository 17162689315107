export const ENGLISH = {
  GENERAL: "General",
  All: "All",
  ENTER_NAME: "Enter name",
  ENTER_BALANCE: "Enter Balance",
  ENTER_SHARES: "Enter Shares",
  ENTER_CREDIT_REFERENCE: "Enter Credit Reference",
  ENTER_EMAIL_OPTIONAL: "Enter email (optional)",
  ENTER_MOBILE_OPTIONAL: "Enter mobile (optional)",
  ENTER_USERNAME: "Enter username",
  FROM: "From",
  CASINO: "Casino",
  NO_LEAGUE_AVAILABLE: "Please select the sport...",
  SELECT_SPORT_AND_LEAGUE: "Please select the sport and league...",
  SELECT_SPORT_AND_CURRENCY: "Please select the sport and currency...",
  LOGIN_HISTORY: "Login History",
  LEAGUE_SETTING: "League setting",
  MATCH_SETTING: "Match setting",
  NO_LOGIN_HISTORY_AVAILABLE: "no login history available..",
  DASHBOARD: "Dashboard",
  SETTLEMENT: "Settlement",
  NEW_PASSWORD: "New Password",
  SPORT_SETTING: "Sport Setting",
  ENTER_CHIPS: "Enter Chips",
  BET_ALLOW: "Bet Allow",
  SELECT_RESULT: "Select Result",
  GAME: "Game",
  RESET: "Reset",
  CHIP_VALUE: "Chip Value",
  SPORTS: "Sports",
  LIVE_CASINO: "Live Casino",
  TEEN_PATTI: "Teen Patti",
  SELECT_CURRENCY: "Select Currency",
  REMARK: "Remark",
  ACTIVE: "Active",
  DIRECTOR: "Director",
  ACTIVE_CLIENTS: "Active Clients",
  ENTER_PASSWORD: "Enter Password",
  PERMISSIONS_OF: "Permissions Of",
  SETTLEMENT_HISTORY: "Settlement History",
  SETTLEMENT_OF: "Settlement of",
  CHANGE_PASSWORD_OF: "Change Password Of",
  CHANGE_PASSWORD: "Change Password",
  SEARCH: "Search",
  SELECT_DOT: "Select...",
  SELECT_STX_DATA: "Select STX Data",
  MARKET_TYPE: "Market Type",
  BLOCK_AND_LOGOUT: "Block & Logout",
  LAST_LOGGED_IN_TIME: "Last logged in time",
  EVENT_MANAGEMENT: "Event Management",
  MANAGE: "Manage",
  ACTIVE_BETS: "Active Bets",
  NO_DATA_AVAILABLE: "no data available...",
  CANCEL: "Cancel",
  BANKING: "Banking",
  ACTIVE_CLIENT: "Active Clients",
  LIVE_EXPOSURE: "Live Exposure",
  AVAILABLE_BALANCE: "Available Balance",
  TOTAL_BALANCE: "Total Balance",
  TOTAL_CLIENTS: "Total Clients",
  CLIENT_BALANCE: "Clients Balance",
  ACTUAL_PL: "Actual P/L",
  TOTAL_PL: "Total P/L",
  EXPOSURE: "Exposure",
  ODD_SIZE: "Odds [Size]",
  ODDS: "Odds",
  CREDIT_REFERENCE: "Credit Reference",
  CREATED_BY: "Created by",
  MY_BALANCE: "My Balance",
  LOGOUT: "Logout",
  SUBMIT: "Submit",
  ROW_COUNT: "Row count",
  CREATE: "Create",
  ALLOW: "Allow",
  ALL: "All",
  CHILD: "Child D/W",
  PARENT: "Parent D/W",
  WALLET_TRANSACTIONS: "Wallet Transactions",
  USERNAME: "Username",
  MARKET_PROFIT_AND_LOSS: "Market Profit & Loss",
  INBOX: "Inbox",
  WELCOME_TO_CUBA: "Welcome To  Betxfair Admin Panel",
  WELCOME_MESSAGE: "Heres whats happening in your account today",
  ACTION: "Action",
  BROWSER_IP_ADDRESS: "browser ip",
  SYSTEM_IP_ADDRESS: "system ip",
  ACCOUNT: "Account",
  BLOCK: "Block",
  REPORTS: "Reports",
  REPORTS_OF: "Reports of",
  COMMISSION_REPORTS: "Commission Reports",
  SELECTION: "Selection",
  OF: "Of",
  USER_LIST: "User List",
  DEPOSIT_OF: "Deposit Of",
  WITHDRAWAL_OF: "Withdrawal Of",
  BALANCE: "Balance",
  WIDGETS: "Widgets",
  CREDIT: "Credit",
  DEBIT: "Debit",
  PASSWORD: "Password",
  EMAIL: "Email",
  SHARES: "Shares",
  SPORTS_SHARES: "Sports Shares",
  DECLARED_BY: "Declared By",
  SELECT_SPORTS: "Select Sports",
  CLEAR: "Clear",
  CREATE_ACCOUNT: "Create Account",
  CODE: "Code",
  STAKE_SIZE: "Stake size",
  AMOUNT: "Amount",
  OLD_PL: "Old P/L",
  NEW_PL: "New P/L",
  STAKE: "Stake",
  UPDATED_AT: "Updated At",
  "ADD-CURRENCY": "Add currency",
  MIN_STAKE: "Min stake",
  MAX_STAKE: "Max stake",
  MIN_STAKE: "Min stake",
  MAX_STAKE: "Max stake",
  MIN_STAKE: "Min stake",
  MAX_STAKE: "Max stake",
  CURRENCY_LIST: "Currency list",
  BET_DELAY: "Bet delay",
  BET_DELAY_EXCH: "Bet delay(EXCH)",
  BET_DELAY_FANCY: "Bet delay(FANCY)",
  BET_DELAY_BOOKMARK: "Bet delay(BOOKMAKER)",
  CURRENCY_CODE: "Currency code",
  CURRENCY_NAME: "Currency name",
  SELECT_EVENT: "Select Event",
  FIELD_REQUIRED: "Field Required",
  NAME: "Name",
  LIMIT: "Limit",
  ROLE: "Role",
  PROFIT_LIMIT: "Profit limit",
  "RESTORE-PANEL": "Restore panel",
  RESTORE_PANEL: "Restore panel",
  SELECT_LEAGUE: "Select League",
  SELECT_MATCH: "Select Match",
  "MANAGE-EVENTS": "Manage events",
  EVENT: "Event",
  PERSONAL_DETAILS: "Personal Details",
  RESULTS_TRANSACTION: "Result Transaction",
  RUNNING_MARKET_ANALYSIS: "Running Market Analysis",
  "MATCH-SETTING": "Match setting",
  DESCRIPTION: "Description",
  CURRENCY_VALUE: "Currency value",
  VALUE: "Value",
  USER_NAME: "User Name",
  USER_NAME_NAME: "Username (Name)",
  CHART: "Chart",
  SETTINGS: "Settings",
  TASKBOARD: "Taskboard",
  CREATED_AT: "Created At",
  CASINO_SHARES: "Casino Shares",
  "ADD-USER": "Add user",
  ADD_CURRENCY: "Add currency",
  ADD_USER: "Add user",
  BETS: "Bets",
  PROFIT_LOSS: "Profit/loss",
  RUNNING_MARKETS: "Running markets",
  CURRENCIES: "Currencies",
  MY_MARKET: "My market",
  RESULTS: "Results",
  APPLICATIONS: "Applications",
  MOBILE_NO: "Mobile no",
  SELECT_MARKET: "Select Market",
  CURRENCY: "Currency",
  USERS: "Users",
  INACTIVE_USERS: "Inactive Users",
  USER: "User",
  ADD: "Add",
  RESULT: "Result",
  TYPE: "Type",
  FANCY: "Fancy",
  TO: "To",
  BOOKMAKER: "Bookmaker",
  EXCHANGE: "Exchange",
  SPORT: "Sport",
  LEAGUE: "League",
  MARKET: "Market",
  DATE: "Date",
  ADD_CURRENCY: "Add Currency",
  BOOK_VIEW: "Book View",
  "DELETE_BET_?": "Delete Bet ?",
  SHARED_BOOKS: "Shared Books",
  USER_PARENT: "User [Parent]",
  CHANGE_MY_PASSWORD: "Change My Password",
  CURRENT_PASSWORD: "Current Password",
  CONFIRM_PASSWORD: "Confirm Password",
  INACTIVE_USER_LIST: "Inactive User List",
  DELETED_BETS: "Deleted Bets",
  DELETED_BETS_REPORTS: "Deleted Bets Reports",
  HOME_SLIDER_LIST: "Home Slider List",
  ADD_HOME_SLIDER: "Add Home Slider",
  "HOME-SLIDER": "Home Slider",
  HOME_SLIDERS_LIST: "Home Sliders List",
  "ADD-SLIDER": "Add Slider",
  ADD_SLIDER: "Add Slider",
  MANAGE_SPORTS_ACCORDINGLY:"Manage Sports Accordingly",
  MANAGERS: "Managers",
  ADD_MANAGER: "Add Manager",
  ACCOUNTS_MANAGERS: "Accounts Managers",
  OPERATIONAL_MANAGERS: "Operational Managers",
  MONITORING_MANAGERS: "Monitoring Managers",
  MANAGERS_LIST: "Managers List ",
  COMMISSION: "Commission",
  COMMISSION_PERCENTAGE:"Commission Percentage",
  ENTER_COMMISSION:"Enter Commission",
  "WHITE-LABELS": "White labels",
  "ADD-WHITELABEL": "Add Whitelabel",
  "WHITELABELS-LIST": "White labels List",
  CLIENTSHARE :"Client Share",
  ADD_SSO_ID :"Change Id",
  COMMISSION_WISE : "Commission",
  FANCY_LIMIT :"Max. Bookmaker Stake Limit per match",
  CLIENTSHARE: "Client Share",
  ADD_SSO_ID: "Change Id",
  COMMISSION_WISE: "Commission",
  FANCY_STAKE_SETTING :"Fancy stake settings",
  NOTIFICATION :"Notification"
};

export const SPANISH = {
  GENERAL: "General",
  All: "todo",
  CASINO: "Casino",
  ENTER_BALANCE: "Ingresar Saldo",
  ENTER_CREDIT_REFERENCE: "Ingrese la referencia de crédito",
  ENTER_SHARES: "Introduzca acciones",
  ENTER_EMAIL_OPTIONAL: "Ingrese el correo electrónico (opcional)",
  ENTER_MOBILE_OPTIONAL: "Introducir móvil (opcional)",
  ENTER_USERNAME: "Introduzca su nombre de usuario",
  ENTER_PASSWORD: "Introducir la contraseña",
  ENTER_NAME: "Ingrese su nombre",
  FROM: "De",
  NO_LEAGUE_AVAILABLE: "Por favor selecciona el deporte...",
  SELECT_SPORT_AND_LEAGUE: "Por favor selecciona el deporte y la liga...",
  SELECT_SPORT_AND_CURRENCY: "Por favor seleccione el deporte y la moneda...",
  LOGOUT: "Cerrar sesión ",
  MATCH_SETTING: "Configuración de coincidencia",
  LEAGUE_SETTING: "Configuración de la liga",
  LOGIN_HISTORY: "Historial de inicio de sesión",
  NO_LOGIN_HISTORY_AVAILABLE:
    "no hay historial de inicio de sesión disponible..",
  "RESTORE-PANEL": "Restaurar panel",
  RESTORE_PANEL: "Restaurar panel",
  CREDIT_REFERENCE: "Referencia crediticia",
  REMARK: "Observación",
  NEW_PASSWORD: "Nueva contraseña",
  FANCY: "Elegante",
  SPORT_SETTING: "Escenario deportivo",
  SELECT_RESULT: "Seleccionar resultado",
  RESET: "Reiniciar",
  BET_ALLOW: "Permitir apuesta",
  LIVE_CASINO: "casino en vivo",
  TEEN_PATTI: "patty adolescente",
  ACTIVE: "Activo",
  GAME: "Juego",
  TO: "A",
  SELECT_CURRENCY: "Seleccione el tipo de moneda",
  ALLOW: "Permitir",
  SPORTS: "Deportes",
  DEBIT: "Débito",
  RUNNING_MARKET_ANALYSIS: "Ejecución de análisis de mercado",
  PROFIT_LOSS: "Ganancia/pérdida",
  DIRECTOR: "Director",
  CHIP_VALUE: "Valor de la ficha",
  ENTER_CHIPS: "Introducir fichas",
  SEARCH: "Buscar",
  PERMISSIONS_OF: "permisos de",
  CHANGE_PASSWORD_OF: "Cambiar contraseña de",
  CHANGE_PASSWORD: "Cambiar contraseña",
  SETTLEMENT_HISTORY: "Historial de liquidación",
  SETTLEMENT_OF: "Historial de of",
  SELECT_DOT: "Seleccionar...",
  BOOKMAKER: "Corredor de apuestas",
  DEPOSIT_OF: "Depósito de",
  WITHDRAWAL_OF: "Retiro de",
  EXCHANGE: "Intercambio",
  ROLE: "Role",
  ACTIVE_CLIENTS: "Clientes activos",
  SHARES: "Comparte",
  SELECTION: "Selección",
  MARKET_TYPE: "Tipo de mercado",
  ENTER_PASSWORD: "Introducir la contraseña",
  ACTUAL_PL: "Actual P/L",
  ACTIVE_BETS: "Apuestas activas",
  STAKE: "Apostar",
  BLOCK_AND_LOGOUT: "Bloquear y cerrar sesión",
  EVENT_MANAGEMENT: "Gestión de eventos",
  MANAGE: "Administrar",
  ODD_SIZE: "Impares [Tamaño]",
  ODDS: "Impares",
  BROWSER_IP_ADDRESS: "dirección IP del navegador",
  SYSTEM_IP_ADDRESS: "dirección IP del sistema",
  LAST_LOGGED_IN_TIME: "Última hora de inicio de sesión",
  ACCOUNT: "Cuenta",
  PARENT: "Madre D/W",
  WALLET_TRANSACTIONS: "transacciones de billetera",
  NO_DATA_AVAILABLE: "datos no disponibles...",
  BANKING: "Bancario",
  ACTIVE_CLIENT: "Clientes Activos",
  LIVE_EXPOSURE: "Exposición en Vivo",
  ALL: "Todos",
  CHILD: "Niño/Niña D/W",
  MARKET_PROFIT_AND_LOSS: "Ganancias y Pérdidas del Mercado",
  TOTAL_BALANCE: "Saldo Total",
  CLIENT_BALANCE: "Saldo de Clientes",
  TOTAL_CLIENTS: "Total de clientes",
  TOTAL_PL: "Total P/L",
  MY_BALANCE: "Mi balance",
  WELCOME_TO_CUBA: "Bienvenido al panel de administración de  Betxfair",
  WELCOME_MESSAGE: "Aquí lo que está pasando en su cuenta hoy",
  INBOX: "Bandeja de entrada",
  CANCEL: "Cancelar",
  SETTLEMENT: "Asentamiento",
  ADD: "Agregar",
  CLEAR: "Claro",
  REPORTS: "Informes",
  REPORTS_OF: "Informes",
  COMMISSION_REPORTS: "Informes de la Comisión",
  ACTION: "Comportamiento",
  VALUE: "Valor",
  EXPOSURE: "Exposición",
  USER_LIST: "Lista de usuarios",
  SUBMIT: "Entregar",
  EMAIL: "Correo electrónico",
  USER: "Usuario",
  ROW_COUNT: "Número de filas",
  DESCRIPTION: "Descripción",
  TASKBOARD: "Panel de tareas",
  TYPE: "Tipo",
  DECLARED_BY: "declarado por",
  SELECT_SPORTS: "Seleccionar deportes",
  RESULTS_TRANSACTION: "transacción de resultados",
  CODE: "Código",
  CREDIT: "Crédito",
  OF: "Van",
  DASHBOARD: "Panel",
  BALANCE: "Balance",
  DATE: "Fecha",
  USERS: "Usuarios",
  INACTIVE_USERS: "Usuarios Inactivos",
  PASSWORD: "Contraseña",
  FIELD_REQUIRED: "Campo requerido",
  "ADD-USER": "Agregar usuario",
  ADD_CURRENCY: "Agregar moneda",
  ADD_USER: "Agregar usuario",
  SELECT_MARKET: "Seleccionar mercado",
  CURRENCIES: "Monedas",
  "ADD-CURRENCY": "Agregar moneda",
  CREATE: "Crear",
  SELECT_EVENT: "Seleccionar evento",
  BETS: "apuestas",
  STAKE_SIZE: "tamaño de la apuesta",
  AMOUNT: "Amount",
  OLD_PL: "Old P/L",
  NEW_PL: "New P/L",
  MIN_STAKE: "Apuesta mínima",
  MAX_STAKE: "máx. mínima",
  MIN_STAKE: "Apuesta mínima",
  MAX_STAKE: "máx. mínima",
  MIN_STAKE: "Apuesta mínima",
  MAX_STAKE: "máx. mínima",
  USERNAME: "Nombre de usuario",
  BET_DELAY: "Retraso de apuesta",
  BET_DELAY_EXCH: "Retraso de apuesta(EXCH)",
  BET_DELAY_FANCY: "Retraso de apuesta(FANCY)",
  BET_DELAY_BOOKMARK: "Retraso de apuesta(BOOKMARK)",
  PROFIT_LIMIT: "límite de beneficio",
  LIMIT: "Límite",
  CURRENCY: "Divisa",
  "MANAGE-EVENTS": "Administrar eventos",
  EVENT: "Event",
  RESULTS: "Resultados",
  RESULT: "Resultado",
  BLOCK: "Bloquear",
  RUNNING_MARKETS: "mercados corrientes",
  SETTINGS: "Configuración",
  "MATCH-SETTING": "Ajuste de coincidencia",
  PERSONAL_DETAILS: "Detalles Personales",
  MOBILE_NO: "No móviles",
  SPORTS_SHARES: "Acciones deportivas",
  CASINO_SHARES: "Acciones de casino",
  CREATE_ACCOUNT: "Crear una cuenta",
  USER_NAME: "Nombre de usuario",
  USER_NAME_NAME: "उपयोक्तानाम (नाम)nombre de usuario (nombre)",
  NAME: "Nombre",
  CURRENCY_NAME: "Nombre de moneda",
  CURRENCY_CODE: "Código de moneda",
  CURRENCY_VALUE: "valor de la moneda",
  CURRENCY_LIST: "Lista de divisas",
  CREATED_AT: "Creado en",
  UPDATED_AT: "Actualizado en",
  SELECT_SPORT: "Seleccione Deporte",
  SELECT_LEAGUE: "Seleccionar liga",
  SPORT: "Deporte",
  LEAGUE: "Liga",
  MARKET: "Mercado",
  MY_MARKET: "mi mercado",
  BOOK_VIEW: "vista de libro",
  "DELETE_BET_?": "¿Eliminar apuesta?",
  SHARED_BOOKS: "libros compartidos",
  USER_PARENT: "USUARIO [PADRE]",
  CHANGE_MY_PASSWORD: "cambiar mi contraseña",
  CURRENT_PASSWORD: "Contraseña actual",
  CONFIRM_PASSWORD: "confirmar Contraseña",
  INACTIVE_USER_LIST: "lista de usuarios inactivos",
  DELETED_BETS: "Apuestas eliminadas",
  DELETED_BETS_REPORTS: "Informes de apuestas eliminadas",
  HOME_SLIDER_LIST: "Lista deslizante de inicio",
  ADD_HOME_SLIDER: "Agregar control deslizante de inicio",
  "HOME-SLIDER": "Control deslizante de inicio",
  HOME_SLIDERS_LIST: "Lista de controles deslizantes de inicio",
  "ADD-SLIDER": "Agregar control deslizante",
  ADD_SLIDER: "Agregar control deslizante",
  ORDER: "ORDEN",
  BANNER: "BANDERA",
  STATUS: "ESTADO",
  DESKTOP: "ESCRITORIO",
  MOBILE: "MÓVIL",
  IMAGE: "IMAGEN",
  MANAGERS: "Gerentes",
  ADD_MANAGER: "Agregar administrador",
  ACCOUNTS_MANAGERS: "Administradores de cuentas",
  OPERATIONAL_MANAGERS: "Gerentes Operativos",
  MONITORING_MANAGERS: "Gerentes de Monitoreo",
  MANAGERS_LIST: "Lista de gerentes ",
  CREATED_BY: "creado por",
  COMMISSION: "COMISIÓN",
  COMMISSION_PERCENTAGE:"PORCENTAJE DE COMISIÓN",
  ENTER_COMMISSION:"Ingresar comisión",
  CLIENTSHARE: "Cliente compartido",
  ADD_SSO_ID: "Cambiar ID",
};

export const HINDI = {
  LOGOUT: "लॉग आउट",
  LEAGUE_SETTING: "लीग सेटिंग",
  CASINO: "कैसीनो",
  ENTER_BALANCE: "शेष राशि दर्ज करें",
  ENTER_CREDIT_REFERENCE: "क्रेडिट संदर्भ दर्ज करें",
  ENTER_SHARES: "शेयर दर्ज करें",
  ENTER_PASSWORD: "पास वर्ड दर्ज करें",
  ENTER_MOBILE_OPTIONAL: "मोबाइल दर्ज करें (वैकल्पिक)",
  ENTER_EMAIL_OPTIONAL: "ईमेल दर्ज करें (वैकल्पिक)",
  ENTER_USERNAME: "उपयोगकर्ता नाम दर्ज करें",
  ENTER_NAME: "नाम दर्ज करें",
  FROM: "से",
  NO_LEAGUE_AVAILABLE: "कृपया खेल का चयन करें...",
  SELECT_SPORT_AND_LEAGUE: "कृपया खेल और लीग का चयन करें...",
  SELECT_SPORT_AND_CURRENCY: "कृपया खेल और मुद्रा का चयन करें...",
  MATCH_SETTING: "मिलान सेटिंग",
  LOGIN_HISTORY: "लॉगिन इतिहास",
  NO_LOGIN_HISTORY_AVAILABLE: "कोई लॉगिन इतिहास उपलब्ध नहीं है..",
  "RESTORE-PANEL": "पैनल पुनर्स्थापित करें",
  RESTORE_PANEL: "पैनल पुनर्स्थापित करें",
  ACCOUNT: "खाता",
  REPORTS: "रिपोर्टों",
  REPORTS_OF: "रिपोर्टों",
  COMMISSION_REPORTS: "आयोग रिपोर्ट",
  MY_BALANCE: "मेरा संतुलन",
  BET_ALLOW: "शर्त की अनुमति दें",
  SELECT_RESULT: "परिणाम चुनें",
  RESET: "रीसेट",
  SPORT_SETTING: "खेल सेटिंग",
  NEW_PASSWORD: "नया पासवर्ड",
  TEEN_PATTI: "तीन पत्ती",
  GAME: "खेल",
  LIVE_CASINO: "लाइव कैसीनो",
  SPORTS: "खेल",
  ACTIVE: "सक्रिय",
  DEBIT: "खर्चे में लिखना",
  REMARK: "टिप्पणी",
  PROFIT_LOSS: "लाभ/नुकसान",
  ACTUAL_PL: "वास्तविक P/L",
  TO: "को",
  SELECT_CURRENCY: "मुद्रा चुनें",
  RUNNING_MARKET_ANALYSIS: "बाजार विश्लेषण",
  CHIP_VALUE: "चिप मूल्य",
  DEPOSIT_OF: "का जमा",
  DIRECTOR: "निदेशक",
  PERMISSIONS_OF: "की अनुमतियाँ",
  ENTER_CHIPS: "चिप्स दर्ज करें",
  WITHDRAWAL_OF: "वापस लेना",
  CHANGE_PASSWORD_OF: "का पासवर्ड बदलें",
  CHANGE_PASSWORD: "पासवर्ड बदलें",
  FANCY: "कल्पना",
  ACTIVE_BETS: "सक्रिय दांव",
  MARKET_TYPE: "बाज़ार का प्रकार",
  BOOKMAKER: "बूक मेय्केड़",
  EXCHANGE: "अदला-बदली",
  FIELD_REQUIRED: "आवश्यक क्षेत्र",
  ROLE: "भूमिका",
  SHARES: "शेयरों",
  BLOCK_AND_LOGOUT: "ब्लॉक करें और लॉगआउट करें",
  LAST_LOGGED_IN_TIME: "अंतिम लॉग इन समय",
  SEARCH: "खोज",
  ENTER_PASSWORD: "पास वर्ड दर्ज करें",
  SELECT_DOT: "चुनना...",
  STAKE: "दांव लगाना",
  SELECTION: "चयन",
  ODD_SIZE: "भाव [आकार]",
  ODDS: "भाव",
  ACTIVE_CLIENTS: "सक्रिय ग्राहक",
  PARENT: "माता-पिता D/W",
  WALLET_TRANSACTIONS: "वॉलेट लेनदेन",
  BROWSER_IP_ADDRESS: "ब्राउज़र आईपी पता",
  SYSTEM_IP_ADDRESS: "सिस्टम आईपी पता",
  USERNAME: "उपयोगकर्ता नाम",
  USER: "उपयोगकर्ता",
  MANAGE: "प्रबंधित करना",
  EVENT_MANAGEMENT: "इवेंट मैनेजमेंट",
  NO_DATA_AVAILABLE: "कोई डेटा मौजूद नहीं...",
  BANKING: "बैंकिंग",
  OF: "का",
  ACTIVE_CLIENT: "सक्रिय ग्राहक",
  ALL: "सभी",
  CHILD: "बच्चा D/W",
  SETTLEMENT_HISTORY: "निपटान इतिहास",
  SETTLEMENT_OF: "निपटान of",
  MARKET_PROFIT_AND_LOSS: "बाजार लाभ और हानि",
  LIVE_EXPOSURE: "लाइव अनुभव",
  TOTAL_BALANCE: "कुल शेष",
  CLIENT_BALANCE: "ग्राहक शेष",
  TOTAL_CLIENTS: "कुल ग्राहक",
  TOTAL_PL: "कुल लाभ/हानि P/L",
  CREDIT_REFERENCE: "क्रेडिट संदर्भ",
  WELCOME_TO_CUBA: "मनी एक्सचेंज एडमिन पैनल में आपका स्वागत है",
  WELCOME_MESSAGE: "यहां जानिए आज आपके खाते में क्या हो रहा है",
  INBOX: "खाता",
  TASKBOARD: "टास्कबोर्ड",
  GENERAL: "आम",
  All: "सभी",
  SUBMIT: "जमा करना",
  DASHBOARD: "डैशबोर्ड",
  USERS: "उपयोगकर्ताओं",
  INACTIVE_USERS: "निष्क्रिय उपयोगकर्ता",
  "ADD-USER": "उपयोगकर्ता जोड़ें",
  ADD_CURRENCY: "मुद्रा जोड़ें",
  ADD_USER: "उपयोगकर्ता जोड़ें",
  CURRENCIES: "मुद्राओं",
  "ADD-CURRENCY": "मुद्रा जोड़ें",
  ADD: "जोड़ना",
  ACTION: "कार्रवाई",
  PASSWORD: "पासवर्ड",
  DESCRIPTION: "विवरण",
  VALUE: "कीमत",
  EXPOSURE: "खुलासा",
  CREDIT: "श्रेय",
  SETTLEMENT: "समझौता",
  CURRENCY: "मुद्रा",
  ROW_COUNT: "पंक्ति गिनती",
  CREATE: "बनाएं",
  USER_LIST: "उपयोगकर्ता सूची",
  "MANAGE-EVENTS": "इवेंट प्रबंधित करें",
  CODE: "कोड",
  CANCEL: "रद्द करना",
  DECLARED_BY: "द्वारा घोषित",
  DATE: "तारीख",
  RESULTS: "परिणाम",
  RESULT: "परिणाम",
  ALLOW: "अनुमति देना",
  BLOCK: "अवरोध पैदा करना",
  CLEAR: "साफ़",
  RUNNING_MARKETS: "चल रहे बाज़ार",
  STAKE_SIZE: "हिस्सेदारी का आकार",
  AMOUNT: "Amount",
  OLD_PL: "Old P/L",
  NEW_PL: "New P/L",
  SELECT_SPORTS: "खेल चुनें",
  MIN_STAKE: "न्यूनतम हिस्सेदारी",
  MAX_STAKE: "अधिकतम हिस्सेदारी",
  MIN_STAKE: "न्यूनतम हिस्सेदारी",
  MAX_STAKE: "अधिकतम हिस्सेदारी",
  MIN_STAKE: "न्यूनतम हिस्सेदारी",
  MAX_STAKE: "अधिकतम हिस्सेदारी",
  BET_DELAY: "दांव में देरी",
  BET_DELAY_EXCH: "दांव में देरी(EXCH)",
  BET_DELAY_FANCY: "दांव में देरी(FANCY)",
  BET_DELAY_BOOKMARK: "दांव में देरी(BOOKMARK)",
  PROFIT_LIMIT: "लाभ सीमा",
  LIMIT: "रोक",
  SETTINGS: "सेटिंग",
  SELECT_MARKET: "बाज़ार का चयन करें",
  "MATCH-SETTING": "मैच सेटिंग",
  BETS: "दांव",
  PERSONAL_DETAILS: "व्यक्तिगत विवरण",
  EMAIL: "ईमेल",
  TYPE: "प्रकार",
  MOBILE_NO: "मोबाइल नंबर",
  SPORTS_SHARES: "खेल शेयर",
  CASINO_SHARES: "कैसीनो शेयर",
  USER_NAME: "उपयोगकर्ता नाम",
  USER_NAME_NAME: "اسم المستخدم (الاسم)उपयोक्तानाम (नाम)",
  RESULTS_TRANSACTION: "परिणाम लेनदेन",
  CREATE_ACCOUNT: "क्रिएट_अकाउंट: 'खाता बनाएं'",
  CURRENCY_NAME: "मुद्रा का नाम",
  SELECT_EVENT: "ईवेंट चुनें",
  BALANCE: "संतुलन",
  EVENT: "आयोजन",
  NAME: "नाम",
  CURRENCY_CODE: "मुद्रा कोड",
  CURRENCY_VALUE: "मुद्रा मूल्य",
  CURRENCY_LIST: "मुद्रा सूची",
  CREATED_AT: "पर बनाया गया",
  UPDATED_AT: "पर अद्यतन किया गया",
  SELECT_SPORT: "खेल चुनें",
  SPORT: "खेल",
  SELECT_LEAGUE: "लीग चुनें",
  LEAGUE: "संघ",
  MARKET: "बाज़ार",
  MY_MARKET: "मेरा बाज़ार",
  BOOK_VIEW: "पुस्तक दृश्य",
  "DELETE_BET_?": "शर्त हटाएँ?",
  SHARED_BOOKS: "साझा पुस्तकें",
  USER_PARENT: "उपयोगकर्ता [अभिभावक]",
  CHANGE_MY_PASSWORD: "पासवर्ड बदलें",
  CURRENT_PASSWORD: "वर्तमान पासवर्ड",
  CONFIRM_PASSWORD: "पासवर्ड की पुष्टि कीजिये",
  INACTIVE_USER_LIST: "निष्क्रिय उपयोक्ता सूची",
  DELETED_BETS: "हटाए गए दांव",
  DELETED_BETS_REPORTS: "हटाई गई बेट्स रिपोर्ट",
  HOME_SLIDER_LIST: "होम स्लाइडर सूची",
  ADD_HOME_SLIDER: "होम स्लाइडर जोड़ें",
  "HOME-SLIDER": "होम स्लाइडर",
  HOME_SLIDERS_LIST: "होम स्लाइडर सूची",
  "ADD-SLIDER": "स्लाइडर जोड़ें",
  ADD_SLIDER: "स्लाइडर जोड़ें",
  ORDER: "आदेश",
  BANNER: "बैनर",
  STATUS: "स्थिति",
  DESKTOP: "डेस्कटॉप",
  MOBILE: "गतिमान",
  IMAGE: "छवि",
  MANAGERS: "प्रबंधकों",
  ADD_MANAGER: "प्रबंधक जोड़ें",
  ACCOUNTS_MANAGERS: "लेखा प्रबंधक",
  OPERATIONAL_MANAGERS: "परिचालन प्रबंधक",
  MONITORING_MANAGERS: "निगरानी प्रबंधक",
  MANAGERS_LIST: "प्रबंधकों की सूची ",
  CREATED_BY: "बनाएं द्वारा",
  COMMISSION: "आयोग",
  COMMISSION_PERCENTAGE:"कमीशन प्रतिशत",
  ENTER_COMMISSION:"कमीशन दर्ज करें",
  CLIENTSHARE: "ग्राहक साझा",
  ADD_SSO_ID: "आईडी बदलें",
};

export const ARABIC = {
  GENERAL: "عام",
  All: "الجميع",
  CASINO: "كازينو",
  ENTER_BALANCE: "أدخل الرصيد",
  ENTER_CREDIT_REFERENCE: "أدخل مرجع الائتمان",
  ENTER_SHARES: "أدخل الأسهم",
  ENTER_PASSWORD: "أدخل كلمة المرور",
  ENTER_MOBILE_OPTIONAL: "أدخل الجوال (اختياري)",
  ENTER_EMAIL_OPTIONAL: "أدخل البريد الإلكتروني (اختياري)",
  ENTER_NAME: "أدخل الاسم",
  ENTER_USERNAME: "ادخل اسم المستخدم",
  FROM: "من",
  ACTUAL_PL: "فِعلي P/L",
  NO_LEAGUE_AVAILABLE: "الرجاء اختيار الرياضة...",
  SELECT_SPORT_AND_LEAGUE: "الرجاء تحديد الرياضة والدوري...",
  SELECT_SPORT_AND_CURRENCY: "الرجاء تحديد الرياضة والعملة...",
  LEAGUE_SETTING: "إعداد الدوري",
  MATCH_SETTING: "إعداد المباراة",
  LOGIN_HISTORY: "سجل تسجيل الدخول",
  NO_LOGIN_HISTORY_AVAILABLE: "لا يوجد سجل دخول متاح...",
  DASHBOARD: "لوحة القيادة",
  SETTLEMENT: "مستعمرة",
  CANCEL: "يلغي",
  TEEN_PATTI: "في سن المراهقة باتي",
  GAME: "لعبة",
  SPORT_SETTING: "ضبط الرياضة",
  SELECT_RESULT: "حدد النتيجة",
  RESET: "إعادة ضبط",
  REMARK: "ملاحظة",
  NEW_PASSWORD: "كلمة المرور الجديدة",
  BET_ALLOW: "الرهان السماح",
  SPORTS: "رياضات",
  ACTIVE: "نشيط",
  EXPOSURE: "التعرض",
  LIVE_CASINO: "كازينو مباشر",
  RUNNING_MARKET_ANALYSIS: "إجراء تحليل السوق",
  DIRECTOR: "مخرج",
  DEBIT: "دَين",
  PROFIT_LOSS: "ربح/خسارة",
  SEARCH: "يبحث",
  TO: "ل",
  SELECT_CURRENCY: "اختر العملة",
  CHIP_VALUE: "قيمة الرقاقة",
  SETTLEMENT_HISTORY: "تاريخ التسوية",
  SETTLEMENT_OF: "تاريخ of",
  ENTER_CHIPS: "أدخل الرقائق",
  WITHDRAWAL_OF: "سحب من",
  PERMISSIONS_OF: "أذونات",
  CHANGE_PASSWORD_OF: "تغيير كلمة المرور لـ",
  CHANGE_PASSWORD: "تغيير كلمة المرور",
  FIELD_REQUIRED: "حقل مطلوب",
  SELECT_DOT: "يختار...",
  DEPOSIT_OF: "إيداع",
  MARKET_TYPE: "نوع السوق",
  ROLE: "دور",
  SHARES: "تشارك",
  PARENT: "الأبوين D/W",
  WALLET_TRANSACTIONS: "معاملات المحفظة",
  ENTER_PASSWORD: "أدخل كلمة المرور",
  USER: "مستخدم",
  ACTIVE_BETS: "الرهانات النشطة",
  STAKE: "حصة",
  ODD_SIZE: "غريب [مقاس]",
  ODDS: "غريب",
  SELECTION: "اختيار",
  ACTIVE_CLIENTS: "العملاء النشطين",
  BLOCK_AND_LOGOUT: "حظر وتسجيل الخروج",
  SYSTEM_IP_ADDRESS: "عنوان IP للنظام",
  LAST_LOGGED_IN_TIME: "وقت آخر تسجيل الدخول",
  USERNAME: "اسم المستخدم",
  OF: "ل",
  MANAGE: "يدير",
  BANKING: "الخدمات المصرفية",
  EVENT_MANAGEMENT: "أدارة الحدث",
  NO_DATA_AVAILABLE: "لا تتوافر بيانات...",
  MY_BALANCE: "رصيدي",
  ALL: "الكل",
  CHILD: "طفل D/W",
  CURRENCIES: "العملات",
  MARKET_PROFIT_AND_LOSS: "أرباح وخسائر السوق",
  CREDIT_REFERENCE: "إشارة الائتمان",
  ACTIVE_CLIENT: "العملاء النشطين",
  LIVE_EXPOSURE: "التعرض الحيّ",
  TOTAL_BALANCE: "الرصيد الإجمالي",
  CLIENT_BALANCE: "رصيد العملاء",
  TOTAL_CLIENTS: "إجمالي العملاء",
  TOTAL_PL: "إجمالي الأرباح/الخسائر",
  LOGOUT: "تسجيل خروج",
  TASKBOARD: "لوحة المهام",
  SUBMIT: "يُقدِّم",
  INBOX: "صندوق الوارد",
  ROW_COUNT: "عدد الصفوف",
  WELCOME_TO_CUBA: "مرحبًا بكم في لوحة إدارة  Betxfair",
  WELCOME_MESSAGE: "إليك ما يحدث في حسابك اليوم",
  CREATE: "يخلق",
  ALLOW: "يسمح",
  ACTION: "أجراءات",
  BLOCK: "حاجز",
  REPORTS: "التقارير",
  REPORTS_OF: "التقارير",
  COMMISSION_REPORTS: "تقرير اللجنة",
  USER_LIST: "User List",
  BALANCE: "توازن",
  WIDGETS: "الحاجيات",
  CREDIT: "ائتمان",
  PASSWORD: "كلمة المرور",
  EMAIL: "بريد إلكتروني",
  SPORTS_SHARES: "المشاركات الرياضية",
  DECLARED_BY: "أعلن بواسطة",
  SELECT_SPORTS: "اختر الرياضة",
  CLEAR: "واضح",
  CREATE_ACCOUNT: "إنشاء حساب",
  ACCOUNT: "حساب",
  CODE: "شفرة",
  STAKE_SIZE: "حجم الحصة",
  AMOUNT: "Amount",
  OLD_PL: "Old P/L",
  NEW_PL: "New P/L",
  UPDATED_AT: "تم التحديث في",
  "ADD-CURRENCY": "أضف العملة",
  MIN_STAKE: "الحد الأدنى للحصة",
  MAX_STAKE: "ماكس حصة",
  MIN_STAKE: "حصة دقيقة",
  MAX_STAKE: "ماكس حصة",
  MIN_STAKE: "حصة دقيقة",
  MAX_STAKE: "ماكس حصة",
  CURRENCY_LIST: "قائمة العملات",
  BET_DELAY: "تأخير الرهان",
  BET_DELAY_EXCH: "تأخير الرهان(EXCH)",
  BET_DELAY_FANCY: "تأخير الرهان(FANCY)",
  BET_DELAY_BOOKMARK: "تأخير الرهان(BOOKMARK)",
  CURRENCY_CODE: "رمز العملة",
  CURRENCY_NAME: "اسم العملة",
  SELECT_EVENT: "حدد حدث",
  NAME: "اسم",
  LIMIT: "حد",
  PROFIT_LIMIT: "حد الربح",
  "RESTORE-PANEL": "استعادة اللوحة",
  RESTORE_PANEL: "استعادة اللوحة",
  SELECT_LEAGUE: "حدد الدوري",
  "MANAGE-EVENTS": "إدارة الأحداث",
  PERSONAL_DETAILS: "تفاصيل شخصية",
  RESULTS_TRANSACTION: "معاملة النتائج",
  "MATCH-SETTING": "إعداد المباراة",
  DESCRIPTION: "وصف",
  CURRENCY_VALUE: "قيمة العملة",
  VALUE: "قيمة",
  USER_NAME: "اسم المستخدم",
  USER_NAME_NAME: "صارف نام (نام)اسم المستخدم (الاسم)",
  INACTIVE_USERS: "المستخدمون غير النشطين",
  CHART: "جدول",
  SETTINGS: "جلسة",
  CREATED_AT: "أنشئت في",
  CASINO_SHARES: "كازينو سهم",
  "ADD-USER": "إضافة مستخدم",
  ADD_CURRENCY: "إضافة العملة",
  ADD_USER: "إضافة مستخدم",
  BETS: "الرهانات",
  SPORT_SETTING: "وضع الرياضة",
  RUNNING_MARKETS: "الأسواق الجارية",
  MY_MARKET: "سوقي",
  RESULTS: "نتائج",
  APPLICATIONS: "التطبيقات",
  MOBILE_NO: "Mobile no",
  SELECT_MARKET: "رقم المحمول",
  CURRENCY: "عملة",
  USERS: "المستخدمون",
  ADD: "يضيف",
  RESULT: "نتيجة",
  TYPE: "يكتب",
  FANCY: "باهِظ",
  BOOKMAKER: "المراهنات",
  EXCHANGE: "تبادل",
  SPORT: "رياضة",
  LEAGUE: "الدوري",
  EVENT: "حدث",
  MARKET: "سوق",
  DATE: "تاريخ",
  "DELETE_BET_?": "حذف الرهان؟",
  SHARED_BOOKS: "الكتب المشتركة",
  BOOK_VIEW: "عرض الكتاب",
  USER_PARENT: "المستخدم [الوالد]",
  CHANGE_MY_PASSWORD: "تغيير كلمة المرور الخاصة بي",
  CURRENT_PASSWORD: "كلمة السر الحالية",
  CONFIRM_PASSWORD: "تأكيد كلمة المرور",
  INACTIVE_USER_LIST: "غیر فعال صارف کی فہرست النشطين",
  DELETED_BETS: "الرهانات المحذوفة",
  DELETED_BETS_REPORTS: "تقارير الرهانات المحذوفة",
  HOME_SLIDER_LIST: "قائمة المنزلق الرئيسية",
  ADD_HOME_SLIDER: "إضافة المنزلق المنزل",
  "HOME-SLIDER": "الصفحة الرئيسية المنزلق",
  HOME_SLIDERS_LIST: "قائمة المتزلجون الرئيسية",
  "ADD-SLIDER": "إضافة المنزلق",
  ADD_SLIDER: "إضافة المنزلق",
  ORDER: "طلب",
  BANNER: "راية",
  STATUS: "حالة",
  DESKTOP: "سطح المكتب",
  MOBILE: "متحرك",
  IMAGE: "صورة",
  MANAGERS: "المديرين",
  ADD_MANAGER: "إضافة مدير",
  ACCOUNTS_MANAGERS: "مدراء الحسابات",
  OPERATIONAL_MANAGERS: "مدراء العمليات",
  MONITORING_MANAGERS: "مدراء المراقبة",
  MANAGERS_LIST: "قائمة المديرين ",
  CREATED_BY: "انشأ من قبل",
  COMMISSION: "عمولة",
  COMMISSION_PERCENTAGE:"نسبة العمولة",
  ENTER_COMMISSION:"أدخل العمولة",
  CLIENTSHARE: "حصة العميل",
  ADD_SSO_ID: "تغيير معرف",
};

export const URDU = {
  GENERAL: "عمومی",
  All: "تمام",
  CASINO: "کیسینو",
  ENTER_BALANCE: "بیلنس درج کریں۔",
  ENTER_CREDIT_REFERENCE: "کریڈٹ حوالہ درج کریں۔",
  ENTER_SHARES: "شیئرز درج کریں۔",
  ENTER_PASSWORD: "پاس ورڈ درج کریں",
  ENTER_EMAIL_OPTIONAL: "ای میل درج کریں (اختیاری)",
  ENTER_MOBILE_OPTIONAL: "موبائل درج کریں (اختیاری)",
  ENTER_USERNAME: "صارف نام درج کریں۔",
  ENTER_NAME: "نام درج کریں",
  FROM: "سے",
  ACTUAL_PL: "اصل P/L",
  NO_LEAGUE_AVAILABLE: "براہ کرم کھیل کو منتخب کریں۔...",
  SELECT_SPORT_AND_LEAGUE: "براہ کرم کھیل اور لیگ کا انتخاب کریں۔...",
  SELECT_SPORT_AND_CURRENCY: "براہ کرم کھیل اور کرنسی کا انتخاب کریں۔y...",
  NO_LOGIN_HISTORY_AVAILABLE: "کھیل کے لیے کوئی لیگ دستیاب نہیں ہے۔...",
  LEAGUE_SETTING: "لیگ کی ترتیب",
  MATCH_SETTING: "میچ کی ترتیب",
  LOGIN_HISTORY: "لاگ ان کی تاریخ",
  NO_LOGIN_HISTORY_AVAILABLE: "لاگ ان کی کوئی تاریخ دستیاب نہیں ہے....",
  DASHBOARD: "ڈیش بورڈ",
  SETTLEMENT: "سیٹلمنٹ",
  NEW_PASSWORD: "نیا پاسورڈ",
  ENTER_CHIPS: "چپس درج کریں",
  BET_ALLOW: "شرط لگانے کی اجازت",
  GAME: "کھیل",
  SELECT_RESULT: "نتیجہ منتخب کریں۔",
  SPORT_SETTING: "کھیلوں کی ترتیب",
  RESET: "دوبارہ ترتیب دیں۔",
  CHIP_VALUE: "چپ قیمت",
  SPORTS: "کھیل",
  LIVE_CASINO: "لائیو کیسینو",
  TEEN_PATTI: "ٹین پٹی",
  REMARK: "تبصرہ",
  ACTIVE: "فعال",
  DIRECTOR: "ڈائریکٹر",
  RUNNING_MARKET_ANALYSIS: "مارکیٹ تجزیہ چل رہا ہے",
  ACTIVE_CLIENTS: "فعال صارفین",
  ENTER_PASSWORD: "پاسورڈ درج کریں",
  PERMISSIONS_OF: "کی اجازتیں",
  SETTLEMENT_HISTORY: "سیٹلمنٹ تاریخچہ",
  SETTLEMENT_OF: "سیٹلمنٹ of",
  FIELD_REQUIRED: "فیلڈ کی ضرورت ہے",
  CHANGE_PASSWORD_OF: "کا پاسورڈ تبدیل کریں",
  CHANGE_PASSWORD: "پاس ورڈ تبدیل کریں",
  SEARCH: "تلاش کریں",
  SELECT_DOT: "منتخب کریں...",
  MARKET_TYPE: "مارکیٹ کی قسم",
  BLOCK_AND_LOGOUT: "بلاک اور لاگ آؤٹ",
  LAST_LOGGED_IN_TIME: "آخری لاگ ان کی وقت",
  EVENT_MANAGEMENT: "واقعہ کا انتظام",
  MANAGE: "انتظام کریں",
  ACTIVE_BETS: "فعال شرطیں",
  NO_DATA_AVAILABLE: "کوئی ڈیٹا دستیاب نہیں...",
  CANCEL: "منسوخ کریں",
  BANKING: "بینکنگ",
  ACTIVE_CLIENT: "فعال صارف",
  LIVE_EXPOSURE: "لائیو انکشاف",
  TOTAL_BALANCE: "کل بیلنس",
  TOTAL_CLIENTS: "کل صارفین",
  CLIENT_BALANCE: "صارفین کا بیلنس",
  TOTAL_PL: "کل منافع / نقصان",
  EXPOSURE: "انکشاف",
  ODD_SIZE: "اوڈ سائز",
  ODDS: "مشکلات",
  CREDIT_REFERENCE: "کریڈٹ حوالہ",
  MY_BALANCE: "میرا بیلنس",
  LOGOUT: "لاگ آؤٹ",
  SUBMIT: "جمع کرائیں",
  ROW_COUNT: "صف حساب",
  CREATE: "تشکیل دیں",
  ALLOW: "اجازت دیں",
  ALL: "سبھی",
  CHILD: "بچہ D/W",
  PARENT: "ماں باپ D/W",
  WALLET_TRANSACTIONS: "بٹوے کے لین دین",
  USERNAME: "صارف نام",
  MARKET_PROFIT_AND_LOSS: "مارکیٹ مفاد و نقصان",
  INBOX: "ان باکس",
  WELCOME_TO_CUBA: "ایکس ایکس ایکسچینج ایڈمن پینل میں خوش آمدید",
  WELCOME_MESSAGE: "آپ کے اکاؤنٹ میں آج کے واقعات کیا ہورہا ہے، یہاں دیکھیے",
  ACTION: "اقدامات",
  BROWSER_IP_ADDRESS: "براؤزر کا آئی پی ایڈریس",
  SYSTEM_IP_ADDRESS: "سسٹم آئی پی ایڈریس",
  ACCOUNT: "اکاؤنٹ",
  BLOCK: "بلاک کریں",
  REPORTS: "رپورٹیں",
  REPORTS_OF: "رپورٹیں",
  COMMISSION_REPORTS: "کمیشن کی رپورٹ",
  SELECTION: "انتخاب",
  OF: "کا",
  USER_LIST: "صارف لسٹ",
  DEPOSIT_OF: "جمع کرائیں",
  WITHDRAWAL_OF: "واپسی",
  BALANCE: "بیلنس",
  WIDGETS: "ویجٹس",
  CREDIT: "کریڈٹ",
  DEBIT: "ڈیبٹ",
  PASSWORD: "پاسورڈ",
  EMAIL: "ای میل",
  SHARES: "شیئرز",
  SPORTS_SHARES: "کھیل شیئرز",
  DECLARED_BY: "کے ذریعہ اعلان کیا گیا",
  SELECT_SPORTS: "کھیل منتخب کریں",
  CLEAR: "صاف کریں",
  CREATE_ACCOUNT: "اکاؤنٹ بنائیں",
  CODE: "کوڈ",
  STAKE_SIZE: "شرط سائز",
  AMOUNT: "Amount",
  OLD_PL: "Old P/L",
  NEW_PL: "New P/L",
  STAKE: "شرط",
  UPDATED_AT: "اپ ڈیٹ کیا گیا",
  ADD_CURRENCY: "کرنسی شامل کریں",
  MIN_STAKE: "کم سے کم شرط ",
  MAX_STAKE: "زیادہ سے زیادہ شرط ",
  CURRENCY_LIST: "کرنسی کی فہرست",
  BET_DELAY: "شرط میں تاخیر",
  BET_DELAY_EXCH: "شرط میں تاخیر (EXCH)",
  BET_DELAY_FANCY: "شرط میں تاخیر (FANCY)",
  BET_DELAY_BOOKMARK: "شرط میں تاخیر (BOOKMARK)",
  CURRENCY_CODE: "کرنسی کوڈ",
  CURRENCY_NAME: "کرنسی کا نام",
  SELECT_EVENT: "انتخاب واقعہ",
  NAME: "نام",
  LIMIT: "حد",
  ROLE: "کردار",
  PROFIT_LIMIT: "منافع کی حد",
  "RESTORE-PANEL": "پینل بحال کریں",
  RESTORE_PANEL: "پینل بحال کریں",
  SELECT_LEAGUE: "لیگ منتخب کریں",
  "MANAGE-EVENTS": "واقعات کا انتظام کریں",
  EVENT: "واقعہ",
  PERSONAL_DETAILS: "ذاتی تفصیلات",
  RESULTS_TRANSACTION: "لین دین کے نتائج",
  "MATCH-SETTING": "میچ کی ترتیبات",
  DESCRIPTION: "تفصیل",
  CURRENCY_VALUE: "کرنسی کی قیمت",
  VALUE: "قیمت",
  USER_NAME: "صارف نام",
  USER_NAME_NAME: "صارف نام (نام)",
  CHART: "چارٹ",
  SETTINGS: "ترتیبات",
  TASKBOARD: "ٹاسک بورڈ",
  CREATED_AT: "تخلیق شدہ",
  CASINO_SHARES: "کیسینو کے حصص",
  ADD_USER: "صارف شامل کریں",
  SELECT_CURRENCY: "کرنسی کا انتخاب کریں",
  BETS: "شرطیں",
  SPORT_SETTING: "کھیل کی ترتیبات",
  PROFIT_LOSS: "منافع/نقصان",
  RUNNING_MARKETS: "چل رہے مارکیٹس",
  CURRENCIES: "کرنسیاں",
  MY_MARKET: "میرا مارکیٹ",
  RESULTS: "نتائج",
  APPLICATIONS: "ایپلیکیشنز",
  MOBILE_NO: "موبائل نمبر",
  SELECT_MARKET: "مارکیٹ منتخب کریں",
  CURRENCY: "کرنسی",
  USERS: "صارفین",
  INACTIVE_USERS: "غیر فعال صارفین",
  USER: "صارف",
  ADD: "شامل کریں",
  RESULT: "نتیجہ",
  TYPE: "قسم",
  FANCY: "خواہش",
  TO: "سے",
  BOOKMAKER: "بک میکر",
  EXCHANGE: "ایکسچینج",
  SPORT: "کھیل",
  LEAGUE: "لیگ",
  MARKET: "مارکیٹ",
  DATE: "تاریخ",
  "DELETE_BET_?": "شرط کو حذف کریں؟",
  SHARED_BOOKS: "مشترکہ کتابیں",
  BOOK_VIEW: "بک ویو",
  USER_PARENT: "صارف [والدین]",
  CHANGE_MY_PASSWORD: "میرا پاس ورڈ تبدیل کریں۔",
  CURRENT_PASSWORD: "موجودہ خفیہ لفظ",
  CONFIRM_PASSWORD: "پاس ورڈ کی تصدیق کریں۔",
  INACTIVE_USER_LIST: "غیر فعال صارف کی فہرست",
  DELETED_BETS: "حذف شدہ شرطیں",
  DELETED_BETS_REPORTS: "بیٹس رپورٹس کو حذف کر دیا گیا۔",
  HOME_SLIDER_LIST: "ہوم سلائیڈر کی فہرست",
  ADD_HOME_SLIDER: "ہوم سلائیڈر شامل کریں۔",
  "HOME-SLIDER": "ہوم سلائیڈر",
  HOME_SLIDERS_LIST: "ہوم سلائیڈرز کی فہرست",
  "ADD-SLIDER": "سلائیڈر شامل کریں۔",
  ADD_SLIDER: "سلائیڈر شامل کریں۔",
  ORDER: "ترتیب",
  BANNER: "بینر",
  STATUS: "حالت",
  DESKTOP: "ڈیسک ٹاپ",
  MOBILE: "موبائل",
  IMAGE: "تصویر",
  MANAGERS: "مینیجرز",
  ADD_MANAGER: "مینیجر شامل کریں۔",
  ACCOUNTS_MANAGERS: "اکاؤنٹس مینیجرز",
  OPERATIONAL_MANAGERS: "آپریشنل مینیجرز",
  MONITORING_MANAGERS: "مانیٹرنگ مینیجرز",
  MANAGERS_LIST: "مینیجرز کی فہرست ",
  CREATED_BY: "بنائی گئی",
  COMMISSION: "کمیشن",
  COMMISSION_PERCENTAGE:"کمیشن فیصد",
  ENTER_COMMISSION:"کمیشن میں داخل ہوں۔",
  CLIENTSHARE: "کلائنٹ کا حصہ",
  ADD_SSO_ID: "آئی ڈی تبدیل کریں۔",
};
