import React from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { FiLogOut } from "react-icons/fi";
import { AiFillLock } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { MdDelete } from "react-icons/md";
import PaginationButtons from "../../../Common/Component/pagination/PaginationButtons";
import { useSelector } from "react-redux";
import { layerRoles, ManagerRoles } from "../../../../Constant";

const TotalClientModal = (props) => {
  const { t } = useTranslation();

  const dashboardData = useSelector(
    (state) =>
      state?.getDashboardDataDetailsReducer?.dashboardDetails?.data?.allClient
  );

  return (
    <Modal
      className="modal-lg"
      show={props.isOpen}
      onHide={props.toggler}
      centered
      size={props.size}
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${t("All Clients")}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={props.bodyClass} style={{ padding: "10px" }}>
        <div className="overflow-auto" style={{ height: "70vh" }}>
          <table className="table table-bordered table-hover">
            <thead className="table-light">
              <tr>
                <th scope="col" className="text-uppercase text-nowrap">
                  {t("SL")}
                </th>
                <th scope="col" className="text-uppercase text-nowrap">
                  {t("CREATED AT")}
                </th>
                <th scope="col" className="text-uppercase text-nowrap">
                  {t("USERNAME")}
                </th>
                <th scope="col" className="text-uppercase text-nowrap">
                  {t("Created By")}
                </th>
              </tr>
            </thead>
            <tbody>
              {dashboardData?.map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td className="fw-semibold text-dark">{index + 1}</td>
                      <td className="fw-semibold text-dark">
                        {new Date(data?.createdAt)?.toLocaleString()}
                      </td>
                      <td className="fw-semibold text-dark">
                        {data?.userName}
                      </td>
                      <td className="fw-semibold text-dark">
                        {data?.parent?.username}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TotalClientModal;
