import { all, call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  notifyDanger,
  notifySuccess,
} from "../../../utils/helper";
import STX_DATA_API from "../../../utils/STXDataApi";
import { STX_ACCOUNT_DETAILS } from "../../action/types";
import { getSTXAccountDetailsFailure, getSTXAccountDetailsSuccess } from "../../action";

function* getSTXAccountDetailsSaga(action) {
  try {
    const { data } = yield STX_DATA_API.post("v1/myAccountDetails",action?.payload);
    if (data?.meta?.code === 200) {
      yield put(getSTXAccountDetailsSuccess(data));
      yield call(action.payload.callback, data);  
    }
    else if (data?.meta?.code !== 200) {
      yield put(getSTXAccountDetailsFailure());
      notifyDanger(data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  } catch (error) {
    yield put(getSTXAccountDetailsFailure());
    notifyDanger(error?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  }
}

export function* watchStxDataAPI() {
  yield takeEvery(STX_ACCOUNT_DETAILS, getSTXAccountDetailsSaga);
}

export default function* rootSaga() {
  yield all([watchStxDataAPI()]);
}