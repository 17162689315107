import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  // const login = useState(JSON.parse(localStorage.getItem('login')))[0];
  // const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");

  return jwt_token ? (
    <Outlet />
  ) : (
    <Navigate exact to={`/login`} replace={true} />
  );
};

export default PrivateRoute
