import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { Breadcrumbs, H3 } from "../../../../AbstractElements";
import usePageTitle from "../../../../Hooks/usePageTitle";
import { addWhiteLabel, getCurrency } from "../../../../redux/action";
import Loader from "../../../../Layout/Loader";
import { notifyWarning } from "../../../../utils/helper";
import { useSelector } from "react-redux";

const AddWhiteLabel = () => {
  const title = usePageTitle();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const userData = useSelector((state) => state.Login);

  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imagePreviewDark, setImagePreviewDark] = useState(null);
  const [imagePreviewLight, setImagePreviewLight] = useState(null);

  useEffect(() => {
    dispatch(
      getCurrency({
        callback: (data) => {
          const newData = data.map(({ _id, name }) => ({
            label: name,
            value: _id,
          }));
          setCurrencies((prevArray) => [...prevArray, ...newData]);
        },
      })
    );
  }, []);

  const handleClear = () => {
    reset();
    setSelectedCurrency(""); // Reset selected currency
    setImagePreviewDark(null);
    setImagePreviewLight(null);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (e.target.name === "logo_light") {
            setImagePreviewLight(reader.result);
          } else {
            setImagePreviewDark(reader.result);
          }
        };

        reader.readAsDataURL(file);
      } else {
        notifyWarning("Selected file type not allowed.");
        handleClear();
      }
    }
  };

  const onSubmit = (data) => {
    setIsSubmit(true);
    setLoading(true);
    if (data) {
      data.logo_dark = imagePreviewDark;
      data.logo_light = imagePreviewLight;
      dispatch(
        addWhiteLabel({
          data,
          callback: (data) => {
            if (data?.meta?.code === 200) {
              handleClear();
              setIsSubmit(false);
              setLoading(false);
            } else {
              setIsSubmit(false);
              setLoading(false);
            }
          },
        })
      );
    }
  };

  const validateBetFairShares = (value) => {
    const sharesValue = watch("sportShares");
    if (value === undefined && value === "") {
      return true;
    } else if (value === "") {
      return true;
    } else {
      if (sharesValue !== "") {
        const greater = parseFloat(value) > 100 - sharesValue;
        if (greater) {
          return `Betfair share cannot be greater than  ${100 - sharesValue}!`;
        } else {
          return true;
        }
      } else {
        if (parseFloat(userData?.userData?.sportShares) < parseFloat(value)) {
          return `Betfair share cannot be greater than total share ${userData?.userData?.sportShares}!`;
        } else {
          return true;
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    // Allow only numeric keys (0-9), Backspace, Delete, and Arrow keys
    if (
      !(
        (e.key >= "0" && e.key <= "9") ||
        e.key === "Backspace" ||
        e.key === "Delete" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight" ||
        e.key === "Tab"
      )
    ) {
      e.preventDefault();
    }
  };

  const validateShares = (value) => {
    if (userData?.userData?.role === 1) {
      if (parseFloat(value) > 0 && parseFloat(value) < 100) {
        return true;
      } else {
        return "Shares must be less than 100.";
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={t("ADD_WHITELABEL")}
        title={title?.title}
        parent={title?.parent}
      />

      <Container fluid={false}>
        {loading && (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        )}
        <Row>
          <Col sm="12" className="px-3">
            <Card className="px-2">
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate=""
                  id="create"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {/* Started Personal Details  */}
                  <H3 attrH3={{ className: "mb-4 fs-5" }}>{t("DETAILS")}</H3>
                  <Row className="d-flex flex-col flex-wrap ">
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("USERID")}
                      </Label>
                      <Controller
                        name="userId"
                        control={control}
                        rules={{
                          required: "This field is required.",
                          //   validate: validateName,
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_USERId")}
                            className="form-control"
                            value={field.value || ""}
                            type="text"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.userId && errors.userId.message}
                      </span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("USERNAME")}
                      </Label>
                      <Controller
                        name="userName"
                        control={control}
                        rules={{
                          required: "This field is required.",
                          //   validate: validateName,
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_USERNAME")}
                            className="form-control"
                            value={field.value || ""}
                            type="text"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.userName && errors.userName.message}
                      </span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("CURRENCY")}
                      </Label>
                      <Controller
                        name="currency"
                        control={control}
                        rules={{ required: "This field is required." }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={currencies}
                            className="mySelect"
                            placeholder={t("SELECT_DOT")}
                            value={selectedCurrency}
                            onChange={(option) => {
                              setSelectedCurrency(option);
                              field.onChange(option?.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.currency && errors.currency.message}
                      </span>
                    </Col>
                  </Row>
                  <Row className="d-flex flex-col flex-wrap ">
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("PHONE")}
                      </Label>
                      <Controller
                        name="phone"
                        control={control}
                        rules={{
                          required: "This field is required.",
                          //   validate: validateName,
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_PHONE")}
                            className="form-control"
                            value={field.value || ""}
                            type="text"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.phone && errors.phone.message}
                      </span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("SHARING")}
                      </Label>
                      <Controller
                        name="sharing"
                        control={control}
                        rules={{
                          required: "This field is required.",
                          validate: validateShares,
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_SHARING")}
                            className="form-control"
                            value={field.value || ""}
                            type="number"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.sharing && errors.sharing.message}
                      </span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("BETFAIR_SHARES")}
                      </Label>
                      <Controller
                        name="betFairShare"
                        control={control}
                        rules={{
                          required: "This field is required.",
                          validate: validateBetFairShares,
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_SHARING")}
                            className="form-control"
                            value={field.value || ""}
                            onKeyDown={handleKeyDown}
                            type="number"
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              field.onChange(numericValue);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.betFairShare && errors.betFairShare.message}
                      </span>
                    </Col>
                  </Row>
                  <Row className="d-flex flex-col flex-wrap ">
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("PASSWORD")}
                      </Label>
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: "This field is required.",
                          //   validate: validateName,
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_PASSWORD")}
                            className="form-control"
                            value={field.value || ""}
                            type="text"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.password && errors.password.message}
                      </span>
                    </Col>
                  </Row>

                  <H3 attrH3={{ className: "mb-4 fs-5" }}>{t("DOMAIN")}</H3>
                  <Row className="d-flex flex-col flex-wrap ">
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("DOMAIN_URL")}
                      </Label>
                      <Controller
                        name="domain"
                        control={control}
                        rules={{
                          required: "This field is required.",
                          //   validate: validateName,
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_DOMAIN_URL")}
                            className="form-control"
                            value={field.value || ""}
                            type="text"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.domain && errors.domain.message}
                      </span>
                    </Col>
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("ADMIN_DOMAIN_URL")}
                      </Label>
                      <Controller
                        name="admin_domain"
                        control={control}
                        rules={{
                          required: "This field is required.",
                          //   validate: validateName,
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_ADMIN_DOMAIN_URL")}
                            className="form-control"
                            value={field.value || ""}
                            type="text"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.admin_domain && errors.admin_domain.message}
                      </span>
                    </Col>
                  </Row>
                  <H3 attrH3={{ className: "mb-4 fs-5" }}>{t("LOGO")}</H3>

                  <Row className="d-flex flex-col flex-wrap ">
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("LIGHT_MODE")}
                      </Label>
                      <Controller
                        name="logo_light"
                        control={control}
                        rules={{
                          required: "This field is required.",
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_NAME")}
                            className="form-control"
                            value={field.value || ""}
                            type="file"
                            onChange={(e) => {
                              handleImageChange(e);
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.name && errors.name.message}
                      </span>
                    </Col>
                    <Col>
                      {imagePreviewLight && (
                        <img
                          src={imagePreviewLight}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            height: "60%",
                            width: "70%",
                            border: "10px solid #ccc", // Add this line for a gray border
                            borderRadius: "5px", // Optional: Add border radius
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row className="d-flex flex-col flex-wrap ">
                    <Col md="4 mb-3">
                      <Label
                        className="col-form-label py-0"
                        htmlFor="validationCustom00"
                      >
                        {t("DARK_MODE")}
                      </Label>
                      <Controller
                        name="logo_dark"
                        control={control}
                        rules={{
                          required: "This field is required.",
                        }}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder={t("ENTER_NAME")}
                            className="form-control"
                            value={field.value || ""}
                            type="file"
                            onChange={(e) => {
                              handleImageChange(e);
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.name && errors.name.message}
                      </span>
                    </Col>
                    <Col>
                      {imagePreviewDark && (
                        <img
                          src={imagePreviewDark}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            height: "60%",
                            width: "70%",
                            border: "10px solid #ccc", // Add this line for a gray border
                            borderRadius: "5px", // Optional: Add border radius
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter className="py-3 text-center text-md-start">
                <button
                  form="create"
                  type="submit"
                  className="btn btn-success "
                  color="success"
                  disabled={isSubmit}
                >
                  {t("ADD")}
                </button>
                <button
                  className="btn ms-2"
                  style={{ backgroundColor: "#CCC" }}
                  onClick={handleClear}
                >
                  {t("RESET")}
                </button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddWhiteLabel;
