import React, { Fragment, useEffect, useState, memo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";
import { Card, CardHeader, Table } from "reactstrap";
import { BetStatus, ManagerRoles, PAGINATION } from "../../../../Constant";
import PaginationButtons from "../../../Common/Component/pagination/PaginationButtons";
import PaginationRow from "../../../Common/Component/pagination/PaginationRow";
import MarketsModal from "../modal/MarketsModal";
import { socket } from "../../../../context/socketContext";
import {
  getBetsOfRunningMarket
} from "../../../../redux/action";
import { default as VoidInvalidModal } from "../modal/VoidInvalidModal";
import {
  convertINRToCurrency,
  findAdminParent,
  finduserParent,
  getLocalStorageItem,
} from "../../../../utils/helper";
import { setContext } from "redux-saga/effects";

const BetsTable = memo(
  ({
    market,
    bets,
    rowCount,
    totalPages,
    currentPage,
    setCurrentPage,
    setRowCount,
    pagination,
    setActionTable,
    setBetIds,
    currency,
    eventId,
    viewDeleted,
    socketData,
    matchType,
    marketType,
    line,
    betFairBooks,
    selectReport
  }) => {
    const { t } = useTranslation();
    const onScrollRef = useRef();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [role, setRole] = useState(1);
    const [userBet, setUserBet] = useState([]);
    const [voidInvalidModal, setvoidInvalidModal] = useState(false);
    const [modalData, setModaldata] = useState({});
    const [marketBets, setMarketBets] = useState([]);
    const [typeOfBetDelete, setTypeofBetDelete] = useState("");
    const [profit, setProfit] = useState(0);
    const [lineProfit, setProfitLine] = useState(0);
    const [fancyProfit, setFancyProfit] = useState(0);
    const [bookMakerProfit, setBookMakerProfit] = useState(0);
    const [currPage, setCurrPage] = useState(PAGINATION?.currentPage);
    const [limit, setLimit] = useState(PAGINATION?.perPage);
    const [hasMore, setHasMore] = useState(true);
    const [prevPage, setPrevPage] = useState(0);
    const [totalDataCount, setTotalDataCount] = useState(1);
    const [scrollControl, setScrollControl] = useState(true);
    const [totalPagesMarketBets, setTotalPagesMarketBets] = useState(1);
    const adminData = useSelector((state) => state.Login.userData);
    const loading = useSelector((state) => state.GetBetsOfRunningMarket.loading);
    // console.log("marketType",marketType);
    // console.log({ loading: loading });
    // console.log("matchType", matchType);
    // console.log("market", market);
    useEffect(() => {
      let roles = JSON.parse(getLocalStorageItem("userData"));
      setRole(roles?.role);
      // const betData = bets?.filter(
      //   (bet) => bet?.status !== "4"
      // );

      // setMarketBets(betData);
    }, [market]);

    useEffect(() => {
      let isMounted = true;
      if (socketData !== undefined && socketData?.length > 0) {
        if (socketData?.data?.allBets[0]?.eventId === eventId) {
          init();
        }
      } else {
        init();
      }
      return () => {
        isMounted = false; // Cancel the operation on unmount
      };
    }, [socketData, currency, viewDeleted]);

    useEffect(() => {
      socket.on("betStatusChange", (data) => {
        if (data && data?.length > 0) {
          init();
        }
      });
    }, [socket]);

    useEffect(() => {
      init();
    }, [currPage, limit]);

    const init = () => {
      const currencyId = currency?.value?._id;
      let type = market;

      dispatch(
        getBetsOfRunningMarket({
          eventId: eventId,
          currencyId,
          deleted: viewDeleted?.value === "ACTIVE" ? false : true,
          type,
          perPage: limit,
          currentPage: currPage,
          callback: (data) => {
            if (data) {
              setScrollControl(true);
              // console.log("data,,,,,,,,,,,,,", data);
              setTotalDataCount(data?.meta?.count);
              setHasMore(data?.meta?.hasMore);
              // setPrevPage(currPage);
              // let currencyConversion = data?.data?.map((bet) => {

              //   return {
              //     ...bet,
              //     stake:
              //       currency === undefined
              //         ? bet?.stake
              //         : parseInt(
              //           convertINRToCurrency(
              //             bet?.stake,
              //             parseFloat(currency)
              //           )
              //         ),
              //     liability:
              //       currency === undefined
              //         ? bet?.liability
              //         : parseInt(
              //           convertINRToCurrency(
              //             bet?.liability,
              //             parseFloat(currency)
              //           )
              //         ),
              //   };
              // })

              setMarketBets(data?.data);
              setTotalPagesMarketBets(Math.ceil(data?.meta?.count / data?.meta?.per_page))
              // if (currPage > prevPage) {
              //   // Appending for forward pagination (scroll down)
              //   setMarketBets((prevBets) => [...prevBets, ...data?.data]);
              // } else {
              //   // Prepending for reverse pagination (scroll up)
              //   setMarketBets((prevBets) => [...data?.data, ...prevBets]);
              // }

              // setPrevPage(currPage);
            }
          },
        })
      );
    };

    const handleModal = () => {
      setOpenModal(!openModal);
      setUserBet([]);
    };

    const profitAndLoss = () => {
      let newLineProfit = 0;
      let newProfit = 0;
      let newFancyProfit = 0;
      let newBookmakerProfit = 0;
      bets?.forEach((bet) => {
        if (
          bet.marketType === market &&
          bet?.status !== "4" &&
          bet?.status !== "7"
        ) {
          if (betFairBooks) {
            if (bet?.bettingType === "LINE" && bet?.marketType === "exchange") {
              newLineProfit += (bet?.stake * bet?.betFairPercentage) / 100;
            }
            if (bet?.marketType === "exchange" && bet?.bettingType !== "LINE") {
              const max =
                (parseFloat(bet?.odds - 1) *
                  (parseFloat(bet?.stake) * bet?.betFairPercentage)) /
                100;
              const betFairPL =
                (parseFloat(bet?.stake) * bet?.betFairPercentage) / 100;
              if (bet?.selectionType == "lay") {
                max > betFairPL ? (newProfit += max) : (newProfit += betFairPL);
              } else {
                max > betFairPL ? (newProfit -= max) : (newProfit -= betFairPL);
              }

            }
          } else {
            if (bet?.bettingType === "LINE" && bet?.marketType === "exchange") {
              newLineProfit += bet?.stake;
            }
            if (bet?.marketType === "exchange" && bet?.bettingType !== "LINE") {

              const max = parseFloat(bet?.odds - 1) * parseFloat(bet?.stake);
              if (bet?.selectionType == "lay") {
                max > parseFloat(bet?.stake)
                  ? (newProfit += max)
                  : (newProfit += bet?.stake)
              } else {
                max > parseFloat(bet?.stake)
                  ? (newProfit -= max)
                  : (newProfit -= bet?.stake)
              }


            }

          }

          if (bet?.marketType === "fancy") {
            newFancyProfit += bet?.stake;
          }
          if (bet?.marketType === "bookmaker") {
            newBookmakerProfit += bet?.stake;
          }
        }
        setProfitLine(newLineProfit);
        setProfit(newProfit);
        setFancyProfit(newFancyProfit);
        setBookMakerProfit(newBookmakerProfit);
      });
    };

    const showUserBets = (userId, parent) => {
      const data = bets?.filter((bet) => bet?.userId?._id === userId?._id);
      setUserBet(data);
      setOpenModal(true);
      setModaldata({ username: userId?.username, parent });
    };

    const handleDelete = (bet, status) => {
      setvoidInvalidModal(true);
      setModaldata(bet);
      setTypeofBetDelete(status);
    };

    const handleDeletes = (betId) => {
      setvoidInvalidModal(true);
      setModaldata(betId);
    };

    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
      setActionTable(market);
    };
    const handlePageChangeMarketBets = (newPage) => {
      setCurrPage(newPage);
    };
    useEffect(() => {
      profitAndLoss();
    }, [bets]);
    console.log("marketBets===========", marketBets);
    if (bets.length) {
      return (
        <Fragment>
          <Card className="p-0  my-2">
            <CardHeader className="bg-darkGreen py-2 p-3">
              <div className="">
                <div className="d-flex align-items-center w-full justify-content-between">
                  <span className="d-flex ">
                    <p className="manageEve-card-text text-capitalize">
                      {market}
                    </p>
                    <p className="show-count px-3 rounded-pill ms-1 text-dark bg-light">
                      {marketBets ? totalDataCount : 0}
                    </p>
                  </span>

                  {/* {selectReport!=="USER" ? <>
                    <span className="show-count px-3 rounded-pill ms-1 text-dark bg-light">
                    PL {line ? Math.abs(lineProfit).toFixed(2) : null}
                    {market === "exchange" && !line ? Math.abs(profit).toFixed(2): null}
                    {market === "fancy" ? fancyProfit.toFixed(2) : null}
                    {market === "bookmaker" ? bookMakerProfit.toFixed(2) : null}
                  </span></> : null } */}
                  {/* <span className="show-count px-3 rounded-pill ms-1 text-dark bg-light">
                    {marketBets ? totalDataCount : 0}
                  </span> */}

                  {/* {loading && (
                    <tr className="mx-5">
                      <td className="">
                        <div className="d-flex justify-content-center align-items-center" >
                          <span className="spinner-border text-dark-custom" role="status">
                            <span className="sr-only">Loading...</span>
                          </span>
                        </div>
                      </td>
                    </tr>
                  )} */}
                  {/* {loading && <span className="spinner-border text-dark-custom" role="status">
                    <span className="sr-only">Loading...</span>
                  </span>} */}
                </div>
                {/* {view && (
                <h5 className="m-0" onClick={handleModal}>
                  <AiFillEye className="text-white link" />
                </h5>
              )} */}
              </div>
            </CardHeader>
            <div
              className="overflow-scroll " style={{ height: "540px", overflowY: "auto" }}>
              <Table bordered className="table table-hover px-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col" className="text-uppercase text-nowrap">
                      {t("USER_PARENT")}
                    </th>
                    <th scope="col" className="text-uppercase text-nowrap">
                      {t("SELECTION")}
                    </th>
                    <th scope="col" className="text-uppercase text-nowrap">
                      {t("ODDS")}
                    </th>
                    <th scope="col" className="text-uppercase text-nowrap">
                      {t("STAKE")}
                    </th>
                    {adminData.role < 2 &&
                      matchType === "/running-markets/exchange" && selectReport !== "USER" && (
                        <>
                          {marketBets[0]?.stx === true ? null : (
  <>
    <th scope="col" className="text-uppercase text-nowrap">
      {t("BETFAIR_STAKE")}
    </th>
    <th scope="col" className="text-uppercase text-nowrap">
      {t("BETFAIR_SHARE") + " (%)"}
    </th>
  </>
)}

                        </>
                      )}
                    <th scope="col" className="text-uppercase text-nowrap">
                      {t("DATE")}
                    </th>
                    <th scope="col" className="text-uppercase text-nowrap">
                      {t("SYSTEM_IP_ADDRESS")}
                    </th>
                    <th scope="col" className="text-uppercase text-nowrap">
                      {t("BROWSER_IP_ADDRESS")}
                    </th>
                  </tr>
                </thead>
                <tbody >
                  {console.log("marketBets",marketBets)}
                  {marketBets?.map((bet, index) => {
                    let parent = finduserParent(bet?.userId?.parents);
                    // let parent = findAdminParent(
                    //   bet?.userId?.parents,
                    //   adminData?.role
                    // );
                    if (bet?.status !== "4")
                      return (
                        <tr
                          style={{
                            ...(bet?.status !== BetStatus.OPEN
                              ? {
                                opacity: 0.5,
                              }
                              : ""),
                          }}
                          className={`${bet?.bettingType === "LINE"
                            ? bet?.selectionType === "back"
                              ? "table-danger"
                              : bet?.selectionType === "lay"
                                ? "table-primary"
                                : "table-danger"
                            : bet?.selectionType === "back"
                              ? "table-primary"
                              : "table-danger"
                            }`}
                          key={index}
                        >
                          {
                            <td className="table-txt-blue link">
                              {bet?.status === "6" ? (
                                <div className="d-flex align-items-center">
                                  {role < 2 &&
                                    role !== ManagerRoles.MONITORING_MANAGER &&
                                    bet?.status === "6" &&
                                    bet?.marketType === "exchange" && (
                                      <MdDelete
                                        className="delete-icon fs-6"
                                        onClick={() =>
                                          handleDelete(bet, bet?.status)
                                        }
                                      />
                                    )}
                                  <span
                                    onClick={() =>
                                      showUserBets(
                                        bet?.userId,
                                        parent?.parent_id?.username
                                      )
                                    }
                                  >
                                    &nbsp; {bet?.userId?.username}&nbsp;[
                                    {parent?.parent_id?.username}]
                                  </span>
                                </div>
                              ) : (
                                <div className="d-flex align-items-center">
                                  {role < 2 &&
                                    role !== ManagerRoles.MONITORING_MANAGER &&
                                    bet?.status === "1" &&
                                    bet?.marketType !== "exchange" && (
                                      <MdDelete
                                        className="delete-icon fs-6"
                                        onClick={() =>
                                          handleDelete(bet?._id, bet?.status)
                                        }
                                      />
                                    )}
                                  <span
                                    onClick={() =>
                                      showUserBets(
                                        bet?.userId,
                                        parent?.parent_id?.username
                                      )
                                    }
                                  >
                                    {bet?.userId?.username}&nbsp;[
                                    {parent?.parent_id?.username}]
                                  </span>
                                </div>
                              )}
                            </td>
                          }
                          <td className="table-text">{bet?.selection}</td>
                          <td className="table-txt-blue">
                            {line ? Math.round(bet?.odds) : bet?.odds}
                          </td>
                          <td className="table-text">
                            {/* {currency
                          ? Math.round(convertINRToCurrency(
                              bet?.stake,
                              parseFloat(bet?.currency?.value)
                            )*100)/100
                          : bet?.stake?.toFixed(2)} */}
                            {selectReport !== undefined && selectReport === "USER" ? convertINRToCurrency(bet?.stake, bet?.currency?.value) : bet?.stake?.toFixed(2)}
                          </td>
                          {adminData.role < 2 && selectReport !== "USER" &&
                            matchType === "/running-markets/exchange" && (
                              <>
                               {marketBets[0]?.stx === true ? null : (
                                <>
                                <td className="table-text">
                                  {currency !== undefined && currency
                                    ? Math.round(
                                      convertINRToCurrency(
                                        (bet?.stake *
                                          bet?.betFairPercentage) /
                                        100,
                                        parseFloat(bet?.currency?.value)
                                      ) * 100
                                    ) / 100
                                    : Math.round(
                                      ((bet?.stake * bet?.betFairPercentage) /
                                        100) *
                                      100
                                    ) / 100}
                                </td>

                                <td className="table-text">
                                  {bet?.betFairPercentage}
                                </td>
                                </> )}
                              </>
                            )}
                          <td className="table-text">
                            {new Date(bet?.createdAt)?.toLocaleString()}
                          </td>
                          <td className="table-txt-blue">
                            {bet?.userId?.ip_address?.system_ip
                              ? bet?.userId?.ip_address?.system_ip
                              : "-"}
                          </td>
                          <td className="table-txt-blue">
                            {bet?.userId?.ip_address?.browser_ip
                              ? bet?.userId?.ip_address?.browser_ip
                              : "-"}
                          </td>
                        </tr>
                      );
                  })}

                </tbody>
              </Table>
            </div>
            <div className="p-3 d-flex justify-content-end gap-3 ">
              <PaginationRow
                rowCount={limit}
                setRowCount={setLimit}
                setCurrPage={setCurrPage}
              // keyVal={market}
              />
              <PaginationButtons
                currentPage={currPage}
                totalPages={totalPagesMarketBets}
                handlePageChange={handlePageChangeMarketBets}
              />
            </div>

            {/* {pagination && (
              <div className="p-CardHeader p-2 pagination-bdy">
                <PaginationRow
                  rowCount={rowCount}
                  setRowCount={setRowCount}
                  keyVal={market}
                />
                <PaginationButtons
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </div>
            )} */}
            {openModal && <MarketsModal
              isOpen={openModal}
              title={modalData?.username}
              parent={modalData?.parent}
              toggler={handleModal}
              size="lg"
              pagination={true}
              role={role}
              // modalData={userBet?.length !== 0 ? userBet : bets}
              matchType={matchType}
              marketType={marketType}
              isMultiple={true}
              eventId={eventId}
              viewDeleted={viewDeleted}
              socketData={socketData}
              currency={currency}
            />}

          </Card>
          {voidInvalidModal && (
            <VoidInvalidModal
              isOpen={voidInvalidModal}
              title={t("DELETE_BET_?")}
              toggler={setvoidInvalidModal}
              modalData={modalData}
              setBetIds={setBetIds}
              setMarketBets={setMarketBets}
              size="md"
              fieldTitle={t("NEW_PASSWORD")}
              typeofBet={typeOfBetDelete}
              setTotalDataCount={setTotalDataCount}
            />
          )}
        </Fragment>
      );
    } else {
      return null;
    }
  }
);

export default BetsTable;
