import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import usePageTitle from "../../../../Hooks/usePageTitle";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Form,
  Row,
  Input,
  Button,
  CardFooter,
  CardHeader,
  Label,
  Media,
} from "reactstrap";
import {
  notifyWarning,
  notifySuccess,
  notifyDanger,
} from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { addssoId } from "../../../../redux/action";
import { addBetxExchangeSsoId } from "../../../../redux/action";
import { betxexchangeid } from "../../../../utils/helper";
import STX_DATA_API from "../../../../utils/STXDataApi";

const STXController = () => {
  const title = usePageTitle();
  const { t } = useTranslation();

  const [stxBetId, setstxBetId] = useState([]);
  const [responseData, setResponseData] = useState([{
      action: "null",
      filled: "null",
      filledPercentage: "null",
      id: "null",
      marketId: "null",
      orderType: "limit",
      price: "null",
      quantity: "null",
      status: "null",
      totalValue: "null",
      stake: "null",
    },
  ]);

  const onSubmit = (e) => {
    e.preventDefault();
    let orderId = [];
    if (stxBetId) {
      orderId.push(stxBetId);
      const args = {
        orderIds: orderId,
      };
      STX_DATA_API.post("v1/my-orders-clientIds", args)
        .then((response) => {
          if (response?.data?.data?.myOrderHistory) {
            setResponseData(response?.data?.data?.myOrderHistory);
          } else {
            setResponseData([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      notifyWarning("Please Enter the BetId");
    }
  };

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={t("STX-CONTROLLER")}
        title={title?.title}
        parent={title?.parent}
        className="ms-2"
      />

      <Row>
        <Col sm="12" className="px-3">
          <Card className="px-2">
            <CardHeader>
              <Media
                body
                className={`${"text-left"} d-flex align-items-center justify-content-end gap-2`}
              >
                <span
                  // onClick={(e) => handleBetfair(e)}
                  style={{ cursor: "pointer" }}
                  className="border d-flex align-items-center justify-content-end gap-2 px-2 py-2 rounded-3"
                >
                  <span className="fw-semibold fs-6 d-flex align-items-center">
                    {/* {country ? "odds" : "bets"} */}
                  </span>

                  <Label className={`m-0 ${"switch"}`}>
                    {/* <input type="checkbox"/> */}
                    {/* <span
                      className="switch-state"
                      style={{
                        backgroundColor: country ? "limegreen" : "lightgray",
                      }}
                    /> */}
                  </Label>
                </span>
              </Media>
            </CardHeader>
            <CardBody>
              <Form
                className="needs-validation d-flex"
                noValidate=""
                id="create"
                onSubmit={onSubmit}
              >
                <Input
                  type="text"
                  value={stxBetId}
                  onChange={(e) => setstxBetId(e.target.value)}
                  className="w-50"
                >
                  {" "}
                </Input>
                <Button
                  // disabled={defaultValue === ssoId}
                  className="m-2"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </CardBody>
            <CardFooter>
              <p className="text-danger">
                Response:{" "}
                {responseData && responseData.length && (
                  <div className="response-data-box p-1">
                    {responseData.map((element) => (
                      <div key={element.id} className="response-data-item">
                        <table className="">
                          <thead className="border rounded m-2">
                            <tr>
                              <th className="p-2">Bets</th>
                              <th className="p-2">Current Value</th>
                            </tr>
                          </thead>
                          <tbody className="border rounded p-2">
                            {Object.entries(element).map(([key, value]) => (
                              <tr className="p-2" key={key}>
                                <td className="p-1">{key}</td>
                                <td className="p-2">{value}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                )}
              </p>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default STXController;
