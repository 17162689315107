import {
    GET_STX_DATAS,
    GET_STX_DATAS_SUCCESS,
    GET_STX_DATAS_FAILURE,
  } from "../../action/types";
  
  const INIT_STATE = {
    loading: false,
    data: null,
  };
  
  const getSTXDataReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_STX_DATAS:
        return { ...state, loading: true };
      case GET_STX_DATAS_SUCCESS:
        return { ...state, data: action.payload, loading: false };
      case GET_STX_DATAS_FAILURE:
        return { ...state, loading: false };
      default:
        return state;
    }
  };
  
  export default getSTXDataReducer;