import { all, call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  notifyDanger,
  notifySuccess,
} from "../../../utils/helper";
import STX_DATA_API from "../../../utils/STXDataApi";
import { getSTXDataFailure, getSTXDataSuccess } from "../../action";
import { GET_STX_DATAS } from "../../action/types";

function* getStxLeagueData(action) {
  console.log(action)
  console.log("action",action?.payload?.sportName)
  try {
    const { data } = yield STX_DATA_API.post("v1/get-market-by-sport",action?.payload);
    if (data?.meta?.code === 200) {
      yield put(getSTXDataSuccess(data));
      yield call(action.payload.callback, data);  
    }
    else if (data?.meta?.code !== 200) {
      yield put(getSTXDataFailure());
      notifyDanger(data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  } catch (error) {
    yield put(getSTXDataFailure());
    notifyDanger(error?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  }
}

export function* watchStxDataAPI() {
  yield takeEvery(GET_STX_DATAS, getStxLeagueData);
}

export default function* rootSaga() {
  yield all([watchStxDataAPI()]);
}