// import React from "react";
// import { Col, Row, Table } from "reactstrap";
// import {
//   findEventFancyOdds,
//   findFancyRunnerBooks,
//   groupArrayasObject,
// } from "../../../../../utils/helper";
// import { useEffect } from "react";
// import { useState } from "react";

// const FancyOddsCard = ({ fancyMarkets, odds, eventId, eventCode, allBets }) => {
//   const [groupedBets, setGroupedBets] = useState([]);
//   useEffect(() => {
//     const fancyBets = allBets.filter(
//       (bet) => bet?.sportBets?.marketType === "fancy"
//     );

//     const grouped = groupArrayasObject(fancyBets, "selectionId");
//     setGroupedBets(grouped);
//   }, [allBets]);

//   return (
//     <>
//       {fancyMarkets?.map((runner, index) => {
//         const eventFancy = findEventFancyOdds(odds, eventCode);

//         if (eventFancy && Object.values(eventFancy).length !== 0) {
//           let currentRunnerodd = eventFancy[runner?.id];
//           currentRunnerodd = currentRunnerodd && JSON.parse(currentRunnerodd);
//           const marketBooks = findFancyRunnerBooks(groupedBets[runner?.id]);
//           return (
//             <>
//               <hr className="m-0" key={index} />
//               <Row className="">
//                 <Col sm="6" className="pl-2">
//                   <p className="runner-name">{runner?.name}</p>

//                   {/* {books.map((book) => {
//                     if (book?.selectionId === runner?.selection_id) {
//                       return <span>{book?.pl}</span>;
//                     } else {
//                       return null;
//                     }
//                   })} */}
//                 </Col>

//                 <Col sm="1" className="px-1">
//                   <div className={`rounded my-1 runner-back`}>
//                     <div>
//                       <p className="runner-price">
//                         {currentRunnerodd?.b1 ? currentRunnerodd?.b1 : "_"}
//                       </p>
//                       <p className="runner-size">{currentRunnerodd?.bs1}</p>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col sm="1" className="px-1">
//                   <div className={`rounded my-1 runner-lay`}>
//                     <div>
//                       <p className="runner-price">
//                         {currentRunnerodd?.l1 ? currentRunnerodd?.l1 : "_"}
//                       </p>
//                       <p className="runner-size"> {currentRunnerodd?.ls1}</p>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//               <Row>
//                 <Table bordered striped responsive>
//                   <thead className="bg-darkGreen">
//                     <tr className="text-center">
//                       {marketBooks &&
//                         Object.keys(marketBooks)?.map((val) => (
//                           <th scope="col" className="text-uppercase">
//                             {val}
//                           </th>
//                         ))}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr key={index}>
//                       {marketBooks &&
//                         Object.values(marketBooks)?.map((item, index) => (
//                           <>
//                             <td
//                               className={`${
//                                 -1 * item < 0 ? "text-danger" : "text-success"
//                               }`}
//                             >
//                               {(-1 * item).toFixed(2)}
//                             </td>
//                           </>
//                         ))}
//                     </tr>
//                   </tbody>
//                 </Table>
//               </Row>
//             </>
//           );
//         }
//       })}
//     </>
//   );
// };

// export default FancyOddsCard;

import React from "react";
import { Col, Row, Table } from "reactstrap";
import EmptyRunners from "./EmptyRunners";

const FancyOddsCard = ({ currentRunnerodd, runner, marketBooks }) => {
  // const [groupedBets, setGroupedBets] = useState([]);

  // useEffect(() => {
  //   const fancyBets = allBets.filter(
  //     (bet) => bet?.sportBets?.marketType === "fancy"
  //   );
  //   const grouped = groupArrayasObject(fancyBets, "selectionId");
  //   setGroupedBets(grouped);
  // }, [allBets]);

  return (
    <>
      <div className="d-flex justify-content-end mr-4">
        {currentRunnerodd !== undefined ?
          <>
            <div className="d-flex px-1">
              <div className={`rounded my-1 runner-back`}>
                <div>
                  <p className="runner-price text-black">
                    {currentRunnerodd?.b1 ? currentRunnerodd?.b1 : "_"}
                  </p>
                  <p className="runner-size text-black">{currentRunnerodd?.bs1}</p>
                </div>
              </div>
            </div>
            <div className="px-1">
              <div className={`rounded my-1 runner-lay`}>
                <div>
                  <p className="runner-price text-black">
                    {currentRunnerodd?.l1 ? currentRunnerodd?.l1 : "_"}
                  </p>
                  <p className="runner-size text-black"> {currentRunnerodd?.ls1}</p>
                </div>
              </div>
            </div>
          </> : <EmptyRunners oddCount={2} status={"suspended"} />}
      </div>
      <div className="overflow-auto pt-1">
        <table className="table table-bordered table-striped table-responsive">
          <thead className="table-light">
            <tr className="text-center">
              {marketBooks &&
                Object?.keys(marketBooks)?.map((val, index) => (
                  <th key={index} scope="col" className="text-uppercase">
                    {val}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {marketBooks &&
                Object?.values(marketBooks)?.map((item, index) => (
                  <>
                    <td
                      className={`fw-bold ${-1 * item < 0 ? "text-danger" : "text-success"
                        }`}
                      key={index}
                    >
                       {(-1 * item)?.toFixed(2)}
                    </td>
                  </>
                ))}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FancyOddsCard;
