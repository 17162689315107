// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .modal-dialog {
    max-width: 90%; /* Adjust max-width as needed */
  }
  
  .custom-modal .modal-body {
    padding: 20px; /* Adjust padding as needed */
  }
  
  .custom-modal .modal-body .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .custom-modal .modal-body .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10px;
  }
  
  @media (min-width: 576px) {
    .custom-modal .modal-body .col-md-4 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  
  @media (min-width: 768px) {
    .custom-modal .modal-body .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
  
  @media (min-width: 992px) {
    .custom-modal .modal-body .col-md-4 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }


  .btn-outline-dark:hover {
    background-color: transparent; /* Keeps the background transparent on hover */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/Components/Pages/PageLayout/modal/style.css"],"names":[],"mappings":"AAAA;IACI,cAAc,EAAE,+BAA+B;EACjD;;EAEA;IACE,aAAa,EAAE,6BAA6B;EAC9C;;EAEA;IACE,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,aAAa;EACf;;EAEA;IACE;MACE,aAAa;MACb,cAAc;IAChB;EACF;;EAEA;IACE;MACE,mBAAmB;MACnB,oBAAoB;IACtB;EACF;;EAEA;IACE;MACE,aAAa;MACb,cAAc;IAChB;EACF;;;EAGA;IACE,6BAA6B,EAAE,8CAA8C;EAC/E","sourcesContent":[".custom-modal .modal-dialog {\n    max-width: 90%; /* Adjust max-width as needed */\n  }\n  \n  .custom-modal .modal-body {\n    padding: 20px; /* Adjust padding as needed */\n  }\n  \n  .custom-modal .modal-body .row {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  \n  .custom-modal .modal-body .col-md-4 {\n    flex: 0 0 100%;\n    max-width: 100%;\n    padding: 10px;\n  }\n  \n  @media (min-width: 576px) {\n    .custom-modal .modal-body .col-md-4 {\n      flex: 0 0 50%;\n      max-width: 50%;\n    }\n  }\n  \n  @media (min-width: 768px) {\n    .custom-modal .modal-body .col-md-4 {\n      flex: 0 0 33.33333%;\n      max-width: 33.33333%;\n    }\n  }\n  \n  @media (min-width: 992px) {\n    .custom-modal .modal-body .col-md-4 {\n      flex: 0 0 25%;\n      max-width: 25%;\n    }\n  }\n\n\n  .btn-outline-dark:hover {\n    background-color: transparent; /* Keeps the background transparent on hover */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
