import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const ProfitLossCasinoModal = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={props.isOpen}
      onHide={props.toggler}
      centered
      size={props.size}
    >
      <Modal.Header closeButton>
        <Modal.Title>{`${props.title}  ${props?.modalData?.username}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`p-0 ${props.bodyClass}`}>
        <div className="overflow-scroll">
          <table className="table table-bordered">
            <thead>
              <tr className="">
                <th
                  scope="col"
                  className="text-nowrap text-uppercase text-dark"
                >
                  RoundId
                </th>
                <th
                  scope="col"
                  className="text-nowrap text-uppercase text-dark"
                >
                  Selection
                </th>
                <th
                  scope="col"
                  className="text-nowrap text-uppercase text-dark"
                >
                  {t("stake")}
                </th>
                <th
                  scope="col"
                  className="text-nowrap text-uppercase text-dark"
                >
                  {"Profit"}
                </th>
                <th
                  scope="col"
                  className="text-nowrap text-uppercase text-dark"
                >
                  {"Loss"}
                </th>
                <th
                  scope="col"
                  className="text-nowrap text-uppercase text-dark"
                >
                  {t("date")}
                </th>
                <th
                  scope="col"
                  className="text-nowrap text-uppercase text-dark"
                >
                  {t("status")}
                </th>
              </tr>
            </thead>
            <tbody>
              {props?.modalData?.bets?.length <= 0 ? (
                <>
                  <tr className="text-center">
                    <td colSpan={7} className="py-4">
                    {t("NO_DATA_AVAILABLE")}
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {props?.modalData?.bets?.map((data, index) => {
                    return (
                      <tr
                        className={`${
                          data?.selectionType === "back"
                            ? "table-primary"
                            : "table-danger"
                        } `}
                        key={index}
                      >
                        <td className="table-text  text-start">
                          {data?.roundId}
                        </td>
                        <td className="table-text-blue  text-start">
                          {data?.gameCode}
                        </td>
                        <td className="table-text  text-start">
                          {data?.stake}
                        </td>
                        <td className="table-text  text-start">
                          {" "}
                          {data?.pl > 0 ? data?.pl - data?.stake : "_"}
                        </td>
                        <td className="table-text  text-start">
                          {" "}
                          {data?.pl == 0 ? data?.stake : "_"}
                        </td>
                        <td className="table-text  text-start">
                          {new Date(data?.updatedAt)?.toLocaleString()}
                        </td>
                        <td className="table-text-blue text-center">
                          {"settled"}
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProfitLossCasinoModal;
