import React, { useRef } from "react";
import { Col, Row } from "reactstrap";
import Widgets1 from "../../../Common/Component/CommonWidgets/Widgets1";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ActiveClientsModal from "../modal/ActiveClientsModal";
import LiveExposureModal from "../modal/LiveExposureModal";
import { useEffect } from "react";
import SkeletonLoading from "../../../Common/Component/CommonWidgets/SkeletonLoading";
import { convertINRToCurrency } from "../../../../utils/helper";
import { getDashboardData } from "../../../../redux/action/dashboard/getDashboardDataAction";
import { getBetfairBalance } from "../../../../redux/action/dashboard/getBetFairBalanceAction";
import TotalPlModal from "../modal/TotalPlModal";
import TotalClientModal from "../modal/TotalClientModal";
import ClientBalanceModal from "../modal/ClientBalanceModal";
import { useDispatch } from "react-redux";
import { getLocalStorageItem } from "../../../../utils/helper";
import { getDashboardDataDetails } from "../../../../redux/action";
const WidgetsWrapper = React.memo(() => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.Login.userData);

  const [activeClientsModal, setActiveClientsModal] = useState(false);
  const [liveExposureModal, setLiveExposureModal] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [activeClients, setActiveClients] = useState({});
  const shouldFetchRef = useRef(true); 
  const navigate = useNavigate();
  const [totalClient, setTotalClient] = useState(false);
  const [dashboardData,setDashboardData] = useState({})
  const [totalClientsBalance, setTotalClientsBalance] = useState(false);
  const [totalPl, setTotalPl] = useState(false);
  const dispatch = useDispatch();

  const handleActiveClients = () => {
    setActiveClientsModal(true);
  };
  const handleLiveExposure = () => {
    setLiveExposureModal(true);
  };
  const handleTotalClients = () => {
    setTotalClient(true);
  };
  const handleAllClientBalance = () => {
    setTotalClientsBalance(true);
  };
  const handleTotalPl = () => {
    setTotalPl(true);
  };



  useEffect(() => {
    const userData = getLocalStorageItem("userData");
    if (userData) {
      dispatch(getDashboardData({
        callback: (data) => {
          setDashboardData(data.data);
          // if (shouldFetchRef.current) {
          //   dispatch(getDashboardDataDetails());
          // }
        }
      }));
    } else {
      navigate("/login");
      return;
    }

    return () => {
      shouldFetchRef.current = false; 
    };
  }, [dispatch]);

  useEffect(() => {
    const count = dashboardData?.activeUserCount - userIds?.length;

    const activeClients = {
      title: "ACTIVE_CLIENTS",
      total: count ? count : "0",
      color: "primary",
      icon: "rate",
    };
    setActiveClients(activeClients);
  }, [dashboardData?.activeUserCount, userIds]);

  // const activeClients = {
  //   title: t("ACTIVE_CLIENTS"),
  //   total: dashboardData?.activeUserCount,
  //   color: "primary",
  //   icon: "rate",
  // };
  const liveExposure = {
    title: "LIVE_EXPOSURE",

    total: dashboardData?.total_Exposure,

    color: "primary",
    icon: "rate",
  };
  const totalClients = {
    title: "TOTAL_CLIENTS",
    total: dashboardData?.totalClientCount,
    color: "success",
    icon: "rate",
  };
  const creditReference = {
    title: "CREDIT_REFERENCE",
    total: dashboardData?.myCreditReference?.toLocaleString("en-us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    // total: convertINRToCurrency(
    //   dashboardData?.myCreditReference,
    //   parseFloat(userData?.currencyId?.value)
    // ),
    color: "success",
    icon: "rate",
  };
  const totalBalance = {
    title: "TOTAL_BALANCE",
    total: dashboardData?.totalBalance?.toLocaleString("en-us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    // total: convertINRToCurrency(
    //   dashboardData?.totalBalance,
    //   parseFloat(userData?.currencyId?.value)
    // ),
    color: "success",
    icon: "rate",
  };
  const myBalance = {
    title: "MY_BALANCE",
    total: dashboardData?.myBalance?.toLocaleString("en-us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    // total: convertINRToCurrency(
    //   dashboardData?.myBalance,
    //   parseFloat(userData?.currencyId?.value)
    // ),
    color: "success",
    icon: "rate",
  };
  const clientsBalance = {
    title: "CLIENT_BALANCE",
    total: dashboardData?.totalClientBalance?.toLocaleString("en-us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    // total: convertINRToCurrency(
    //   dashboardData?.totalClientBalance,
    //   parseFloat(userData?.currencyId?.value)
    // ),
    color: "success",
    icon: "rate",
  };
  const totalPL = {
    title: "TOTAL_PL",
    total: dashboardData?.totalP_L?.toLocaleString("en-us", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    // total: convertINRToCurrency(
    //   dashboardData?.totalP_L,
    //   parseFloat(userData?.currencyId?.value)
    // ),
    color: "success",
    icon: "rate",
  };

  const [data, setData] = useState(null); // Replace null with your actual initial data

  useEffect(() => {
    // Simulate API call or data loading
    setTimeout(() => {
      // Replace with actual data
      setData({
        color: "blue",
        icon: "your-icon-id",
        total: 123,
        title: "Your Widget Title",
      });
    }, 2000); // Simulating a 2-second loading time
  }, []);

  return (
    <>
      <Col xxl="4" xl="3" sm="6" className="box-col-6 flex-grow-1">
        <Row>
          <Col xl="12">
            <Link onClick={() => handleActiveClients()}>
              {data ? <Widgets1 data={activeClients} /> : <SkeletonLoading />}
            </Link>
          </Col>
          <Col xl="12">
            <Link>
              {data ? (
                <Widgets1
                  className="pointer"
                  data={liveExposure}
                  decimal={true}
                  handleLiveExposure={handleLiveExposure}
                />
              ) : (
                <SkeletonLoading />
              )}
            </Link>
          </Col>
          <Col xl="12">
            <Link onClick={() => handleTotalClients()}>
              {data ? <Widgets1 data={totalClients} /> : <SkeletonLoading />}
            </Link>
          </Col>
          <Col xl="12">
            <Link onClick={() => handleAllClientBalance()}>
              {data ? (
                <Widgets1 data={clientsBalance} decimal={true} />
              ) : (
                <SkeletonLoading />
              )}
            </Link>
          </Col>
        </Row>
      </Col>
      <Col xxl="4" xl="3" sm="6" className="box-col-6">
        <Row>
          <Col xl="12">
            {data ? (
              <Widgets1 data={creditReference} decimal={true} />
            ) : (
              <SkeletonLoading />
            )}
          </Col>
          <Col xl="12">
            {data ? (
              <Widgets1 data={totalBalance} decimal={true} />
            ) : (
              <SkeletonLoading />
            )}
          </Col>
          <Col xl="12">
            {data ? (
              <Widgets1 data={myBalance} decimal={true} />
            ) : (
              <SkeletonLoading />
            )}
          </Col>
          <Col xl="12" className="flex-grow-1">
            <Link onClick={() => handleTotalPl()}>
              {data ? (
                <Widgets1 data={totalPL} decimal={true} />
              ) : (
                <SkeletonLoading />
              )}
            </Link>
          </Col>
        </Row>
      </Col>

      {/* {activeClientsModal && (
        <ActiveClientsModal
          isOpen={activeClientsModal}
          title={"Active Clients"}
          toggler={setActiveClientsModal}
          setUserIds={setUserIds}
          userIds={userIds}
          size="xl"
        />
      )} */}
      {liveExposureModal && (
        <LiveExposureModal
          isOpen={liveExposureModal}
          title={"Active Bets"}
          toggler={setLiveExposureModal}
          size="xl"
        />
      )}
      {/* {totalClient && (
        <TotalClientModal
          isOpen={totalClient}
          title={"Total Client Details"}
          toggler={setTotalClient}
          size=""
        />
      )} */}
      {/* {totalClientsBalance && (
        <ClientBalanceModal
          isOpen={totalClientsBalance}
          title={"Total Client Balance"}
          toggler={setTotalClientsBalance}
          size="l"
        />
      )} */}
      {/* {totalPl && (
        <TotalPlModal
          isOpen={totalPl}
          title={"Total PL"}
          toggler={setTotalPl}
          size="l"
        />
      )} */}
    </>
  );
});

export default WidgetsWrapper;
