import { ManagerRoles } from "../../Constant";

export const MENUITEMS = [
  {
    menutitle: "GENERAL",
    menucontent: "DASHBOARDS,WIDGETS",
    Items: [
      {
        title: "DASHBOARD",
        icon: "dashboard",
        type: "link",
        path: `dashboard`,
        managerAccess: ["ALL"],
      },
      {
        title: "RUNNING_MARKETS",
        icon: "market",
        type: "sub",
        // auth: true,
        managerAccess: [
          ManagerRoles.MONITORING_MANAGER,
          ManagerRoles.MANAGER,
          ManagerRoles.OPERATIONAL_MANAGER,
        ],
        path: `running-markets`,
        children: [
          {
            active: false,
            path: `running-markets/exchange`,
            title: "Exchange Market",
            type: "link",
            monitor: false, //hide for monitoring manager
          },
          {
            active: false,
            path: `running-markets/bookmaker-fancy`,
            title: "Fancy-Bookmaker Market",
            type: "link",
            monitor: false,
          },

          {
            active: false,
            path: `running-markets/error-bets`,
            title: "Error-bets",
            type: "link",
            monitor: false,
          },
        ],
      },
      // {
      //   title: "BANKING",
      //   icon: "bank",
      //   type: "link",
      //   path: `banking`,
      // },
      {
        title: "USERS",
        icon: "user",
        type: "sub",
        managerAccess: [
          ManagerRoles.ACCOUNTS_MANAGER,
          ManagerRoles.MONITORING_MANAGER,
          ManagerRoles.MANAGER,
        ],
        children: [
          {
            active: false,
            path: `users/add-user`,
            title: "ADD_USER",
            type: "link",
            monitor: true,
          },
          {
            active: false,
            path: `users`,
            title: "USERS",
            type: "link",
          },
          {
            active: false,
            path: `inactive-users`,
            title: "INACTIVE_USERS",
            type: "link",
          },
        ],
      },
      {
        title: "WHITE-LABELS",
        icon: "social",
        type: "sub",
        auth: true,
        children: [
          {
            active: false,
            path: `whitelabels/add-whitelabel`,
            title: "ADD-WHITELABEL",
            type: "link",
          },
          {
            active: false,
            path: `whitelabels`,
            title: "WHITELABELS-LIST",
            type: "link",
          },
          // {
          //   active: false,
          //   path: `inactive-users`,
          //   title: "INACTIVE-USERS",
          //   type: "link",
          // },
        ],
      },
      {
        title: "MANAGERS",
        icon: "user",
        type: "sub",
        auth: true,
        managerAccess: [ManagerRoles.MANAGER],
        children: [
          {
            active: false,
            path: `add-manager`,
            title: "ADD_MANAGER",
            type: "link",
          },
          {
            active: false,
            path: `managers`,
            title: "MANAGERS",
            type: "link",
            manager: true, //Hide for manager
          },
          {
            active: false,
            path: `accounts-managers`,
            title: "ACCOUNTS_MANAGERS",
            type: "link",
          },
          {
            active: false,
            path: `operational-managers`,
            title: "OPERATIONAL_MANAGERS",
            type: "link",
          },
          {
            active: false,
            path: `monitoring-managers`,
            title: "MONITORING_MANAGERS",
            type: "link",
          },
        ],
      },
      {
        title: "REPORTS",
        icon: "report",
        type: "sub",
        managerAccess: [
          ManagerRoles.ACCOUNTS_MANAGER,
          ManagerRoles.MONITORING_MANAGER,
          ManagerRoles.MANAGER,
        ],
        children: [
          {
            active: false,
            path: `reports`,
            title: "REPORTS",
            type: "link",
          },
          {
            active: false,
            path: `deleted-bets`,
            title: "DELETED_BETS",
            type: "link",
            auth: true,
          },
          {
            active: true,
            path: "/reports/analysis",
            title: "Report Analysis",
            type: "link",
            auth: true,

          },
          {
            active: false,
            path: `betfair-reports`,
            title: "BETFAIR_REPORTS",
            type: "link",
            auth: true,
          },
          {
            active: false,
            path: `commission-reports`,
            title: "Commission Reports",
            type: "link",
            auth: true,
          },
        ],
      },
      {
        title: "CURRENCIES",
        icon: "crry",
        type: "sub",
        managerAccess: [
          ManagerRoles.MONITORING_MANAGER,
          ManagerRoles.OPERATIONAL_MANAGER,
          ManagerRoles.ACCOUNTS_MANAGER,
          ManagerRoles.MANAGER,
        ],
        auth: true,
        children: [
          {
            active: false,
            path: "/currencies/add-currency",
            title: "ADD-CURRENCY",
            type: "link",
          },
          {
            active: false,
            path: `/currencies`,
            title: "CURRENCIES",
            type: "link",
          },
        ],
      },
      {
        title: "RESTORE-PANEL",
        icon: "widget",
        type: "link",
        auth: true,
        managerAccess: [ManagerRoles.OPERATIONAL_MANAGER, ManagerRoles.MANAGER],
        path: `restore-panel`,
      },
      {
        title: "MANAGE-EVENTS",
        icon: "task",
        type: "link",
        auth: true,
        managerAccess: [ManagerRoles.OPERATIONAL_MANAGER, ManagerRoles.MANAGER],
        path: `manage-events`,
      },
      {
        title: "RESULTS",
        icon: "file",
        type: "link",
        auth: true,
        managerAccess: [
          ManagerRoles.OPERATIONAL_MANAGER,
          ManagerRoles.MONITORING_MANAGER,
          ManagerRoles.MANAGER,
        ],
        path: `results`,
      },
      {
        title: "SETTINGS",
        icon: "setting",
        type: "sub",
        managerAccess: [ManagerRoles.OPERATIONAL_MANAGER, ManagerRoles.MANAGER],
        whiteLabel: true,
        auth: true,
        children: [
          {
            active: false,

            path: "/sport-setting",
            title: "SPORT_SETTING",

            type: "link",
          },
          {
            active: false,
            path: "/league-setting",
            title: "LEAGUE_SETTING",
            type: "link",
          },
          {
            active: false,
            path: "/match-setting",
            title: "MATCH_SETTING",
            type: "link",
          },
          {
            active: false,
            path: "/fancy-stake-setting",
            title: "FANCY_STAKE_SETTING",
            type: "link",
            auth: true,
          },
        ],
      },
      {
        title: "HOME-MANAGEMENT",
        icon: "gallery",
        type: "sub",
        auth: true,
        managerAccess: ["ALL"],
        children: [
          {
            active: false,
            path: "/home-sliders",
            title: "HOME_SLIDERS_LIST",
            type: "link",
          },
          {
            active: false,
            path: "home-sliders/add-slider",
            title: "ADD_SLIDER",
            type: "link",
          },
          {
            active: false,
            path: "home-sliders/sports-manage-accordingly",
            title: "MANAGE_SPORTS_ACCORDINGLY",
            type: "link",
          },
          // {
          //   active: false,
          //   path: "home-sliders/league-manage-accordingly",
          //   title: "MANAGE_LEAGUE_ACCORDINGLY",
          //   type: "link",
          // },
        ],
      },
      {
        title: "Change ID",
        icon: "dashboard",
        type: "link",
        path: `id`,
        managerAccess: ["ALL"],
        auth: true,
      },
      // {
      //   title:"APK Controller",
      //   icon: "gallery",
      //   type: "link",
      //   path: `apk`,
      //   managerAccess: ["ALL"],
      //   auth: true,
      // },
      {
        title: "Notification",
        icon: "dashboard",
        type: "link",
        path: `notification`,
        managerAccess: ["ALL"],
        auth: true,
      },
      {
        title: "Balance checker",
        icon: "dashboard",
        type: "link",
        path: `betfairbalancechecker`,
        managerAccess: ["ALL"],
        auth: true,
      },
    ],
  },
];
