import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  convertINRToCurrency,
  findLineMarketBooks,
  findRunnerOdds,
} from "../../../../../utils/helper";
import { handlePlAsPerBfAndCs } from "../../../../../utils/book";
import EmptyRunners from "./EmptyRunners";
import { useEffect } from "react";
import { useState } from "react";
import { socket } from "../../../../../context/socketContext";
import {
  getBetsAndBooks,
} from "../../../../../redux/action";
import { layerRoles, ManagerRoles } from "../../../../../Constant";

const MatchOddsCard = ({
  eventName,
  marketName,
  runners,
  market,
  runnerBook,
  runnerBookRefresh,
  marketId,
  currencyId,
  eventId,
  sportCode,
  odds,
  sharedBooks,
  currency,
  betFairBooks,
  line,
  marketBooks,
  totalPLByColumnResult,
  matchOddsCaculation,
  matchOddsCaculations,
  lineMarketBets,
  isColumnTotalNewBet,
  setIsColumnTotalNewBet,
  isColumnTotalNewBetCurrency,
  setIsColumnTotalNewBetCurrency
}) => {
  // console.log("runners",runners);
  // console.log("marketId",marketId);
  // console.log("runnerBook?.books matchodd", runnerBookRefresh[marketId]);
  const adminData = useSelector((state) => state.Login.userData);
  const [books, setBooks] = useState([]);
  const [columnTotals, setColumnTotals] = useState([]);
  const [columnTotalNewBet, setColumnTotalNewBet] = useState([]);
  const [columnTotalNewBetCurrency, setColumnTotalNewBetCurrency] = useState([]);
  const [runnerData, setRunnerData] = useState([]);
  const [marketBook, setMarketBook] = useState([]);
  const [bookviewData, setBookViewData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setBookViewData(runnerBookRefresh[marketId]);
  }, [marketId, runnerBook]);

  useEffect(() => {
    // let modified = runners?.map((element) => {
    //   // console.log("element", element);
    //   return ({ ...element, runnerName: market?.marketName, originalName: (market?.marketName + element?.runnerName), name: element?.runnerName })
    // });
    // setRunnerData(modified);
    if (runners?.length === 1) {
      let modified = runners?.map((element) => {
        return ({ ...element, runnerName: market?.marketName, name: element?.runnerName })
      });
      setRunnerData(modified);
    } else {
      let modified = runners?.map((element) => ({ ...element, name: element?.runnerName }));
      setRunnerData(modified);
    }
    calculateTotalPLByBetshare_shareBook_Column();
    if (currency !== "") {
      setIsColumnTotalNewBet(false);
    }
  }, [runners, runnerBookRefresh, marketId, columnTotalNewBet]);

  useEffect(() => {
    if (currency !== "") {
      setIsColumnTotalNewBetCurrency(true)
      setIsColumnTotalNewBet(false);
      onChangeCallApiCall();
    }
  }, [currency]);

  // useEffect(() => {
  //   if (currency !== "") {
  //     setIsColumnTotalNewBet(false);
  //   }
  //   socket.on("new-bet", (data) => {
  //     setIsColumnTotalNewBet(true);
  //     let type = "exchange";
  //     dispatch(
  //       getBetsAndBooks({
  //         eventId: eventId,
  //         currencyId,
  //         deleted: false,
  //         type,
  //         callback: (data) => {
  //           let book_data = data?.data?.books[marketId];
  //           // console.log("book_data", book_data);
  //           if (book_data) {
  //             setColumnTotals([]);
  //             const totalPLByColumn = [];
  //             const totalPLByColumnResults = [];

  //             if (book_data?.books?.[0]?.booksData) {
  //               book_data.books[0].booksData.forEach(() => {
  //                 totalPLByColumn.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, pl: 0, directParent: false, actual_pl: 0 });
  //                 totalPLByColumnResults.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, pl: 0, directParent: false, actual_pl: 0 });
  //               });
  //             }

  //             const allRolesAreSeven = book_data?.books?.some(item => item.userId.role !== 7);

  //             book_data?.books?.forEach((book) => {
  //               book?.booksData?.forEach((bookData, subIndex) => {
  //                 let valueToAdd = 0;
  //                 let percentage = 0;
  //                 let bfShare = 0;
  //                 let total_pll = 0;
  //                 let isClientShare = false;

  //                 if (bookData?.betFairShare > 0) {
  //                   bfShare = bookData?.betFairShare;
  //                 } else {
  //                   bfShare = -1 * bookData?.betFairShare;
  //                 }

  //                 if (bookData?.pl > 0) {
  //                   total_pll = bookData?.pl;
  //                 } else {
  //                   total_pll = -1 * bookData?.pl;
  //                 }

  //                 if (book?.userId?.sportShares) {
  //                   isClientShare = book?.userId?.sportShares !== 0;
  //                 } else {
  //                   isClientShare = book?.userId?.parents?.[0]
  //                     ?.parent_id?.sportShares !== 0 && book?.userId?.parents?.[0]
  //                       ?.parent_id?.sportShares !== undefined;
  //                 }

  //                 // if (betFairBooks && sharedBooks) {
  //                 const sumOfShares =
  //                   book.userId?.betFairShare + book.userId?.sportShares;
  //                 if (isNaN(sumOfShares)) {
  //                   percentage = total_pll - bfShare;
  //                 } else {
  //                   percentage = (total_pll * (100 - sumOfShares)) / 100;
  //                 }
  //                 valueToAdd = percentage;
  //                 // }

  //                 let client_shares = 0;
  //                 if (isClientShare) {
  //                   if (book?.userId?.sportShares) {
  //                     if (adminData?.currencyId?.value) {
  //                       client_shares = convertINRToCurrency(

  //                         (bookData?.pl / 100) *
  //                         book?.userId?.sportShares
  //                         ,
  //                         adminData?.currencyId?.value, true
  //                       )
  //                     } else {
  //                       client_shares =
  //                         (bookData?.pl / 100) *
  //                         book?.userId?.sportShares

  //                     }
  //                   } else {
  //                     if (adminData?.currencyId?.value) {
  //                       client_shares = convertINRToCurrency(

  //                         (bookData?.pl / 100) *
  //                         book?.userId?.parents?.[0]
  //                           ?.parent_id?.sportShares
  //                         ,
  //                         parseInt(
  //                           adminData?.currencyId?.value
  //                         ), true
  //                       )
  //                     } else {
  //                       client_shares = (bookData?.pl / 100) *
  //                         book?.userId?.parents?.[0]
  //                           ?.parent_id?.sportShares

  //                     }
  //                   }
  //                 }

  //                 // if (client_shares > 0) {
  //                 //   client_shares = client_shares;
  //                 // } else {
  //                 //   client_shares = -1 * client_shares;
  //                 // }
  //                 bfShare = bookData?.betFairShare;


  //                 // console.log("book...........", book);
  //                 if (book?.userId?.role !== 7) {
  //                   // console.log("llllllllllllllll");
  //                   totalPLByColumn[subIndex].clientShare += parseFloat(client_shares);
  //                   totalPLByColumnResults[subIndex].clientShare += parseFloat(client_shares);
  //                   // totalPLByColumnResults[subIndex].directParent = false;
  //                 } else {
  //                   totalPLByColumn[subIndex].clientShare += 0;
  //                   totalPLByColumnResults[subIndex].clientShare += 0;
  //                   // totalPLByColumnResults[subIndex].directParent = true;
  //                 }

  //                 totalPLByColumnResults[subIndex].directParent = !allRolesAreSeven;
  //                 // if (eventNameArray?.includes(bookData?.name)) {
  //                 if (valueToAdd < 0) {
  //                   valueToAdd = -1 * valueToAdd
  //                 }
  //                 if (bookData?.pl > 0) {
  //                   totalPLByColumnResults[subIndex].actual_pl += -Math.abs(bookData?.pl);
  //                   totalPLByColumnResults[subIndex].total_pl += -valueToAdd;
  //                 } else {
  //                   totalPLByColumnResults[subIndex].actual_pl += Math.abs(bookData?.pl);
  //                   totalPLByColumnResults[subIndex].total_pl += valueToAdd;
  //                 }

  //                 totalPLByColumn[subIndex].pl = bookData?.pl;
  //                 totalPLByColumnResults[subIndex].clientShareVisibility = allRolesAreSeven;
  //                 totalPLByColumnResults[subIndex].name = bookData?.runnerName;
  //                 totalPLByColumnResults[subIndex].originalName = bookData?.originalName;
  //                 totalPLByColumnResults[subIndex].betFairShare += bfShare;
  //                 totalPLByColumnResults[subIndex].role = book?.userId?.role;
  //                 // }
  //                 // if (book?.userId?.role !== 7) {
  //                 //   totalPLByColumn[subIndex].clientShare += book?.userId?.share;
  //                 // } else {
  //                 //   totalPLByColumn[subIndex].clientShare += 0;
  //                 // }
  //                 totalPLByColumn[subIndex].pl = bookData?.pl;
  //                 totalPLByColumn[subIndex].role = book?.userId?.role;
  //                 totalPLByColumn[subIndex].name = bookData?.runnerName;
  //                 totalPLByColumn[subIndex].originalName = bookData?.originalName;
  //                 totalPLByColumn[subIndex].betFairShare += bfShare;

  //                 if (bookData?.pl > 0) {
  //                   totalPLByColumn[subIndex].actual_pl += -Math.abs(bookData?.pl);
  //                   totalPLByColumn[subIndex].total_pl += -valueToAdd;
  //                 } else {
  //                   totalPLByColumn[subIndex].actual_pl += Math.abs(bookData?.pl);
  //                   totalPLByColumn[subIndex].total_pl += valueToAdd;
  //                 }
  //               });
  //             });
  //             // console.log("totalPLByColumnResults...........", totalPLByColumnResults);
  //             setColumnTotalNewBet([...totalPLByColumnResults])
  //             // let data = findLineMarketBooks(
  //             //   lineMarketBets[market?._id],
  //             //   false,
  //             //   totalPLByColumnResults,
  //             //   books,
  //             //   sharedBooks,
  //             //   betFairBooks
  //             // )
  //             // setMarketBook(data);
  //             setBooks(totalPLByColumnResults);
  //           }
  //         },
  //       })
  //     );
  //     // calculateTotalPLByBetshare_shareBook_Column();
  //   });
  // }, [socket]);
  // console.log("totalPLByColumnResult",totalPLByColumnResult);
 
  useEffect(() => {
    if (currency !== "") {
      setIsColumnTotalNewBet(false);
    }


    if (bookviewData) {
      setColumnTotals([]);
      const totalPLByColumn = [];
      const totalPLByColumnResults = [];

      if (bookviewData?.books?.[0]?.booksData) {
        bookviewData.books[0].booksData.forEach(() => {
          totalPLByColumn.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, pl: 0, directParent: false, actual_pl: 0 });
          totalPLByColumnResults.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, pl: 0, directParent: false, actual_pl: 0 });
        });
      }

      const allRolesAreSeven = bookviewData?.books?.some(item => item.userId.role !== 7);

      bookviewData?.books?.forEach((book) => {
        book?.booksData?.forEach((bookData, subIndex) => {
          let valueToAdd = 0;
          let percentage = 0;
          let bfShare = 0;
          let total_pll = 0;
          let isClientShare = false;

          if (bookData?.betFairShare > 0) {
            bfShare = bookData?.betFairShare;
          } else {
            bfShare = -1 * bookData?.betFairShare;
          }

          if (bookData?.pl > 0) {
            total_pll = bookData?.pl;
          } else {
            total_pll = -1 * bookData?.pl;
          }

          if (book?.userId?.sportShares) {
            isClientShare = book?.userId?.sportShares !== 0;
          } else {
            isClientShare = book?.userId?.parents?.[0]
              ?.parent_id?.sportShares !== 0 && book?.userId?.parents?.[0]
                ?.parent_id?.sportShares !== undefined;
          }

          // if (betFairBooks && sharedBooks) {
          const sumOfShares =
            book.userId?.betFairShare + book.userId?.sportShares;
          if (isNaN(sumOfShares)) {
            percentage = total_pll - bfShare;
          } else {
            percentage = (total_pll * (100 - sumOfShares)) / 100;
          }
          valueToAdd = percentage;
          // }

          let client_shares = 0;
          if (isClientShare) {
            if (book?.userId?.sportShares) {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(

                  (bookData?.pl / 100) *
                  book?.userId?.sportShares
                  ,
                  adminData?.currencyId?.value, true
                )
              } else {
                client_shares =
                  (bookData?.pl / 100) *
                  book?.userId?.sportShares

              }
            } else {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(

                  (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]
                    ?.parent_id?.sportShares
                  ,
                  parseInt(
                    adminData?.currencyId?.value
                  ), true
                )
              } else {
                client_shares = (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]
                    ?.parent_id?.sportShares

              }
            }
          }
          bfShare = bookData?.betFairShare;

          if (book?.userId?.role !== 7) {
            totalPLByColumn[subIndex].clientShare += parseFloat(client_shares);
            totalPLByColumnResults[subIndex].clientShare += parseFloat(client_shares);
          } else {
            totalPLByColumn[subIndex].clientShare += 0;
            totalPLByColumnResults[subIndex].clientShare += 0;
          }

          totalPLByColumnResults[subIndex].directParent = !allRolesAreSeven;
          if (valueToAdd < 0) {
            valueToAdd = -1 * valueToAdd
          }
          if (bookData?.pl > 0) {
            totalPLByColumnResults[subIndex].actual_pl += -Math.abs(bookData?.pl);
            totalPLByColumnResults[subIndex].total_pl += -valueToAdd;
          } else {
            totalPLByColumnResults[subIndex].actual_pl += Math.abs(bookData?.pl);
            totalPLByColumnResults[subIndex].total_pl += valueToAdd;
          }

          totalPLByColumn[subIndex].pl = bookData?.pl;
          totalPLByColumnResults[subIndex].clientShareVisibility = allRolesAreSeven;
          totalPLByColumnResults[subIndex].name = bookData?.runnerName;
          totalPLByColumnResults[subIndex].originalName = bookData?.originalName;
          totalPLByColumnResults[subIndex].betFairShare += bfShare;
          totalPLByColumnResults[subIndex].role = book?.userId?.role;

          totalPLByColumn[subIndex].pl = bookData?.pl;
          totalPLByColumn[subIndex].role = book?.userId?.role;
          totalPLByColumn[subIndex].name = bookData?.runnerName;
          totalPLByColumn[subIndex].originalName = bookData?.originalName;
          totalPLByColumn[subIndex].betFairShare += bfShare;

          if (bookData?.pl > 0) {
            totalPLByColumn[subIndex].actual_pl += -Math.abs(bookData?.pl);
            totalPLByColumn[subIndex].total_pl += -valueToAdd;
          } else {
            totalPLByColumn[subIndex].actual_pl += Math.abs(bookData?.pl);
            totalPLByColumn[subIndex].total_pl += valueToAdd;
          }
        });
      });
      setColumnTotalNewBet([...totalPLByColumnResults])
      setBooks(totalPLByColumnResults);
    }
  }, [bookviewData]);

  useEffect(() => {
    if (isColumnTotalNewBet) {
      let data = findLineMarketBooks(
        lineMarketBets[market?._id],
        false,
        columnTotalNewBet,
        books,
        sharedBooks,
        betFairBooks
      )
      setMarketBook(data);
    } else {
      let data = findLineMarketBooks(
        lineMarketBets[market?._id],
        false,
        totalPLByColumnResult,
        books,
        sharedBooks,
        betFairBooks
      )
      setMarketBook(data);
    }
    if (currency !== "") {
      setIsColumnTotalNewBet(false);
    }
  }, [books, betFairBooks, sharedBooks]);
  // console.log("marketBook",marketBook);
  // console.log("totalPLByColumnResult",totalPLByColumnResult);
  useEffect(() => {
    if (sharedBooks === true && betFairBooks === true) {
      setColumnTotals(totalPLByColumnResult);
      const totalPLByColumn = [];
      totalPLByColumnResult?.forEach((bkData) => {
        // console.log("bkData",bkData);
        let data = {
          name: bkData?.name,
          pl_pr: 0,
          pl: bkData?.total_pl,
          clientShare: bkData?.clientShare,
          role: bkData?.role,
          directParent: bkData?.directParent,
          clientShareVisibility: bkData?.clientShareVisibility,
          count: bkData?.count,
          actual_pl: bkData?.actual_pl,
          individual_pl: bkData?.individual_pl,
          originalName: bkData?.originalName,
        };
        totalPLByColumn.push(data);
      });
      setBooks(totalPLByColumn);
    } else if (sharedBooks === false && betFairBooks === true) {
      setColumnTotals(totalPLByColumnResult);
      const totalPLByColumn = [];
      totalPLByColumnResult?.forEach((bkData) => {
        let data = {
          name: bkData?.name,
          pl_pr: 0,
          pl: bkData?.total_pl,
          clientShare: bkData?.clientShare,
          role: bkData?.role,
          directParent: bkData?.directParent,
          clientShareVisibility: bkData?.clientShareVisibility,
          count: bkData?.count,
          individual_pl: bkData?.individual_pl,
          actual_pl: bkData?.actual_pl,
          originalName: bkData?.originalName,
        };
        totalPLByColumn.push(data);
      });
      setBooks(totalPLByColumn);
    } else {
      const totalPLByColumn = [];
      runnerBook?.books?.[0]?.booksData?.forEach((bkData) => {
        // console.log("bkData",bkData);
        let data = {
          name: bkData?.runnerName,
          pl_pr: 0,
          pl: 0,
          clientShare: 0,
          role: 0,
          directParent: false,
          clientShareVisibility: false,
          actual_pl: 0,
          individual_pl: 0,
          isWinToss: bkData?.isWinToss,
          originalName: bkData?.originalName,
        };
        totalPLByColumn.push(data);
      });
      // const allRolesAreSeven = runnerBook?.books?.some(item => item.userId.role !== 7);
      const allRolesAreSeven = runnerBook?.books?.some(item => item.userId.role !== 7);
      // console.log("allRolesAreSeven", allRolesAreSeven);
      runnerBook?.books?.forEach((book) => {
        totalPLByColumn.forEach((plData, index) => {
          book?.booksData?.forEach((bookData, subIndex) => {
            let total_pll = 0;
            let client_shares = 0;
            // if (bookData?.pl > 0) {
            //   total_pll = bookData?.pl;
            // } else {
            //   total_pll = -1 * bookData?.pl;
            // }
            let isClientShare = false;

            if (book?.userId?.sportShares) {
              isClientShare = book?.userId?.sportShares !== 0;
            } else {
              isClientShare = book?.userId?.parents?.[0]
                ?.parent_id?.sportShares !== 0 && book?.userId?.parents?.[0]
                  ?.parent_id?.sportShares !== undefined;
            }

            if (isClientShare) {
              if (book?.userId?.sportShares) {
                if (adminData?.currencyId?.value) {
                  client_shares = convertINRToCurrency(
                    (bookData?.pl / 100) *
                    book?.userId?.sportShares
                    ,
                    adminData?.currencyId?.value, true
                  )
                } else {
                  client_shares =
                    (bookData?.pl / 100) *
                    book?.userId?.sportShares

                }
              } else {
                if (adminData?.currencyId?.value) {
                  client_shares = convertINRToCurrency(

                    (bookData?.pl / 100) *
                    book?.userId?.parents?.[0]
                      ?.parent_id?.sportShares
                    ,
                    parseInt(
                      adminData?.currencyId?.value
                    ), true
                  )
                } else {
                  client_shares = (bookData?.pl / 100) *
                    book?.userId?.parents?.[0]
                      ?.parent_id?.sportShares

                }
              }
            }

            // if (client_shares > 0) {
            //   client_shares = client_shares;
            // } else {
            //   client_shares = -1 * client_shares;
            // }

            total_pll = bookData?.pl;
            // if (bookData?.pl < 0) {
            //   total_pll = -1 * bookData?.pl;
            // }
            if (String(bookData?.runnerName) === String(plData?.name)) {
              plData.individual_pl = bookData?.pl
              plData.pl += !betFairBooks
                ? sharedBooks && bookData?.p_r
                  ? bookData?.p_r || 0
                  : total_pll || 0
                : bookData?.betFairShare
                  ? bookData?.betFairShare
                  : 0;

              if (bookData?.pl > 0) {
                plData.actual_pl += -Math.abs(bookData?.pl);
              } else {
                plData.actual_pl += Math.abs(bookData?.pl);
              }

              if (book?.userId?.role !== 7) {
                plData.clientShare += parseFloat(client_shares);
              } else {
                plData.clientShare += 0;
              }

              // console.log({clientShare:plData.clientShare,actual_pl:plData.actual_pl,betFairShare: bookData?.betFairShare});
              plData.role = book?.userId?.role;
              plData.clientShareVisibility = allRolesAreSeven;
              // if (book?.userId?.role !== 7) {
              //   plData.directParent = false;
              // } else {
              //   plData.directParent = true;
              // }
              plData.directParent = !allRolesAreSeven;

            }
          });
        });
      });
      setBooks(totalPLByColumn);
    }
    if (currency !== "") {
      setIsColumnTotalNewBet(false);
    }
  }, [runnerBook, sharedBooks, betFairBooks, totalPLByColumnResult, columnTotals, isColumnTotalNewBetCurrency]);

  const onChangeCallApiCall = () => {
    let type = "exchange";
    dispatch(
      getBetsAndBooks({
        eventId: eventId,
        currencyId,
        deleted: false,
        type,
        callback: (data) => {
          let book_data = data?.data?.books[marketId];
          // console.log("book_data", book_data);
          if (book_data) {
            setColumnTotals([]);
            const totalPLByColumn = [];
            const totalPLByColumnResults = [];

            if (book_data?.books?.[0]?.booksData) {
              book_data.books[0].booksData.forEach(() => {
                totalPLByColumn.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, pl: 0, directParent: false, actual_pl: 0 });
                totalPLByColumnResults.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, pl: 0, directParent: false, actual_pl: 0 });
              });
            }

            const allRolesAreSeven = book_data?.books?.some(item => item.userId.role !== 7);

            book_data?.books?.forEach((book) => {
              book?.booksData?.forEach((bookData, subIndex) => {
                let valueToAdd = 0;
                let percentage = 0;
                let bfShare = 0;
                let total_pll = 0;
                let isClientShare = false;

                if (bookData?.betFairShare > 0) {
                  bfShare = bookData?.betFairShare;
                } else {
                  bfShare = -1 * bookData?.betFairShare;
                }

                if (bookData?.pl > 0) {
                  total_pll = bookData?.pl;
                } else {
                  total_pll = -1 * bookData?.pl;
                }

                if (book?.userId?.sportShares) {
                  isClientShare = book?.userId?.sportShares !== 0;
                } else {
                  isClientShare = book?.userId?.parents?.[0]
                    ?.parent_id?.sportShares !== 0 && book?.userId?.parents?.[0]
                      ?.parent_id?.sportShares !== undefined;
                }

                // if (betFairBooks && sharedBooks) {
                const sumOfShares =
                  book.userId?.betFairShare + book.userId?.sportShares;
                if (isNaN(sumOfShares)) {
                  percentage = total_pll - bfShare;
                } else {
                  percentage = (total_pll * (100 - sumOfShares)) / 100;
                }
                valueToAdd = percentage;
                // }

                let client_shares = 0;
                if (isClientShare) {
                  if (book?.userId?.sportShares) {
                    if (adminData?.currencyId?.value) {
                      client_shares = convertINRToCurrency(

                        (bookData?.pl / 100) *
                        book?.userId?.sportShares
                        ,
                        adminData?.currencyId?.value, true
                      )
                    } else {
                      client_shares =
                        (bookData?.pl / 100) *
                        book?.userId?.sportShares

                    }
                  } else {
                    if (adminData?.currencyId?.value) {
                      client_shares = convertINRToCurrency(

                        (bookData?.pl / 100) *
                        book?.userId?.parents?.[0]
                          ?.parent_id?.sportShares
                        ,
                        parseInt(
                          adminData?.currencyId?.value
                        ), true
                      )
                    } else {
                      client_shares = (bookData?.pl / 100) *
                        book?.userId?.parents?.[0]
                          ?.parent_id?.sportShares

                    }
                  }
                }

                // if (client_shares > 0) {
                //   client_shares = client_shares;
                // } else {
                //   client_shares = -1 * client_shares;
                // }
                bfShare = bookData?.betFairShare;


                // console.log("book...........", book);
                if (book?.userId?.role !== 7) {
                  // console.log("llllllllllllllll");
                  totalPLByColumn[subIndex].clientShare += parseFloat(client_shares);
                  totalPLByColumnResults[subIndex].clientShare += parseFloat(client_shares);
                  // totalPLByColumnResults[subIndex].directParent = false;
                } else {
                  totalPLByColumn[subIndex].clientShare += 0;
                  totalPLByColumnResults[subIndex].clientShare += 0;
                  // totalPLByColumnResults[subIndex].directParent = true;
                }

                totalPLByColumnResults[subIndex].directParent = !allRolesAreSeven;
                // if (eventNameArray?.includes(bookData?.name)) {
                if (valueToAdd < 0) {
                  valueToAdd = -1 * valueToAdd
                }
                if (bookData?.pl > 0) {
                  totalPLByColumnResults[subIndex].actual_pl += -Math.abs(bookData?.pl);
                  totalPLByColumnResults[subIndex].total_pl += -valueToAdd;
                } else {
                  totalPLByColumnResults[subIndex].actual_pl += Math.abs(bookData?.pl);
                  totalPLByColumnResults[subIndex].total_pl += valueToAdd;
                }

                totalPLByColumn[subIndex].pl = bookData?.pl;
                totalPLByColumnResults[subIndex].clientShareVisibility = allRolesAreSeven;
                totalPLByColumnResults[subIndex].name = bookData?.runnerName;
                totalPLByColumnResults[subIndex].originalName = bookData?.originalName;
                totalPLByColumnResults[subIndex].betFairShare += bfShare;
                totalPLByColumnResults[subIndex].role = book?.userId?.role;
                // }
                // if (book?.userId?.role !== 7) {
                //   totalPLByColumn[subIndex].clientShare += book?.userId?.share;
                // } else {
                //   totalPLByColumn[subIndex].clientShare += 0;
                // }
                totalPLByColumn[subIndex].pl = bookData?.pl;
                totalPLByColumn[subIndex].role = book?.userId?.role;
                totalPLByColumn[subIndex].name = bookData?.runnerName;
                totalPLByColumn[subIndex].originalName = bookData?.originalName;
                totalPLByColumn[subIndex].betFairShare += bfShare;

                if (bookData?.pl > 0) {
                  totalPLByColumn[subIndex].actual_pl += -Math.abs(bookData?.pl);
                  totalPLByColumn[subIndex].total_pl += -valueToAdd;
                } else {
                  totalPLByColumn[subIndex].actual_pl += Math.abs(bookData?.pl);
                  totalPLByColumn[subIndex].total_pl += valueToAdd;
                }
              });
            });

            setColumnTotalNewBetCurrency([...totalPLByColumnResults])
            setBooks(totalPLByColumnResults);
          }
        },
      })
    );
  }

  const calculateTotalPLByBetshare_shareBook_Column = () => {
    setColumnTotals([]);
    const totalPLByColumn = [];
    const totalPLByColumnResults = [];
    if (runnerBookRefresh[marketId] !== undefined) {
      if (runnerBookRefresh[marketId]?.books?.[0]?.booksData) {
        runnerBookRefresh[marketId].books[0].booksData.forEach(() => {
          totalPLByColumn.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, individual_pl: 0, actual_pl: 0, clientShareVisibility: false });
          totalPLByColumnResults.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, individual_pl: 0, actual_pl: 0, clientShareVisibility: false });
        });
      }

      const allRolesAreSeven = runnerBookRefresh[marketId]?.books?.some(item => item.userId.role !== 7);

      runnerBookRefresh[marketId]?.books?.forEach((book) => {
        book?.booksData?.forEach((bookData, subIndex) => {
          let valueToAdd = 0;
          let percentage = 0;
          let bfShare = 0;
          let total_pll = 0;
          let isClientShare = false;

          if (bookData?.betFairShare > 0) {
            bfShare = bookData?.betFairShare;
          } else {
            bfShare = -1 * bookData?.betFairShare;
          }

          if (bookData?.pl > 0) {
            total_pll = bookData?.pl;
          } else {
            total_pll = -1 * bookData?.pl;
          }

          if (book?.userId?.sportShares) {
            isClientShare = book?.userId?.sportShares !== 0;
          } else {
            isClientShare = book?.userId?.parents?.[0]
              ?.parent_id?.sportShares !== 0 && book?.userId?.parents?.[0]
                ?.parent_id?.sportShares !== undefined;
          }

          // if (betFairBooks && sharedBooks) {
          const sumOfShares =
            book.userId?.betFairShare + book.userId?.sportShares;
          if (isNaN(sumOfShares)) {
            percentage = total_pll - bfShare;
          } else {
            percentage = (total_pll * (100 - sumOfShares)) / 100;
          }
          valueToAdd = percentage;
          // }

          let client_shares = 0;
          if (isClientShare) {
            if (book?.userId?.sportShares) {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(

                  (bookData?.pl / 100) *
                  book?.userId?.sportShares
                  ,
                  adminData?.currencyId?.value, true
                )
              } else {
                client_shares =
                  (bookData?.pl / 100) *
                  book?.userId?.sportShares
              }
            } else {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(

                  (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]
                    ?.parent_id?.sportShares
                  ,
                  parseInt(
                    adminData?.currencyId?.value
                  ), true
                )
              } else {
                client_shares = (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]
                    ?.parent_id?.sportShares

              }
            }
          }
          // if (client_shares > 0) {
          //   client_shares = client_shares;
          // } else {
          //   client_shares = -1 * client_shares;
          // }
          bfShare = bookData?.betFairShare;
          if (book?.userId?.role !== 7) {
            totalPLByColumn[subIndex].clientShare += parseFloat(client_shares);
            totalPLByColumnResults[subIndex].clientShare += parseFloat(client_shares);
            totalPLByColumnResults[subIndex].directParent = false;
          } else {
            totalPLByColumn[subIndex].clientShare += 0;
            totalPLByColumnResults[subIndex].clientShare += 0;
            totalPLByColumnResults[subIndex].directParent = true;
          }


          if (valueToAdd < 0) {
            valueToAdd = -1 * valueToAdd
          }
          // if (eventNameArray?.includes(bookData?.name)) {
          if (bookData?.pl > 0) {
            totalPLByColumnResults[subIndex].actual_pl += -Math.abs(bookData?.pl);
            totalPLByColumnResults[subIndex].total_pl += -valueToAdd;
          } else {
            totalPLByColumnResults[subIndex].actual_pl += Math.abs(bookData?.pl);
            totalPLByColumnResults[subIndex].total_pl += valueToAdd;
          }

          totalPLByColumnResults[subIndex].originalName = bookData?.originalName;
          totalPLByColumnResults[subIndex].individual_pl = bookData?.pl;
          totalPLByColumnResults[subIndex].clientShareVisibility = allRolesAreSeven;
          totalPLByColumnResults[subIndex].name = bookData?.runnerName;
          totalPLByColumnResults[subIndex].betFairShare += bfShare;
          totalPLByColumnResults[subIndex].role = book?.userId?.role;
          // }
          // if (book?.userId?.role !== 7) {
          //   totalPLByColumn[subIndex].clientShare += book?.userId?.share;
          // } else {
          //   totalPLByColumn[subIndex].clientShare += 0;
          // }
          totalPLByColumn[subIndex].individual_pl = bookData?.pl;
          totalPLByColumn[subIndex].clientShareVisibility = allRolesAreSeven;
          totalPLByColumn[subIndex].role = book?.userId?.role;
          totalPLByColumn[subIndex].name = bookData?.runnerName;
          totalPLByColumn[subIndex].originalName = bookData?.originalName;
          totalPLByColumn[subIndex].betFairShare += bfShare;

          if (bookData?.pl > 0) {
            totalPLByColumn[subIndex].actual_pl += -Math.abs(bookData?.pl);
            totalPLByColumn[subIndex].total_pl += -valueToAdd;
          } else {
            totalPLByColumn[subIndex].actual_pl += Math.abs(bookData?.pl);
            totalPLByColumn[subIndex].total_pl += valueToAdd;
          }
        });
      });
      setColumnTotals([...totalPLByColumnResults])
    }
  };

  // console.log("columnTotalNewBet", columnTotalNewBet);
  // console.log("books", books);
  // console.log("runnerData", runnerData);
  // console.log("columnTotals", columnTotals);
  // console.log("isColumnTotalNewBet", isColumnTotalNewBet);
  // console.log("isColumnTotalNewBet", isColumnTotalNewBet);
  // console.log("isColumnTotalNewBetCurrency", isColumnTotalNewBetCurrency);
  // console.log("columnTotalNewBetCurrency", columnTotalNewBetCurrency);

  // console.log("marketBook",marketBook);
  return (
    <>
      {!["2378961", "7"]?.includes(sportCode) && runnerData?.length && runnerData?.map((runner, index) => {
        let currentMarket = findRunnerOdds(odds, runner?.selectionId);
        //Manipulating each array with 3 elements always if not
        let backArray = Array.from({ length: 3 }).map(
          (_, index) => currentMarket?.ex?.availableToBack[index] || null
        );

        let layArray = Array.from({ length: 3 }).map(
          (_, index) => currentMarket?.ex?.availableToLay[index] || null
        );

        return (
          <React.Fragment key={index}>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <Col>
                  {runner?.name === "Total Runs" ? <p className="runner-name">{runner?.name}</p> : <p className="runner-name">{runner?.runnerName}</p>}
                  {books?.map((book, i) => {
                    // { console.log("isRunner", isRunner) }
                    let isBook = false;
                    // if ((runner?.originalName === book?.originalName) && (book?.pl !== 0)) {
                    //   isBook = true;
                    // }else if(){

                    // }
                    if ((runner?.originalName === book?.originalName) && (book?.pl !== 0)) {
                      // if ((runner?.runnerName === book?.name) && (book?.pl !== 0)) {
                      // { console.log({ runner: runner?.originalName, book: book?.originalName }) }
                      // { console.log({ runner1: runner?.runnerName, book1: book?.name }) }
                      // { console.log({ runner: runner?.runnerName, book: book?.name }) }
                      // { console.log("columnTotalNewBet[i]?.total_pl in", columnTotalNewBet[i]) }
                      // { console.log("columnTotalNewBet[i]?.total_pl runnerData", columnTotalNewBet[index]) }
                      let actualPl = isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.originalName === runner?.originalName)?.actual_pl : isColumnTotalNewBetCurrency ? columnTotalNewBetCurrency?.find(element => element?.originalName === runner?.originalName)?.actual_pl : columnTotals?.find(element => element?.originalName === runner?.originalName)?.actual_pl;
                      let pl = currency ? (
                        convertINRToCurrency(
                          actualPl,
                          currency?.value?.value, true
                        )
                      ) : book?.actual_pl ? (
                        actualPl
                      ) : (
                        0
                      )
                      pl = parseFloat(pl);

                      let totalPl = currency ? (
                        convertINRToCurrency(
                          isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.originalName === runner?.originalName)?.actual_pl : isColumnTotalNewBetCurrency ? columnTotalNewBetCurrency?.find(element => element?.originalName === runner?.originalName)?.actual_pl : book.actual_pl,
                          currency?.value?.value, true
                        )
                      ) : book?.actual_pl ? (
                        isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.originalName === runner?.originalName)?.actual_pl : isColumnTotalNewBetCurrency ? columnTotalNewBetCurrency?.find(element => element?.originalName === runner?.originalName)?.actual_pl : book.actual_pl
                      ) : (
                        0
                      )
                      totalPl = parseFloat(totalPl);

                      let bf = currency
                        ? convertINRToCurrency(
                          isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.originalName === runner?.originalName)?.betFairShare : isColumnTotalNewBetCurrency ? columnTotalNewBetCurrency?.find(element => element?.originalName === runner?.originalName)?.betFairShare : columnTotals?.find(element => element?.originalName === runner?.originalName)?.betFairShare,
                          parseFloat(currency?.value?.value), true
                        )
                        : isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.originalName === runner?.originalName)?.betFairShare : isColumnTotalNewBetCurrency ? columnTotalNewBetCurrency?.find(element => element?.originalName === runner?.originalName)?.betFairShare : columnTotals?.find(element => element?.originalName === runner?.originalName)?.betFairShare;
                      bf = parseFloat(bf);

                      let client_share = isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.originalName === runner?.originalName)?.clientShare : isColumnTotalNewBetCurrency ? columnTotalNewBetCurrency?.find(element => element?.originalName === runner?.originalName)?.clientShare : columnTotals?.find(element => element?.originalName === runner?.originalName)?.clientShare;

                      let cs = currency
                        ? convertINRToCurrency(
                          client_share,
                          parseFloat(currency?.value?.value), true
                        )
                        : client_share;
                      cs = parseFloat(cs);

                      let finalPl = 0;
                      // let plCal = (Math.abs(pl) - (Math.abs(bf) + Math.abs(cs)));
                      // if (Math.abs(pl) < (Math.abs(bf) + Math.abs(cs))) {
                      //   plCal = (Math.abs(pl) - (Math.abs(bf) + Math.abs(cs)));
                      // } else {
                      //   plCal = (Math.abs(pl) - (Math.abs(bf) + Math.abs(cs)));

                      //   if (actualPl < 0) {
                      //     plCal = -1 * Math.abs(plCal);
                      //   } else {
                      //     plCal = plCal
                      //   }
                      // }
                      let bfCal = 0;
                      let csCal = 0;

                      if (bf < 0) {
                        bfCal = -1 * bf;
                      } else {
                        bfCal = -bf;
                      }

                      if (cs < 0) {
                        csCal = -1 * cs;
                      } else {
                        csCal = -cs;
                      }

                      // let totalShare = csCal + bfCal;
                      // if (totalShare >= pl) {
                      //   finalPl = totalShare - Math.abs(pl);
                      // } else {
                      //   finalPl = pl - totalShare;
                      // }

                      finalPl = pl - (csCal) - (bfCal);
                      // finalPl = handlePlAsPerBfAndCs(actualPl, bfCal, csCal, bf, cs);
                      // console.log("matchodd");
                      // console.log({cs:csCal,bf:bfCal});
                      // console.log({ actualPl: actualPl, totalPl: totalPl, plCal: plCal, pl: pl, bf: bf, cs: cs });
                      // console.log("actualPl",actualPl);
                      // console.log({ actualPl: actualPl,finalPl: finalPl, bfCal: bfCal, csCal: csCal, bf: bf, cs: cs });
                      // if (actualPl < 0) {
                      //   finalPl = Math.abs(plCal);
                      // } else {
                      //   finalPl = -1 * Math.abs(plCal);
                      // }
                      // console.log("totalPl", totalPl);
                      // console.log("plCal", plCal);
                      // console.log({ actualPl: actualPl, bf: bf, cs: cs });
                      return (
                        <span
                          key={i}
                        >
                          {/* <p className={`mr-2 mb-1 ${betFairBooks ? book?.pl > 0 ? "runner-p" : "runner-l" : sharedBooks ? book?.pl > 0 ? "runner-l" : "runner-p" : book?.pl > 0 ? "runner-l" : "runner-p"
                            }`}>
                            {(sharedBooks && betFairBooks) ? (
                              <>
                                {book?.pl > 0 ? "" : "-"}
                                {currency
                                  ? convertINRToCurrency(
                                    Math.abs(isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.name === runner?.runnerName)?.total_pl?.toFixed(2) : columnTotals[i]?.total_pl?.toFixed(2)),
                                    currency?.value?.value
                                  )
                                  : columnTotals[i]
                                    ? Math.abs(isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.name === runner?.runnerName)?.total_pl?.toFixed(2) : columnTotals[i]?.total_pl?.toFixed(2))
                                    : "No data"}
                              </>
                            ) : (!sharedBooks && betFairBooks) ? (
                              <>
                                {book?.individual_pl > 0 ? "-" : ""}
                                {currency
                                  ? convertINRToCurrency(
                                    Math.abs(isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.name === runner?.runnerName)?.total_pl?.toFixed(2) : columnTotals[i]?.total_pl?.toFixed(2)),
                                    currency?.value?.value
                                  )
                                  : columnTotals[i]
                                    ? Math.abs(isColumnTotalNewBet ? columnTotalNewBet?.find(element => element?.name === runner?.runnerName)?.total_pl?.toFixed(2) : columnTotals[i]?.total_pl?.toFixed(2))
                                    : "No data"}
                              </>
                            ) : <>
                              {book?.pl > 0 ? "-" : ""}
                              {currency ? (
                                convertINRToCurrency(
                                  Math.abs(book?.pl || 225),
                                  currency?.value?.value
                                )
                              ) : book?.pl ? (
                                Math.abs(book.pl).toFixed(2)
                              ) : (
                                "No data"
                              )}
                            </>}
                          </p> */}
                          {/* {console.log("book?.actual_pl", totalPl)} */}
                          <p className={`mr-2 mb-1 bg-amber-300 p-1 ${finalPl < 0 ? "runner-l" : "runner-p"
                            }`}>
                            {/* {book?.actual_pl > 0 ? "-" : ""} */}
                            {/* {betFairBooks ? book?.pl > 0 ? "" : "-" : sharedBooks ? book?.pl > 0 ? "-" : "" : book?.pl > 0 ? "-" : ""} */}
                            {/* {console.log("book?.actual_pl",(Math.abs(pl) - (Math.abs(bf) + Math.abs(cs)))?.toFixed(2))} */}
                            {finalPl < 0 ? "-" : ""}
                            {isNaN(finalPl) ? "loading" : Math.abs(finalPl)?.toFixed(2)}
                          </p>
                          <span className={`m-0 ${totalPl < 0 ? "runner-l" : "runner-p"
                            }`}>
                            {/* {console.log("book?.actual_pl",book?.actual_pl)} */}
                            [PL:
                            {/* {betFairBooks ? book?.pl > 0 ? "" : "-" : sharedBooks ? book?.pl > 0 ? "-" : "" : book?.pl > 0 ? "-" : ""} */}
                            {totalPl < 0 ? "-" : ""}
                            {isNaN(totalPl) ? "loading" : Math.abs(totalPl)?.toFixed(2)}
                            ]
                          </span>

                          {/* {betFairBooks && <p className="m-0">[{<>BS:
                            {currency
                              ? convertINRToCurrency(
                                Math.abs(columnTotal[i]?.betFairShare),
                                parseFloat(currency?.value?.value)
                              )
                              : Math.abs(columnTotal[i]?.betFairShare).toFixed(2)}
                          </>}]</p>} */}
                          {/* {console.log("columnTotalNewBet[i]?.betFairShare",columnTotalNewBet[i]?.betFairShare)} */}
                          {[layerRoles.DIRECTOR, ManagerRoles.MANAGER]?.includes(adminData?.role) && <p className={`m-0 ${bf < 0
                            ? "runner-p"
                            : "runner-l"}`}>[{<>BS:
                              {bf < 0 ? "" : "-"}
                              {isNaN(bf) ? "loading" : Math.abs(bf?.toFixed(2))}
                            </>}]</p>}
                          {/* {(sharedBooks && book?.role != 7) && <p>[{<>CS:
                            {currency
                              ? convertINRToCurrency(
                                (
                                  book?.clientShare),
                                parseFloat(currency?.value?.value)
                              )
                              : (
                                book?.clientShare).toFixed(2)}
                          </>}]</p>} */}
                          {(book?.clientShareVisibility) && <p className={`m-0 ${cs <= 0
                            ? "runner-p"
                            : "runner-l"}`}>[{<>CS:
                              {cs <= 0 ? "" : "-"}
                              {isNaN(cs) ? "loading" : Math.abs(cs?.toFixed(2))}
                            </>}]</p>}
                        </span>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Col>
              </div>

              {currentMarket !== undefined ? (
                <>
                  {currentMarket?.ex?.availableToBack?.length !== 0 ? (
                    <div className="d-flex">
                      {/* Lay Array */}
                      <Col
                        className={`px-1 flex-grow-1 text-black ${layArray[0]?.price ? 'block' : 'hidden xl:block'
                          }`}
                      >
                        <div className="rounded flex-grow-1 my-1 runner-back">
                          <p className="runner-price">
                            {layArray[0]?.price
                              ? line
                                ? Math.round(layArray[0]?.price)
                                : layArray[0]?.price
                              : '-'}
                          </p>
                          <p className="runner-size text-nowrap">
                            {layArray[0]?.size ? layArray[0]?.size : '-'}
                          </p>
                        </div>
                      </Col>

                      {/* Back Array */}
                      <Col
                        className={`px-1 flex-grow-1 text-black ${backArray[0]?.price ? 'block' : 'hidden xl:block'
                          }`}
                      >
                        <div className="rounded flex-grow-1 my-1 runner-lay">
                          <div>
                            <p className="runner-price">
                              {backArray[0]?.price
                                ? line
                                  ? Math.round(backArray[0]?.price)
                                  : backArray[0]?.price
                                : '-'}
                            </p>
                            <p className="runner-size text-nowrap">
                              {backArray[0]?.size ? backArray[0]?.size : '-'}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </div>
                  ) : (
                    <EmptyRunners oddCount={6} empty={true} />
                  )}
                </>
              ) : (
                <EmptyRunners oddCount={6} empty={true} />
              )}
            </div>
            {index < runners.length - 1 && (
              <hr className="my-0" style={{ borderTop: '1px solid #ccc' }} />
            )}{' '}
            {/* Render hr for all but the last row */}
          </React.Fragment>

        );
      })}
      <div className="overflow-auto pt-1">
        <table className="table table-bordered table-striped table-responsive">
          <thead className="table-light">
            <tr className="text-center">
              {marketBook !== undefined &&
                Object?.values(marketBook)?.map((val, index) => (
                  <th key={index} scope="col" className="text-uppercase">
                    {val?.odds}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              {marketBook !== undefined &&
                marketBook?.map((item, index) => (
                  <Fragment key={index}>
                    <td
                      className={`fw-bold`}
                    >
                      <p className={`${(-1 * item?.result) < 0 ? "text-danger" : "text-success"
                        }`}> {(-1 * item?.result)?.toFixed(2)}</p>
                      {<p className={``}>[PL : {(-1 * item?.pl)?.toFixed(2)}]</p>}
                      {(betFairBooks && !sharedBooks) && <p className={``}>[BS : {item?.betFairShare > 0 ? "-" : ""}{Math.abs((item?.betFairShare)?.toFixed(2))}]</p>}
                      {(sharedBooks && !betFairBooks) && (item?.clientShareVisibility) && <p className={``}>[CS : {item?.clientShare > 0 ? "-" : ""}{Math.abs((item?.clientShare)?.toFixed(2))}]</p>}
                      {(betFairBooks && sharedBooks) && <>
                        <p className={``}>[BS : {item?.betFairShare > 0 ? "-" : ""}{Math.abs((item?.betFairShare)?.toFixed(2))}]</p>
                        {item?.clientShareVisibility && <p className={``}>[CS : {item?.clientShare > 0 ? "-" : ""}{Math.abs((item?.clientShare)?.toFixed(2))}]</p>}
                      </>}
                    </td>
                  </Fragment>
                ))}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MatchOddsCard;
