import { POST_STX_MARKET_ASSIGNING, POST_STX_MARKET_ASSIGNING_FAILURE, POST_STX_MARKET_ASSIGNING_SUCCESS } from "../types";

export const stxMarketAssigning = (payload) => ({
  type: POST_STX_MARKET_ASSIGNING,
  payload,
});

export const stxMarketAssigningSuccess = (payload) => ({
  type: POST_STX_MARKET_ASSIGNING_SUCCESS,
  payload,
});

export const stxMarketAssigningFailure = () => ({
  type: POST_STX_MARKET_ASSIGNING_FAILURE,
})