// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
  height: 100vh; /* Set the container height to fill the viewport */
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.upload-container {
  max-width: 400px;
  margin: 0 auto;
}

.upload-animation {
  width: 100%;
  height: 5px;
  background-color: #007bff; /* Blue color */
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.upload-animation::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Gray color with some transparency */
  position: absolute;
  animation: progressAnimation 3s linear infinite;
}

@keyframes progressAnimation {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/PageLayout/APKComponent/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,kDAAkD;AACnE;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB,EAAE,eAAe;EAC1C,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,sCAAsC;EAC5E,kBAAkB;EAClB,+CAA+C;AACjD;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".upload-container {\n  height: 100vh; /* Set the container height to fill the viewport */\n}\n\n.d-flex {\n  display: flex;\n}\n\n.align-items-center {\n  align-items: center;\n}\n\n.justify-content-center {\n  justify-content: center;\n}\n\n.upload-container {\n  max-width: 400px;\n  margin: 0 auto;\n}\n\n.upload-animation {\n  width: 100%;\n  height: 5px;\n  background-color: #007bff; /* Blue color */\n  margin-top: 10px;\n  position: relative;\n  overflow: hidden;\n}\n\n.upload-animation::after {\n  content: \"\";\n  display: block;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.1); /* Gray color with some transparency */\n  position: absolute;\n  animation: progressAnimation 3s linear infinite;\n}\n\n@keyframes progressAnimation {\n  0% {\n    left: -100%;\n  }\n  100% {\n    left: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
