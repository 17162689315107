import axios from "axios";
import { betxExchangeUrl, x_auth_key } from "./helper";

const api = axios.create({
  baseURL: betxExchangeUrl,
});

api.interceptors.request.use(
  function (config) {
    config.headers['x-auth-key'] = x_auth_key; 
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  function (error) {
    console.log(error, "error");
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default api;
