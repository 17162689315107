import {
  STX_ACCOUNT_DETAILS,
  STX_ACCOUNT_DETAILS__FAILURE,
  STX_ACCOUNT_DETAILS__SUCCESS,
  STX_ACCOUNT_DETAILS_SOCKET_DATA,
} from "../../action/types";

const INIT_STATE = {
  loading: false,
  data: null,
};

const getStxAccountDetails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STX_ACCOUNT_DETAILS:
      return { ...state, loading: true };
    case STX_ACCOUNT_DETAILS__SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case STX_ACCOUNT_DETAILS_SOCKET_DATA:
      return { ...state, data: action.payload, loading: false };
    case STX_ACCOUNT_DETAILS__FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default getStxAccountDetails;
