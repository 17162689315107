import React, { Fragment, useEffect, useState, memo } from "react";
import { Card, CardBody, CardHeader, Col, Collapse } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { ManagerRoles, layerRoles } from "../../../../Constant";
import { convertINRToCurrency } from "../../../../utils/helper";
import { handlePlAsPerBf, handlePlAsPerBfAndCs, handlePlAsPerCs } from "../../../../utils/book";
import { useSelector } from "react-redux";

const BookView = memo(
  ({
    eventName,
    marketId,
    runnerBook,
    sharedBooks,
    setSharedBooks,
    setBetFairBooks,
    betFairBooks,
    currency,
    setTotalPLByColumnResult,
    setTotalPLCalculation,
    setMatchOddsCaculations,
    stx
  }) => {
    // console.log("runnerBook?.books bookview",runnerBook[marketId]);
    const adminData = useSelector((state) => state.Login.userData);
    const { t } = useTranslation();
    const [openLive, setOpenLive] = useState(false);
    const [bookviewData, setBookViewData] = useState([]);
    const [pl, setpl] = useState([]);

    useEffect(() => {
      setBookViewData(runnerBook[marketId]);
    }, [marketId, runnerBook]);

    const toggleLiveAccordion = () => {
      if (adminData?.role === 1) {
        setSharedBooks(!sharedBooks);
        setBetFairBooks(!betFairBooks);
        setOpenLive(!openLive);
      } else {
        setOpenLive(!openLive);
      }
    };

    if (!openLive) {
      if (sharedBooks || betFairBooks) {
        setOpenLive(!openLive);
        setOpenLive(true);
      }
    }

    // // Calculate the sum of PL for each column
    const calculateTotalPLByColumn = () => {
      const totalPLByColumnResult = [];
      const totalPLByColumn = [];

      bookviewData?.books?.[0]?.booksData?.forEach(() => {
        totalPLByColumn.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, pl: 0, individual_pl: 0, actual_pl: 0, clientShareVisibility: false, directParent: false });
        totalPLByColumnResult.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, actual_pl: 0, individual_pl: 0, clientShareVisibility: false, directParent: false });
      });

      const allRolesAreSeven = bookviewData?.books?.some(item => item.userId.role !== 7);
      // console.log("allRolesAreSeven", allRolesAreSeven);
      bookviewData?.books?.forEach((book) => {
        book?.booksData?.forEach((bookData, subIndex) => {
          let client_shares = 0;
          let bfShare = 0;
          let total_pll = 0;
          let isClientShare = false;

          if (book?.userId?.sportShares) {
            isClientShare = book?.userId?.sportShares !== 0;
          } else {
            isClientShare = book?.userId?.parents?.[0]
              ?.parent_id?.sportShares !== 0 && book?.userId?.parents?.[0]
                ?.parent_id?.sportShares !== undefined;
          }

          if (isClientShare) {
            if (book?.userId?.sportShares) {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(

                  (bookData?.pl / 100) *
                  book?.userId?.sportShares
                  ,
                  adminData?.currencyId?.value, true
                )

              } else {
                client_shares =
                  (bookData?.pl / 100) *
                  book?.userId?.sportShares
              }
            } else {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(

                  (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]
                    ?.parent_id?.sportShares
                  ,
                  parseInt(
                    adminData?.currencyId?.value
                  ), true
                )

              } else {
                client_shares = (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]
                    ?.parent_id?.sportShares
              }
            }
          }

          // if (client_shares > 0) {
          //   client_shares = client_shares;
          // } else {
          //   client_shares = -1 * client_shares;
          // }

          if (book?.userId?.role !== 7) {
            totalPLByColumn[subIndex].clientShare += parseFloat(client_shares);
          } else {
            totalPLByColumn[subIndex].clientShare += 0;
          }
          totalPLByColumnResult[subIndex].directParent = !allRolesAreSeven;
          totalPLByColumn[subIndex].directParent = !allRolesAreSeven;

          // if (bookData?.betFairShare > 0) {
          //   bfShare = bookData?.betFairShare;
          // } else {
          //   bfShare = -1 * bookData?.betFairShare;
          // }
          bfShare = bookData?.betFairShare;
          totalPLByColumnResult[subIndex].betFairShare += bfShare;
          // if (bookData?.pl > 0) {
          //   total_pll = bookData?.pl;
          // } else {
          //   total_pll = -1 * bookData?.pl;
          // }
          total_pll = bookData?.pl;
          // if (book?.userId?.role !== 7) {
          //   totalPLByColumn[subIndex].clientShare += book?.userId?.share;
          // } else {
          //   totalPLByColumn[subIndex].clientShare += 0;
          // }
          // console.log("total_pll",total_pll);

          totalPLByColumn[subIndex].clientShareVisibility = allRolesAreSeven;
          totalPLByColumn[subIndex].individual_pl = bookData?.pl;
          totalPLByColumn[subIndex].role = book?.userId?.role;
          totalPLByColumn[subIndex].name = bookData?.name;
          totalPLByColumn[subIndex].originalName = bookData?.originalName;
          totalPLByColumn[subIndex].betFairShare += bfShare;
          totalPLByColumn[subIndex].total_pl += !betFairBooks
            ? sharedBooks && bookData?.p_r
              ? bookData?.p_r || 0
              : total_pll || 0
            : bfShare
              ? bfShare
              : 0;
          if (bookData?.pl > 0) {
            totalPLByColumn[subIndex].actual_pl += -Math.abs(bookData?.pl);
          } else {
            totalPLByColumn[subIndex].actual_pl += Math.abs(bookData?.pl);
          }

          totalPLByColumnResult[subIndex].total_pl += !betFairBooks
            ? sharedBooks && bookData?.p_r
              ? bookData?.p_r || 0
              : total_pll || 0
            : bfShare
              ? bfShare
              : 0;
          if (bookData?.pl > 0) {
            totalPLByColumnResult[subIndex].actual_pl += -Math.abs(bookData?.pl);
          } else {
            totalPLByColumnResult[subIndex].actual_pl += Math.abs(bookData?.pl);
          }
        });
      });
      // console.log("caseeeeeeeeee 3", totalPLByColumn);
      setTotalPLByColumnResult((prevData) => {
        // Create a Set of existing names to check for duplicates
        const existingNames = new Set(prevData.map(item => item.originalName));

        // Filter out duplicates by name
        const uniqueResults = totalPLByColumnResult.filter(item =>
          !existingNames.has(item.originalName)
        );

        // Only update if there are new unique results
        if (uniqueResults.length > 0) {
          return [...prevData, ...uniqueResults];
        } else {
          return prevData; // No change needed
        }
      });

      return totalPLByColumn;
    };

    const getCurrencyValue = (value, currency) => {
      let data = convertINRToCurrency(
        Math.abs(value),
        parseFloat(currency?.value?.value), true
      );

      data = parseFloat(data)
      return data;
    };

    const getAbsoluteValue = (value) => {
      return Math.abs(value);
    };

    const handleBetFairBooksFalse = (bookData, sharedBooks, currency) => {
      if (sharedBooks && bookData?.p_r !== null && bookData?.p_r !== undefined) {
        let result = currency
          ? getCurrencyValue(bookData?.p_r, currency)
          : getAbsoluteValue(bookData?.p_r);
        return parseFloat(result);
      } else {
        let result = currency
          ? getCurrencyValue(bookData?.pl, currency)
          : getAbsoluteValue(bookData?.pl);
        return parseFloat(result);
      }
    };

    const handleBetFairBooksTrue = (bookData, currency, userId) => {
      let bfShare = 0;
      let total_pl = 0;

      if (bookData?.betFairShare > 0) {
        bfShare = bookData?.betFairShare;
      } else {
        bfShare = -1 * bookData?.betFairShare;
      }

      if (bookData?.pl > 0) {
        total_pl = bookData?.pl;
      } else {
        total_pl = -1 * bookData?.pl;
      }

      let result = currency
        ? getCurrencyValue(
          userId?.role !== layerRoles?.USER
            ? bfShare - total_pl
            : total_pl,
          currency
        )
        : getAbsoluteValue(
          userId?.role !== layerRoles?.USER
            ? bfShare - total_pl
            : bfShare - total_pl
        );

      if (bookData?.pl > 0) {
        return -parseFloat(result);
      } else {
        return parseFloat(result);
      }

    };

    const handleBetFairAndSharedBooksTrue = (bookData, currency, userId) => {
      let percentage;
      percentage = bookData?.pl;
      let bfShare = 0;
      let total_pl = 0;

      if (bookData?.betFairShare > 0) {
        bfShare = bookData?.betFairShare;
      } else {
        bfShare = -1 * bookData?.betFairShare;
      }

      if (bookData?.pl > 0) {
        total_pl = bookData?.pl;
      } else {
        total_pl = -1 * bookData?.pl;
      }

      if (userId?.role !== layerRoles?.USER) {
        const sumOfShares = userId?.betFairShare + userId?.sportShares;
        percentage = (total_pl * (100 - sumOfShares)) / 100;
      } else {
        percentage = total_pl - bfShare;
      }

      let result = currency
        ? getCurrencyValue(percentage, currency)
        : Math.abs(percentage.toFixed(2));
      result = parseFloat(result);

      if (bookData?.pl > 0) {
        return -result;
      } else {
        return result;
      }
    };

    const calculateValue = (
      betFairBooks,
      sharedBooks,
      bookData,
      currency,
      userId
    ) => {
      let data = null;
      if (!betFairBooks) {
        data = handleBetFairBooksFalse(bookData, sharedBooks, currency);
        return data;
      } else if (betFairBooks && sharedBooks) {
        data = handleBetFairAndSharedBooksTrue(bookData, currency, userId);
        return data;
      } else {
        data = handleBetFairBooksTrue(bookData, currency, userId);
        return data;
      }
    };

    const calculateTotalPLByBetshare_shareBook_Column = () => {
      const totalPLByColumn = [];
      const totalPLByColumnResult = [];

      if (bookviewData?.books?.[0]?.booksData) {
        bookviewData.books[0].booksData.forEach(() => {
          totalPLByColumn.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, individual_pl: 0, actual_pl: 0, clientShareVisibility: false });
          totalPLByColumnResult.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, individual_pl: 0, actual_pl: 0, clientShareVisibility: false, directParent: false });
        });
      }

      const allRolesAreSeven = bookviewData?.books?.some(item => item.userId.role !== 7);
      bookviewData?.books?.forEach((book) => {
        book?.booksData?.forEach((bookData, subIndex) => {
          let valueToAdd = 0;
          let percentage = 0;
          let bfShare = 0;
          let total_pll = 0;
          let isClientShare = false;
          //check client share is zero
          // console.log("book?.userId?.sportShares", book?.userId?.sportShares);
          // console.log("book?.userId?.parents?.[0]", book?.userId?.parents?.[0]
          //   ?.parent_id?.sportShares);
          if (book?.userId?.sportShares) {
            isClientShare = book?.userId?.sportShares !== 0;
          } else {
            isClientShare = book?.userId?.parents?.[0]
              ?.parent_id?.sportShares !== 0 && book?.userId?.parents?.[0]
                ?.parent_id?.sportShares !== undefined;
          }

          if (bookData?.betFairShare > 0) {
            bfShare = bookData?.betFairShare;
          } else {
            bfShare = -1 * bookData?.betFairShare;
          }

          if (bookData?.pl > 0) {
            total_pll = bookData?.pl;
          } else {
            total_pll = -1 * bookData?.pl;
          }
          // total_pll = bookData?.pl;
          if (betFairBooks && sharedBooks) {
            const sumOfShares =
              book.userId?.betFairShare + book.userId?.sportShares;
            if (isNaN(sumOfShares)) {
              percentage = total_pll - bfShare;
            } else {
              percentage = (total_pll * (100 - sumOfShares)) / 100;
            }
            valueToAdd = percentage;
          }

          let client_shares = 0;

          if (isClientShare) {
            if (book?.userId?.sportShares) {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(

                  (bookData?.pl / 100) *
                  book?.userId?.sportShares
                  ,
                  adminData?.currencyId?.value, true
                )
              } else {
                client_shares =
                  (bookData?.pl / 100) *
                  book?.userId?.sportShares

              }
            } else {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(

                  (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]
                    ?.parent_id?.sportShares
                  ,
                  parseInt(
                    adminData?.currencyId?.value
                  ), true
                )
              } else {
                client_shares = (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]
                    ?.parent_id?.sportShares
              }
            }
          }

          // if (client_shares > 0) {
          //   client_shares = client_shares;
          // } else {
          //   client_shares = -1 * client_shares;
          // }

          bfShare = bookData?.betFairShare;
          // console.log("bfShare", bfShare);
          if (book?.userId?.role !== 7) {
            totalPLByColumn[subIndex].clientShare += parseFloat(client_shares);
            totalPLByColumnResult[subIndex].clientShare += parseFloat(client_shares);
            // totalPLByColumnResult[subIndex].directParent = false;
          } else {
            totalPLByColumn[subIndex].clientShare += 0;
            totalPLByColumnResult[subIndex].clientShare += 0;
            // totalPLByColumnResult[subIndex].directParent = true;
          }
          totalPLByColumnResult[subIndex].directParent = !allRolesAreSeven;
          // if (eventNameArray?.includes(bookData?.name)) {
          // if (bookData?.name === "West Indies") {
          //   console.log("calculateTotalPLByBetshare_shareBook_Column bookData?.pl", bookData?.pl);
          //   console.log("calculateTotalPLByBetshare_shareBook_Column valueToAdd", valueToAdd);
          // }

          // totalPLByColumnResult[subIndex].total_pl += parseFloat(valueToAdd);
          if (valueToAdd < 0) {
            valueToAdd = -1 * valueToAdd
          }
          // console.log("bookData",bookData);
          // console.log("valueToAdd",valueToAdd);
          if (bookData?.pl > 0) {
            totalPLByColumnResult[subIndex].actual_pl += -Math.abs(bookData?.pl);
            totalPLByColumnResult[subIndex].total_pl += -parseFloat(valueToAdd);
          } else {
            totalPLByColumnResult[subIndex].actual_pl += Math.abs(bookData?.pl);
            totalPLByColumnResult[subIndex].total_pl += parseFloat(valueToAdd);
          }


          totalPLByColumnResult[subIndex].clientShareVisibility = allRolesAreSeven;
          totalPLByColumnResult[subIndex].name = bookData?.runnerName;
          totalPLByColumnResult[subIndex].originalName = bookData?.originalName;
          totalPLByColumnResult[subIndex].betFairShare += bfShare;
          totalPLByColumnResult[subIndex].role = book?.userId?.role;
          totalPLByColumnResult[subIndex].individual_pl = bookData?.pl;
          // }
          // if (book?.userId?.role !== 7) {
          //   totalPLByColumn[subIndex].clientShare += book?.userId?.share;
          // } else {
          //   totalPLByColumn[subIndex].clientShare += 0;
          // }
          totalPLByColumn[subIndex].clientShareVisibility = allRolesAreSeven;

          totalPLByColumn[subIndex].individual_pl = bookData?.pl;
          totalPLByColumn[subIndex].role = book?.userId?.role;
          totalPLByColumn[subIndex].name = bookData?.runnerName;
          totalPLByColumn[subIndex].originalName = bookData?.originalName;
          totalPLByColumn[subIndex].betFairShare += bfShare;

          // totalPLByColumn[subIndex].total_pl += parseFloat(valueToAdd);

          if (bookData?.pl > 0) {
            totalPLByColumn[subIndex].actual_pl += -Math.abs(bookData?.pl);
            totalPLByColumn[subIndex].total_pl += -parseFloat(valueToAdd);
          } else {
            totalPLByColumn[subIndex].actual_pl += Math.abs(bookData?.pl);
            totalPLByColumn[subIndex].total_pl += parseFloat(valueToAdd);
          }
        });
      });

      setMatchOddsCaculations(totalPLByColumn);
      // console.log("caseeeeeeeeee 2", totalPLByColumnResult);
      // setTotalPLByColumnResult((prevData) => [...prevData, ...totalPLByColumnResult]);
      setTotalPLByColumnResult((prevData) => {
        // Create a Set of existing names to check for duplicates
        const existingNames = new Set(prevData.map(item => item.originalName));

        // Filter out duplicates by name
        const uniqueResults = totalPLByColumnResult.filter(item =>
          !existingNames.has(item.originalName)
        );

        // Only update if there are new unique results
        if (uniqueResults.length > 0) {
          return [...prevData, ...uniqueResults];
        } else {
          return prevData; // No change needed
        }
      });

      return totalPLByColumn;
    };

    const calculateTotalPLByBetshare_shareBook_false_Column = () => {
      const totalPLByColumn = [];
      const totalPLByColumnResult = [];

      if (bookviewData?.books?.[0]?.booksData) {
        bookviewData.books[0].booksData.forEach(() => {
          totalPLByColumn.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, actual_pl: 0, individual_pl: 0, clientShareVisibility: false });
          totalPLByColumnResult.push({ total_pl: 0, betFairShare: 0, clientShare: 0, role: 0, actual_pl: 0, individual_pl: 0, clientShareVisibility: false, directParent: false });
        });
      }

      const allRolesAreSeven = bookviewData?.books?.some(item => item.userId.role !== 7);

      bookviewData?.books?.forEach((book, index) => {
        book?.booksData?.forEach((bookData, subIndex) => {
          let valueToAdd = 0;
          let bfShare = 0;
          let total_pll = 0;
          let isClientShare = false;

          if (book?.userId?.sportShares) {
            isClientShare = book?.userId?.sportShares !== 0;
          } else {
            isClientShare = book?.userId?.parents?.[0]
              ?.parent_id?.sportShares !== 0 && book?.userId?.parents?.[0]
                ?.parent_id?.sportShares !== undefined;
          }

          if (betFairBooks && sharedBooks === false) {
            valueToAdd = handleBetFairBooksTrue(
              bookData,
              currency,
              book.userId
            );
          }

          // if (bookData?.pl > 0) {
          //   total_pll = bookData?.pl;
          // } else {
          //   total_pll = -1 * bookData?.pl;
          // }
          total_pll = bookData?.pl;
          let client_shares = 0;
          if (isClientShare) {
            if (book?.userId?.sportShares) {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(
                  (bookData?.pl / 100) *
                  book?.userId?.sportShares,
                  adminData?.currencyId?.value,
                  true
                )
              } else {
                client_shares =
                  (bookData?.pl / 100) *
                  book?.userId?.sportShares
              }
            } else {
              if (adminData?.currencyId?.value) {
                client_shares = convertINRToCurrency(
                  (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]?.parent_id?.sportShares,
                  parseInt(adminData?.currencyId?.value), true
                )
              } else {
                client_shares = (bookData?.pl / 100) *
                  book?.userId?.parents?.[0]?.parent_id?.sportShares
              }
            }
          }

          // if (client_shares > 0) {
          //   client_shares = client_shares;
          // } else {
          //   client_shares = -1 * client_shares;
          // }

          //test
          // if (bookData?.name === "West Indies") {
          //   console.log("calculateTotalPLByBetshare_shareBook_false_Column bookData?.pl", bookData?.pl);
          //   console.log("calculateTotalPLByBetshare_shareBook_false_Column valueToAdd", valueToAdd);
          // }

          // totalPLByColumnResult[subIndex].total_pl += parseFloat(valueToAdd);
          if (valueToAdd < 0) {
            valueToAdd = -1 * valueToAdd
          }

          // console.log("valueToAdd",valueToAdd);
          if (bookData?.pl > 0) {
            totalPLByColumnResult[subIndex].actual_pl += -Math.abs(bookData?.pl);
            totalPLByColumnResult[subIndex].total_pl += -parseFloat(valueToAdd);
          } else {
            totalPLByColumnResult[subIndex].actual_pl += Math.abs(bookData?.pl);
            totalPLByColumnResult[subIndex].total_pl += parseFloat(valueToAdd);
          }

          // if (bookData?.betFairShare > 0) {
          //   bfShare = bookData?.betFairShare;
          // } else {
          //   bfShare = -1 * bookData?.betFairShare;
          // }
          bfShare = bookData?.betFairShare;
          // console.log("valueToAdd", valueToAdd);
          if (book?.userId?.role !== 7) {
            totalPLByColumn[subIndex].clientShare += parseFloat(client_shares);
            totalPLByColumnResult[subIndex].clientShare += parseFloat(client_shares);
            // totalPLByColumnResult[subIndex].directParent = false;
          } else {
            totalPLByColumn[subIndex].clientShare += 0;
            totalPLByColumnResult[subIndex].clientShare += 0;
            // totalPLByColumnResult[subIndex].directParent = true;
          }
          totalPLByColumnResult[subIndex].directParent = !allRolesAreSeven;


          totalPLByColumnResult[subIndex].clientShareVisibility = allRolesAreSeven;
          totalPLByColumnResult[subIndex].individual_pl = bookData?.pl;
          totalPLByColumnResult[subIndex].name = bookData?.runnerName;
          totalPLByColumnResult[subIndex].originalName = bookData?.originalName;
          totalPLByColumnResult[subIndex].betFairShare += bfShare;
          totalPLByColumnResult[subIndex].role = book?.userId?.role;


          totalPLByColumn[subIndex].clientShareVisibility = allRolesAreSeven;
          totalPLByColumn[subIndex].individual_pl = bookData?.pl;
          totalPLByColumn[subIndex].role = book?.userId?.role;
          totalPLByColumn[subIndex].name = bookData?.runnerName;
          totalPLByColumn[subIndex].originalName = bookData?.originalName;
          totalPLByColumn[subIndex].betFairShare += bfShare;

          // totalPLByColumn[subIndex].total_pl += parseFloat(valueToAdd);

          if (bookData?.pl > 0) {
            totalPLByColumn[subIndex].actual_pl += -Math.abs(bookData?.pl);
            totalPLByColumn[subIndex].total_pl += -parseFloat(valueToAdd);
          } else {
            totalPLByColumn[subIndex].actual_pl += Math.abs(bookData?.pl);
            totalPLByColumn[subIndex].total_pl += parseFloat(valueToAdd);
          }
        });
      });

      setTotalPLCalculation([
        {
          total: 100,
          betfairShare: 12,
          clientShare: 52,
        },
      ]);

      // console.log("caseeeeeeeeee 1", totalPLByColumnResult);
      setMatchOddsCaculations(totalPLByColumn);
      setTotalPLByColumnResult((prevData) => {
        // Create a Set of existing names to check for duplicates
        const existingNames = new Set(prevData.map(item => item.originalName));

        // Filter out duplicates by name
        const uniqueResults = totalPLByColumnResult.filter(item =>
          !existingNames.has(item.originalName)
        );

        // Only update if there are new unique results
        if (uniqueResults.length > 0) {
          return [...prevData, ...uniqueResults];
        } else {
          return prevData; // No change needed
        }
      });

      return totalPLByColumn;
    };

    return (
      <>
        {bookviewData !== undefined && (
          <Card className="m-0 rounded-0" style={{ boxShadow: "none" }}>
            <CardHeader
              className="rounded-0 p-0"
              style={{ backgroundColor: "#EEE" }}
            >
              <Btn
                attrBtn={{
                  as: Card.Header,
                  className: "btn accordian-btn accordian-tab",
                  color: "default",
                  onClick: () => {
                    toggleLiveAccordion();
                  },
                }}
              >
                <div className="d-flex py-1  align-items-center  w-100 m-0">
                  <Col className="manageEve-card-text text-black live-tv-txt-left">
                    {t(`BOOK_VIEW`)}
                  </Col>
                  <Col className="dropDown-icon d-flex align-items-center justify-content-end live-tv-txt-right">
                    <RiArrowDropDownLine className="" />
                  </Col>
                </div>
              </Btn>
            </CardHeader>
            <Collapse isOpen={openLive}>
              <div className="overflow-auto">
                <CardBody className="p-0 ">
                  <table className="table table-bordered table-responsive table-strip">
                    <thead className="table-light">
                      <tr>
                        {bookviewData?.books?.map((bookData, index) => {
                          if (bookData !== undefined && index === 0) {
                            return (
                              <Fragment key={index}>
                                <th>Username</th>
                                {bookData?.booksData?.map((data, subIndex) => (
                                  <th
                                    className="text-uppercase w-25 text-uppercase py-2"
                                    key={subIndex}
                                  >
                                    <span>{data?.name}</span>
                                  </th>
                                ))}
                              </Fragment>
                            );
                          }
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {bookviewData?.books?.map((book, index) => (
                        <tr key={index}>
                          <td className="d-flex gap-1">
                            <badges
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#f4f4f4",
                                width: "30px",
                                height: "18px",
                                borderRadius: "5px",
                                color: "#2f2f3b",
                                padding: "0.25rem 0.4rem",
                                fontSize: "12px",
                                fontWeight: "700",
                                textAlign: "center",
                              }}
                            >
                              {` ${book?.userId?.role ===
                                ManagerRoles.ACCOUNTS_MANAGER
                                ? "AC_M"
                                : book?.userId?.role === layerRoles?.USER
                                  ? "U"
                                  : book?.userId?.role === layerRoles?.AGENT
                                    ? "AG"
                                    : book?.userId?.role === layerRoles?.MASTER
                                      ? "MS"
                                      : book?.userId?.role ===
                                        layerRoles?.SUPER_MASTER
                                        ? "SM"
                                        : book?.userId?.role === layerRoles?.ADMIN
                                          ? "AD"
                                          : "SA"
                                }`}
                            </badges>

                            {/* {sharedBooks &&
                            book?.userId?.role !== layerRoles?.USER ? (
                              <>
                                {book?.userId?.username}
                                <br />
                                BS ({book?.userId?.betFairShare}%)
                                <br />
                                CS ({book?.userId?.sportShares}%)
                              </>
                            ) : (
                              <>
                                {book?.userId?.username} (BF Share
                                {book?.userId?.betFairShare}%)
                              </>
                            )} */}

                            {sharedBooks && betFairBooks ? (
                              <>
                                {book?.userId?.username}
                                {(adminData?.role === layerRoles.DIRECTOR ||
                                  Object.values(ManagerRoles).includes(
                                    adminData?.role
                                  )) && (
                                    <>
                                      <br />
                                     {stx ? null : <> BS ({book?.userId?.betFairShare}%)</>}
                                      <br />
                                      <>
                                        { }
                                        {(7 !== book?.userId?.role) && <>
                                          CS (
                                          {book?.userId?.parents?.length >= 0
                                            ? book?.userId?.parents?.[0]
                                              ?.parent_id?.sportShares !== 0 ? adminData?.currencyId?.value
                                              ? convertINRToCurrency(
                                                book?.userId?.parents?.[0]
                                                  ?.parent_id?.sportShares,
                                                parseFloat(
                                                  adminData?.currencyId?.value
                                                ), true
                                              )
                                              : book?.userId?.parents?.[0]?.parent_id
                                                ?.sportShares : 0
                                            : book?.userId?.sportShares !== 0 ? book?.userId?.sportShares : 0}
                                          %)
                                        </>}
                                      </>
                                    </>
                                  )}
                              </>
                            ) : sharedBooks === true ? (
                              <>
                                {book?.userId?.username}
                                {/* {(adminData?.role === layerRoles.DIRECTOR ||
                                  Object.values(ManagerRoles).includes(
                                    adminData?.role
                                  )) && (7 !== book?.userId?.role) && (
                                    <>
                                      <br />
                                      CS (
                                      {book?.userId?.parents?.length >= 0
                                        ? adminData?.currencyId?.value
                                          ? convertINRToCurrency(
                                            book?.userId?.parents?.[0]
                                              ?.parent_id?.sportShares,
                                            parseFloat(
                                              adminData?.currencyId?.value
                                            )
                                          )
                                          : book?.userId?.parents?.[0]?.parent_id
                                            ?.sportShares
                                        : book?.userId?.sportShares}
                                      %)
                                    </>
                                  )} */}

                                {(7 !== book?.userId?.role) && (
                                  <>
                                    <br />
                                    CS (
                                    {book?.userId?.parents?.length >= 0
                                      ? book?.userId?.parents?.[0]
                                        ?.parent_id?.sportShares !== 0 ? adminData?.currencyId?.value
                                        ? convertINRToCurrency(
                                          book?.userId?.parents?.[0]
                                            ?.parent_id?.sportShares,
                                          parseFloat(
                                            adminData?.currencyId?.value
                                          ), true
                                        )
                                        : book?.userId?.parents?.[0]?.parent_id
                                          ?.sportShares : 0
                                      : book?.userId?.sportShares !== 0 ? book?.userId?.sportShares : 0}
                                    %)
                                  </>
                                )}
                              </>
                            ) : betFairBooks === true ? (
                              <>
                                {(adminData?.role === layerRoles.DIRECTOR ||
                                  Object.values(ManagerRoles).includes(
                                    adminData?.role
                                  )) && (
                                    <>
                                      {book?.userId?.username} (BF
                                      {book?.userId?.betFairShare}%)
                                    </>
                                  )}
                              </>
                            ) : (
                              book?.userId?.username
                            )}
                          </td>
                          {book?.booksData?.map((bookData, subIndex) => {
                            let cs = 0;
                            if (book?.userId?.sportShares && book?.userId?.sportShares !== 0) {
                              if (adminData?.currencyId?.value) {
                                cs = parseFloat(convertINRToCurrency(
                                  (
                                    (bookData?.pl / 100) *
                                    book?.userId?.sportShares
                                  ),
                                  parseFloat(
                                    adminData?.currencyId?.value
                                  ), true
                                ))?.toFixed(2);
                              } else {
                                cs = (
                                  (bookData?.pl / 100) *
                                  book?.userId?.sportShares
                                )?.toFixed(2);
                              }
                            } else {
                              if (book?.userId?.parents?.[0]
                                ?.parent_id?.sportShares !== 0) {
                                if (adminData?.currencyId?.value) {
                                  cs = parseFloat(convertINRToCurrency(
                                    (
                                      (bookData?.pl / 100) *
                                      book?.userId?.parents?.[0]
                                        ?.parent_id?.sportShares
                                    ),
                                    parseFloat(
                                      adminData?.currencyId?.value
                                    ), true
                                  ))?.toFixed(2);
                                } else {
                                  cs = parseFloat(
                                    (bookData?.pl / 100) *
                                    book?.userId?.parents?.[0]
                                      ?.parent_id?.sportShares
                                  )?.toFixed(2);
                                }
                              }
                            }
                            cs = isNaN(cs) ? 0 : cs;

                            let bf = 0;
                            if (bookData?.betFairShare) {
                              if (adminData?.currencyId?.value) {
                                bf = parseFloat(convertINRToCurrency(
                                  (
                                    bookData?.betFairShare
                                  ),
                                  parseFloat(
                                    adminData?.currencyId?.value
                                  ), true
                                ))?.toFixed(2);
                              } else {
                                bf = parseFloat(
                                  bookData?.betFairShare
                                )?.toFixed(2);
                              }
                            } else {
                              if (adminData?.currencyId?.value) {
                                bf = parseFloat(convertINRToCurrency(
                                  (bookData?.betFairShare)?.toFixed(2),
                                  parseFloat(
                                    adminData?.currencyId?.value
                                  ), true
                                ))?.toFixed(2);
                              } else {
                                bf = parseFloat(
                                  bookData?.betFairShare
                                )?.toFixed(2);
                              }
                            }
                            // console.log("cs", cs);
                            return (
                              <td key={subIndex}>
                                <div>
                                  {betFairBooks && sharedBooks ? (
                                    <>
                                      {(adminData?.role === layerRoles.DIRECTOR ||
                                        Object.values(ManagerRoles).includes(
                                          adminData?.role
                                        )) && (
                                          <>
                                            <p
                                              style={{
                                                margin: 0,
                                                marginLeft: "5px",
                                              }}
                                              className={`${bookData?.pl < 0
                                                ? "runner-p"
                                                : "runner-l"}`}
                                            >
                                              PL :{bookData?.pl < 0 ? "" : "-"}{currency
                                                ? convertINRToCurrency(
                                                  Math.abs(bookData?.pl),
                                                  parseFloat(currency?.value?.value), true
                                                )
                                                : Math.abs(bookData?.pl).toFixed(2)}
                                            </p>
                                          </>
                                        )}
                                      {(adminData?.role === layerRoles.DIRECTOR ||
                                        Object.values(ManagerRoles).includes(
                                          adminData?.role
                                        )) && (7 !== book?.userId?.role) && (
                                          <>
                                            <span
                                              style={{
                                                margin: 0,
                                                marginLeft: "5px",
                                              }}
                                              className={`${cs <= 0
                                                ? "runner-p"
                                                : "runner-l"}`}
                                            >
                                              CS :{" "}
                                              {cs <= 0 ? "" : "-"}
                                              {Math.abs(cs)}
                                            </span>
                                          </>
                                        )}

                                      {(adminData?.role === layerRoles.DIRECTOR ||
                                        Object.values(ManagerRoles).includes(
                                          adminData?.role
                                        )) && (
                                          <>
                                            <p
                                              style={{
                                                margin: 0,
                                                marginLeft: "5px",
                                              }}
                                              className={`${bf <= 0
                                                ? "runner-p"
                                                : "runner-l"}`}
                                            >
                                              {/* {book?.userId?.betFairShare
                                                ? adminData?.currencyId?.value
                                                  ? convertINRToCurrency(
                                                    (
                                                      (bookData?.pl / 100) *
                                                      book?.userId?.betFairShare
                                                    )?.toFixed(2),
                                                    parseFloat(
                                                      adminData?.currencyId?.value
                                                    )
                                                  )
                                                  : (
                                                    (bookData?.pl / 100) *
                                                    book?.userId?.betFairShare
                                                  )?.toFixed(2)
                                                : adminData?.currencyId?.value
                                                  ? convertINRToCurrency(
                                                    (
                                                      (bookData?.pl / 100) *
                                                      book?.userId?.parents?.[0]
                                                        ?.parent_id?.betFairShare
                                                    )?.toFixed(2),
                                                    parseFloat(
                                                      adminData?.currencyId?.value
                                                    )
                                                  )
                                                  : (
                                                    (bookData?.pl / 100) *
                                                    book?.userId?.parents?.[0]
                                                      ?.parent_id?.betFairShare
                                                  )?.toFixed(2)} */}
                                               {stx ? null : <>
                                                BS :
                                              {bf <= 0 ? "" : "-"}
                                              {Math.abs(bf)}
                                              </>}
                                            </p>
                                          </>
                                        )}
                                    </>
                                  ) : betFairBooks ? (
                                    <>
                                      {(adminData?.role === layerRoles.DIRECTOR ||
                                        Object.values(ManagerRoles).includes(
                                          adminData?.role
                                        )) && (
                                          <p
                                            style={{ margin: 0, marginLeft: "5px" }}
                                            className={`${bookData?.pl < 0
                                              ? "runner-p"
                                              : "runner-l"}`}
                                          >

                                            PL :{bookData?.pl < 0 ? "" : "-"}{currency
                                              ? convertINRToCurrency(
                                                Math.abs(bookData?.pl),
                                                parseFloat(currency?.value?.value), true
                                              )
                                              : Math.abs(bookData?.pl).toFixed(2)}
                                          </p>
                                        )}
                                      <p style={{ margin: 0, marginLeft: "5px" }} className={`${bf <= 0
                                        ? "runner-p"
                                        : "runner-l"}`}>
                                        {/* {bookData?.betFairShare
                                          ? adminData?.currencyId?.value
                                            ? convertINRToCurrency(
                                              (
                                                (bookData?.pl / 100) *
                                                bookData?.betFairShare
                                                )?.toFixed(2),
                                              parseFloat(
                                                adminData?.currencyId?.value
                                                )
                                                )
                                                : (
                                              (bookData?.pl / 100) *
                                              bookData?.betFairShare
                                            )?.toFixed(2)
                                            : null} */}
                                            {stx ? null : <>
                                            BS :{" "}
                                        {bf <= 0 ? "" : "-"}
                                        {Math.abs(bf)}
                                            </>}
                                      </p>
                                    </>
                                  ) : sharedBooks ? (
                                    <>
                                      <p style={{ margin: 0, marginLeft: "5px" }}>
                                        {(adminData?.role ===
                                          layerRoles.DIRECTOR ||
                                          Object.values(ManagerRoles).includes(
                                            adminData?.role
                                          )) && (
                                            <>
                                              <span className={`${bookData?.pl < 0
                                                ? "runner-p"
                                                : "runner-l"}`}>
                                                PL :{" "}
                                                {bookData?.pl < 0 ? "" : "-"}
                                                {adminData?.currencyId?.value
                                                  ? convertINRToCurrency(
                                                    bookData.pl?.toFixed(2),
                                                    parseInt(
                                                      adminData?.currencyId?.value
                                                    ), true
                                                  )
                                                  : currency
                                                    ? convertINRToCurrency(
                                                      Math.abs(bookData?.pl),
                                                      parseFloat(currency?.value?.value), true
                                                    )
                                                    : Math.abs(bookData?.pl).toFixed(2)}
                                              </span>
                                              <br />
                                            </>
                                          )}
                                        {/* {(adminData?.role ===
                                          layerRoles.DIRECTOR ||
                                          Object.values(ManagerRoles).includes(
                                            adminData?.role
                                          )) && (7 !== book?.userId?.role) && ( */}
                                        {(7 !== book?.userId?.role && book?.userId?.sportShares !== 0) && (
                                          <>
                                            <span className={`${cs <= 0
                                              ? "runner-p"
                                              : "runner-l"}`}>
                                              CS :
                                              {cs <= 0 ? "" : "-"}
                                              {Math.abs(cs)}
                                            </span>
                                          </>
                                        )}
                                      </p>
                                    </>
                                  ) : null}
                                </div>
                                <span
                                  className={`${!betFairBooks
                                    ? bookData?.pl < 0
                                      ? "runner-p"
                                      : "runner-l"
                                    : bookData?.pl < 0
                                      ? "runner-p"
                                      : "runner-l"
                                    }`}
                                >
                                  {/* {!betFairBooks ? sharedBooks && bookData?.p_r !== undefined 
  
                                ? currency ? convertINRToCurrency( Math.abs(bookData?.p_r),parseFloat(currency?.value?.value))
                                      
                                : Math.abs(bookData?.p_r?.toFixed(2))
                                
                                : currency ? convertINRToCurrency( Math.abs(bookData?.pl),parseFloat(currency?.value?.value))
  
                                : Math.abs(bookData?.pl?.toFixed(2))
                                
                                : currency ? convertINRToCurrency( Math.abs(bookData?.betFairShare),parseFloat(currency?.value?.value))
  
                                : Math.abs(bookData?.betFairShare?.toFixed(2))} */}
                                  {/* {console.log("calculateValue", calculateValue(
                                    betFairBooks,
                                    sharedBooks,
                                    bookData,
                                    currency,
                                    book?.userId
                                  ))} */}
                                 {stx ? <>
                                 {betFairBooks ? null : 
                                 <>
                                  {parseFloat(calculateValue(
                                    betFairBooks,
                                    sharedBooks,
                                    bookData,
                                    currency,
                                    book?.userId
                                  ))?.toFixed(2)}
                                 </>
                                 }
                                 </> : 
                                 <>
                                  {!betFairBooks && bookData?.pl > 0 ? "-" : ""}
                                  {parseFloat(calculateValue(
                                    betFairBooks,
                                    sharedBooks,
                                    bookData,
                                    currency,
                                    book?.userId
                                  ))?.toFixed(2)}
                                 </>}
                                </span>
                              </td>
                            )
                          })}
                        </tr>
                      ))}

                      <tr>
                        <td>Total PL</td>
                        {betFairBooks === true && sharedBooks === true
                          ? calculateTotalPLByBetshare_shareBook_Column()?.map(
                            (total, subIndex) => {
                              let totalPl = 0;
                              let pl = currency
                                ? convertINRToCurrency(
                                  Math.abs(total?.actual_pl),
                                  parseFloat(currency?.value?.value), true
                                )
                                : (Math.abs(total?.actual_pl));
                              pl = parseFloat(pl);
                              // if (total?.actual_pl < 0) {
                              //   totalPl = Math.abs(pl);
                              // } else {
                              //   totalPl = -1 * Math.abs(pl);
                              // }
                              let bf = currency
                                ? convertINRToCurrency(
                                  total?.betFairShare,
                                  parseFloat(currency?.value?.value), true
                                )
                                : total?.betFairShare;
                              bf = parseFloat(bf);

                              let cs = currency
                                ? convertINRToCurrency(
                                  (
                                    total?.clientShare),
                                  parseFloat(currency?.value?.value), true
                                )
                                : (
                                  total?.clientShare);
                              cs = parseFloat(cs);

                              // if (Math.abs(total?.actual_pl) < (Math.abs(bf) + Math.abs(cs))) {
                              //   totalPl = pl - (Math.abs(bf) + Math.abs(cs));
                              // } else {
                              //   totalPl = pl - (Math.abs(bf) + Math.abs(cs));


                              //   if (total?.actual_pl < 0) {
                              //     totalPl = -1 * Math.abs(totalPl);
                              //   } else {
                              //     totalPl = totalPl
                              //   }
                              // }
                              // totalPl = parseFloat(totalPl);
                              let bfCal = 0;
                              let csCal = 0;
                              let finalPl = 0;

                              if (bf < 0) {
                                bfCal = -1 * bf;
                              } else {
                                bfCal = -bf;
                              }

                              if (cs < 0) {
                                csCal = -1 * cs;
                              } else {
                                csCal = -cs;
                              }

                              // let totalShare = csCal + bfCal;
                              // if (totalShare >= total?.actual_pl) {
                              //   finalPl = totalShare - Math.abs(total?.actual_pl);
                              // } else {
                              //   finalPl = total?.actual_pl - totalShare;
                              // }
                              finalPl = stx ? total?.actual_pl - csCal : total?.actual_pl - csCal - bfCal;
                              // console.log("bfCal", bfCal);
                              // console.log("csCal", csCal);
                              //  finalPl = handlePlAsPerBfAndCs(total?.actual_pl, bfCal, csCal, bf, cs);

                              // console.log({ actualPl: total?.actual_pl, bfCal: bfCal, csCal: csCal, bf: bf, cs: cs });
                              // console.log("bookview");
                              // console.log({cs:cs,bf:bf,totalPl:totalPl});
                              // { console.log("calculateTotalPLByBetshare_shareBook_Column", pl) }

                              return ((
                                <td key={subIndex}>
                                  {/* <span
                                    className={`${total?.total_pl < 0 ? "runner-p" : "runner-l"
                                      }`}
                                  > */}
                                  {/* {console.log("total?.total_pl", totalPl)} */}
                                  <span
                                    className={`${finalPl > 0 ? "runner-p" : "runner-l"
                                      }`}
                                  >
                                    {finalPl > 0 ? "" : "-"}
                                    {/* {currency
                                      ? convertINRToCurrency(
                                        Math.abs(total?.total_pl),
                                        parseFloat(currency?.value?.value)
                                      )
                                      : Math.abs(total?.total_pl).toFixed(2)} */}
                                    {Math.abs(finalPl)?.toFixed(2)}
                                  </span>
                                  {betFairBooks && <div className={`${bf <= 0
                                    ? "runner-p"
                                    : "runner-l"}`}>
                                      {stx ? null :
                                      [<>BS:
                                      <span>
                                        {bf <= 0 ? "" : "-"}
                                        {Math.abs(bf?.toFixed(2))}
                                      </span></>]
                                      }
                                  </div>}
                                  {/* {(sharedBooks && total?.role != 7) && <div>[<>CS:
                                    <span
                                      className=""
                                    >
                                      {currency
                                        ? convertINRToCurrency(
                                          ((total?.total_pl / 100) *
                                            total?.clientShare),
                                          parseFloat(currency?.value?.value)
                                        )
                                        : ((total?.total_pl / 100) *
                                          total?.clientShare).toFixed(2)}
                                    </span></>]
                                  </div>} */}

                                  {(sharedBooks && total?.clientShareVisibility) && <div className={`${cs <= 0
                                    ? "runner-p"
                                    : "runner-l"}`}>[<>CS:
                                      <span

                                      >
                                        {cs <= 0 ? "" : "-"}
                                        {Math.abs(cs?.toFixed(2))}
                                      </span></>]
                                  </div>}
                                </td>
                              ))
                            }
                          )
                          : betFairBooks === true && sharedBooks === false
                            ? calculateTotalPLByBetshare_shareBook_false_Column()?.map(
                              (total, subIndex) => {
                                let totalPl = 0;
                                let pl = currency
                                  ? convertINRToCurrency(
                                    Math.abs(total?.actual_pl),
                                    parseFloat(currency?.value?.value), true
                                  )
                                  : Math.abs(total?.actual_pl);
                                pl = parseFloat(pl);

                                let bf = currency
                                  ? convertINRToCurrency(
                                    total?.betFairShare,
                                    parseFloat(currency?.value?.value), true
                                  )
                                  : total?.betFairShare;
                                bf = parseFloat(bf);

                                let cs = currency
                                  ? convertINRToCurrency(
                                    (
                                      total?.clientShare),
                                    parseFloat(currency?.value?.value), true
                                  )
                                  : (
                                    total?.clientShare);
                                cs = parseFloat(cs);

                                let bfCal = 0;
                                let finalPl = 0;

                                if (bf < 0) {
                                  bfCal = -1 * bf;
                                } else {
                                  bfCal = -bf;
                                }

                                // let totalShare = bfCal;
                                // if (totalShare >= total?.actual_pl) {
                                //   finalPl = totalShare - Math.abs(total?.actual_pl);
                                // } else {
                                //   finalPl = total?.actual_pl - totalShare;
                                // }
                                finalPl = stx ? total?.actual_pl : total?.actual_pl - bfCal;
                                // finalPl = handlePlAsPerBf(total?.actual_pl, bfCal, bf);
                                // console.log("finalPl",finalPl);
                                // if (Math.abs(total?.actual_pl) < Math.abs(bf)) {
                                //   totalPl = Math.abs(pl) - (Math.abs(bf));
                                // } else {
                                //   totalPl = Math.abs(pl) - (Math.abs(bf));;

                                //   if (total?.actual_pl < 0) {
                                //     totalPl = -1 * Math.abs(totalPl);
                                //   } else {
                                //     totalPl = totalPl
                                //   }
                                // }
                                // totalPl = parseFloat(totalPl);
                                // console.log({ actualPl: total?.actual_pl, bf: bf, cs: cs });

                                // let totalPl = 0;
                                // if (total?.actual_pl < 0) {
                                //   totalPl = Math.abs(pl);
                                // } else {
                                //   totalPl = -1 * Math.abs(pl);
                                // }
                                return (<td key={subIndex}>
                                  {/* <span
                                    className={`${total?.total_pl < 0 ? "runner-p" : "runner-l"
                                      }`}
                                  > */}
                                  {/* {console.log("calculateTotalPLByBetshare_shareBook_false_Column", pl)} */}
                                  <span
                                    className={`${finalPl > 0 ? "runner-p" : "runner-l"
                                      }`}
                                  >
                                    {finalPl > 0 ? "" : "-"}
                                    {/* {currency
                                      ? convertINRToCurrency(
                                        Math.abs(total?.total_pl),
                                        parseFloat(currency?.value?.value)
                                      )
                                      : Math.abs(total?.total_pl).toFixed(2)} */}
                                    {Math.abs(finalPl)?.toFixed(2)}
                                  </span>
                                  {betFairBooks && <div className={`${bf <= 0
                                    ? "runner-p"
                                    : "runner-l"}`}>
                                      {stx ? null :<>
                                      [<>BS:
                                      <span

>
                                        {bf <= 0 ? "" : "-"}
                                        {Math.abs(bf?.toFixed(2))}
                                      </span></>]
                                        </>}
                                  </div>}
                                  {/* {(sharedBooks && total?.role != 7) && <div>[<>CS:
                                    <span
                                      className=""
                                    >
                                      {currency
                                        ? convertINRToCurrency(
                                          ((total?.total_pl / 100) *
                                            total?.clientShare),
                                          parseFloat(currency?.value?.value)
                                        )
                                        : ((total?.total_pl / 100) *
                                          total?.clientShare).toFixed(2)}
                                    </span></>]
                                  </div>} */}

                                  {(sharedBooks && total?.clientShareVisibility) && <div className={`${cs <= 0
                                    ? "runner-p"
                                    : "runner-l"}`}>[<>CS:
                                      <span

                                      >
                                        {cs <= 0 ? "" : "-"}
                                        {Math.abs(cs?.toFixed(2))}
                                      </span></>]
                                  </div>}
                                </td>)
                              }
                            )
                            : sharedBooks ? calculateTotalPLByColumn()?.map(
                              (total, subIndex) => {
                                let totalPl = 0;
                                let pl = currency
                                  ? convertINRToCurrency(
                                    Math.abs(total?.actual_pl),
                                    parseFloat(currency?.value?.value), true
                                  )
                                  : Math.abs(total?.actual_pl);
                                pl = parseFloat(pl);

                                let bf = currency
                                  ? convertINRToCurrency(
                                    total?.betFairShare,
                                    parseFloat(currency?.value?.value), true
                                  )
                                  : total?.betFairShare;
                                bf = parseFloat(bf);

                                let cs = currency
                                  ? convertINRToCurrency(
                                    (
                                      total?.clientShare),
                                    parseFloat(currency?.value?.value), true
                                  )
                                  : (
                                    total?.clientShare);
                                cs = parseFloat(cs);

                                // if (Math.abs(total?.actual_pl) < Math.abs(cs)) {
                                //   totalPl = Math.abs(pl) - Math.abs(cs);
                                // } else {
                                //   totalPl = Math.abs(pl) - Math.abs(cs);
                                //   if (total?.actual_pl < 0) {
                                //     totalPl = -1 * Math.abs(totalPl);
                                //   } else {
                                //     totalPl = totalPl
                                //   }
                                // }

                                // totalPl = parseFloat(totalPl);
                                let csCal = 0;
                                let finalPl = 0;

                                if (cs < 0) {
                                  csCal = -1 * cs;
                                } else {
                                  csCal = -cs;
                                }

                                // let totalShare = csCal;
                                // if (totalShare >= total?.actual_pl) {
                                //   finalPl = totalShare - Math.abs(total?.actual_pl);
                                // } else {
                                //   finalPl = total?.actual_pl - totalShare;
                                // }

                                finalPl = total?.actual_pl - csCal;

                                // finalPl = handlePlAsPerCs(total?.actual_pl, csCal, cs);
                                // { console.log("total?.total_pl", total?.actual_pl) }
                                // { console.log("calculateTotalPLByColumn", pl) }
                                return (
                                  <td key={subIndex}>
                                    <span
                                      className={`${finalPl > 0 ? "runner-p" : "runner-l"
                                        }`}
                                    >
                                      {finalPl > 0 ? "" : "-"}
                                      {Math.abs(finalPl)?.toFixed(2)}
                                    </span>
                                    {betFairBooks && <div className={`${bf <= 0
                                      ? "runner-p"
                                      : "runner-l"}`}>
                                        {stx ? null : <>
                                        [<>BS:
                                        <span

>
                                          {bf <= 0 ? "" : "-"}
                                          {Math.abs(bf?.toFixed(2))}
                                        </span></>]
                                          </>}
                                    </div>}
                                    {/* {(sharedBooks && total?.role != 7) && <div>[<>CS:
                                      <span
                                        className=""
                                      >
                                        {currency
                                          ? convertINRToCurrency(
                                            ((total?.total_pl / 100) *
                                              total?.clientShare),
                                            parseFloat(currency?.value?.value)
                                          )
                                          : ((total?.total_pl / 100) *
                                            total?.clientShare).toFixed(2)}
                                      </span></>]
                                    </div>} */}

                                    {((sharedBooks && total?.clientShareVisibility) && !isNaN(total?.clientShare)) && <div className={`${cs <= 0
                                      ? "runner-p"
                                      : "runner-l"}`}>[<>CS:
                                        <span

                                        >
                                          {cs <= 0 ? "" : "-"}
                                          {Math.abs(cs?.toFixed(2))}
                                        </span></>]
                                    </div>}
                                  </td>
                                )
                              }
                            ) : calculateTotalPLByColumn()?.map(
                              (total, subIndex) => {
                                let totalPl = 0;
                                let pl = currency
                                  ? convertINRToCurrency(
                                    total?.total_pl,
                                    parseFloat(currency?.value?.value), true
                                  )
                                  : total?.actual_pl;
                                pl = parseFloat(pl);

                                let bf = currency
                                  ? convertINRToCurrency(
                                    total?.betFairShare,
                                    parseFloat(currency?.value?.value), true
                                  )
                                  : total?.betFairShare;
                                bf = parseFloat(bf);

                                let cs = currency
                                  ? convertINRToCurrency(
                                    (
                                      total?.clientShare),
                                    parseFloat(currency?.value?.value), true
                                  )
                                  : (
                                    total?.clientShare);
                                cs = parseFloat(cs);

                                // { console.log("calculateTotalPLByColumn 2", pl) }
                                return (
                                  <td key={subIndex}>
                                    <span
                                      className={`${pl > 0 ? "runner-p" : "runner-l"
                                        }`}
                                    >
                                      {pl > 0 ? "" : "-"}
                                      {Math.abs(pl)?.toFixed(2)}
                                    </span>
                                    {betFairBooks && <div className={`${bf <= 0
                                      ? "runner-p"
                                      : "runner-l"}`}>
                                        {stx ? null : <>
                                        [<>BS:
                                        <span

>
                                          {bf <= 0 ? "" : "-"}
                                          {bf?.toFixed(2)}
                                        </span></>]
                                          </>}
                                    </div>}
                                    {/* {(sharedBooks && total?.role != 7) && <div>[<>CS:
                                      <span
                                        className=""
                                      >
                                        {currency
                                          ? convertINRToCurrency(
                                            ((total?.total_pl / 100) *
                                              total?.clientShare),
                                            parseFloat(currency?.value?.value)
                                          )
                                          : ((total?.total_pl / 100) *
                                            total?.clientShare).toFixed(2)}
                                      </span></>]
                                    </div>} */}

                                    {((sharedBooks && total?.clientShareVisibility) && !isNaN(total?.clientShare)) && <div className={`${cs <= 0
                                      ? "runner-p"
                                      : "runner-l"}`}>[<>CS:
                                        <span

                                        >
                                          {cs <= 0 ? "" : "-"}
                                          {Math.abs(cs?.toFixed(2))}
                                        </span></>]
                                    </div>}
                                  </td>
                                )
                              }
                            )}
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </div>
            </Collapse>
          </Card>
        )}
      </>
    );
  }
);

export default BookView;
