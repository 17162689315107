import { handlePlAsPerBff } from "./helper";

export const handlePlAsPerBfAndCs = (actualPl, bfCal, csCal, bf, cs) => {
  if (bf < 0) {
    bfCal = -1 * bf;
  } else {
    bfCal = -bf;
  }

  if (cs < 0) {
    csCal = -1 * cs;
  } else {
    csCal = -cs;
  }
  if (bf !== undefined && cs !== undefined) {
    // console.log({ actualPl: actualPl, bfCal: bfCal, csCal: csCal, bf: bf, cs: cs });
    let finalPl = 0;
    //pl calculation
    //bf and cs positive 
    if (bfCal >= 0 & csCal >= 0) {
      let total_share = (Math.abs(bf) + Math.abs(cs));
      if (actualPl >= total_share) {
        // console.log("a");
        finalPl = actualPl - total_share;
      } else {
        // console.log("b");
        finalPl = total_share - Math.abs(actualPl);
      }
    } else if (bfCal >= 0 & csCal <= 0) {
      let total_share = (Math.abs(bf) + Math.abs(cs));
      if (actualPl >= bfCal) {
        // console.log("c");
        finalPl = actualPl - total_share;
      } else {
        // console.log("d");
        finalPl = total_share - Math.abs(actualPl);
      }
    } else if (bfCal <= 0 & csCal >= 0) {
      let total_share = (Math.abs(bf) + Math.abs(cs));
      if (actualPl >= csCal) {
        // console.log("e");
        finalPl = actualPl - total_share;
      } else {
        // console.log("f");
        finalPl = total_share - Math.abs(actualPl);
      }
    } else if (bfCal >= 0 & csCal >= 0) {
      let total_share = (Math.abs(bf) + Math.abs(cs));
      if (actualPl >= csCal) {
        // console.log("g");
        finalPl = actualPl - total_share;
      } else {
        // console.log("h");
        finalPl = total_share - Math.abs(actualPl);
      }
    } else {
      let total_share = (Math.abs(bf) + Math.abs(cs));
      finalPl = Math.abs(actualPl) - total_share;
      if (actualPl < 0) {
        if (finalPl > 0) {
          // console.log("i");
          finalPl = -1 * finalPl;
        }
      } else {
        if (finalPl < 0) {
          // console.log("j");
          finalPl = -1 * finalPl;
        }
      }
      // console.log("h");
    }
    // console.log("finalPl", finalPl);
    return finalPl;
  }
};

export const handlePlAsPerBf = (actualPl, bfCal, bf) => {
  let finalPl = 0;
  if (bf < 0) {
    bfCal = -1 * bf;
  } else {
    bfCal = -bf;
  }
  if (bfCal >= 0) {
    let total_share = (Math.abs(bf));
    if (actualPl >= total_share) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else {
    let total_share = Math.abs(bf);
    finalPl = Math.abs(actualPl) - total_share;
    if (actualPl < 0) {
      if (finalPl > 0) {
        finalPl = -1 * finalPl;
      }
    } else {
      if (finalPl < 0) {
        finalPl = -1 * finalPl;
      }
    }
  }
  return finalPl;
};

export const handlePlAsPerCs = (actualPl, csCal, cs) => {
  let finalPl = 0;
  if (cs < 0) {
    csCal = -1 * cs;
  } else {
    csCal = -cs;
  }

  if (csCal >= 0) {
    let total_share = (Math.abs(cs));
    if (actualPl >= total_share) {
      finalPl = actualPl - total_share;
    } else {
      finalPl = total_share - Math.abs(actualPl);
    }
  } else {
    let total_share = Math.abs(cs);
    finalPl = Math.abs(actualPl) - total_share;
    if (actualPl < 0) {
      if (finalPl > 0) {
        finalPl = -1 * finalPl;
      }
    } else {
      if (finalPl < 0) {
        finalPl = -1 * finalPl;
      }
    }
  }
  return finalPl;
};

export const handleLineBookPlAsPerCs = (betFairBooks, sharedBooks, betFairShare, clientShare, directParent, final, bf, cs) => {
  let toggle = false;
  let bookValue = 0;
  let bfCal = 0;
  let csCal = 0;
  if (betFairBooks && sharedBooks) {
    if (directParent) {
      // bookValue = Math.abs(final) - (betFairShare);
      // bookValue = handlePlAsPerBff(final, bfCal, betFairShare);
      bookValue = handlePlAsPerBf(final, bfCal, bf);
      // console.log("case 1", bookValue);
    } else {
      // bookValue = Math.abs(final) - (betFairShare + clientShare);
      bookValue = handlePlAsPerBfAndCs(final, bfCal, csCal, bf, cs);
      // console.log("case 2", bookValue);
    }
    // toggle = true;
  } else if (betFairBooks) {
    // bookValue = Math.abs(final) - betFairShare;
    bookValue = handlePlAsPerBf(final, bfCal, bf);
    // console.log("case 3", bookValue);
    // toggle = true;
  } else if (sharedBooks) {
    if (directParent) {
      bookValue = final;
      // console.log("case 4", bookValue);
    } else {
      // bookValue = Math.abs(final) - clientShare;
      bookValue = handlePlAsPerCs(final, csCal, cs);
      // console.log("case 5", bookValue);
    }
    // toggle = true;
  } else {
    bookValue = final;
    // console.log("case 6", bookValue);
  }
  // console.log("final", bookValue);
  return ({ bookValue: bookValue, toggle: toggle })
};