import { POST_STX_MARKET_ASSIGNING, POST_STX_MARKET_ASSIGNING_FAILURE, POST_STX_MARKET_ASSIGNING_SUCCESS } from "../../action/types";

  
  const INIT_STATE = {
    loading: false,
    data: null,
  };
  
  const stxMarketAssigning = (state = INIT_STATE, action) => {
    switch (action.type) {
      case POST_STX_MARKET_ASSIGNING:
        return { ...state, loading: true };
      case POST_STX_MARKET_ASSIGNING_SUCCESS:
        return { ...state, data: action.payload, loading: false };
      case POST_STX_MARKET_ASSIGNING_FAILURE:
        return { ...state, loading: false };
      default:
        return state;
    }
  };
  
  export default stxMarketAssigning;