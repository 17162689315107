// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-label {
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.custom-label > input[type='checkbox'] {
  position: absolute;
  transform: scale(0);
}

.custom-label > input[type='checkbox']:checked ~ .checkbox {
  transform: rotate(45deg);
  width: 14px;
  margin-left: 5px;
  border-color: #24c78e;
  border-width: 5px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 0;
}

.custom-label > .checkbox {
  display: block;
  width: inherit;
  height: inherit;
  border: solid 3px #2a2a2ab7;
  border-radius: 6px;
  transition: all 0.375s;
}

.checkbox-reverted {
  transform: none;
  width: 20px;
  margin-left: 0;
  border-color: #2a2a2ab7;
  border-width: 3px;
  border-radius: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/PageLayout/EventManagement/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,WAAW;EACX,gBAAgB;EAChB,qBAAqB;EACrB,iBAAiB;EACjB,6BAA6B;EAC7B,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,eAAe;EACf,2BAA2B;EAC3B,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,cAAc;EACd,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".custom-label {\n  display: block;\n  width: 20px;\n  height: 20px;\n  cursor: pointer;\n}\n\n.custom-label > input[type='checkbox'] {\n  position: absolute;\n  transform: scale(0);\n}\n\n.custom-label > input[type='checkbox']:checked ~ .checkbox {\n  transform: rotate(45deg);\n  width: 14px;\n  margin-left: 5px;\n  border-color: #24c78e;\n  border-width: 5px;\n  border-top-color: transparent;\n  border-left-color: transparent;\n  border-radius: 0;\n}\n\n.custom-label > .checkbox {\n  display: block;\n  width: inherit;\n  height: inherit;\n  border: solid 3px #2a2a2ab7;\n  border-radius: 6px;\n  transition: all 0.375s;\n}\n\n.checkbox-reverted {\n  transform: none;\n  width: 20px;\n  margin-left: 0;\n  border-color: #2a2a2ab7;\n  border-width: 3px;\n  border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
