export const TRANSLATION_FILE_PATH =
  process.env.REACT_APP_TRANSLATION_FILE_PATH;
export const TWO_FACTOR_OTP_TIMER = 60

export const dateFilter = [
  { value: "1",  label: "1 Days" },
  { value: "7",  label: "7 Days" },
  { value: "14", label: "14 Days" },
  { value: "30", label: "30 Days" }
];
export const STX_AVAILABLE_SPORTS = ['Cricket','Soccer']

export const homeRoles = ["home", "home_winner_regulation_3way","home_winner_regulation"];
export const awayRoles = ["away", "away_winner_regulation_3way","away_winner_regulation"];
export const drawRoles = ["draw", "draw_winner_regulation_3way","draw_regulation_3way"];

export const STX_SLUG_NAME = "betxfair_odds_slug"

export const STX_AccountDetails_exclude = [
  "baseFeePercent",
  "base_fee_percent",
  "escrow",
  "totalWithdrawals",
  "total_withdrawals",
  "points",
  "feeSchedule",
  "fee_schedule",
  "positionPremiumLiability",
  "position_premium_liability",
  "loyalty_tier",
  "loyaltyTier",
  "userId",
  "accountId"
];
