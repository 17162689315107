import React from "react";

const DetailsDropdown = ({ details }) => {
  return (
    <select disabled style={{ cursor: "not-allowed", color: "#555" }}>
      {details && details.length > 0 ? (
        details.map((detail, index) => (
          <option key={index} value={detail.name}>
            {detail.label}
          </option>
        ))
      ) : (
        <option>No details available</option>
      )}
    </select>
  );
};
export default DetailsDropdown