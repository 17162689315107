// =====================Login===========================
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// =====================TWO AUTHENTICATION===========================
export const TWO_AUTHENTICATION = "TWO_AUTHENTICATION";
export const TWO_AUTHENTICATION_SUCCESS = "TWO_AUTHENTICATION_SUCCESS";
export const TWO_AUTHENTICATION_FAILURE = "TWO_AUTHENTICATION_FAILURE";

// =====================TWO AUTHENTICATION HIDE===========================
export const CLIENT_2FA_DETAILS = "CLIENT_2FA_DETAILS";
export const CLIENT_2FA_DETAILS_SUCCESS = "CLIENT_2FA_DETAILS_SUCCESS";
export const CLIENT_2FA_DETAILS_FAILURE = "CLIENT_2FA_DETAILS_FAILURE";

// =====================TWO AUTHENTICATION MANAGEMENT TOGGLE ===========================
export const TWO_FACTOR_TOGGLE_SETTING = "TWO_FACTOR_TOGGLE_SETTING";
export const TWO_FACTOR_TOGGLE_SETTING_SUCCESS =
  "TWO_FACTOR_TOGGLE_SETTING_SUCCESS";
export const TWO_FACTOR_TOGGLE_SETTING_FAILURE =
  "TWO_FACTOR_TOGGLE_SETTING_FAILURE";

// =====================Logout===========================
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// =====================Get Layers===========================
export const GET_LAYERS = "GET_LAYERS";
export const GET_LAYERS_SUCCESS = "GET_LAYERS_SUCCESS";
export const GET_LAYERS_FAILURE = "GET_LAYERS_FAILURE";

// =====================Add Layers===========================
export const ADD_LAYERS = "ADD_LAYERS";
export const ADD_LAYERS_SUCCESS = "ADD_LAYERS_SUCCESS";
export const ADD_LAYERS_FAILURE = "ADD_LAYERS_FAILURE";

// =====================Add Currency===========================
export const ADD_CURRENCY = "ADD_CURRENCY";
export const ADD_CURRENCY_SUCCESS = "ADD_CURRENCY_SUCCESS";
export const ADD_CURRENCY_FAILURE = "ADD_CURRENCY_FAILURE";

//====================UPDATE CURRENCY==========================

export const UPDATE_CURRENCY_REQUEST = "UPDATE_CURRENCY_REQUEST";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";
export const UPDATE_CURRENCY_FAILURE = "UPDATE_CURRENCY_FAILURE";

// =====================Get Currency===========================
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";
export const GET_CURRENCY_FAILURE = "GET_CURRENCY_FAILURE";

// =====================Get Sports From API===========================
export const GET_SPORTS = "GET_SPORTS";
export const GET_SPORTS_SUCCESS = "GET_SPORTS_SUCCESS";
export const GET_SPORTS_FAILURE = "GET_SPORTS_FAILURE";

// =====================Get Leagues From API===========================
export const GET_LEAGUES = "GET_LEAGUES";
export const GET_LEAGUES_SUCCESS = "GET_LEAGUES_SUCCESS";
export const GET_LEAGUES_FAILURE = "GET_LEAGUES_FAILURE";

// =====================Get Events From API===========================
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";

// =====================Get Markets From API===========================
export const GET_MARKETS = "GET_MARKETS";
export const GET_MARKETS_SUCCESS = "GET_MARKETS_SUCCESS";
export const GET_MARKETS_FAILURE = "GET_MARKETS_FAILURE";

// =====================Add Panel===========================
export const ADD_PANEL = "ADD_PANEL";
export const ADD_PANEL_SUCCESS = "ADD_PANEL_SUCCESS";
export const ADD_PANEL_FAILURE = "ADD_PANEL_FAILURE";

// =====================Get Management Data Panel===========================
export const GET_MANAGE_EVENTDATA = "GET_MANAGE_EVENTDATA";
export const GET_MANAGE_EVENTDATA_SUCCESS = "GET_MANAGE_EVENTDATA_SUCCESS";
export const GET_MANAGE_EVENTDATA_FAILURE = "GET_MANAGE_EVENTDATA_FAILURE";

// =====================Get Db Sports Data Panel===========================
export const GET_DBSPORTS = "GET_DBSPORTS";
export const GET_DBSPORTS_SUCCESS = "GET_DBSPORTS_SUCCESS";
export const GET_DBSPORTS_FAILURE = "GET_DBSPORTS_FAILURE";

// =====================Update Datas of Management===========================
export const UPDATE_EVENTMANAGEMENT_DATAS = "UPDATE_EVENTMANAGEMENT_DATAS";
export const UPDATE_EVENTMANAGEMENT_DATAS_SUCCESS =
  "UPDATE_EVENTMANAGEMENT_DATAS_SUCCESS";
export const UPDATE_EVENTMANAGEMENT_DATAS_FAILURE =
  "UPDATE_EVENTMANAGEMENT_DATAS_FAILURE";

// =====================Get Runners Data From API Panel===========================
export const GET_RUNNERS = "GET_RUNNERS";
export const GET_RUNNERS_SUCCESS = "GET_RUNNERS_SUCCESS";
export const GET_RUNNERS_FAILURE = "GET_RUNNERS_FAILURE";

// =====================Get Leagues Data From DB Panel===========================
export const GET_DBLEAGUES = "GET_DBLEAGUES";
export const GET_DBLEAGUES_SUCCESS = "GET_DBLEAGUES_SUCCESS";
export const GET_DBLEAGUES_FAILURE = "GET_DBLEAGUES_FAILURE";

// =====================Get Runners Data From DB===========================
export const GET_DBRUNNERS = "GET_DBRUNNERS";
export const GET_DBRUNNERS_SUCCESS = "GET_DBRUNNERS_SUCCESS";
export const GET_DBRUNNERS_FAILURE = "GET_DBRUNNERS_FAILURE";

// =====================Add Results===========================
export const ADD_RESULT = "ADD_RESULT";
export const ADD_RESULT_SUCCESS = "ADD_RESULT_SUCCESS";
export const ADD_RESULT_FAILURE = "ADD_RESULT_FAILURE";

// =====================Get Results===========================
export const GET_RESULT = "GET_RESULT";
export const GET_RESULT_SUCCESS = "GET_RESULT_SUCCESS";
export const GET_RESULT_FAILURE = "GET_RESULT_FAILURE";

// =====================Get Sport Settings===========================
export const GET_SPORT_SETTINGS = "GET_SPORT_SETTINGS";
export const GET_SPORT_SETTINGS_SUCCESS = "GET_SPORT_SETTINGS_SUCCESS";
export const GET_SPORT_SETTINGS_FAILURE = "GET_SPORT_SETTINGS_FAILURE";

// =====================Post Sport Settings===========================
export const POST_SPORT_SETTINGS = "POST_SPORT_SETTINGS";
export const POST_SPORT_SETTINGS_SUCCESS = "POST_SPORT_SETTINGS_SUCCESS";
export const POST_SPORT_SETTINGS_FAILURE = "POST_SPORT_SETTINGS_FAILURE";

// =====================Update Settings===========================
export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";
export const UPDATE_SETTING_FAILURE = "UPDATE_SETTING_FAILURE";

// =====================Get All Bets===========================

export const GET_ALLBETS = "GET_ALLBETS";
export const GET_ALLBETS_SUCCESS = "GET_ALLBETS_SUCCESS";
export const GET_ALLBETS_FAILURE = "GET_ALLBETS_FAILURE";

// =====================Delete USer Bets===========================

export const DELETE_USERBET = "DELETE_USERBET";
export const DELETE_USERBET_SUCCESS = "DELETE_USERBET_SUCCESS";
export const DELETE_USERBET_FAILURE = "DELETE_USERBET_FAILURE";

//  =====================GLobal Markets Data===========================
export const GLOBAL_MARKET_CODES = "GLOBAL_MARKET_CODES";
export const GLOBAL_MARKET_CODES_SUCCESS = "GLOBAL_MARKET_CODES_SUCCESS";

//  =====================Get Event Market Data===========================
export const GET_EVENT_MARKET = "GET_EVENT_MARKET";
export const GET_EVENT_MARKET_SUCCESS = "GET_EVENT_MARKET_SUCCESS";
export const GET_EVENT_MARKET_FAILURE = "GET_EVENT_MARKET_FAILURE";

// =====================ADD DEPOSIT (ADD)==============================
export const ADD_DEPOSIT = "ADD_DEPOSIT";
export const ADD_DEPOSIT_SUCCESS = "ADD_DEPOSIT_SUCCESS";
export const ADD_DEPOSIT_FAILURE = "ADD_DEPOSIT_FAILURE";

// =====================ADD WITHDRAWAL (ADD)===========================
export const ADD_WITHDRAWAL = "ADD_WITHDRAWAL";
export const ADD_WITHDRAWAL_SUCCESS = "ADD_WITHDRAWAL_SUCCESS";
export const ADD_WITHDRAWAL_FAILURE = "ADD_WITHDRAWAL_FAILURE";

// =====================GET WITHDRAWAL (GET)===========================
export const GET_WITHDRAWAL = "GET_WITHDRAWAL";
export const GET_WITHDRAWAL_SUCCESS = "GET_WITHDRAWAL_SUCCESS";
export const GET_WITHDRAWAL_FAILURE = "GET_WITHDRAWAL_FAILURE";

// =====================GET USER BALANCE (GET)===========================
export const GET_USER_BALANCE = "GET_USER_BALANCE";
export const GET_USER_BALANCE_SUCCESS = "GET_USER_BALANCE_SUCCESS";
export const GET_USER_BALANCE_FAILURE = "GET_USER_BALANCE_FAILURE";

// =====================GET USER EXPOSURE (GET)===========================
export const GET_USER_EXPOSURE = "GET_USER_EXPOSURE";
export const GET_USER_EXPOSURE_SUCCESS = "GET_USER_EXPOSURE_SUCCESS";
export const GET_USER_EXPOSURE_FAILURE = "GET_USER_EXPOSURE_FAILURE";

// =====================ADD PERMISSION (ADD)===========================
export const ADD_PERMISSION = "ADD_PERMISSION";
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_FAILURE = "ADD_PERMISSION_FAILURE";

// =====================ADD CREDIT REFERENCE (ADD)===========================
export const ADD_CREDIT_REFERENCE = "ADD_CREDIT_REFERENCE";
export const ADD_CREDIT_REFERENCE_SUCCESS = "ADD_CREDIT_REFERENCE_SUCCESS";
export const ADD_CREDIT_REFERENCE_FAILURE = "ADD_CREDIT_REFERENCE_FAILURE";

// =====================RESET_PASSWORD ===========================
export const RESET_PASSWORD = "ADD_RESET_PASSWORD_REFERENCE";
export const RESET_PASSWORD_SUCCESS = "ADD_RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "ADD_RESET_PASSWORD_FAILURE";

// =====================CHANGE_PASSWORD ===========================
export const CHANGE_PASSWORD = "ADD_CHANGE_PASSWORD_REFERENCE";
export const CHANGE_PASSWORD_SUCCESS = "ADD_CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "ADD_CHANGE_PASSWORD_FAILURE";

// =====================GET RESULT TRANSACTION (GET)===========================
export const GET_RESULT_TRANSACTION = "GET_RESULT_TRANSACTION";
export const GET_RESULT_TRANSACTION_SUCCESS = "GET_RESULT_TRANSACTION_SUCCESS";
export const GET_RESULT_TRANSACTION_FAILURE = "GET_RESULT_TRANSACTION_FAILURE";

// =====================GET RESULT TRANSACTION (GET)===========================
export const GET_RESULT_TRANSACTION_DETAILS = "GET_RESULT_TRANSACTION_DETAILS";
export const GET_RESULT_TRANSACTION_DETAILS_SUCCESS =
  "GET_RESULT_TRANSACTION_DETAILS_SUCCESS";
export const GET_RESULT_TRANSACTION_DETAILS_FAILURE =
  "GET_RESULT_TRANSACTION_DETAILS_FAILURE";


// =====================GET COMMISSION REPORTS (GET)===========================
export const GET_COMMISSION_REPORTS = "GET_COMMISSION_REPORTS";
export const GET_COMMISSION_REPORTS_SUCCESS = "GET_COMMISSION_REPORTS_SUCCESS";
export const GET_COMMISSION_REPORTS_FAILURE = "GET_COMMISSION_REPORTS_FAILURE";


// =====================GET USER BETS BY EVENT (GET)===========================
export const GET_USERBETS_EVENT = "GET_USERBETS_EVENT";
export const GET_USERBETS_EVENT_SUCCESS = "GET_USERBETS_EVENT_SUCCESS";
export const GET_USERBETS_EVENT_FAILURE = "GET_USERBETS_EVENT_FAILURE";

// =====================GET_REPORT_TRANSACTION (GET)===========================
export const GET_REPORT_TRANSACTION = "GET_REPORT_TRANSACTION";
export const GET_REPORT_TRANSACTION_SUCCESS = "GET_REPORT_TRANSACTION_SUCCESS";
export const GET_REPORT_TRANSACTION_FAILURE = "GET_REPORT_TRANSACTION_FAILURE";

// =====================GET ACTIVE FANCIES (GET)===========================
export const GET_ACTIVE_FANCIES = "GET_ACTIVE_FANCIES";
export const GET_ACTIVE_FANCIES_SUCCESS = "GET_ACTIVE_FANCIES_SUCCESS";
export const GET_ACTIVE_FANCIES_FAILURE = "GET_ACTIVE_FANCIES_FAILURE";

// =====================SETTLEMENT===========================
export const SETTLEMENT = "SETTLEMENT";
export const SETTLEMENT_SUCCESS = "SETTLEMENT_SUCCESS";
export const SETTLEMENT_FAILURE = "SETTLEMENT_FAILURE";

// =====================GET SETTLEMENT===========================
export const GET_SETTLEMENT = "GET_SETTLEMENT";
export const GET_SETTLEMENT_SUCCESS = "GET_SETTLEMENT_SUCCESS";
export const GET_SETTLEMENT_FAILURE = "GET_SETTLEMENT_FAILURE";

// ====================DASHBOARD===========================
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILURE = "GET_DASHBOARD_DATA_FAILURE";

// ====================Betfair balance===========================
export const GET_BETFAIR_BALANCE = "GET_BETFAIR_BALANCE";
export const GET_BETFAIR_BALANCE_SUCCESS = "GET_BETFAIR_BALANCE_SUCCESS";
export const GET_BETFAIR_BALANCE_FAILURE = "GET_BETFAIR_BALANCE_FAILURE";

// ====================BANKING===========================
export const GET_BANKING_DATA = "GET_BANKING_DATA";
export const GET_BANKING_DATA_SUCCESS = "GET_BANKING_DATA_SUCCESS";
export const GET_BANKING_DATA_FAILURE = "GET_BANKING_DATA_FAILURE";

//  =====================GLobal Currency Value===========================
export const GLOBAL_CURRENCY_VALUE = "GLOBAL_CURRENCY_VALUE";
export const GLOBAL_CURRENCY_VALUE_SUCCESS = "GLOBAL_CURRENCY_VALUE_SUCCESS";

// ====================INACTIVE LAYERS===========================
export const GET_INACTIVE_LAYERS = "GET_INACTIVE_LAYERS";
export const GET_INACTIVE_LAYERS_SUCCESS = "GET_INACTIVE_LAYERS_SUCCESS";
export const GET_INACTIVE_LAYERS_FAILURE = "GET_INACTIVE_LAYERS_FAILURE";

// ==================== ANALYSIS DATA ===========================
export const GET_ANALYSIS_DATAS = "GET_ANALYSIS_DATAS";
export const GET_ANALYSIS_DATAS_SUCCESS = "GET_ANALYSIS_DATAS_SUCCESS";
export const GET_ANALYSIS_DATAS_FAILURE = "GET_ANALYSIS_DATAS_FAILURE";

// ==================== GET BETS AND BOOKS ===========================
export const GET_BETS_AND_BOOKS = "GET_BETS_AND_BOOKS";
export const GET_BETS_AND_BOOKS_SUCCESS = "GET_BETS_AND_BOOKS_SUCCESS";
export const GET_BETS_AND_BOOKS_FAILURE = "GET_BETS_AND_BOOKS_FAILURE";

// ==================== GET BETS STATUS ===========================
export const GET_BET_STATUS = "GET_BET_STATUS";
export const GET_BET_STATUS_SUCCESS = "GET_BET_STATUS_SUCCESS";
export const GET_BET_STATUS_FAILURE = "GET_BET_STATUS_FAILURE";

// ==================== GET BETS By MARKET TYPE ===========================
export const GET_BET_BY_MARKETTYPE = "GET_BET_BY_MARKETTYPE";
export const GET_BET_BY_MARKETTYPE_SUCCESS = "GET_BET_BY_MARKETTYPE_SUCCESS";
export const GET_BET_BY_MARKETTYPE_FAILURE = "GET_BET_BY_MARKETTYPE_FAILURE";

// ==================== LOGIN HISTORY ===========================
export const LOGIN_HISTORY = "LOGIN_HISTORY";
export const LOGIN_HISTORY_SUCCESS = "LOGIN_HISTORY_SUCCESS";
export const LOGIN_HISTORY_FAILURE = "LOGIN_HISTORY_FAILURE";

// ==================== GET LEAGUE SETTING ===========================
export const GET_LEAGUE_SETTING = "GET_LEAGUE_SETTING";
export const GET_LEAGUE_SETTING_SUCCESS = "GET_LEAGUE_SETTING_SUCCESS";
export const GET_LEAGUE_SETTING_FAILURE = "GET_LEAGUE_SETTING_FAILURE";

// ==================== GET MATCH SETTING ===========================
export const GET_MATCH_SETTING = "GET_MATCH_SETTING";
export const GET_MATCH_SETTING_SUCCESS = "GET_MATCH_SETTING_SUCCESS";
export const GET_MATCH_SETTING_FAILURE = "GET_MATCH_SETTING_FAILURE";

// ==================== POST LEAGUE SETTING ===========================
export const POST_LEAGUE_SETTING = "POST_LEAGUE_SETTING";
export const POST_LEAGUE_SETTING_SUCCESS = "POST_LEAGUE_SETTING_SUCCESS";
export const POST_LEAGUE_SETTING_FAILURE = "POST_LEAGUE_SETTING_FAILURE";

// ==================== POST MATCH SETTING ===========================
export const POST_MATCH_SETTING = "POST_MATCH_SETTING";
export const POST_MATCH_SETTING_SUCCESS = "POST_MATCH_SETTING_SUCCESS";
export const POST_MATCH_SETTING_FAILURE = "POST_MATCH_SETTING_FAILURE";

// ==================== GET EVENT DB ===========================
export const GET_DBEVENT = "GET_DBEVENT";
export const GET_DBEVENT_SUCCESS = "GET_DBEVENT_SUCCESS";
export const GET_DBEVENT_FAILURE = "GET_DBEVENT_FAILURE";

// ==================== GET AUTH DETAIL ===========================
export const AUTH_DETAIL = "AUTH_DETAIL";
export const AUTH_DETAIL_SUCCESS = "AUTH_DETAIL_SUCCESS";
export const AUTH_DETAIL_FAILURE = "AUTH_DETAIL_FAILURE";

// ==================== GET CASINO BETS ===========================
export const GET_CASINO_BETS_BY_DATE = "GET_CASINO_BETS_BY_DATE";
export const GET_CASINO_BETS_BY_DATE_SUCCESS =
  "GET_CASINO_BETS_BY_DATE_SUCCESS";
export const GET_CASINO_BETS_BY_DATE_FAILURE =
  "GET_CASINO_BETS_BY_DATE_FAILURE";

// ==================== GET CASINO BETS REPORT ===========================
export const GET_CASINO_BETS_REPORT = "GET_CASINO_BETS_REPORT";
export const GET_CASINO_BETS_REPORT_SUCCESS = "GET_CASINO_BETS_REPORT_SUCCESS";
export const GET_CASINO_BETS_REPORT_FAILURE = "GET_CASINO_BETS_REPORT_FAILURE";

// ==================== ADD ODD LIMIT ===========================
export const ADD_ODDLIMIT = "ADD_ODDLIMIT";
export const ADD_ODDLIMIT_SUCCESS = "ADD_ODDLIMIT_SUCCESS";
export const ADD_ODDLIMIT_FAILURE = "ADD_ODDLIMIT_FAILURE";

// ==================== EVENT DEACTIVATED ===========================

export const EVENT_DEACTIVATED = "EVENT_DEACTIVATED";
export const EVENT_DEACTIVATED_SUCCESS = "EVENT_DEACTIVATED_SUCCESS";
export const EVENT_DEACTIVATED_FAILURE = "EVENT_DEACTIVATED_FAILURE";

// ==================== ROLLBACK ===========================

export const ROLLBACK = "ROLLBACK";
export const ROLLBACK_SUCCESS = "ROLLBACK_SUCCESS";
export const ROLLBACK_FAILURE = "ROLLBACK_FAILURE";

//  ==================== DELETE MULTIPLE EVENTS===========================

export const DELETE_MULTIPLE_EVENTS = "DELETE_MULTIPLE_EVENTS";
export const DELETE_MULTIPLE_EVENTS_SUCCESS = "DELETE_MULTIPLE_EVENTS_SUCCESS";
export const DELETE_MULTIPLE_EVENTS_FAILURE = "DELETE_MULTIPLE_EVENTS_FAILURE";

//  ==================== GET DELETED BETS===========================

export const GET_DELETED_BETS = "GET_DELETED_BETS";
export const GET_DELETED_BETS_SUCCESS = "GET_DELETED_BETS_SUCCESS";
export const GET_DELETED_BETS_FAILURE = "GET_DELETED_BETS_FAILURE";

//  ==================== GET BET FILTER CATEGORIES ===========================

export const GET_BET_FILTER = "GET_BET_FILTER";
export const GET_BET_FILTER_SUCCESS = "GET_BET_FILTER_SUCCESS";
export const GET_BET_FILTER_FAILURE = "GET_BET_FILTER_FAILURE";

// =====================Add Banners===========================
export const ADD_BANNER = "ADD_BANNER";
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS";
export const ADD_BANNER_FAILURE = "ADD_BANNER_FAILURE";

// ==================== GET BANNERS ===========================
export const GET_BANNER = "GET_BANNER";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_FAILURE = "GET_BANNER_FAILURE";

// ==================== UPDATE BANNERS ===========================
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS";
export const UPDATE_SLIDER_FAILURE = "UPDATE_SLIDER_FAILURE";

// ==================== DELETE BANNERS ===========================
export const DELETE_SLIDER = "DELETE_SLIDER";
export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS";
export const DELETE_SLIDER_FAILURE = "DELETE_SLIDER_FAILURE";

// =====================Add Managers===========================
export const ADD_MANAGER = "ADD_MANAGER";
export const ADD_MANAGER_SUCCESS = "ADD_MANAGER_SUCCESS";
export const ADD_MANAGER_FAILURE = "ADD_MANAGER_FAILURE";

// =====================Get Managers===========================
export const GET_MANAGERS = "GET_MANAGERS";
export const GET_MANAGERS_SUCCESS = "GET_MANAGERS_SUCCESS";
export const GET_MANAGERS_FAILURE = "GET_MANAGERS_FAILURE";

// =====================Get Accounts Manager Reports===========================
export const GET_ACCOUNTS_MANAGER_REPORTS = "GET_ACCOUNTS_MANAGER_REPORTS";
export const GET_ACCOUNTS_MANAGER_REPORTS_SUCCESS =
  "GET_ACCOUNTS_MANAGER_REPORTS_SUCCESS";
export const GET_ACCOUNTS_MANAGER_REPORTS_FAILURE =
  "GET_ACCOUNTS_MANAGER_REPORTS_FAILURE";

// =====================Add White Labels===========================
export const ADD_WHITELABEL = "ADD_WHITELABEL";
export const ADD_WHITELABEL_SUCCESS = "ADD_WHITELABEL_SUCCESS";
export const ADD_WHITELABEL_FAILURE = "ADD_WHITELABEL_FAILURE";

// =====================Get White Labels===========================
export const GET_WHITELABEL = "GET_WHITELABEL";
export const GET_WHITELABEL_SUCCESS = "GET_WHITELABEL_SUCCESS";
export const GET_WHITELABEL_FAILURE = "GET_WHITELABEL_FAILURE";

// =====================Fetch WiteLabel DATA by DOMAIN ===========================
export const FETCH_WHITELABEL_DATA = "FETCH_WHITELABEL_DATA";
export const FETCH_WHITELABEL_DATA_SUCCESS = "FETCH_WHITELABEL_DATA_SUCCESS";
export const FETCH_WHITELABEL_DATA_FAILURE = "FETCH_WHITELABEL_DATA_FAILURE";

// =====================Change Betfair currency ===========================
export const CHANGE_BETFAIR_CURRENCY = "CHANGE_BETFAIR_CURRENCY";
export const CHANGE_BETFAIR_CURRENCY_SUCCESS =
  "CHANGE_BETFAIR_CURRENCY_SUCCESS";
export const CHANGE_BETFAIR_CURRENCY_FAILURE =
  "CHANGE_BETFAIR_CURRENCY_FAILURE";

// =====================Udate Betfair Shares ===========================
export const UPDATE_BETFAIR_SHARES = "UPDATE_BETFAIR_SHARES";
export const UPDATE_BETFAIR_SHARES_SUCCESS = "UPDATE_BETFAIR_SHARES_SUCCESS";
export const UPDATE_BETFAIR_SHARES_FAILURE = "UPDATE_BETFAIR_SHARES_FAILURE";

// =====================Udate COMMISSION PERCENTAGE ===========================
export const UPDATE_COMMISSION_PERCENTAGE = "UPDATE_COMMISSION_PERCENTAGE";
export const UPDATE_COMMISSION_PERCENTAGE_SUCCESS = "UPDATE_COMMISSION_PERCENTAGE_SUCCESS";
export const UPDATE_COMMISSION_PERCENTAGE_FAILURE = "UPDATE_COMMISSION_PERCENTAGE_FAILURE";

// =====================GET Betfair COUNTIRES ===========================
export const GET_BETFAIR_COUNTRIES = "GET_BETFAIR_COUNTRIES";
export const GET_BETFAIR_COUNTRIES_SUCCESS = "GET_BETFAIR_COUNTRIES_SUCCESS";
export const GET_BETFAIR_COUNTRIES_FAILURE = "GET_BETFAIR_COUNTRIES_FAILURE";

// =====================GET Betfair VENUES ===========================
export const GET_BETFAIR_VENUES = "GET_BETFAIR_VENUES";
export const GET_BETFAIR_VENUES_SUCCESS = "GET_BETFAIR_VENUES_SUCCESS";
export const GET_BETFAIR_VENUES_FAILURE = "GET_BETFAIR_VENUES_FAILURE";

// =====================GET Betfair Time Ranges ===========================
export const GET_BETFAIR_TIMERANGES = "GET_BETFAIR_TIMERANGES";
export const GET_BETFAIR_TIMERANGES_SUCCESS = "GET_BETFAIR_TIMERANGES_SUCCESS";
export const GET_BETFAIR_TIMERANGES_FAILURE = "GET_BETFAIR_TIMERANGES_FAILURE";

// ===================== TV URL (POST)===========================
export const GET_TV_URL = "GET_TV_URL";
export const GET_TV_URL_SUCCESS = "GET_TV_URL_SUCCESS";
export const GET_TV_URL_FAILURE = "GET_TV_URL_FAILURE";

// ===================== Update Radar ID===========================
export const UPDATE_RADAR_ID = "UPDATE_RADAR_ID";
export const UPDATE_RADAR_ID_SUCCESS = "UPDATE_RADAR_ID_SUCCESS";
export const UPDATE_RADAR_ID_FAILURE = "UPDATE_RADAR_ID_FAILURE";

// ===================== Add Race Events ===========================
export const ADD_RACE_EVENTS = "ADD_RACE_EVENTS";
export const ADD_RACE_EVENTS_SUCCESS = "ADD_RACE_EVENTS_SUCCESS";
export const ADD_RACE_EVENTS_FAILURE = "ADD_RACE_EVENTS_FAILURE";

// ===================== Add SSOID ===========================
export const ADD_SSOID = "ADD_SSOID";
export const ADD_SSOID_SUCCESS = "ADD_SSOID_SUCCESS";
export const ADD_SSOID_FAILURE = "ADD_SSOID_FAILURE";

// ===================== Add BETXEXCHANGE SSOID ===========================
export const ADD_BETXEXCHANGE_SSOID = "ADD_BETXEXCHANGE_SSOID";
export const ADD_BETXEXCHANGE_SSOID_SUCCESS = "ADD_BETXEXCHANGE_SSOID_SUCCESS";
export const ADD_BETXEXCHANGE_SSOID_FAILURE = "ADD_BETXEXCHANGE_SSOID_FAILURE";

// ===================== Add PRIPORTY LINE MARKET ===========================
export const SET_PRIORITY_LINE_MARKET = "SET_PRIORITY_LINE_MARKET";
export const SET_PRIORITY_LINE_MARKET_SUCCESS =
  "SET_PRIORITY_LINE_MARKET_SUCCESS";
export const SET_PRIORITY_LINE_MARKET_FAILURE =
  "SET_PRIORITY_LINE_MARKET_FAILURE";

// ===================== GET PROPRITY DATA BASED ON EVENT ===========================
export const GET_PRIORITY_DATA_BASED_EVENT_MARKET =
  "GET_PRIORITY_DATA_BASED_EVENT_MARKET";
export const GET_PRIORITY_DATA_BASED_EVENT_MARKET_SUCCESS =
  "GET_PRIORITY_DATA_BASED_EVENT_MARKET_SUCCESS";
export const GET_PRIORITY_DATA_BASED_EVENT_MARKET_FAILURE =
  "GET_PRIORITY_DATA_BASED_EVENT_MARKET_FAILURE";

// ===================== SET COMMISSION VALUE ===========================
export const SET_COMMISSION_VALUE = "SET_COMMISSION_VALUE";
export const SET_COMMISSION_VALUE_SUCCESS = "SET_COMMISSION_VALUE_SUCCESS";
export const SET_COMMISSION_VALUE_FAILURE = "SET_COMMISSION_VALUE_FAILURE";

// ===================== SET COMMISSION VALUE ===========================
export const COMMISSION_CURRENT_VALUE = "COMMISSION_CURRENT_VALUE";

// ===================== EXPOSURE ===========================

export const LIVEEXPOSURE = "LIVEEXPOSURE";
export const LIVEEXPOSURE_SUCCESS = "LIVEEXPOSURE_SUCCESS";
export const LIVEEXPOSURE_FAIL = "LIVEEXPOSURE_FAIL";
// =====================UPDATE 2F AUTH (UPDATE)===========================

export const UPDATE_TWO_FACTOR_STATUS = "UPDATE_TWO_FACTOR_STATUS";
export const UPDATE_TWO_FACTOR_STATUS_SUCCESS =
  "UPDATE_TWO_FACTOR_STATUS_SUCCESS";
export const UPDATE_TWO_FACTOR_STATUS_FAILURE =
  "UPDATE_TWO_FACTOR_STATUS_FAILURE";

// =====================TWO FACTOR CODE VERIFICATION (VERIFY)===========================

export const VERIFY_TWO_FACTOR_CODE = "VERIFY_TWO_FACTOR_CODE";
export const VERIFY_TWO_FACTOR_CODE_SUCCESS = "VERIFY_TWO_FACTOR_CODE_SUCCESS";
export const VERIFY_TWO_FACTOR_CODE_FAILURE = "VERIFY_TWO_FACTOR_CODE_FAILURE";

// =====================GOOGLE LOGIN VALIDATION (VERIFY)===========================

export const GOOGLE_LOGIN_VALIDATION = "GOOGLE_LOGIN_VALIDATION";
export const GOOGLE_LOGIN_VALIDATION_SUCCESS =
  "GOOGLE_LOGIN_VALIDATION_SUCCESS";
export const GOOGLE_LOGIN_VALIDATION_FAILURE = "VERIFY_TWO_FACTOR_CODE_FAILURE";

// =====================GET FANCY STAKE LIMIT ===========================

export const GET_FANCY_STAKE_LIMIT = "GET_FANCY_STAKE_LIMIT";
export const GET_FANCY_STAKE_LIMIT_SUCCESS = "GET_FANCY_STAKE_LIMIT_SUCCESS";
export const GET_FANCY_STAKE_LIMIT_FAILURE = "GET_FANCY_STAKE_LIMIT_FAILURE";

// =====================POST FANCY STAKE LIMIT ===========================

export const UPDATE_FANCY_STAKE_LIMIT = "UPDATE_FANCY_STAKE_LIMIT";
export const UPDATE_FANCY_STAKE_LIMIT_SUCCESS =
  "UPDATE_FANCY_STAKE_LIMIT_SUCCESS";
export const UPDATE_FANCY_STAKE_LIMIT_FAILURE =
  "UPDATE_FANCY_STAKE_LIMIT_FAILURE";
// =====================GOOGLE LOGIN VALIDATION (VERIFY)===========================

export const GENERATE_QR_CODE = "GENERATE_QR_CODE";
export const GENERATE_QR_CODE_SUCCESS = "GENERATE_QR_CODE_SUCCESS";
export const GENERATE_QR_CODE_FAILURE = "GENERATE_QR_CODE_FAILURE";

// =====================GOOGLE LOGIN VALIDATION (VERIFY)===========================

export const GOOGLE_AUTHENTICATOR_OTP_VALIDATION =
  "GOOGLE_AUTHENTICATOR_OTP_VALIDATION";
export const GOOGLE_AUTHENTICATOR_OTP_VALIDATION_SUCCESS =
  "GOOGLE_AUTHENTICATOR_OTP_VALIDATION_SUCCESS";
export const GOOGLE_AUTHENTICATOR_OTP_VALIDATION_FAILURE =
  "GOOGLE_AUTHENTICATOR_OTP_VALIDATION_FAILURE";
// =====================GET LINE MARKETS===========================

export const GET_LINE_MARKETS = "GET_LINE_MARKETS";
export const GET_LINE_MARKETS_SUCCESS = "GET_LINE_MARKETS_SUCCESS";
export const GET_LINE_MARKETS_FAILURE = "GET_LINE_MARKETS_FAILURE";

// =====================ADD NEW MARKETS===========================

export const ADD_NEW_MARKET = "ADD_NEW_MARKET";
export const ADD_NEW_MARKET_SUCCESS = "ADD_NEW_MARKET_SUCCESS";
export const ADD_NEW_MARKET_FAILURE = "ADD_NEW_MARKET_FAILURE";

// =====================UPLOAD APK===========================

export const UPLOAD_APK = "UPLOAD_APK";
export const UPLOAD_APK_SUCCESS = "UPLOAD_APK_SUCCESS";
export const UPLOAD_APK_FAILURE = "UPLOAD_APK_FAILURE";

// =====================GET SPORTS ACCORDING===========================

export const GET_SPORTS_DATA_WITH_PRIORITY = "GET_SPORTS_DATA_WITH_PRIORITY";
export const GET_SPORTS_DATA_WITH_PRIORITY_SUCCESS =
  "GET_SPORTS_DATA_WITH_PRIORITY_SUCCESS";
export const GET_SPORTS_DATA_WITH_PRIORITY_FAILURE =
  "GET_SPORTS_DATA_WITH_PRIORITY_FAILURE";

// =====================SET SPORTS WITH PRIORITY ACCORDING===========================
export const SET_SPORTS_DATA_WITH_PRIORITY = "SET_SPORTS_DATA_WITH_PRIORITY";
export const SET_SPORTS_DATA_WITH_PRIORITY_SUCCESS =
  "SET_SPORTS_DATA_WITH_PRIORITY_SUCCESS";
export const SET_SPORTS_DATA_WITH_PRIORITY_FAILURE =
  "SET_SPORTS_DATA_WITH_PRIORITY_FAILURE";

// =====================GET LEAGUES ACCORDING===========================

export const GET_LEAGUE_DATA_WITH_PRIORITY = "GET_LEAGUE_DATA_WITH_PRIORITY";
export const GET_LEAGUE_DATA_WITH_PRIORITY_SUCCESS =
  "GET_LEAGUE_DATA_WITH_PRIORITY_SUCCESS";
export const GET_LEAGUE_DATA_WITH_PRIORITY_FAILURE =
  "GET_LEAGUE_DATA_WITH_PRIORITY_FAILURE";

// =====================SET SPORTS WITH PRIORITY ACCORDING===========================
export const SET_LEAGUE_DATA_WITH_PRIORITY = "SET_LEAGUE_DATA_WITH_PRIORITY";
export const SET_LEAGUE_DATA_WITH_PRIORITY_SUCCESS =
  "SET_LEAGUE_DATA_WITH_PRIORITY_SUCCESS";
export const SET_LEAGUE_DATA_WITH_PRIORITY_FAILURE =
  "SET_LEAGUE_DATA_WITH_PRIORITY_FAILURE";

// ===================== NOTIFICATION ===========================

export const POSTNOTIFICATION = "POSTNOTIFICATION";
export const POSTNOTIFICATIONSUCCESS = "POSTNOTIFICATIONSUCCESS";
export const POSTNOTIFICATIONFAILURE = "POSTNOTIFICATIONFAILURE";

// ===================== GET_ALL_USERS ===========================

export const GETALLUSERS = "GETALLUSERS";
export const GETALLUSERSSUCCESS = "GETALLUSERSSUCCESS";
export const GETALLUSERSFAILURE = "GETALLUSERSFAILURE";

// ==================== DELETE NOTIFICATION ===========================

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATIONSUCCESS = "DELETE_NOTIFICATIONSUCCESS";
export const DELETE_NOTIFICATION_FAILURE = "DELETE_NOTIFICATION_FAILURE";

// ===================== EDIT NOTIFICATION ===========================

export const EDITNOTIFICATION = "EDITNOTIFICATION";
export const EDITNOTIFICATIONSUCCESS = "EDITNOTIFICATIONSUCCESS";
export const EDITNOTIFICATIONFAILURE = "EDITNOTIFICATIONFAILURE";

// ===================== ERROR BETS ===========================

export const ERRORBETS = "ERRORBETS";
export const ERRORBETSSUCCESS = "ERRORBETSSUCCESS";
export const ERRORBETSFAILURE = "ERRORBETSFAILURE";

// ===================== CHANGE STATUS OF ERROR BETS ===========================

export const CHANGE_ERROR_BETS = "CHANGE_ERROR_BETS";
export const CHANGE_ERROR_BETS_SUCCESS = "CHANGE_ERROR_BETS_SUCCESS";
export const CHANGE_ERROR_BETS_FAILURE = "CHANGE_ERROR_BETS_FAILURE";

// ===================== RESTRICT CLIENT BETS ===========================

export const RESTRICT_LAYERS_BETS = "RESTRICT_LAYERS_BETS";
export const RESTRICT_LAYERS_BETS_SUCCESS = "RESTRICT_LAYERS_BETS_SUCCESS";
export const RESTRICT_LAYERS_BETS_FAILURE = "RESTRICT_LAYERS_BETS_FAILURE";
// ===================== GET BETFAIR ENGINE DATA ===========================

export const BETFAIR_ENGINE = "BETFAIR_ENGINE";
export const BETFAIR_ENGINE_SUCCESS = "BETFAIR_ENGINE_SUCCESS";
export const BETFAIR_ENGINE_FAILURE = "BETFAIR_ENGINE_FAILURE";

// =====================GET LEAGUE DATA===========================

export const GET_LEAGUE_EVENT_DATA = "GET_LEAGUE_EVENT_DATA";
export const GET_LEAGUE_EVENT_DATA_SUCCESS = "GET_LEAGUE_EVENT_DATA_SUCCESS";
export const GET_LEAGUE_EVENT_DATA_FAILURE = "GET_LEAGUE_EVENT_DATA_FAILURE";

// =====================DELETE LEAGUE OR EVENET OR MARKET===========================

export const DELETE_EVENT_DATA = "DELETE_EVENT_DATA";
export const DELETE_EVENT_DATA_SUCCESS = "DELETE_EVENT_DATA_SUCCESS";
export const DELETE_EVENT_DATA_FAILURE = "DELETE_EVENT_DATA_FAILURE";

// =====================SEARCH KEYS===========================

export const SEARCH_BASED_ON_EVENTS = "SEARCH_BASED_ON_EVENTS";
export const SEARCH_BASED_ON_EVENTS_SUCCESS = "SEARCH_BASED_ON_EVENTS_SUCCESS";
export const SEARCH_BASED_ON_EVENTS_FAILURE = "SEARCH_BASED_ON_EVENTS_FAILURE";

// =====================Layer currencyDetails ===========================

export const LAYERCURRENCY = "LAYERCURRENCY";
export const LAYERCURRENCY_SUCCESS = "LAYERCURRENCY_SUCCESS";
export const LAYERCURRENCY_FAILURE = "LAYERCURRENCY_FAILURE";

// =====================GET EVENT MARKET BASED REPORTS ===========================

export const GET_EVENT_BASED_BETS = "GET_EVENT_BASED_BETS";
export const GET_EVENT_BASED_BETS_SUCCESS = "GET_EVENT_BASED_BETS_SUCCESS";
export const GET_EVENT_BASED_BETS_FAILURE = "GET_EVENT_BASED_BETS_FAILURE";

// =====================GET BETS BY MARKEID ===========================

export const GET_BETS_BY_MARKET_ID = "GET_BETS_BY_MARKET_ID";
export const GET_BETS_BY_MARKET_ID_SUCCESS = "GET_BETS_BY_MARKET_ID_SUCCESS";
export const GET_BETS_BY_MARKET_ID_FAILURE = "GET_BETS_BY_MARKET_ID_FAILURE";

export const GET_MARKET_ANALYSIS_DATA = "GET_MARKET_ANALYSIS_DATA";
export const GET_MARKET_ANALYSIS_DATA_SUCCESS =
  "GET_MARKET_ANALYSIS_DATA_SUCCESS";
export const GET_MARKET_ANALYSIS_DATA_FAILURE =
  "GET_MARKET_ANALYSIS_DATA_FAILURE";

// =====================EVENT SEARCH ===========================
export const SEARCH_EVENT_DATA = "SEARCH_EVENT_DATA";
export const SEARCH_EVENT_DATA_SUCCESS = "SEARCH_EVENT_DATA_SUCCESS";
export const SEARCH_EVENT_DATA_FAILURE = "GET_EVENT_DATA_SEARCH_FAILURE";

// =====================CHANNEL CODE ===========================
export const UPDATE_CHANNEL_CODE = "UPDATE_CHANNEL_CODE";
export const UPDATE_CHANNEL_CODE_SUCCESS = "UPDATE_CHANNEL_CODE_SUCCESS";
export const UPDATE_CHANNEL_CODE_FAILURE = "UPDATE_CHANNEL_CODE_FAILURE";

// =====================DASHBOARD DETAILS ===========================

export const DASHBOARD_DETAILS = "DASHBOARD_DETAILS";
export const DASHBOARD_DETAILS_SUCCESS = "DASHBOARD_DETAILS_SUCCESS";
export const DASHBOARD_DETAILS_FAILURE = "DASHBOARD_DETAILS_FAILURE";

// =====================ADD ALL HORSE RACE MATCH AT ONCE ==============

export const ADD_HORSE_RACE_MATCH_ALL = "ADD_HORSE_RACE_MATCH_ALL";
export const ADD_HORSE_RACE_MATCH_ALL_SUCCESS = "ADD_HORSE_RACE_MATCH_ALL_SUCCESS";
export const ADD_HORSE_RACE_MATCH_ALL_FAILURE = "ADD_HORSE_RACE_MATCH_ALL_FAILURE";

// =====================GET BETS OF RUNNING MARKET=======================

export const GET_BETS_OF_RUNNING_MARKET = "GET_BETS_OF_RUNNING_MARKET";
export const GET_BETS_OF_RUNNING_MARKET_SUCCESS = "GET_BETS_OF_RUNNING_MARKET_SUCCESS";
export const GET_BETS_OF_RUNNING_MARKET_FAILURE = "GET_BETS_OF_RUNNING_MARKET_FAILURE";

// =====================GET VIEW BETS OF RUNNING MARKET=======================

export const GET_VIEW_BETS_OF_RUNNING_MARKET = "GET_VIEW_BETS_OF_RUNNING_MARKET";
export const GET_VIEW_BETS_OF_RUNNING_MARKET_SUCCESS = "GET_VIEW_BETS_OF_RUNNING_MARKET_SUCCESS";
export const GET_VIEW_BETS_OF_RUNNING_MARKET_FAILURE = "GET_VIEW_BETS_OF_RUNNING_MARKET_FAILURE";
// =====================UPDATE CLIENT SHARES ==============

export const UPDATE_CLIENT_SHARES = "ADD_HORSE_RACE_MATCH_ALL";
export const UPDATE_CLIENT_SHARES_SUCCESS = "UPDATE_CLIENT_SHARES_SUCCESS";
export const UPDATE_CLIENT_SHARES_FAILURE = "UPDATE_CLIENT_SHARES_FAILURE";


// =====================GET STX LEAGUES ==============

export const GET_STX_DATAS = "GET_STX_DATAS";
export const GET_STX_DATAS_SUCCESS = "GET_STX_DATAS_SUCCESS";
export const GET_STX_DATAS_FAILURE = "UPDATE_CLIENT_SHARES_FAILURE";


// ===================== STX MARKET ASSINGING ==============

export const POST_STX_MARKET_ASSIGNING = "POST_STX_MARKET_ASSIGNING";
export const POST_STX_MARKET_ASSIGNING_SUCCESS = "POST_STX_MARKET_ASSIGNING_SUCCESS";
export const POST_STX_MARKET_ASSIGNING_FAILURE = "POST_STX_MARKET_ASSIGNING_FAILURE";


// ===================== STX ACCOUNT DETAILS ==============

export const STX_ACCOUNT_DETAILS = "STX_ACCOUNT_DETAILS";
export const STX_ACCOUNT_DETAILS_SOCKET_DATA = "STX_ACCOUNT_DETAILS_SOCKET_DATA";
export const STX_ACCOUNT_DETAILS__SUCCESS = "STX_ACCOUNT_DETAILS__SUCCESS";
export const STX_ACCOUNT_DETAILS__FAILURE = "STX_ACCOUNT_DETAILS__FAILURE";